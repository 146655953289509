import { IPlayerProvider } from './videoPlayerProvider';
import { AudioPlayerManager } from '../../audioPlayer/audioPlayerManager';

export class AudioPlayerProvider implements IPlayerProvider {

  public async play(time?: number) {
    await AudioPlayerManager.getInstance().play(time)
  }

  public async seek(time?: number) {
    await AudioPlayerManager.getInstance().seek(time);
  }

  public async getCurrentTime(): Promise<number> {
    return AudioPlayerManager.getInstance().getCurrentTime();
  }

  public async pause() {
    await AudioPlayerManager.getInstance().pause();
  }
}