import { CONTENT_LIB_USER_VIDEOS_SEPARATOR, contentLibVideosLoadPartSize, IContentLibVideoListProps } from './types';
import React from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { ContentLibGroupInfoHOC } from '../../ContentLibGroupInfo/hocs/ContentLibGroupInfoHOC';
import { Loader } from '../../../../../../common/components/Loader/Loader';
import { IContentLibVideo } from '../../../../../store/contentLib/types';
import { ContentLibVideo } from '../../ContentLibVideo/components/ContentLibVideo';
import { YoutubeUrlUtil } from '../../../../../../common/utils/youtube-url-util';
import { Text } from '../../../../../../common/components/Text/Text';
import { LibraryIcon } from '../../../../../../../icons/LibraryIcon';

export function ContentLibVideoList({
  groupId,
  searchText,
  targetLangCode,
  targetLangId,
  nativeLangCode,
  userVideosLine,
  lockedMode,
  onLoadVideos,
  onVideoClick,
  onClickMyStuff,
}: IContentLibVideoListProps) {
  const [videoList, setVideoList] = React.useState<IContentLibVideo[]>([]);
  const [currentStartIndex, setCurrentStartIndex] = React.useState<number>(0);
  const [allListLoaded, setAllListLoaded] = React.useState<boolean>(false);
  const [_userVideos, setUserVideos] = React.useState<string[]>([]);

  const currentStartIndexRef = React.useRef<number>(0);
  currentStartIndexRef.current = currentStartIndex;

  const allListLoadedRef = React.useRef<boolean>(false);
  allListLoadedRef.current = allListLoaded;

  const videoListRef = React.useRef<IContentLibVideo[]>([]);
  videoListRef.current = videoList;

  const userVideosRef = React.useRef<string[]>([]);
  userVideosRef.current = _userVideos;

  const targetLangIdRef = React.useRef<number>();
  targetLangIdRef.current = targetLangId;


  const onListScrollToBottom = async () => {
    if (allListLoadedRef.current || videoListRef.current?.length <= 0) return;
    setCurrentStartIndex(currentStartIndexRef.current + contentLibVideosLoadPartSize);
  }

  React.useEffect(() => {
    setCurrentStartIndex(0);
    setVideoList([]);
    setAllListLoaded(false);
  }, [groupId, searchText, targetLangCode, nativeLangCode]);

  React.useEffect(() => {
    onLoadVideos(currentStartIndexRef.current, contentLibVideosLoadPartSize).then(list => {
      if (list?.length <= 0) {
        setAllListLoaded(true);
      } else {
        if (list?.length < contentLibVideosLoadPartSize) {
          setAllListLoaded(true);
        }

        const addList = list.reduce((arr: IContentLibVideo[], v) => {
          if (v.langId === targetLangIdRef.current) {
            const videoKey = YoutubeUrlUtil.getVideoId(v.url);
            if (userVideosRef.current.includes(videoKey)) {
              v.isSaved = true;
            }
            arr.push(v);
          }
          return arr;
        }, []);
        setVideoList([
          ...videoListRef.current,
          ...addList
        ]);
      }
    })
  }, [groupId, searchText, targetLangCode, nativeLangCode, currentStartIndexRef.current])


  React.useEffect(() => {
    const userVideos = userVideosLine?.split(CONTENT_LIB_USER_VIDEOS_SEPARATOR) || [];
    if (userVideos.length) {
      setVideoList([
        ...videoList.map(v => {
          const videoKey = YoutubeUrlUtil.getVideoId(v.url);
          if (userVideos.includes(videoKey)) {
            v.isSaved = true;
          }
          return v;
        })]);
    }
    setUserVideos(userVideos);
  }, [userVideosLine]);


  const handleVideoClick = (id: number): Promise<any>  => {
    const video = videoList.find(v => v.id === id);
    if (video) {
      return onVideoClick(video);
    } else {
      return Promise.resolve();
    }
  }

  const progress = <Box>
    <Stack
      justifyContent={'center'}
      sx={{
        textAlign: 'center',
        aspectRatio: 16/9,
      }}
    >
      <Loader
        onShow={onListScrollToBottom}
      >
        <CircularProgress
          size={theme.spacing(3)}
          sx={{
            alignSelf: 'center' }}
          />
      </Loader>
    </Stack>
  </Box>

  return (
    <Stack
      gap={2}
      sx={{
        py: 3,

        '& + &': {
          borderTop: `2px solid ${theme.palette.grey[200]}`
        },
      }}
    >
      {!!groupId &&
      <ContentLibGroupInfoHOC
        groupId={groupId}
      />
      }

    <Stack
        className={`${!!groupId ? 'stack' : 'grid'}`}
        gap={2}
				sx={{
          '&.grid': {				
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: `repeat(5, 1fr)`,
            gap: 2,

            '@media (min-width: 1600px)': {
              gridTemplateColumns: `repeat(7, 1fr)`,
            },

            '@media (max-width: 1000px)': {
              gridTemplateColumns: `repeat(3, 1fr)`,
            },

            '@media (max-width: 600px)': {
              gridTemplateColumns: `repeat(2, 1fr)`,
            },
					},
				}}
			>
        {videoList.map((video) => {
          return (
            <ContentLibVideo
              isBig={!!groupId}
              key={video.id}
              title={video.title}
              thumbUrl={video.youtubeThumbMediumUrl || video.youtubeThumbUrl}
              channel={video.youtubeChannel}
              disableClick={lockedMode}
              isSaved={video.isSaved}
              onClick={() => handleVideoClick(video.id)}
            />

          )
        })}

        {!videoList.length
          ? <Stack
            gap={3}
            sx={{
              gridColumn: '1 / -1',
              justifyContent: 'center',
              alignItems: 'center',
              p: theme.spacing(2),
              height: 1,
              minHeight: '50vh',
            }}
          >
            {!allListLoaded
              ? progress
              : <>
                <Text
                  sx={{
                    fontSize: theme.typography.h5,
                    color: theme.palette.grey[500],
                  }}
                >
                  There are no videos for selected language
                </Text>

                <Text
                  sx={{
                    fontSize: theme.typography.subtitle2,
                    fontWeight: 300,
                  }}
                >
                  Go to
                  {' '}
                  <Button
                    variant={'contained'}
                    sx={{
                      px: theme.spacing(.5),
                      color: theme.palette.grey[900],
                      background: 'transparent',

                      '&:hover': {
                        background: theme.palette.grey[50],
                      }
                    }}
                    onClick={onClickMyStuff}
                  >
                    <LibraryIcon
                      sx={{
                        mr: theme.spacing(1),
                        color: theme.palette.primary.main,
                      }}
                    />
                    My Stuff
                  </Button>
                  {' '}
                  section and add content from there...
                </Text>
              </>
            }
          </Stack>
          : !allListLoaded && progress
        }

      </Stack>
    </Stack>
  )
};
