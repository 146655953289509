import React from 'react';
import { Box, IconButton, SxProps } from '@mui/material';
import { ITextWithSpeakerProps } from './types';
import { theme } from '../../../styles/theme';
import { SpeakerIcon } from '../../../../../icons/SpeakerIcon';
import { Text } from '../../Text/Text';
import { StopIcon } from "../../../../../icons/StopIcon";

export function TextWithSpeaker ({
	boxSx,
	children,
	textSx,
	text,
	onSpeak,
	onStopSpeak,
}: ITextWithSpeakerProps) {

	const [playMode, setPlayMode] = React.useState<boolean>(false);

	const boxStyle: SxProps = {
		position: 'relative',

		'.speaker': {
			opacity: 0,
			transition: 'opacity .3s ease',
		},

		'&:hover': {
			'.speaker': {
				opacity: 1,
			}
		},

		'& > span': {
			p: '4px 0',
			fontSize: theme.typography.subtitle2,
			lineHeight: theme.spacing(3.25),
		},
		'.context': {
			backgroundColor: theme.palette.info[500],
		},
		'.phrase': {
			bgcolor: theme.palette.info[100],
		},
	};

	const speakerStyle = {
		position: 'sticky',
		top: 0,
		transform: `translateX(${theme.spacing(-2.5)})`,
		width: theme.spacing(2),
		height: theme.spacing(2),
		color: theme.palette.grey[400],
	}

	const textStyle: SxProps = {
		mt: theme.spacing(-2.4),
		cursor: 'pointer',
		fontSize: theme.typography.subtitle2.fontSize,
		wordWrap: 'break-word',
	};

	const onBlur = (e: any) => {
		if (onStopSpeak) {
			const target = e.relatedTarget as HTMLElement;
			if (target && (
				target.classList && target.classList.contains('.text-with-speaker') ||
				target && target.closest('.text-with-speaker')
			)) {
				// not blur
			} else {
				onStopSpeak();
			}
		}
	}

	const handleMouseUp = (e: React.SyntheticEvent) => {
		const target = e.target as HTMLElement;
		if (target.closest(`.class-controll`)) return;

		if (playMode) {
			onStopSpeak();
		} else {
			setPlayMode(true);
			onSpeak(() => {
				setPlayMode(false);
			})
		}
	}

	const stopIconStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: theme.spacing(2),
		height: theme.spacing(2),
		color: `${theme.palette.common.white}`,
		background: theme.palette.grey[400],
		borderRadius: '50%',
		cursor: 'pointer',

		'svg': {
			width: theme.spacing(.75),
			height: theme.spacing(.75),
		},
	}

	const textHtml = children ? {} : {
		dangerouslySetInnerHTML: { __html: text }
	};

	return (
		<Box
			className={'text-with-speaker'}
			sx={{
				...boxStyle,
				...boxSx,
			}}
			tabIndex={0}
			onBlur={onBlur}
			onMouseUp={handleMouseUp}
		>
			<IconButton
				className={'speaker'}
				sx={speakerStyle}
			>
				{playMode
					? <Box sx={stopIconStyle}>
						<StopIcon />
					</Box>
					: <SpeakerIcon
						sx={{
							width: theme.spacing(2),
							height: theme.spacing(2),
						}}
					/>
				}
			</IconButton>
			<Text
				sx={{
					...textStyle,
					...textSx,
				}}
				{...textHtml}
			>
				{children}
			</Text>
		</Box>
	);

}
