import { AppThunk } from '../../../../store/types';
import { setCaptionsFixAiInfoAction } from '../../../../store/general/actions';
import { VideoCaptionsPunctuationCheck } from '../../../../effects/videoCaptionsPunctuationCheck';
import { getTargetCaptions } from '../../../../store/videos/selectors';
import {
  TCaptionsFixAiPopupOnErrror,
  TCaptionsFixAiPopupOnProgress,
  TCaptionsFixAiPopupOnSuccess
} from '../components/types';
import { ICaptionsItem } from '../../../../types/common';
import { VideoLangRest } from '../../../../../common/rest/videoLang/videoLangRest';
import { getCaptionsFixAiInfo } from '../../../../store/general/selectors';
import { getUserGroupById, getUserGroupLangToLearn } from '../../../../store/models/selectors';
import { Navigator } from '../../../../effects/navigator';
import { updateVideoCaptionsFixStatus } from '../../../../effects/video_effects';
import { EVideoLangCaptionsFixStatus } from '../../../../store/current-video/types';

export class CaptionsFixAiPopupEvents {

  private static checkInstance: VideoCaptionsPunctuationCheck

  public static onStart(
    onProgress: TCaptionsFixAiPopupOnProgress,
    onSuccess: TCaptionsFixAiPopupOnSuccess,
    onError: TCaptionsFixAiPopupOnErrror
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      onProgress(0, 100);
      const captions = getTargetCaptions(getState());
      try {
        this.checkInstance = new VideoCaptionsPunctuationCheck(captions);

        const resultCaptions: ICaptionsItem[] = await this.checkInstance.check(onProgress);

        const data = getCaptionsFixAiInfo(getState());
        const group = getUserGroupById(getState(), data.groupId);
        const langCode = getUserGroupLangToLearn(getState(), group)?.code;
        await VideoLangRest.saveVideoCaptions(data.videoId, langCode, resultCaptions);
        await updateVideoCaptionsFixStatus(data.videoId, langCode, EVideoLangCaptionsFixStatus.FIXED);
        await onSuccess();
        dispatch(setCaptionsFixAiInfoAction(null));
        Navigator.openVideo(data.groupId, data.videoId)
      } catch(e) {
        onError(e);
      }
    }
  }

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (this.checkInstance) {
        this.checkInstance.interrupt();
      }
      dispatch(setCaptionsFixAiInfoAction(null));
    }
  }
}