import { IEvent, IEventData, IEventsProvider } from './types';
import { GoogleAnalyticsEventsProvider } from './providers/googleAnalyticsEventsProvider';
import { isEventsEnabled, isProduction } from '../constants';
import { getAuthUser, isAuthorised } from '../../spa/store/general/selectors';
import { getState } from '../../spa/store';
import { TAuthUser } from '../../spa/store/general/types';
import { AmplitudeEventsProvider } from './providers/amplitudeEventsProvider';
import { FbqEventsProvider } from './providers/fbqEventsProvider';

export class EventsRouter {

  private static providers: IEventsProvider[] = [
    new GoogleAnalyticsEventsProvider(),
    new AmplitudeEventsProvider(),
    new FbqEventsProvider()
  ]

  public static setUser(user: TAuthUser): void {
    if (isEventsEnabled() && isAuthorised(getState())) {
      EventsRouter.providers.forEach(provider => {
        provider.setUser(user);
      })
    }
  }

  public static trackEvent(event: IEvent, date?: Date, data?: IEventData): void {
    if (isEventsEnabled()) {
      let eventData = data || {userId: ''};
      if (!eventData.userId) {
        const user = getAuthUser(getState());
        if (user && isAuthorised(getState())) {
          eventData.userId = user.hashId;
        }
      }
      EventsRouter.providers.forEach(provider => {
        provider.trackEvent(event, date || new Date(), eventData);
      })
    }
  }
}
