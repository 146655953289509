import { AppThunk } from '../../../../store/types';
import { AudioPlayerManager } from '../../../../effects/audioPlayer/audioPlayerManager';
import { AudioEffects } from '../../../../effects/audioEffects';
import { getUserStateStorage } from '../../../../../common/utils/local-storage/user-state';
import { getLibraryGroupId } from '../../../../store/general/selectors';
import { UserGroupEffects } from '../../../../effects/userGroupEffects';

export class AudioEvents {

  public static onLoad(
    audioId: number,
    groupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      await AudioEffects.load(audioId, groupId);
      getUserStateStorage().setAudioInfo(audioId, groupId);
    }
  }


  public static onUpdateAudioPlayer(
    player: HTMLAudioElement
  ): AppThunk {
    return (
      dispatch
    ) => {
      AudioPlayerManager.getInstance().setPlayer(player);
    }
  }

  public static onUpdateGroupId(
    groupId: number
  ): AppThunk {
    return (
      dispatch,
      getState,
    ) => {
      setTimeout(() => {
        const libraryGroupId = getLibraryGroupId(getState());
        if (libraryGroupId !== groupId) { // browser back
          UserGroupEffects.setLibraryGroupId(groupId);
        }
      }, 100);
    }
  }


}