import { ContentLibRest, TContentLibVideosSearchRequest } from '../../common/rest/contentLib/contentLibRest';
import { ContentLibActions } from '../store/contentLib/actions';
import { getDispatch, getState } from '../store';
import { ContentLibSelectors } from '../store/contentLib/selectors';
import {
  createGroupNameIndex,
  findLanguageById,
  getUserGroupById,
  getUserGroupByVideoId,
  getVideoById
} from '../store/models/selectors';
import { UserGroupEffects } from './userGroupEffects';
import { fetchUserGroup } from '../../common/rest/userGroup/fetchUserGroup';
import { addGroup, addVideoInGroup } from '../store/models/actions';
import { updateUserGroups } from './updateUserGroups';
import { TUserGroup } from '../store/models/types';
import { EContentLibViewMode, IContentLibVideo } from '../store/contentLib/types';
import { UrlUtil } from '../../common/utils/url-util';
import { YoutubeUrlUtil } from '../../common/utils/youtube-url-util';
import { updateUserGroup } from '../../common/rest/userGroup/updateUserGroup';

export class ContentLibEffects {


  public static async searchGroups() {
    const dispatch = getDispatch();
    const state = getState();
    const {targetLangCode, nativeLangCode, text} = ContentLibSelectors.getSearchFilter(state);
    dispatch(ContentLibActions.setGroupsSearchResult({ loading: true }));
    try {
      const groups = await ContentLibRest.searchGroups({
        targetLangCode,
        nativeLangCode,
        searchText: text,
        maxVideoCount: 100
      });
      dispatch(ContentLibActions.setGroupsSearchResult({ groups, loading: false }));
    } catch(e) {
      console.error(e)
      dispatch(ContentLibActions.setGroupsSearchResult({ groups: [], loading: false }));
    }
  }

  public static async searchVideos(filter: TContentLibVideosSearchRequest): Promise<IContentLibVideo[]> {
    return ContentLibRest.searchVideos(filter);
  }

  public static async copyVideo(contentLibGroupId: number, contentLibVideoId: number, targetGroupId: number) {
    const state = getState();
    const dispatch = getDispatch();
    const contentLibGroup = ContentLibSelectors.findGroupById(state, contentLibGroupId);
    const targetGroup = getUserGroupById(state, targetGroupId);
    if (!targetGroup) {
      const targetLangCode = findLanguageById(state, contentLibGroup?.targetLangId || 0)?.code || '';
      const nativeLangCode = findLanguageById(state, contentLibGroup?.nativeLangId || 0)?.code || '';
      let groupName = createGroupNameIndex(state, contentLibGroup?.title || '');
      targetGroupId = await UserGroupEffects.createGroup(groupName, targetLangCode, nativeLangCode);
      const createdGroup = await dispatch(fetchUserGroup(targetGroupId));
      dispatch(addGroup(createdGroup));
    }
    const video = await ContentLibRest.copyVideo(contentLibVideoId, targetGroupId) as any;
    dispatch(addVideoInGroup(video, targetGroupId));
    return {videoId: video.videoKey, groupId: targetGroupId}
  }

  public static async previewVideo(contentLibGroupId: number, contentLibVideoId: number, videoUrl: string) {
    const state = getState();
    const dispatch = getDispatch();
    const videoKey = YoutubeUrlUtil.getVideoId(videoUrl);
    if (videoKey) {
      const video = getVideoById(state, videoKey);
      if (video) {
        const group = getUserGroupByVideoId(state, videoKey);
        return {videoId: video.videoKey, groupId: group?.id};
      }
    }

    const contentLibGroup = ContentLibSelectors.findGroupById(state, contentLibGroupId);
    console.log('contentLibGroup', contentLibGroup)
    const targetLangCode = findLanguageById(state, contentLibGroup?.targetLangId || 0)?.code || '';
    const {video, groupId} = await ContentLibRest.previewVideo(contentLibVideoId, targetLangCode);
    const group = getUserGroupById(state, groupId);
    if (!group) {
      await dispatch(updateUserGroups(true));
    }
    dispatch(addVideoInGroup(video, groupId));
    return {videoId: video.videoKey, groupId}
  }

  public static async copyGroup(contentLibGroupId: number, updateGroups: boolean = true): Promise<TUserGroup> {
    const dispatch = getDispatch();
    const group: TUserGroup = await ContentLibRest.copyGroup(contentLibGroupId);
    if (updateGroups) {
      await dispatch(updateUserGroups(true));
    }
    return group;
  }

  public static async loadLangs() {
    const dispatch = getDispatch();
    const langs = await ContentLibRest.getLangList();
    dispatch(ContentLibActions.setLangs(langs));
  }
}