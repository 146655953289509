import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IMyLibAddButtonsEvents, IMyLibAddButtonsFields } from '../types';
import { MyLibAddButtonsEvents } from './events';
import { MyLibAddButtons } from '../MyLibAddButtons';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { getAuthUserLangCode } from '../../../../../store/general/selectors';

const mapStateToProps = (
) => {
	return (
		state: IState
	): IMyLibAddButtonsFields => {
		const videos = ContentLibSelectors.getVideosSearchResult(state);
		let langCode = state.contentLib.searchFilter.targetLangCode || getAuthUserLangCode(state);

		return {
			isLib: !!videos && !!videos.length,
			langCode,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IMyLibAddButtonsEvents => ({
	onAddVideoLib: () => dispatch(MyLibAddButtonsEvents.onAddVideoLib()),
	onAddVideoYT: () => dispatch(MyLibAddButtonsEvents.onAddVideoYT()),
	onAddText: () => dispatch(MyLibAddButtonsEvents.onAddText()),
	onLoad: () => dispatch(MyLibAddButtonsEvents.onLoad()),
});

export const MyLibAddButtonsHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(MyLibAddButtons);

MyLibAddButtonsHOC.displayName = 'MyLibAddButtonsHOC';
