import { connect } from 'react-redux';
import { IStoryFromTranslatedButtonsEvents } from '../../StoryFromTranslatedButtons/types';
import { StoryFromTranslatedButtonsEvents } from './events';
import { StoryFromTranslatedButtons } from '../../StoryFromTranslatedButtons/StoryFromTranslatedButtons';

const mapDispatchToProps = (
	dispatch: any
): IStoryFromTranslatedButtonsEvents => {
	return {
		onGenerateAudio: () => dispatch(StoryFromTranslatedButtonsEvents.onGenerateAudio()),
		onClose: () => dispatch(StoryFromTranslatedButtonsEvents.onClose()),
	}
};

export const StoryFromTranslatedButtonsHOC = connect(
	null,
	mapDispatchToProps
)(StoryFromTranslatedButtons);

StoryFromTranslatedButtonsHOC.displayName = 'StoryFromTranslatedButtonsHOC';
