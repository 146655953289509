import React from 'react';
import { IMenuWidgetsProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { alpha, Box, IconButton, Stack, SxProps, Tooltip } from '@mui/material';
import { ButtonHelp } from './ButtonHelp';
import { DailyGoalHOC } from '../../ToolbarPanel/DailyGoal/hocs/DailyGoalHOC';
import { MenuButton } from '../../ToolbarPanel/Menu/components/MenuButton/MenuButton';
import { OneTimeTooltipTutorialHOC } from '../../OneTimeTooltip/hocs/OneTimeTooltipTutorialHOC/OneTimeTooltipTutorialHOC';
import { PlaylistHOC } from '../../ToolbarPanel/Playlist/hocs/PlaylistHOC';
import { BookIcon } from '../../../../../../icons/BookIcon';
import { LibraryIcon } from '../../../../../../icons/LibraryIcon';
import { RecentIcon } from '../../../../../../icons/RecentIcon';

export const MenuWidgets: React.FC<IMenuWidgetsProps> = ({
	isEmpty,
	isTextSelectionPopup,
	onClickContent,
	onClickMyLibrary,
	onClickRecent,
	onShowHelp,
}) => {
	if (isEmpty) return null;

	const buttonSx: SxProps = {
		width: theme.spacing(4),
		height: theme.spacing(4),
		color: theme.palette.grey[400],
		borderRadius: '50%',
		transition: 'all .3s ease',

		'&:hover': {
			background: alpha(theme.palette.common.white, .75),
		},

		'.MuiSvgIcon-root': {
			width: theme.spacing(2.5),
			height: theme.spacing(2.5),
		},
	};

	return (
		<Stack
			gap={theme.spacing(1)}
			className={`menu-vidgets
				${isTextSelectionPopup ? 'hide' : ''}
			`}
			sx={{
				position: 'fixed',
				zIndex: 500,
				left: theme.spacing(1),
				top: theme.spacing(2),

				'&.hide': {
					opacity: .2,
					pointerEvents: 'none',
					transition: 'opacity .3s ease',
				},
			}}
		>
			<Box
				sx={{
					mb: theme.spacing(1),
					'.menu__button': buttonSx,

					'.PrivateSwipeArea-root': {
						width: theme.spacing(.5) + ' !important',
					},
				}}
			>
				<MenuButton isLogo />
			</Box>

			<Tooltip
				placement={'left'}
				title={`Content Library`}
			>
				<IconButton
					sx={{
						...buttonSx
					}}
					onClick={onClickContent}
				>
					<BookIcon
						sx={{
							color: theme.palette.secondary[200],
						}}
					/>
				</IconButton>
			</Tooltip>

			<Stack
				sx={{
					position: 'relative',

					'&:before': {
						content: '""',
						position: 'absolute',
						zIndex: -1,
						borderRadius: theme.spacing(3),
						left: theme.spacing(-.5),
						right: theme.spacing(-.5),
						top: theme.spacing(-.5),
						bottom: theme.spacing(-.5),
						background: alpha(theme.palette.common.white, .75),
						pointerEvents: 'none',
					},
				}}
			>
				<Tooltip
					placement={'left'}
					title={`My Stuff`}
				>
					<IconButton
						sx={{
							...buttonSx
						}}
						onClick={onClickMyLibrary}
					>
						<LibraryIcon
							sx={{
								width: `${theme.spacing(2.75)} !important`,
								height: `${theme.spacing(2.75)} !important`,
								color: theme.palette.primary.main,
							}}
						/>
					</IconButton>
				</Tooltip>
				
				<PlaylistHOC isSmall />
			</Stack>

			<Tooltip
				placement={'left'}
				title={`Recent`}
			>
				<IconButton
					sx={{
						...buttonSx
					}}
					onClick={onClickRecent}
				>
					<RecentIcon
						sx={{
							width: `${theme.spacing(3.2)} !important`,
							height: `${theme.spacing(3.2)} !important`,
							color: theme.palette.secondary[300],
						}}
					/>
				</IconButton>
			</Tooltip>

			<ButtonHelp onClick={onShowHelp} />
			<OneTimeTooltipTutorialHOC />
			<DailyGoalHOC isSmall />

		</Stack>
	);
}
