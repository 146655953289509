import { AppThunk } from '../../../../../store/types';
import { PhraseContextEditorActions } from '../../../../../store/phrase-context-editor/actions';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { findVideoPhraseByWordId } from '../../../../../store/videos/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { PhraseContextEditorEffects } from '../../../PhraseContextEditor/effects/phraseContextEditorEffects';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';

export class PhraseTextEvents {

	public static onContextModify(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(PhraseContextEditorEffects.open());
		}
	}

	public static onContextDelete(
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const phraseId = PhraseDetailsSelectors.getPhraseId(getState());
			let phrase;
			if (phraseId) {
				phrase = findVideoPhraseByWordId(getState(), phraseId);
			}
			if (phrase && phrase.id) {
				PhraseEffects.deleteContext(phrase.id);
			} 
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
				isContext: false,
			}));
		}
	}

	public static onContextTranslate(
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const isTranslateContext = PhraseContextEditorSelectors.getIsTranslateContext(getState());
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
				isTranslateContext: !isTranslateContext,
			}));
		}
	}

	public static onEdit(text: string): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			if (text) {
				text = text.replaceAll(/\n/gm, ' ');
			}

			dispatch(PhraseDetailsActions.setTranslateResult({
				edit: {
					enabled: !!text,
					text
				}
			}));
		}
	}



}