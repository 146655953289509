import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { IStoryFromTranslatedEvents, IStoryFromTranslatedFields } from '../components/types';
import { StoryFromTranslatedEvents } from './events';
import { EVocabularyPhraseSaveType, EVocabularyPhraseType } from '../../../../types/common';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { StoryFromTranslated } from '../components/StoryFromTranslated';
import { TextSelectors } from '../../../../store/text/selectors';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IStoryFromTranslatedFields => {
		const phraseList = PhraseListSelectors.findPhrasesByTypes(state, [
			EVocabularyPhraseType.DEFAULT,
			EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
		])
			.reduce((arr: string[], phrase) => {
				if (phrase.saveType === EVocabularyPhraseSaveType.TRANSLATE &&
					phrase.highlighted?.trim()?.length
				) {
					arr.push(phrase.highlighted);
				}
				return arr;
			}, []);

		const texts = TextSelectors.getTextsByVideo(state, getCurrentMovieKey(state));
		let stories;
		if (texts) {
			stories = Object.keys(texts).map(key => {
				return {
					key,
					title: texts[key].title,
				}
			});
		}

		return {
			activeId: TextSelectors.getTextActiveId(state),
			phraseList,
			stories,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IStoryFromTranslatedEvents => {
	return {
		onGenerate: (phraseList) => dispatch(StoryFromTranslatedEvents.onGenerate(phraseList)),
		onOpen: (key) => dispatch(StoryFromTranslatedEvents.onOpen(key)),
		onDelete: (key) => dispatch(StoryFromTranslatedEvents.onDelete(key)),
	}
};

export const StoryFromTranslatedHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(StoryFromTranslated);

StoryFromTranslatedHOC.displayName = 'StoryFromTranslatedHOC';
