import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseNotes } from '../components/PhraseNotes';
import { IPhraseNotesEvents, IPhraseNotesFields } from '../components/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseNotesEvents } from './events';
import { getPlayerState } from '../../../../../store/general/selectors';
import { PlayerStates } from '../../../../../types/common';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { getCurrentVideoClickedCaptionIndex } from '../../../../../store/current-video/selectors';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { EPlayerControllerMode, PlayerController } from '../../../../../effects/player/manager/playerController';
import { findVideoPhraseById, getVideoPhraseNoteByPhraseId } from '../../../../../store/videos/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseNotesFields => {
    const activeTab = PhraseDetailsSelectors.getActiveTab(state);
    const active = !!PhraseListSelectors.getNoteId(state);
    const playVideo = getPlayerState(state) === PlayerStates.PLAYING;
    let phraseId = 0;
    let canEdit;
    let selectResult;
    let clickedCaptionIndex;
    let note;

    const playMode = PlayerController.getInstance().getMode();
    if (playMode === EPlayerControllerMode.PLAY_PHRASE_LIST || playMode === EPlayerControllerMode.PLAY_PHRASE) {
      phraseId = PhraseListSelectors.getCurrentPhraseId(state);
      canEdit = false;
      note = getVideoPhraseNoteByPhraseId(state, phraseId);
      if (!note) {
        const phrase = findVideoPhraseById(state, phraseId);
        if (phrase?.wordPhraseId) {
          note = getVideoPhraseNoteByPhraseId(state, phrase.wordPhraseId)
        }
      }
    } else {
      if (playVideo) {
        phraseId = PhraseDetailsSelectors.getNotePhraseId(state) || 0;
        canEdit = false;
      } else {
        selectResult = CaptionsSelectionPopupSelectors.getSelectResult(state);
        phraseId = PhraseDetailsSelectors.getPhraseId(state) || 0;
        clickedCaptionIndex = getCurrentVideoClickedCaptionIndex(state);
        canEdit = !!(selectResult || phraseId || clickedCaptionIndex >= 0);
      }
      note = PhraseDetailsSelectors.getCurrentNote(state);
    }

    //const note = PhraseDetailsSelectors.getCurrentNote(state);

    const pause = note?.pause || false;
    const processing = PhraseDetailsSelectors.getNoteResultProcessing(state) || false;
    const text = note?.text || '';
    const isEdit = activeTab?.isNoteEdit;
    const authorId = note?.authorInfo?.id || 0;
    const authorName = note?.authorInfo?.name || '';
    const isPopup = PhraseListSelectors.getIsNotePopup(state);

    return {
      active,
      phraseId,
      selectResult,
      clickedCaptionIndex,
      text,
      processing,
      canEdit,
      pause,
      isEdit,
      authorId,
      authorName,
      isPopup,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseNotesEvents => ({
    onSave: (note) => dispatch(PhraseNotesEvents.onSave(note)),
    onUpdate: () => dispatch(PhraseNotesEvents.onUpdate()),
    onClickAuthor: () => dispatch(PhraseNotesEvents.onClickAuthor()),
    onLoadImage: data => dispatch(PhraseNotesEvents.onLoadImage(data)),
    onBack: () => dispatch(PhraseNotesEvents.onBack()),
  }
);

export const PhraseNotesHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseNotes);

PhraseNotesHOC.displayName = 'PhraseNotesHOC';
