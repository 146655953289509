import React from 'react';
import { IPhrasePromptHeaderProps } from './types';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { ChatGptLightIcon } from '../../../../../../../icons/ChatGptLightIcon';
import { Stack } from '@mui/material';

export const PhrasePromptHeader: React.FC<IPhrasePromptHeaderProps> = ({
}) => {

	return <>
		<Text
			sx={{
				position: 'sticky',
				bottom: 0,
				pt: theme.spacing(1),
				pb: theme.spacing(1.5),
				pl: theme.spacing(2),
				fontSize: theme.typography.subtitle2,
				fontWeight: 600,
				color: theme.palette.grey[900],
				background: theme.palette.common.white,
			}}
		>
			<Stack
				flexDirection={'row'}
				alignItems={'center'}
				gap={theme.spacing(.5)}
			>
				<Stack
					justifyContent={'center'}
					alignItems={'center'}
					sx={{
						width: theme.spacing(2.5),
						height: theme.spacing(2.5),
						borderRadius: '50%',
						color: theme.palette.primary.main,
					}}
				>
					<ChatGptLightIcon sx={{
						width: theme.spacing(2),
						height: theme.spacing(2),
					}} />
				</Stack>
				ChatGPT
			</Stack>
		</Text>
	</>;

}
