import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { DailyGoalEvents } from './events';
import { IDailyGoalEvents, IDailyGoalFields } from '../components/types';
import { DailyGoal } from '../components/DailyGoal';
import { DAILY_GOAL_MIN_DEFAULT } from '../constants';
import { getVideoWatchToday } from '../../../../../store/general/selectors';
import { IDailyGoalHOCProps } from './types';

const mapStateToProps = (
	dispatch: any,
) => {
	return (
		state: IState,
		props: IDailyGoalHOCProps,
	): IDailyGoalFields => {
		const { isSmall } = props;

		return {
			goal: DAILY_GOAL_MIN_DEFAULT,
			today: getVideoWatchToday(state),
			isSmall,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IDailyGoalEvents => ({
	onClick: () => dispatch(DailyGoalEvents.onClick()),
	onLoad: () => dispatch(DailyGoalEvents.onLoad()),
}
);

export const DailyGoalHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(DailyGoal);

DailyGoalHOC.displayName = 'DailyGoalHOC';
