import * as React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { alpha, Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import { IDailyGoalProps } from './types';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Text } from '../../../../../../common/components/Text/Text';
import { DAILY_GOAL, DAILY_GOAL_BUTTON, TOTAL_TIME } from '../wordings';
import { getPlayerState } from '../../../../../store/general/selectors';
import { getState } from '../../../../../store';

const formatTime = (sec: number): string => {
	let str = '';
	if (sec) {
		let m = sec;
		let h = Math.floor(m / 60);
		if (h) {
			m = m - h * 60;
			str = h + 'h ';
		}
		str += m + 'm';
	} else {
		str = '0';
	}
	return str;
}

export function DailyGoal ({
	isShow,
	goal,
	today,
	isSmall,
	isTotal,
	langCode,
	play,
	onClick,
	onLoad,
	onUpdateTime
}: IDailyGoalProps) {
	if (!isShow) return null;

	const {isMobile} = React.useContext(DeviceContext);

	const className = 'daily-goal';

	const [loaded, setLoaded] = React.useState(false);
	const [total, setTotal] = React.useState<string>(null as any);
	const [seconds, setSeconds] = React.useState<any>(null);

	const secondsRef = React.useRef<number>(0);
	if (seconds !== null) {
		secondsRef.current = seconds;
	}

	const playRef = React.useRef<boolean>(false);
	playRef.current = play;


	React.useEffect(() => {
		if (today !== null) {
			setSeconds(today);
			setLoaded(true);
		}
	}, [today]);

	React.useEffect(() => {
		let interval;
		if (isTotal) {
			onLoad().then((ret) => {
				setTotal(formatTime(ret));
				setLoaded(true);
			});
		} else {
			onLoad();

			interval = setInterval(() => {
				onLoad();
			}, 10 * 60 * 1000); // 10 min
		}
		return () => {
			clearInterval(interval);
		}
	}, [isTotal, langCode]);


	React.useEffect(() => {
		const interval = setInterval(() => {
			if (playRef.current) {
				const value = secondsRef.current + 1;
				setSeconds(value);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		}

	}, []);

	const _today = Math.floor((secondsRef.current || 0) / 60);
	const isDone = _today && _today/goal > 1;
	const title = seconds < 60 ? seconds : _today;

	return today === null ? null : isSmall
		? <Tooltip
			placement={'left'}
			title={`${DAILY_GOAL}: ${_today} ${!isDone && ' / ' + goal} min`}
		>
			<Stack
				flexDirection={'row'}
				justifyContent={'center'}
				alignItems={'center'}
				className={className}
				gap={theme.spacing(2)}
				sx={{
					width: theme.spacing(4),
					height: theme.spacing(4),
					m: `0 !important`,
					color: theme.palette.grey[400],
					borderRadius: '50%',
					cursor: 'pointer',
					transition: 'all .3s ease',

					'&:hover': {
						background: alpha(theme.palette.common.white, .75),
					},
				}}
				onClick={onClick}
			>
				{loaded
					? <>
						<CircularProgress
							variant={'determinate'}
							value={100}
							color={'secondary'}
							size={theme.spacing(3)}
							thickness={4}
							sx={{
								position: 'absolute',
								color: theme.palette.grey[200],
							}}
						/>
						<CircularProgress
							variant={'determinate'}
							value={isDone ? 100 : _today/goal * 100}
							size={theme.spacing(3)}
							thickness={4}
							sx={{
								position: 'absolute',
							}}
						/>
						<Text
							sx={{
								lineHeight: 1,
								fontSize: '10px',
							}}
						>
							{title}
						</Text>
					</>

					: <CircularProgress
						size={theme.spacing(3)}
						thickness={4}
					/>
				}
			</Stack>
		</Tooltip>
		: <Stack
			flexDirection={'row'}
			justifyContent={'center'}
			alignItems={'center'}
			className={className}
			gap={1}
			sx={{
				position: 'sticky',
				bottom: theme.spacing(-2),
				left: 0,
				right: 0,
				p: `${theme.spacing(.5)} ${theme.spacing(2)}`,
				background: theme.palette.grey[50],
				borderRadius: theme.spacing(.75),
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<Text
				sx={{
					fontSize: theme.typography.subtitle2,
					fontWeight: 600,
				}}
			>
				{isTotal ? TOTAL_TIME : DAILY_GOAL}
			</Text>
			{loaded
				? <Stack
					justifyContent={'center'}
					alignItems={'center'}
					sx={{
						width: theme.spacing(8),
						height: theme.spacing(8),
					}}
				>
					{isTotal
						? <>
							<Text
								sx={{
									lineHeight: 1,
									fontSize: theme.typography.h6,
									fontWeight: 300,
									color: theme.palette.grey[500],
									textAlign: 'center',
								}}
							>
								{total}
							</Text>
						</>
						: <>
							<CircularProgress
								variant={'determinate'}
								value={100}
								color={'secondary'}
								size={theme.spacing(8)}
								thickness={5}
								sx={{
									position: 'absolute',
									color: theme.palette.grey[200],
								}}
							/>
							<CircularProgress
								variant={'determinate'}
								value={isDone ? 100 : _today/goal * 100}
								size={theme.spacing(8)}
								thickness={5}
								sx={{
									position: 'absolute',
								}}
							/>
							<Text
								sx={{
									lineHeight: 1,
								}}
							>
								{_today}
								{!isDone && ' / ' + goal}
							</Text>
							<Text
								sx={{
									lineHeight: 1,
									color: theme.palette.grey[400],
								}}
							>
								min
							</Text>
						</>
					}
				</Stack>

				: <CircularProgress
					size={theme.spacing(8)}
					thickness={5}
				/>
			}
			<Button
				variant={'text'}
				sx={{
					fontSize: theme.typography.fontSize,
					textDecoration: 'underline',
				}}
			>
				{DAILY_GOAL_BUTTON}
			</Button>
		</Stack>
};