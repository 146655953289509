import * as React from 'react';
import { MenuItem, Select } from '@mui/material';
import { IPlayListLevelSelectProps, PlayListLevelTitles } from './types';
import { EUserLevel } from '../../../../store/general/types';


export const PlayListLevelSelect: React.FC<IPlayListLevelSelectProps> = ({
  value,
  onChange
}) => {

  let levels: EUserLevel[] = (Object.keys(PlayListLevelTitles) as any);
  levels = levels.map(l => parseInt(''+l)).filter(level => {
    if (level === EUserLevel.UNKNOWN) {
      return value === level;
    }
    return true;
  })

  const handleChange = ({ target }: any) => {
    onChange(target.value);
  };

  return (
    <Select
      variant={'filled'}
      onChange={handleChange}
      value={value}
      fullWidth
    >
      {levels.map((level) => {
        return (
          <MenuItem
            key={level}
            value={level}
          >
            {PlayListLevelTitles[level]}
          </MenuItem>
        )
      })}
    </Select>
  );
};