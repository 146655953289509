import { setPublicPageAction } from '../store/general/actions';
import { UserProfileRest } from '../../common/rest/user/userProfileRest';
import { PublicProfileActions } from '../store/public-profile/actions';
import { getDispatch } from '../store';

export class PublicProfileEffects {

  public static async load(nick: string) {
    const dispatch = getDispatch();
    dispatch(setPublicPageAction(true));
    dispatch(PublicProfileActions.set({ loading: true }))
    try {
      const [profile, groups, notes] = await Promise.all([
        UserProfileRest.getByNick(nick),
        UserProfileRest.findGroups(nick),
        UserProfileRest.findNotes(nick)
      ])
      dispatch(PublicProfileActions.set({
        profile, groups, notes
      }))
    } catch(e) {
      console.error(e);
    } finally {
      dispatch(PublicProfileActions.set({ loading: false }))
    }

  }

  public static close() {
    const dispatch = getDispatch();
    dispatch(setPublicPageAction(false));
    dispatch(PublicProfileActions.clear());
  }
}