import React, { useRef, useState } from 'react';
import { IPhraseListProps, phrasesPartLoadSize } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Box, CircularProgress, IconButton, List, MenuItem, Select, Stack } from '@mui/material';
import { EPhraseListMode, EPhraseListPlayMode } from '../../../../store/phrase-list/types';
import { PlayIcon } from '../../../../../../icons/PlayIcon';
import { PauseIcon } from '../../../../../../icons/PauseIcon';
import { Loader } from '../../../../../common/components/Loader/Loader';
import { VocabularyPhraseHOC } from '../../Vocabulary/vocabulary-phrase/hocs/VocabularyPhraseHOC';
import { Paper } from '../../../../../common/components/Paper/Paper';
import { PhrasePlaySettingsHOC } from '../../PhrasePlaySettings/hocs/PhrasePlaySettingsHOC';
import { IPlayerManagerListener } from '../../../../effects/player/manager/playerManagerListener';
import { PlayerManager } from '../../../../effects/player/manager/playerManager';
import { EMainViewMode, EPhrasesColumnType } from '../../../../store/general/types';
import { CLASS_ONBOARDING_BLUR, CLASS_ONBOARDING_TRIGGER } from '../../Onboarding/constants';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { UserTeacherModeSwitchHOC } from '../../Teacher/UserTeacherModeSwitch/hocs/UserTeacherModeSwitchHOC';
import { CLASS_PLAY_CONTROL } from '../../Video/components/constants';
import { AddAnnotationButton } from '../AddAnnotationButton/AddAnnotationButton';
import { ArrowLightLeftIcon } from '../../../../../../icons/ArrowLightLeftIcon';
import { PhraseNotesHOC } from '../../PhraseDetails/PhraseNotes/hocs/PhraseNotesHOC';
import { PhraseTextHOC } from '../../PhraseDetails/PhraseText/hocs/PhraseTextHOC';
import { AddVideoConfirmInlineHOC } from '../../AddVideoConfirm/hocs/AddVideoConfirmInlineHOC';

export const PhraseList: React.FC<IPhraseListProps> = ({
  groupId,
  videoId,
  phraseList,
  phrasesPlayStatus,
  phrasesExist,
  activeListModeId,
  listModes,
  lazyLoadingPhrases,
  mainViewMode,
  isTeacherMode,
  isNote,
  phrasesColumn,
  isPreview,
  onPlay,
  onStop,
  onChangeListMode,
  onLoadPhrases,
  onAppendPhrases,
  onTargetCaptionIndexChange,
  onKeyDown,
  onKeyUp,
  // onClickTitle,
  onAddAnnotation,
}) => {
  const {isMobile, scrollWidth = 0 } = React.useContext(DeviceContext);
  const className = 'phrases-list';

  const isPhrasesView = mainViewMode === EMainViewMode.PHRASES;

  const [allPhrasesLoaded, setAllPhrasesLoaded] = useState<boolean>(false);
  const pauseSelectRef = React.useRef<HTMLElement | null>(null);
  const phraseSelectRef = React.useRef<HTMLElement | null>(null);
  const allPhrasesLoadedRef = useRef(allPhrasesLoaded);
  allPhrasesLoadedRef.current = allPhrasesLoaded;

  const [phrasesLoadCount, setPhrasesLoadCount] = useState<number>(0);
  const phrasesLoadCountRef = useRef(phrasesLoadCount);
  phrasesLoadCountRef.current = phrasesLoadCount;

  const phraseListRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (groupId && videoId) {
      setPhrasesLoadCount(0);
      setAllPhrasesLoaded(true);
      const loadCount = lazyLoadingPhrases ? phrasesPartLoadSize : 0;
      onLoadPhrases(loadCount).then(allLoaded => {
        setAllPhrasesLoaded(allLoaded);
        setPhrasesLoadCount(phrasesPartLoadSize);
      });
    }
  }, [groupId, videoId, activeListModeId]);

  const onListScrollToBottom = async () => {
    if (lazyLoadingPhrases && !allPhrasesLoadedRef.current) {
      const allLoaded = await onAppendPhrases(phrasesLoadCountRef.current, phrasesPartLoadSize);
      if (allLoaded) {
        setAllPhrasesLoaded(true);
      }
      setPhrasesLoadCount(phrasesLoadCountRef.current + phrasesPartLoadSize);
    }
  }

  const handleChangeListMode = ({ target }: any) => {
    onChangeListMode(target.value);
  };

  function handleClose(
    isPauseSelect: boolean
  ) {
    const el = isPauseSelect ? pauseSelectRef.current : phraseSelectRef.current;
    if (el) {
      const selectList = el.querySelector('.MuiSelect-select') as HTMLElement;
      if (selectList) setTimeout(() => selectList.blur(), 0);
    }
  }

  const paperStyle = {
    position: 'relative',
    flex: '1 1 33%',
    minWidth: 0,
    height: 1,
    p: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
    display: 'flex',
    justifyContent: 'center',

    '.mobile &': {
      p: theme.spacing(1),
    },

    '&.empty': {
      [`.${className}__controls,
        .${className}__select-video`]: {
          display: 'none',
      },
    },
  };

  const playerListener: IPlayerManagerListener = {
    onTargetIndexChange(index: number): void {
      onTargetCaptionIndexChange(index);
    }
  }

  React.useEffect(() => {
    PlayerManager.getInstance().addListener(playerListener);
    return () => {
      PlayerManager.getInstance().removeListener(playerListener);
    };
  }, [phraseList, videoId]);

  const [showSelectListMode, setShowSelectListMode] = useState(false);
  React.useEffect(() => {
    setShowSelectListMode(
      isPhrasesView ||
      activeListModeId !== String(EPhraseListMode.CURRENT_VIDEO)
    );
  }, []);

  const selectVideo = showSelectListMode && (
    <Select
      variant={'filled'}
      ref={phraseSelectRef}
      onClose={() => handleClose(false)}
      onChange={handleChangeListMode}
      value={activeListModeId}
      disabled={!phrasesExist}
      fullWidth
    >
      {listModes.map((listMode) => {
        return (
          <MenuItem
            key={listMode.id}
            value={listMode.id}
          >
            {listMode.title}
          </MenuItem>
        )
      })}
    </Select>
  );

  const isEmpty = !phraseList.length;

  let prevId = 0;

  return (
      <Paper
        className={`
          ${className}
          ${isEmpty ? 'empty' : ''}
          ${CLASS_ONBOARDING_BLUR}
          ${isNote ? 'tab-notes' : ''}
        `}
        sx={paperStyle}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      >
        {isNote
          ? <Stack
              sx={{
                flex: `1 1 100%`,
                minHeight: 0,
                pb: theme.spacing(2),

                '.text-with-speaker': {
                  p: '.2em 0',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  lineHeight: 1,
                  maxHeight: '5.8em',
                  minHeight: '1.8em',
                  mb: 0,
                },
              }}
            >
              {phrasesColumn !== EPhrasesColumnType.COLUMNS_1
                ? null
                : <Box
                  sx={{
                    pb: theme.spacing(2),
                  }}
                >
                  <PhraseTextHOC />
                </Box>
              }
              <PhraseNotesHOC />
            </Stack>
          
          : <Stack
            className={`shortcuts-listener shortcuts-listener--phrases`}
            sx={{
              position: 'relative',
              width: '100%',

              '&.shortcuts-listener--active': {
                ':after': {
                  content: '""',
                  position: 'absolute',
                  inset: '-22px',
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: '10px',
                }
              }
            }}
            gap={theme.spacing()}
          >
            {!isMobile &&
              <Stack
                className={`${className}__controls`}
                direction='row'
                gap={theme.spacing(2)}
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pr: theme.spacing(1.5),
                }}
              >
                {isPhrasesView
                  ? selectVideo
                  : null
                }
                
                <Stack direction='row' gap={theme.spacing()}>
                  <IconButton
                    className={CLASS_PLAY_CONTROL}
                    onClick={EPhraseListPlayMode.STOP === phrasesPlayStatus ? onPlay : onStop}
                    color={'secondary'}
                    disabled={!phrasesExist}
                    sx={{
                      borderRadius: '50%',
                      border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`,
                      background: 'none',
                      '&:hover': {
                        background: theme.palette.grey[50],
                      },
                      '&:active': {
                        bgcolor: 'none',
                        color: theme.palette.primary[400]
                      },
                      '&:focus': {
                        '&:after': {
                          borderRadius: '50%'
                        }
                      },
                      '.MuiSvgIcon-root': {
                        width: theme.spacing(2),
                        height: theme.spacing(2),
                      }
                    }}
                  >
                    {EPhraseListPlayMode.STOP === phrasesPlayStatus ?
                      <PlayIcon sx={{
                        ml: theme.spacing(0.5),
                        pointerEvents: 'none',
                      }} /> : <PauseIcon />}
                  </IconButton>

                  <PhrasePlaySettingsHOC />
                  
                </Stack>
              </Stack>
            }

            <UserTeacherModeSwitchHOC />

            {!isPhrasesView && !isMobile &&
              <Box
                className={`${className}__select-video`}
              >
                {selectVideo}
              </Box>
            }

            
            <List
              className={`phrase-list
                ${isTeacherMode ? 'teacher-mode' : ''}
              `}
              sx={{
                position: 'static',
                mr: theme.spacing(-2),
                overflowY: 'scroll',
                overflowX: 'hidden',
                '.onboarding-active &': {
                  overflowY: 'hidden',
                }
              }}
              ref={phraseListRef}
            >
              <Box
                sx={{
                  maxWidth: `calc(100% - ${parseInt(theme.spacing(2), 10) - scrollWidth}px)`,
                }}
              >
                <Box
                  className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step8`}
                  sx={{
                    position: 'absolute',
                    left: theme.spacing(1),
                    right: theme.spacing(1),
                    height: '1px',
                    mt: theme.spacing(5),
                    pointerEvents: 'none',
                  }}
                />
                  {isTeacherMode && !phraseList.length &&
                    <AddAnnotationButton
                      onClick={() => onAddAnnotation()}
                    />
                  }
                  {!!phraseList.length && <>
                    {phraseList.map((phraseId, idx) => {
                        let _prevId = prevId;
                        prevId = phraseId;
                        return <>
                          {isTeacherMode &&
                            <AddAnnotationButton
                              isCollapsed={!!idx}
                              onClick={() => onAddAnnotation(_prevId, phraseId)}
                            />
                          }
                          <VocabularyPhraseHOC
                            phraseId={phraseId}
                            key={phraseId}
                          />
                        </>
                    })}
                    {isTeacherMode &&
                      <AddAnnotationButton
                        onClick={() => onAddAnnotation(prevId)}
                      />
                    }

                    {lazyLoadingPhrases && !allPhrasesLoaded &&
                      <Loader onShow={onListScrollToBottom}>
                        <CircularProgress size='small' sx={{ alignSelf: 'center' }}/>
                      </Loader>
                    }
                  </>}
              </Box>
            </List>

            {!isTeacherMode && !phraseList.length &&
              <Stack
                gap={1}
                sx={{
                  p: theme.spacing(3, 3, 0, 3),
                  color: theme.palette.grey[300],
                  fontSize: theme.typography.subtitle2,
                  fontWeight: 400,
                }}
              >
                {isPreview
                  ? <AddVideoConfirmInlineHOC />
                  : <>
                    <ArrowLightLeftIcon
                      sx={{
                        position: 'absolute',
                        ml: theme.spacing(-3),
                        transform: 'scaleX(-1)',
                      }}
                    />
                    <Box>
                      Your saved phrases <br/>
                      will be here
                    </Box>
                  </>
                }
              </Stack>
            }

          </Stack>
        }

          <Box
            className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step8-highlight`}
            sx={{
              position: 'absolute',
              pointerEvents: 'none',
              left: theme.spacing(-.5),
              right: theme.spacing(-.5),
              top: theme.spacing(-.5),
              bottom: theme.spacing(-.5),
              borderRadius: theme.spacing(1),
            }}
          />
      </Paper>
  );
};
