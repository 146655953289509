import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseChatItemEvents, IPhraseChatItemFields } from '../components/types';
import { IPhraseChatItemHOCProps } from './types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { EDefaultGptPromptType, EPhraseDetailsTabType } from '../../../../../store/models/types';
import { GptUtils } from '../../../../../effects/gptUtils';
import { PhraseChatItem } from '../components/PhraseChatItem';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { getDefaultGptPrompt, getUserGptPromptById } from '../../../../../store/models/selectors';
import { PhraseDetailsCustomPromptTestService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-custom-prompt-test-service';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { PhraseChatItemEvents } from './events';
import { PhraseDetailsCustomPromptVars } from '../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { getAllVideoPhrasesWordTextList } from '../../../../../store/videos/selectors';

const MAX_PHRASE = 20;
const MAX_CONTEXT = 50;

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
		props: IPhraseChatItemHOCProps,
	): IPhraseChatItemFields => {
		const { id, type } = props;

		let prompt;
		let result;
		let processing;

		if (id) {
			let promptResult;
			if (type === EPhraseDetailsTabType.LIB_PROMPT) {
				promptResult = PhraseDetailsSelectors.getLibPrompt(state, id);
				prompt = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(state, id)?.prompt;
				//const libs = PhraseDetailsTabsEditorPopupSelectors.getPromptLibs(state);
			} else {
				promptResult = PhraseDetailsSelectors.getCustomPrompt(state, id);
				prompt = getUserGptPromptById(state, id)?.prompt;
			}
			result = promptResult?.resultText;
			processing = promptResult?.processing;
		}
		if (type === EPhraseDetailsTabType.EXPLAIN) {
			result = PhraseDetailsSelectors.getExplainResultText(state);
			processing = PhraseDetailsSelectors.getExplainResultProcessing(state) || false;
			if (!prompt) prompt = getDefaultGptPrompt(state, EDefaultGptPromptType.EXPLAIN)?.prompt;
		} else if (type === EPhraseDetailsTabType.USAGE) {
			result = PhraseDetailsSelectors.getUsageResultText(state);
			processing = PhraseDetailsSelectors.getUsageResultProcessing(state) || false;
			if (!prompt) prompt = getDefaultGptPrompt(state, EDefaultGptPromptType.USAGE)?.prompt;
		}

		let text = PhraseContextEditorSelectors.getPhraseText(state)?.highlighted || '';
		let context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || '';

		if (text && text.length > MAX_PHRASE * 2) text = text.slice(0, MAX_PHRASE) + '...';
		if (context && context.length > MAX_CONTEXT * 1.5) context = context.slice(0, MAX_CONTEXT) + '...';

		const canExec = PhraseDetailsCustomPromptVars.canExec(text, prompt);


		prompt = PhraseDetailsCustomPromptTestService.preparePrompt({
			text,
			context,
			targetLang: PhraseDetailsSelectors.getFromLang(state)?.name || '',
			nativeLang: PhraseDetailsSelectors.getToLang(state)?.name || '',
				entireScript: '"full text will be inserted here"',
				phraseList: getAllVideoPhrasesWordTextList(state).join(', ')
		},
			prompt
		);

		const activeTab = PhraseDetailsSelectors.getActiveTab(state);
		const active = activeTab &&
			activeTab.type === type &&
			(!id || activeTab.relationId === id);


		return {
			active,
			processing,
			prompt,
			result: result && GptUtils.formatResult(result),
			text,
			canExec,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any,
	props: IPhraseChatItemHOCProps,
): IPhraseChatItemEvents => {
	const { id, type } = props;
	return {
		onClickSettings: () => dispatch(PhraseChatItemEvents.onClickSettings(type, id)),
		onLoad: (useCache) => dispatch(PhraseChatItemEvents.onLoad(useCache, type, id)),
		onCopyNote: () => dispatch(PhraseChatItemEvents.onCopyNote(type, id)),
	}
}

export const PhraseChatItemHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PhraseChatItem);

PhraseChatItemHOC.displayName = 'PhraseChatItemHOC';
