export class DateUtil {

  public static trimToDay(date: Date): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  }

  public static trimToHour(date: Date): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()));
  }

  public static incDays(date: Date, days: number): Date {
    return new Date(date.getTime() + days * 1000 * 60 * 60 *24);
  }

  public static formatDate(date: Date): string {
    const yyyy = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    let monthStr = ''+month;
    let dayStr = ''+day;

    if (day < 10) dayStr = '0' + dayStr;
    if (month < 10) monthStr = '0' + monthStr;

    return dayStr + '/' + monthStr + '/' + yyyy;
  }
}