import { AppThunk } from '../../../../../store/types';
import { setEditPlaylistModalIdAction } from '../../../../../store/general/actions';
import { getEditPlaylistModalId } from '../../../../../store/general/selectors';
import { UserGroupEffects } from '../../../../../effects/userGroupEffects';
import { EUserLevel } from '../../../../../store/general/types';
import DOMPurify from 'dompurify';

export class EditGroupEvents {

  public static onSave(
    name: string,
    description: string,
    publicGroup: boolean,
    level: EUserLevel
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = getEditPlaylistModalId(getState());
      if (description) {
        const doc = new DOMParser().parseFromString(DOMPurify.sanitize(description), 'text/html');
        description = doc.body.textContent || doc.body.innerText;
      }
      await UserGroupEffects.updateGroup(groupId, name, description, undefined, undefined, publicGroup, level);
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalIdAction(0));
    }
  }

}