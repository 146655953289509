import { ILoginPopupSetAction, ILoginPopupState, LOGIN_POPUP_SET_ACTION, } from './types';

export class LoginPopupActions {

	public static set(
		value: ILoginPopupState
	): ILoginPopupSetAction {
		return {
			type: LOGIN_POPUP_SET_ACTION,
			value,
		}
	}

}