import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseDetailsTabPromptTestEvents, IPhraseDetailsTabPromptTestFields } from '../components/types';
import { PhraseDetailsTabPromptTest } from '../components/PhraseDetailsTabPromptTest';
import { PhraseDetailsTabPromptTestEvents } from './events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { GptUtils } from '../../../../../effects/gptUtils';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { getAllVideoPhrasesWordTextList, isSavedVideoPhrasesExist } from '../../../../../store/videos/selectors';
import { PhraseDetailsCustomPromptVars } from '../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { getPhraseDetailsTabById } from '../../../../../store/models/selectors';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { EVocabularyPhraseSaveType } from '../../../../../types/common';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabPromptTestFields => {
    const prompt = PhraseDetailsTabsEditorPopupSelectors.getPromptTestPrompt(state);
    let phrasePreview = PhraseContextEditorSelectors.getPhraseText(state);
    let phrase = phrasePreview && phrasePreview.id < 0 && phrasePreview?.highlighted || '';
    let context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
    let targetLanguage = PhraseDetailsSelectors.getFromLang(state)?.name || '';
    let nativeLanguage = PhraseDetailsSelectors.getToLang(state)?.name || '';
    let isNoPhrase;
    if (!phrase) {
      isNoPhrase = true;
      const firstCaptionAsPhrase = PhraseDetailsTabsEditorPopupSelectors.getFirstCaptionAsPhrase(state);
      if (firstCaptionAsPhrase && firstCaptionAsPhrase.text) {
        phrase = firstCaptionAsPhrase.text;
        context = firstCaptionAsPhrase.context || '';
        targetLanguage = firstCaptionAsPhrase.fromLang?.name || '';
        nativeLanguage = firstCaptionAsPhrase.toLang?.name || '';
      }
    }
    const tabId = PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state);
    const tab = getPhraseDetailsTabById(state, tabId);
    const _prompt = tab && PhraseDetailsTabEffects.getCustomPromptByTab(tab);

    const showNoPhrasesForPromptError = !!_prompt?.prompt &&
      PhraseDetailsCustomPromptVars.isPhraseListVarExist(_prompt?.prompt) && !isSavedVideoPhrasesExist(state);
    
    const savedPhrases = getAllVideoPhrasesWordTextList(state, EVocabularyPhraseSaveType.PERSIST).join(', ');
    const translatedPhrases = getAllVideoPhrasesWordTextList(state, EVocabularyPhraseSaveType.TRANSLATE).join(', ');

    return {
      show: !!prompt,
      prompt,
      result: GptUtils.formatResult(PhraseDetailsTabsEditorPopupSelectors.getPromptTestResult(state) || ''),
      processing: PhraseDetailsTabsEditorPopupSelectors.isPromptTestProcessing(state),
      phrase,
      context,
      isNoPhrase,
      showNoPhrasesForPromptError,
      targetLanguage,
      nativeLanguage,
      savedPhrases,
      translatedPhrases,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabPromptTestEvents => ({
    onTest: () => dispatch(PhraseDetailsTabPromptTestEvents.onTest()),
    onBack: () => dispatch(PhraseDetailsTabPromptTestEvents.onBack()),
    onClickContext: () => dispatch(PhraseDetailsTabPromptTestEvents.onClickContext()),
  }
);

export const PhraseDetailsTabPromptTestHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabPromptTest);

PhraseDetailsTabPromptTestHOC.displayName = 'PhraseDetailsTabPromptTestHOC';