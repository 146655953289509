import React from 'react';
import { IPromptsListItemProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { PhraseDetailsTabItemCheckButton } from '../../PhraseDetailsTabItem/components/PhraseDetailsTabItemCheckButton';
import { PinIcon } from '../../../../../../../icons/PinIcon';
import { PinEmptyIcon } from '../../../../../../../icons/PinEmptyIcon';

export function PromptsListItem ({
	favorite,
	isSelected,
	text,
	title,
	onClick,
	onClickFavorite,
}: IPromptsListItemProps) {
	const CLASS_CONTROLS = 'prompts-list-item-controls';

	const handleClick = (e: React.MouseEvent) => {
		const target = e.target as HTMLElement;
		const controls = target.closest(`.${CLASS_CONTROLS}`);
		if (controls) return;

		onClick();
	}

	const containerStyle = {
		position: 'relative',
		py: theme.spacing(1.5),
		px: theme.spacing(2),
		borderRadius: theme.spacing(0.75),
		border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`,
		bgcolor: theme.palette.common.white,
		cursor: 'pointer',

		'&:hover, &:focus': {
			borderColor: theme.palette.grey[300]
		},

		'&.selected': {
			borderColor: `${theme.palette.primary.main} !important`,
			background: theme.palette.primary[100],
		},
	};

	const textStyle = {
		mr: theme.spacing(3),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	};

	const descStyle = {
		display: '-webkit-box',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'-webkit-line-clamp': '2',
		'-webkit-box-orient': 'vertical',
		maxHeight: '2.8em',
		lineHeight: 1.4,
		opacity: .5,
	}

	const controlsStyle = {
		position: 'absolute',
		right: theme.spacing(1),
		top: 0,
	}

	const iconStyle = {
		width: `${theme.spacing(3)} !important`,
    	height: `${theme.spacing(3)} !important`,
	}

	return (
		<Stack
			className={isSelected ? 'selected' : ''}
			gap={theme.spacing(.5)}
			sx={containerStyle}
			onClick={handleClick}
		>
			<Text
				fontWeight={600}
				noWrap
				variant={'subtitle2'}
				sx={textStyle}
			>
				{title}
			</Text>
			{!!text &&
				<Text sx={descStyle}>
					{text}
				</Text>
			}

			<Stack
				className={CLASS_CONTROLS}
				direction={'row'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				gap={theme.spacing(1)}
				sx={controlsStyle}
			>

				<PhraseDetailsTabItemCheckButton
					active={favorite}
					onClick={onClickFavorite}
				>
					{favorite
						? <PinIcon sx={iconStyle} />
						: <PinEmptyIcon sx={iconStyle} />
					}
				</PhraseDetailsTabItemCheckButton>

			</Stack>

		</Stack>
	);
}


