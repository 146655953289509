import React from 'react';
import { IButtonLoginProps } from './types';
import { Button, Stack, Typography } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { GoogleIcon } from '../../../../../../../icons/GoogleIcon';

export const ButtonLogin: React.FC<IButtonLoginProps> = ({
	onLogin,
}) => {

	const [loginError, setLoginError] = React.useState<string>('');
	const [targetLang, setTargetLang] = React.useState<string>('');
	const [nativeLang, setNativeLang] = React.useState<string>('');

	const onLoginClick = async () => {
		const success = await onLogin(targetLang, nativeLang);
		setLoginError(success ? '' : 'Login error');
	}

	const googleButtonStyle = {
		height: theme.spacing(8),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(4),
		bgcolor: theme.palette.common.white,
		color: theme.palette.common.black,
		border: '2px solid #fafafa',
		borderRadius: '56px',
		transition: 'opacity 0.15s ease',
		opacity: 1,
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: theme.palette.common.white,
			opacity: 0.7,
			boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
		},
		'.MuiButton-startIcon': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: 1,
			minWidth: theme.spacing(7),
			m: 0,
		},
	};

	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		setTargetLang(urlParams.get('targetlangauge') || '');
		setNativeLang(urlParams.get('nativelanguage') || '');
	}, []);

	return (
		<Stack
			alignItems={'center'}
			sx={{
				position: 'relative',
			}}
		>
			<Button
				variant={'contained'}
				sx={googleButtonStyle}
				onClick={onLoginClick}
				startIcon={<GoogleIcon />}
			>
				<Typography
					fontWeight={600}
					variant={'h6'}
					sx={{
						width: 1,
					}}
				>
					Sign in with Google
				</Typography>
			</Button>

			{!!loginError &&
				<Text
					fontWeight={600}
					sx={{
						position: 'absolute',
						bottom: theme.spacing(-5),
						color: theme.palette.error.main,
						p: theme.spacing(1.5),
					}}
				>
					{loginError}
				</Text>
			}
		</Stack>
	);
}
