import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseDetailsContainerEvents, IPhraseDetailsContainerFields } from '../components/types';
import { PhraseDetailsContainer } from '../components/PhraseDetailsContainer';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsContainerEvents } from './events';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';
import { getActiveGroupId } from '../../../../../store/models/selectors';
import { getVideoTeacherUserActiveId } from '../../../../../store/videos/selectors';
import { PhraseDetailsPanelSelectors } from '../../selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsContainerFields => {
    const teacherId = getVideoTeacherUserActiveId(state);
    const text = PhraseDetailsPanelSelectors.getText(state);

    return {
      activeTab: PhraseDetailsSelectors.getActiveTab(state),
      groupId: getActiveGroupId(state) || 0,
      videoId: getCurrentMovieKey(state),
      teacherMode: teacherId > 0,
      text,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsContainerEvents => ({
    onLoad: () => dispatch(PhraseDetailsContainerEvents.onLoad()),
    onSelection: selection => dispatch(PhraseDetailsContainerEvents.onSelection(selection)),
    onPlayerPositionChange: time => dispatch(PhraseDetailsContainerEvents.onPlayerPositionChange(time))
  }
);

export const PhraseDetailsContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsContainer);

PhraseDetailsContainerHOC.displayName = 'PhraseDetailsContainerHOC';
