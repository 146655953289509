import React from 'react';
import { Modal } from '../../../../../common/components/Modal/Modal';
import {
	Box,
	Stack,
} from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { IAddVideoConfirmProps } from './types';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { VideoCard } from '../../VideoCard/VideoCard';
import { AddVideoConfirmInline } from '../AddVideoConfirmInline/AddVideoConfirmInline';

export const AddVideoConfirm: React.FC<IAddVideoConfirmProps> = ({
	isOpen,
	groups,
	nativeLang,
	videoLangCodes,
	videoAuthor,
	videoTitle,
	videoImg,
	newListName,
	text,
	onChange,
	onClose,
}) => {
	if (!isOpen) return null;

	const { isMobile } = React.useContext(DeviceContext);

	return (
		<Modal
			onClose={onClose}
			open={isOpen}
			isMobile={isMobile}
			PaperProps={{
				sx: {
					width: 1,
					maxWidth: `${theme.spacing(62)} !important`,
				}
			}}
		>
			<Stack
				gap={theme.spacing(3)}
			>
				<Box
					sx={{
						mt: -2,
						p: 2,
						opacity: .8,
						background: theme.palette.grey[50],
						borderRadius: theme.spacing(1),
					}}
				>
					<VideoCard
						author={videoAuthor}
						img={videoImg}
						title={videoTitle}
						langCodes={videoLangCodes}
					/>
				</Box>
				
				<AddVideoConfirmInline
					groups={groups}
					nativeLang={nativeLang}
					newListName={newListName}
					text={text}
					videoLangCodes={videoLangCodes}
					onChange={onChange}
					onClose={onClose}
				/>
			</Stack>
		</Modal>
	);
}
