import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { getActiveUserGroup, getPhraseDetailsTabByTypeAndRelationId } from '../../../../../store/models/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { EUserGroupType } from '../../../../../store/general/types';
import { SaveVideoConfirmManager } from '../../../../../effects/saveVideoConfirmManager';

export class PromptsLibItemEvents {

	public static onClick (
		id: number,
		onSelected?: () => void,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();

			const group = getActiveUserGroup(state);
			if (group?.type === EUserGroupType.PREVIEW) {
				SaveVideoConfirmManager.saveToMyStuff();
				return;
			}

			let tab = getPhraseDetailsTabByTypeAndRelationId(state, EPhraseDetailsTabType.LIB_PROMPT, id);
			if (!tab) {
				tab = await PhraseDetailsTabEffects.saveLibTab(id) as any;
			}
			dispatch(PhraseDetailsActions.setActiveTab(tab as any));
			if (onSelected) onSelected();

			if (tab) dispatch(PhraseDetailsTabEffects.addPromptsChat({ type: tab.type, id: tab.relationId }));
		}
	}

	public static onClickFavorite(id: number): AppThunk {
		return async(
		  dispatch,
		  getState
		) => {
			PhraseDetailsTabEffects.toggleLibTabFavourite(id);
		}
	}

}
