import { IState } from '../../../../store/types';
import { getActiveGroupId, getActiveUserGroup } from '../../../../store/models/selectors';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';
import { connect } from 'react-redux';
import { IPhraseListTranslatedEvents, IPhraseListTranslatedFields } from '../components/types';
import { PhraseListTranslatedEvents } from './events';
import { EVocabularyPhraseSaveType, EVocabularyPhraseType } from '../../../../types/common';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { PhraseListTranslated } from '../components/PhraseListTranslated';
import { EUserGroupType } from '../../../../store/general/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseListTranslatedFields => {
    const phraseList = PhraseListSelectors.findPhrasesByTypes(state, [
        EVocabularyPhraseType.DEFAULT,
        EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
      ])
        .reduce((arr: number[], phrase) => {
          if (phrase.saveType === EVocabularyPhraseSaveType.TRANSLATE &&
            phrase.fullPhrase?.trim()?.length)
          {
            arr.push(phrase.id);
          }
          return arr;
        }, []);

    const isPreview = getActiveUserGroup(state)?.type === EUserGroupType.PREVIEW;

    return {
      groupId: getActiveGroupId(state) || 0,
      videoId: getCurrentMovieKey(state),
      phraseList,
      isPreview,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseListTranslatedEvents => ({
    onLoadPhrases: (count) => dispatch(PhraseListTranslatedEvents.onLoadPhrases(count)),
    onAppendPhrases: (offset, count) => dispatch(PhraseListTranslatedEvents.onAppendPhrases(offset, count)),
    onGetPhraseTitle: phraseId => dispatch(PhraseListTranslatedEvents.onGetPhraseTitle(phraseId))
  }
);

export const PhraseListTranslatedHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseListTranslated);

PhraseListTranslatedHOC.displayName = 'PhraseListTranslatedHOC';
