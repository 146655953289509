import React from 'react';
import { IOneTimeTooltipSelectProps } from './types';

export function OneTimeTooltipSelect ({
	isShow,
	onClose,
}: IOneTimeTooltipSelectProps) {
	if (!isShow) return null;

	const src = require('../../../../../../../img/select.mp4').default;

	return <video
		autoPlay
		loop
		style={{
			width: '100%',
			maxWidth: '400px',
		}}
	>
		<source src={src} type="video/mp4"/>
	</video>
}
