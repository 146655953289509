import { AppThunk } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibRest } from '../../../../../../common/rest/contentLib/contentLibRest';
import { IContentLibVideo } from '../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { Navigator } from '../../../../../effects/navigator';
import { isAuthorised } from '../../../../../store/general/selectors';
import { AuthUserEffects } from '../../../../../effects/authUserEffects';
import { updateUserLanguages } from '../../../../../effects/updateUserLanguages';


export class ContentLibVideoListEvents {

  public static onLoadVideos(startIndex: number, count: number): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IContentLibVideo[]> => {
      const groupId = ContentLibSelectors.getSelectedGroupId(getState());
      const {text: searchText, targetLangCode, nativeLangCode} = ContentLibSelectors.getSearchFilter(getState());
      let videos;

      let promises: Promise<any>[] = [
        ContentLibEffects.searchVideos({
          groupId, searchText, targetLangCode, nativeLangCode, startIndex, count
        }).then((result) => {
          videos = result;
        }),
      ];

      if (!groupId) {
        promises.push(ContentLibRest.searchGroups({
            targetLangCode,
            nativeLangCode,
            searchText,
            maxVideoCount: 0,
          }).then((groups) => {
            dispatch(ContentLibActions.setGroupsSearchResult({ groups }));
          })
        )
      }
      
      return Promise.all(promises).then(() => videos);
    }
  }

  public static onVideoClick(video: IContentLibVideo | any): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = ContentLibSelectors.getSelectedGroupId(getState()) || video.groupId;
      if (video) {
        if (!isAuthorised(getState)) {
          const loginResult = await AuthUserEffects.loginProcess(AuthUserEffects.newUserDefaultHandler, '', '', true);
          if (!loginResult || loginResult?.authUser?.isNew) {
            return;
          }
        }
        await dispatch(updateUserLanguages());
        const result = await ContentLibEffects.previewVideo(groupId, video.id, video?.url || '');
        Navigator.openVideo(result.groupId, result.videoId);
      }
    }
  }

}