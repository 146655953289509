import React from 'react';
import { Autocomplete, Paper, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { ILangMenuListProps } from './types';
import LangFlag from '../../../LangFlag/LangFlag';
import { TLangSelectOption } from '../../../LangSelect/components/types';
import { Text } from '../../../../../../common/components/Text/Text';

export function LangMenuList({
	groupLangs,
	langCode,
	langs,
	onChange,
	onClose,
}: ILangMenuListProps) {

	const handleChange = (e: React.SyntheticEvent, newValue: TLangSelectOption | null) => {
		if (newValue) {
			onChange(newValue.code);
			onClose();
		}
	};

	const [options, setOptions] = React.useState<TLangSelectOption[]>([{
		code: '',
		name: 'Select language',
		highlight: false,
	}]);
	React.useEffect(() => {
		const langOptions = langs.map((l) => {
			return {
				code: l.code,
				name: l.name,
				highlight: l.installUserGroupExist ? true : false,
			};
		})
			.sort((a, b) => {
				const ai = groupLangs.indexOf(a.code);
				const bi = groupLangs.indexOf(b.code);
				if (ai !== -1 && bi !== -1) {
					return ai - bi;
				}
				if (ai !== -1) {
					return -1;
				}
				if (bi !== -1) {
					return 1;
				}
				return 0;
			});
		setOptions(langOptions);
	}, [langs]);

	const [opened, setOpened] = React.useState(false);
	React.useEffect(() => {
		setTimeout(() => {
			setOpened(true);
		}, 300);
	}, []);

	const selectStyle = {
		minWidth: theme.spacing(24.75),
	};

	const dropdownStyle = {
		boxShadow: theme.shadows[8],
	};

	return (
		<>
			<Autocomplete
				autoHighlight
				disabled={!langs || !langs.length}
				open={opened}
				options={options}
				getOptionLabel={(option) => option.name}
				value={options.find((option) => option.code === langCode) || null as any}
				onChange={handleChange}
				onClose={onClose}
				sx={selectStyle}
				PaperComponent={({ children, ...other }) => (
					<Paper {...other} style={dropdownStyle}>
						{children}
					</Paper>
				)}
				renderInput={(params) => {
					return <TextField
						{...params}
						autoFocus
						variant='filled'
						InputLabelProps={{
							shrink: true,
						}}
					/>;
				}}
				renderOption={(props, option) => (
					<li {...props}>
						<Stack direction='row' alignItems='center' gap={theme.spacing(1)}>
							<LangFlag code={option.code} showCode={false} />
							<Text variant={'subtitle2'}>
								{option.highlight ? <b>{option.name}</b> : option.name}
							</Text>
						</Stack>
					</li>
				)}
			/>
		</>
	);
};
