import * as React from 'react';
import { IPlayerListener, ITargetCaptionsProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, CircularProgress, Stack } from '@mui/material';
import { TargetCaptionItem } from './TargetCaptionItem';
import { ICaptionsItem } from '../../../../../types/common';
import { TargetCaptionsHelper } from './helper';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { ECaptionsItemType } from '../../NativeCaptions/components/types';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { OneTimeTooltipSelectStorage } from '../../../../../../common/utils/local-storage/onetime-tooltip-select';
import { CLASS_MAIN_VIDEO } from '../../../Video/components/constants';
import { TooltipContainer } from '../../../TooltipContainer/TooltipContainer';
import { ElementHeightCalc } from '../../../../../../common/utils/elementHeightCalc';
const md5 = require('md5');

export let targetItemsHeightList: number[] = [];

type TRangeInfo = {
  startIndex: number,
  endIndex: number
}

enum ERenderElementType {ITEMS, BLOCK};
type TRenderElement = {
  startIndex: number,
  endIndex: number,
  type: ERenderElementType
}
type TRenderBlock = {
  height: number,
  type: ERenderElementType
}
type TRenderItem = TRenderElement | TRenderBlock;

export const TargetCaptions: React.FC<ITargetCaptionsProps> = ({
  elementRef,
  captions,
  videoId,
  langCode,
  phrases,
  solo,
  isShowCaptionsSelectionPopup,
  selectResult,
  playerActive,
  forwardCaptionIndex,
  isSelectPopup,
  listenerHolder,
  currentSelectionCaptionIndex,
  currentSelectionCaptionIndexStart,
  currentSelectionCaptionIndexEnd,
  playCaptionByPhraseId,
  isCaptions2Columns,
  onCaptionPlay,
  onCaptionClick,
  onMouseEnter,
  onMouseLeave,
  onSelectText,
  onChangeActiveIndex,
  onCaptionMouseEnter,
  onCaptionMouseLeave,
  onGetPhraseTitle
}) => {
  const {isFullScreen, isMobile, isTouch} = React.useContext(DeviceContext);

  const targetCaptionsContainer = elementRef;
  const [targetScrollTop, setTargetScrollTop] = React.useState<number>(-1);
  const [mouseCapture, setMouseCapture] = React.useState<boolean>(false);
  const [mouseDown, setMouseDown] = React.useState<boolean>(false);
  const [scrolling, setScrolling] = React.useState<boolean>(false);
  const [activeCaptionIndex, setActiveCaptionIndex] = React.useState<number>(0);
  const [activeWordIndex, setActiveWordIndex] = React.useState<number>(-1);
  const [hoverPhraseId, setHoverPhraseId] = React.useState<number>(0);
  const [wordHoverPhraseId, setWordHoverPhraseId] = React.useState<number>(0);
  const [clickCaptionIndex, setClickCaptionIndex] = React.useState<number>(-1);
  const [lastMouseEnterCaptionIndex, setLastMouseEnterCaptionIndex] = React.useState<number>(-1);
  const [mouseUpCatchCaptionIndex, setMouseUpCatchCaptionIndex] = React.useState<number>(-1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [captionsHelper, setCaptionsHelper] = React.useState<TargetCaptionsHelper | null>(null);
  const [scrollInfo, setScrollInfo] = React.useState<TRangeInfo | null>(null)
  const [phraseHoverX, setPhraseHoverX] = React.useState<number>(0);
  const [phraseHoverY, setPhraseHoverY] = React.useState<number>(0);
  const [phraseHoverTitle, setPhraseHoverTitle] = React.useState<string>('');
  const [captionHeightsCalc, setCaptionHeightsCalc] = React.useState<boolean>(false);
  const calcHeightElmRef = React.useRef<any>(null);
  const [startedPlayIndex, setStartedPlayIndex] = React.useState<number>(-1);

  const captionsRef = React.useRef<ICaptionsItem[] | null>(null);
  captionsRef.current = captions;

  const clickCaptionIndexRef = React.useRef<number>(-1);
  clickCaptionIndexRef.current = clickCaptionIndex;

  const lastMouseEnterCaptionIndexRef = React.useRef<number>(-1);
  lastMouseEnterCaptionIndexRef.current = lastMouseEnterCaptionIndex;

  // use for disable hightlight phrase context on mouse selection
  const [commonMouseCapture, setCommonMouseCapture] = React.useState<boolean>(false);
  const commonMouseCaptureRef = React.useRef<boolean>(null);
  commonMouseCaptureRef.current = commonMouseCapture;

  const mouseCaptureRef = React.useRef<boolean>(null);
  mouseCaptureRef.current = mouseCapture;

  const _captionPhraseHoverHandler = (phraseId: number) => {
    setHoverPhraseId(phraseId);
  }
  const _captionWordPhraseHoverHandler = (phraseId: number, x: number, y: number) => {
    setPhraseHoverX(phraseId ? x : -1000);
    setPhraseHoverY(phraseId ? y : -1000);
    setWordHoverPhraseId(phraseId);
  }

  const captionMouseDownHandler = (index: number) => {
    if (isTouch) return;
    setClickCaptionIndex(index);
    setLastMouseEnterCaptionIndex(index);
    setScrollInfo({startIndex: index, endIndex: index});
  }

  const captionMouseEnterHandler = (index: number) => {
    if (isTouch) return;
    if (scrollInfo && clickCaptionIndex >= 0) {
      if (index < scrollInfo.startIndex) {
        setScrollInfo({...scrollInfo, ...{startIndex: index}});
      } else if (index > scrollInfo.endIndex) {
        setScrollInfo({...scrollInfo, ...{endIndex: index}});
      }
      //setScrollInfo({...scrollInfo, ...{currentIndex: index}});
    }
    if (clickCaptionIndex >= 0) {
      setLastMouseEnterCaptionIndex(index);
    }
    onCaptionMouseEnter(index)
  }

  const captionMouseUpHandler = (index: number) => {
    setClickCaptionIndex(-1);
    setLastMouseEnterCaptionIndex(-1);
  }

  const onScroll = (e: any) => {
    setScrolling(true);
    if (targetCaptionsContainer && targetCaptionsContainer.current) {
      setTargetScrollTop(targetCaptionsContainer.current.scrollTop);
    }
  }

  const handleScrollEnd = (e: any) => {
    setScrolling(false);
  }

  const handleMouseLeave = () => {
    onMouseLeave();

  };
  const handleMouseEnter = () => {
    if (isTouch) return;
    onMouseEnter();
  };

  const mouseDebounce = React.useRef(null);

  const captionPlayPauseClickHandler = (index: number) => {
    const caption: ICaptionsItem = captionsRef.current[index];
    onCaptionPlay(caption, playerActive);
    setStartedPlayIndex(index);
    setTimeout(() => {
      setStartedPlayIndex(-1);
    })
  };

  const captionClickHandler = (index: number, wordIndex: number, phraseId?: number) => {
    const caption: ICaptionsItem = captionsRef.current[index];
    onCaptionClick(index, caption, wordIndex, phraseId);
  }

  const captionTextSelectHandler = (index: number, wordIndex: number) => {
    const selection = SelectionUtil.getSelectedText();
    const highlighted = selection ? selection.toString().replace(/[\n\r]/gm, '') : '';
    if (selection && selection.anchorNode && selection.focusNode && highlighted.length > 0) {
      onSelectText(selection, selection.anchorNode.parentElement?.closest('.caption-item')?.id || '');
    }
    OneTimeTooltipSelectStorage.disable();
  }

  const debounceContextMenu = React.useRef<any>(null);
  const contextMenuHandler = (e: any) => {
    e.preventDefault();
    
    const selection = SelectionUtil.getSelectedText();
    const highlighted = selection ? selection.toString().replace(/[\n\r]/gm, '') : '';
    if (selection && selection.anchorNode && selection.focusNode && highlighted.length > 0) {
      onSelectText(null as any, null as any);
      if (debounceContextMenu.current) clearTimeout(debounceContextMenu.current);
      debounceContextMenu.current = setTimeout(() => {
        onSelectText(selection, selection.anchorNode?.parentElement?.closest('.caption-item')?.id || '');
      }, 100);
    }
  }
  
  const handleMouseDown = (event: React.SyntheticEvent) => {
    setMouseDown(true);
    if (isTouch) return;
    setCommonMouseCapture(true);
    if (event.target && event.target.getAttribute('data-start')) {
      setMouseCapture(true);
    }
    if (mouseDebounce.current) clearTimeout(mouseDebounce.current);
  }

  const handleMouseUp = (event: React.SyntheticEvent) => {
    setMouseDown(false);
    setMouseCapture(false);
  }

  React.useEffect(() => {
    const onMouseUp = (e: MouseEvent) => {
      if (clickCaptionIndexRef?.current >= 0 && lastMouseEnterCaptionIndexRef?.current >= 0) {
        setMouseUpCatchCaptionIndex(lastMouseEnterCaptionIndexRef.current);
      }
    }
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    }
  }, []);


  React.useEffect(() => {
    setCaptionsHelper(new TargetCaptionsHelper(phrases, captions));
  }, [phrases, captions]);

  const playerListener: IPlayerListener = {
    onTargetIndexChange(index: number): void {
      setActiveCaptionIndex(index);
      onChangeActiveIndex(index);
    },
    onTargetWordIndexChange(index: number): void {
      setActiveWordIndex(index);
    }
  }

  React.useEffect(() => {
    setScrollInfo(null);
    setClickCaptionIndex(-1);
    setLastMouseEnterCaptionIndex(-1);
    listenerHolder.addListener(playerListener);
    return () => {
      listenerHolder.removeListener(playerListener);
    };
  }, [captions, videoId, langCode]);

  React.useEffect(() => {
    if (elementRef && elementRef.current) {
      elementRef.current.addEventListener('scrollend', handleScrollEnd);
    }

    return () => {
      if (elementRef && elementRef.current) {
        elementRef.current.removeEventListener('scrollend', handleScrollEnd);
      }
    };
  }, []);


  React.useEffect(() => {
    if (captions && captions.length > 0) {
      const defaultHeight = captions.length > 500 ? 18 : 0; // do not allow load all target caption items for big videos
      targetItemsHeightList = Array(captions.length).fill(defaultHeight);
      setLoading(false);

      if (!captionHeightsCalc) {
        setTimeout(() => {
          calcCaptionItemsHeight();
          setCaptionHeightsCalc(true);
        })
      }
    } else {
      targetItemsHeightList = [];
      setLoading(true);
    }

  }, [captions]);

  const calcCaptionItemsHeight = () => {
    if (!calcHeightElmRef.current) return;

    const len = captions.length
    const {width, height} = calcHeightElmRef.current.getBoundingClientRect();
    const lineHeight = Math.round(height);
    const fontSize = 14;
    const fontFamily = '"Twemoji Country Flags", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
    const fontWeight = '400';
    const heightCalc = new ElementHeightCalc({
      lineHeight, width, fontWeight, fontSize, fontFamily
    })

    for (let i = 0; i < len; i++) {
      const height: number = heightCalc.getHeight(captions[i].text);
      targetItemsHeightList[i] = height;
    }
  }

  React.useEffect(() => {
    if (forwardCaptionIndex < 0) return;
    scrollToIndex(forwardCaptionIndex);
  }, [forwardCaptionIndex]);

  const [fullScreenHeight, setFullScreenHeight] = React.useState(0);
  React.useEffect(() => {
    if (isFullScreen) {
      let startIndex = 0;
      let endIndex;
      if (!activeCaptionIndex) {
        endIndex = 2;
      } else {
        startIndex = activeCaptionIndex - 1;
        endIndex = activeCaptionIndex + 1;
      }

      let height = 0;
      for (let i = startIndex; i <= endIndex; i++) {
        height += targetItemsHeightList[i];
      }
      setFullScreenHeight(height - 2);
      setTimeout(() => {
        targetCaptionsContainer && targetCaptionsContainer.current?.scrollTo({
          top: 1000,
          behavior: "smooth",
        })
      }, 100);
      return;
    } else {
      setFullScreenHeight(0);
    }

    const parentTop = targetCaptionsContainer && targetCaptionsContainer.current?.scrollTop as number;
    const parentBottom = parentTop + (targetCaptionsContainer && targetCaptionsContainer.current?.offsetHeight as number);
    const captionTop = getCaptionTopPos(activeCaptionIndex);
    const captionBottom = captionTop + targetItemsHeightList[activeCaptionIndex];

    if (parentBottom - captionBottom <= 100) {
      scrollToIndex(activeCaptionIndex);
    } else if (parentTop - captionTop > 0) {
      scrollToIndex(activeCaptionIndex);
    }
  }, [activeCaptionIndex]);

  const selectedText = React.useRef<any>(null);
  const selectedInterval = React.useRef<any>(null);
  React.useEffect(() => {
    if (!isTouch) return;
    let div = document.getElementById('output');
    if (!div) {
      div = document.createElement('div');
      div.id = 'output';
      div.style.position = 'fixed';
      div.style.bottom = '50%';
      div.style.right = '0';
      div.style.width = '50%';
      div.style.zIndex = '10000';
      div.style.color = '#f00';
      div.style.background = '#fffa';
      // document.body.append(div);
    }
    if (selectResult) {
      const listenSelection = () => { // на мобиле оставляем родное выделение с ушами. При изменении ушей, отправляем в onSelectText
        div.innerHTML += 'selectResult ' + selectResult.text + '<br>';
        selectedInterval.current = setInterval(() => { // событий на сдвиг ушей не нашёл, поэтому запускаем интервал
          const selection = SelectionUtil.getSelectedText();
          let selectedTextNew = selection ? selection.toString().replace(/[\n\r]/gm, '') : '';
          if (selectResult.text !== selectedTextNew) { // selection start change // ухо сдвинулось, теперь надо слушать, когда движение закончится
            div.innerHTML += 'start ' + selectedTextNew + '<br>';
            if (selectedInterval.current) clearInterval(selectedInterval.current);
            selectedText.current = selectedTextNew;
            selectedInterval.current = setInterval(() => {
              const selection = SelectionUtil.getSelectedText();
              let selectedTextNew = selection ? selection.toString().replace(/[\n\r]/gm, '') : '';
              div.innerHTML += 'changed ' + selectedTextNew + '<br>';
              if (selectedText.current === selectedTextNew) { // selection stop change // изменения закончились
                if (selectedInterval.current) clearInterval(selectedInterval.current);
                div.innerHTML += 'end ' + selectedTextNew + '<br>';
                const item = selection?.anchorNode?.parentElement?.closest('.caption-item') as HTMLElement;
                if (item) { // остановить слушатель, если selection вне targetCaptions
                  onSelectText(selection as any, item.id)
                    .then((ret) => { // true, если было изменение
                      div.innerHTML += 'ret ' + ret + '<br>';
                      if (!ret) listenSelection(); // если подвинуть ухо на часть слова, то изменения нет, заускаем слушатель снова
                    });
                }
                
              } else {
                selectedText.current = selectedTextNew;
              }
            }, 300);
          }
        }, 300);
      }
      listenSelection();
    } else {
      setScrollInfo(null);
      if (selectedInterval.current) clearInterval(selectedInterval.current);
      div.innerHTML += 'no<br>';
    }
  }, [selectResult]);

  React.useEffect(() => {
    setPhraseHoverTitle(onGetPhraseTitle(wordHoverPhraseId));
  }, [wordHoverPhraseId])

  const scrollToIndex = (index: number) => {
    const scrollValue = getCaptionTopPos(index);
    targetCaptionsContainer && targetCaptionsContainer.current?.scrollTo({
      top: scrollValue,
      behavior: "smooth",
    })
  }

  const getCaptionTopPos = (index: number) => {
    let result = 0;
    for(let i = 0; i < index; i++) {
      if (i < targetItemsHeightList.length) {
        result += targetItemsHeightList[i];
      }
    }
    return result;
  }

  const getActiveWordText = (caption: ICaptionsItem, wordIndex: number): string => {
    if (caption.words.length > 0 &&
      wordIndex < caption.words.length) {
      return caption.words[wordIndex].text;
    }
    return '';
  }

  const onItemSetHeight = (index: number, height: number) => {
    targetItemsHeightList[index] = height;
  }

  const getVisibleItemsIndexRange = () => {
    if (isSelectPopup) return {
      firstItemIndex: 0,
      lastItemIndex: captions.length - 1,
    };

    let firstItemIndex = -1;
    let lastItemIndex = -1;
    let currentTop = 0;
    const scrollHeight = targetCaptionsContainer && targetCaptionsContainer.current ? targetCaptionsContainer.current.offsetHeight : 0;
    const scrollBottom = targetScrollTop + scrollHeight;
    for(let i=0; i<captions.length; i++) {
      currentTop += targetItemsHeightList[i];
      if (firstItemIndex < 0 && currentTop >= targetScrollTop) {
        firstItemIndex = i - 1;
      }
      if (lastItemIndex < 0 && currentTop >= scrollBottom) {
        lastItemIndex = i + 1;
      }
    }
    if (firstItemIndex < 0)
      firstItemIndex = 0;
    if (lastItemIndex < 0)
      lastItemIndex = captions.length-1;

    return {firstItemIndex, lastItemIndex};
  }


  const getTargetCaptionItems = (firstItemIndex: number, lastItemIndex: number) => {
    const list = [];
    for(let index = firstItemIndex; index <= lastItemIndex; index++) {
      const caption = captions[index];
      if (!caption) continue;
      let phrases = captionsHelper?.getPhrases(caption, index, hoverPhraseId);
      const phrasesHash = phrases ? md5(JSON.stringify(phrases)) : '';
      if (!phrases || !phrases.length) phrases = undefined;
      const isActive = index === activeCaptionIndex;
      let activeWordText = '';
      if (isActive && caption.words?.length && activeWordIndex >=0) {
        activeWordText = getActiveWordText(caption, activeWordIndex);
      }
      let selected = currentSelectionCaptionIndex === index ||
        currentSelectionCaptionIndexStart > 0 && currentSelectionCaptionIndexStart >= index &&
        currentSelectionCaptionIndexEnd > 0 && currentSelectionCaptionIndexEnd <= index ||
        playCaptionByPhraseId && phrases && phrases.length &&
        phrases.find(p => p.phrase?.id === playCaptionByPhraseId)

      list.push(
      <TargetCaptionItem
        index={index}
        captionText={caption.text}
        startTime={caption.startTime}
        endTime={caption.endTime}
        phrases={phrases}
        phrasesHash={phrasesHash}
        isActive={isActive}
        type={ECaptionsItemType.TARGET}
        canHide={!mouseCapture}
        filterSelectContext={true}
        disableSelectMode={clickCaptionIndex === index}
        raiseMouseUpEvent={mouseUpCatchCaptionIndex === index}
        startedPlay={startedPlayIndex === index}
        onPlayPauseClick={captionPlayPauseClickHandler}
        onItemClick={captionClickHandler}
        onItemTextSelect={captionTextSelectHandler}
        onMouseEnter={captionMouseEnterHandler}
        onMouseLeave={onCaptionMouseLeave}
        onMouseDown={captionMouseDownHandler}
        onMouseUp={captionMouseUpHandler}
        onPhraseHover={_captionPhraseHoverHandler}
        onWordPhraseHover={_captionWordPhraseHoverHandler}
        key={`${caption.startTime}-${caption.endTime}`}
        selected={selected}
        selectedWord={activeWordText}
        playerActive={playerActive}
        onSetHeight={onItemSetHeight}
      />)
    }
    return list;
  }

  const getItemsRangeList = (): TRangeInfo[] => {
    const {firstItemIndex, lastItemIndex} = getVisibleItemsIndexRange()
    if (!scrollInfo) {
      return [{
        startIndex: firstItemIndex,
        endIndex: lastItemIndex
      }];
    }


    const intercept = (scrollInfo.startIndex >= firstItemIndex && scrollInfo.startIndex <= lastItemIndex) ||
      (scrollInfo.endIndex >= firstItemIndex && scrollInfo.endIndex <= lastItemIndex) ||
      (scrollInfo.startIndex <= firstItemIndex && scrollInfo.endIndex >= lastItemIndex);
    if (intercept) {
      const startIndex = scrollInfo.startIndex < firstItemIndex ? scrollInfo.startIndex : firstItemIndex;
      const endIndex = scrollInfo.endIndex > lastItemIndex ? scrollInfo.endIndex : lastItemIndex;
      return [{
        startIndex,
        endIndex
      }];
    }

    return [{
      startIndex: firstItemIndex,
      endIndex: lastItemIndex
    }];
/*
    if (scrollInfo.startIndex < firstItemIndex) {
      return [{
          startIndex: scrollInfo.startIndex,
          endIndex: scrollInfo.endIndex
        }, {
          startIndex: firstItemIndex,
          endIndex: lastItemIndex
        }
      ]
    } else {
      return [{
        startIndex: firstItemIndex,
        endIndex: lastItemIndex
      }, {
        startIndex: scrollInfo.startIndex,
        endIndex: scrollInfo.endIndex
      }
      ]
    }*/

  }

  const getRenderElements = (): TRenderItem[] => {
    if (isFullScreen) {
      let startIndex = 0;
      let endIndex;
      if (!activeCaptionIndex) {
        endIndex = 2;
      } else {
        let isFirst = activeCaptionIndex === 1;
        startIndex = activeCaptionIndex - 1 - +!isFirst;
        endIndex = activeCaptionIndex + 1 + +isFirst;
      }

      return [{
        type: ERenderElementType.ITEMS,
        startIndex,
        endIndex,
      }];
    }

    const rangeList: TRangeInfo[] = getItemsRangeList();

    let topSpaceHeight = 0;
    for(let i=0; i<rangeList[0].startIndex; i++) {
      topSpaceHeight += targetItemsHeightList[i];
    }
    let bottomSpaceHeight = 0;
    for(let i=rangeList[rangeList.length-1].endIndex+1; i<captions.length; i++) {
      bottomSpaceHeight += targetItemsHeightList[i];
    }
    const result: TRenderItem[] = [];
    if (topSpaceHeight > 0) {
      result.push({
        type: ERenderElementType.BLOCK,
        height: topSpaceHeight
      })
    }

    for(let i=0; i<rangeList.length; i++) {
      result.push({
        type: ERenderElementType.ITEMS,
        startIndex: rangeList[i].startIndex,
        endIndex: rangeList[i].endIndex
      });
      if (i < rangeList.length - 1) {
        let height = 0;
        for(let n=rangeList[i].endIndex+1; n<rangeList[i+1].startIndex; n++) {
          height += targetItemsHeightList[n];
        }
        result.push({
          type: ERenderElementType.BLOCK,
          height
        });
      }
    }

    if (bottomSpaceHeight > 0) {
      result.push({
        type: ERenderElementType.BLOCK,
        height: bottomSpaceHeight
      })
    }

    return result;
  }

  const [paddingLeft, setPaddingLeft] = React.useState(0);
  let [paddingLeftObserver, setPaddingLeftObserver] = React.useState(null as any);
  const data = React.useRef({ paddingLeftObserver });
	data.current = { paddingLeftObserver };
  const resizeObserverDebounce = React.useRef<any>(null);
  React.useEffect(() => {
    if (isCaptions2Columns) {
      setPaddingLeft(0);
      if (paddingLeftObserver) paddingLeftObserver.disconnect();
      return;
    }

    paddingLeftObserver = new ResizeObserver((e) => {
      if (resizeObserverDebounce.current) clearTimeout(resizeObserverDebounce.current);
      resizeObserverDebounce.current = setTimeout(() => {
        const rect = e[0].contentRect;
        let paddingLeft = 0;
        let width = rect.height * 16/9;
        if (width < rect.width) {
          paddingLeft = (rect.width - width) / 2;
          if (paddingLeft < 36) paddingLeft = 0;
        }
        setPaddingLeft(paddingLeft);
      }, 300);
    });
    setPaddingLeftObserver(paddingLeftObserver);

		const video = document.querySelector('.' + CLASS_MAIN_VIDEO);
		if (paddingLeftObserver && video) {
			paddingLeftObserver.observe(video);
    }

    return () => {
      if (resizeObserverDebounce.current) clearTimeout(resizeObserverDebounce.current);
    }
	}, [isCaptions2Columns]);



  return (
    <TooltipContainer
      x={phraseHoverX}
      y={phraseHoverY}
      title={phraseHoverTitle}
      show={!mouseDown}
    >
    <Box
      className={`captions-parent
        ${isShowCaptionsSelectionPopup ? 'captions-selection-popup-show' : ''}
      `}
      sx={{
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: solo ? '100%' : '50%',
        height: isFullScreen && fullScreenHeight ? `${fullScreenHeight}px` : '100%',
        flex: 1,
        pl: paddingLeft ? paddingLeft + 'px' : theme.spacing(4.5),
        pt: theme.spacing(3),

        '.onboarding-active &': {
          overflow: 'hidden',
        },

        '.root-inner.fullscreen &': {
			    overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          p: 0,
				},
      }}
      data-type={ECaptionsItemType.TARGET}
      ref={elementRef}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      onDragStart={(event) => event.preventDefault()}
      onScroll={onScroll}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchEnd={handleMouseUp}
      onContextMenu={contextMenuHandler}
    >
      {loading &&
      <Stack alignItems={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>
      }

      {!loading &&
        <>
          {getRenderElements().map((elm: TRenderItem) => {
            if (elm.type === ERenderElementType.BLOCK) {
              return (<div	style={{ minHeight: elm.height+'px' }}></div>)
            }
            if (elm.type === ERenderElementType.ITEMS) {
              return getTargetCaptionItems(elm.startIndex, elm.endIndex);
            }
          })}

        </>
      }

     {/* used for calulate element bound*/}
      <Box ref={calcHeightElmRef} style={{
        visibility: 'hidden',
        lineHeight: '1.57',
        fontSize: '14px',
        fontFamily: '"Twemoji Country Flags", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontWeight: '400',
        verticalAlign: 'text-bottom'
      }}>hp</Box>

    </Box>

    </TooltipContainer>
  )
};
