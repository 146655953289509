import { AppThunk } from '../../../../store/types';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';
import { findVideoPhraseById } from '../../../../store/videos/selectors';

export class PhraseListTranslatedEvents {

  public static onLoadPhrases(count: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return await PhraseEffects.loadPhrases(0, count, false);
    }
  }

  public static onAppendPhrases(offset: number, count: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return await PhraseEffects.loadPhrases(offset, count, true);
    }
  }

  public static onGetPhraseTitle(phraseId: number): AppThunk {
    return (
      dispatch,
      getState
    ): string => {
      if (phraseId) {
        const contextPhrase = findVideoPhraseById(getState(), phraseId);
        if (contextPhrase?.wordPhraseId) {
          const phrase = findVideoPhraseById(getState(), contextPhrase?.wordPhraseId);
          return phrase?.translated || '';
        }
      }
      return '';
    }
  }



}