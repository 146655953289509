import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Instance } from '@popperjs/core';
import { ITargetCaptionTranslateTooltipFiledsProps } from '../CaptionsContainer/TargetCaptions/components/types';


export const TooltipContainer: React.FC<ITargetCaptionTranslateTooltipFiledsProps> = ({
  show,
  x,
  y,
  title,
  children
}) => {
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);

  React.useEffect(() => {
    positionRef.current = { x, y };
    if (popperRef.current) {
      popperRef.current.update();
    }
  }, [x, y])

  const getBoundingClientRect = () => {
    return new DOMRect(
      x,
      y - 10,
      0,
      0,
    );
  }

  return (
    <Tooltip
      open={show && !!title}
      title={title}
      placement="top"
      arrow
      slotProps={{
        popper: {
          popperRef,
          anchorEl: {
            getBoundingClientRect
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
};