import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IMyLibEvents, IMyLibFields } from '../components/types';
import { MyLibEvents } from './events';
import { MyLib } from '../components/MyLib';
import { getUserOwnGroups } from '../../../../../store/models/selectors';
import { getAuthUserLangCode } from '../../../../../store/general/selectors';

const mapStateToProps = (
) => {
	return (
		state: IState
	): IMyLibFields => {
		const groups = getUserOwnGroups(state);
		const langCode = getAuthUserLangCode(state);
		
		return {
			groups,
			langCode,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IMyLibEvents => ({
	onShowAddPlaylist: () => dispatch(MyLibEvents.onShowAddPlayList()),
	onAddVideoLib: () => dispatch(MyLibEvents.onAddVideoLib()),
	onAddVideoYT: () => dispatch(MyLibEvents.onAddVideoYT()),
	onAddText: () => dispatch(MyLibEvents.onAddText()),
}
);

export const MyLibHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(MyLib);

MyLibHOC.displayName = 'MyLibHOC';