import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { CaptionsFixAiPopup } from '../components/CaptionsFixAiPopup';
import { ICaptionsFixAiPopupEvents, ICaptionsFixAiPopupFields } from '../components/types';
import { CaptionsFixAiPopupEvents } from './events';
import { getCaptionsFixAiInfo } from '../../../../store/general/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ICaptionsFixAiPopupFields => {
    const data = getCaptionsFixAiInfo(state)
    return {
      isOpen: !!data?.showPopup,
      videoId: data?.videoId || '',
      groupId: data?.groupId || 0
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ICaptionsFixAiPopupEvents => ({
    onStart: (onProgress, onSuccess, onError) =>
      dispatch(CaptionsFixAiPopupEvents.onStart(onProgress, onSuccess, onError)),
    onClose: () => dispatch(CaptionsFixAiPopupEvents.onClose()),
  }
);

export const CaptionsFixAiPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaptionsFixAiPopup);

CaptionsFixAiPopupHOC.displayName = 'CaptionsFixAiPopupHOC';