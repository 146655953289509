import { AppThunk } from '../../../../../store/types';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';

export class PhraseNotesPopupEvents {

	public static onClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			
			dispatch(PhraseListActions.setNotePopup(false));

		}
	}

}