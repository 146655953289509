import { ICaptionsItem } from '../../types/common';

export const TEXT_STATE_LOCAL_STORAGE = 'TEXT_STATE';
export const TEXT_UPDATE_ACTION = 'TEXT/TEXT_UPDATE_ACTION';
export const VIDEOS_UPDATE_ACTION = 'TEXT/VIDEOS_UPDATE_ACTION';

export interface ITextUpdateAction {
	type: typeof TEXT_UPDATE_ACTION;
	text: Partial<ITextState>;
}

export interface IVideosUpdateAction {
	type: typeof VIDEOS_UPDATE_ACTION;
	videos: Partial<TTextVideos>;
}

export interface ITextVideoText {
	title: string;
	captions: ICaptionsItem[];
}

export type TTextVideoTexts = Record<string, ITextVideoText> | undefined;

export type TTextVideos = Record<string, TTextVideoTexts> | undefined;

export interface ITextState {
	activeId?: string;
	videos?: TTextVideos;
}

export type ITextAction =
	| ITextUpdateAction
	| IVideosUpdateAction
