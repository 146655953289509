import { AppThunk } from '../../../../store/types';
import {
	setAddVideoConfirmAction,
	setCaptionsFixAiInfoAction,
	setShowInstallExtensionPopupAction
} from '../../../../store/general/actions';
import { YoutubeUrlUtil } from '../../../../../common/utils/youtube-url-util';
import { getAddVideoConfirm, getCaptionsFixAiInfo } from '../../../../store/general/selectors';
import { TAddVideoResult, addVideoEffect } from '../../../../effects/video_effects';
import { Navigator } from '../../../../effects/navigator';
import { createGroupNameIndex, getNativeLanguage, getTargetLanguage, getUserGroups } from '../../../../store/models/selectors';
import { UserGroupEffects } from '../../../../effects/userGroupEffects';
import { VideoMetaInfo } from '../../../../../common/utils/videoMetaInfo';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { VideoCaptionsPunctuationCheckStarter } from '../../../../effects/videoCaptionsPunctuationCheck';
import { ErrorEffects } from '../../../../effects/errorEffects';
import { ExtensionInstallChecker } from '../../../../effects/extension-install-checker';

export class AddVideoConfirmEvents {

	public static onChange(
		groupId: number,
		newListName?: string,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const {
				videoId,
				videoLangCodes = [],
				videoInfo,
				copyHash
			} = getAddVideoConfirm(state) || {};
			if (!videoId) {
				dispatch(this.onClose());
				return Promise.reject();
			}

			const extensionInstalled = await ExtensionInstallChecker.check();
			if (!extensionInstalled) {
				return dispatch(setShowInstallExtensionPopupAction(true));
			}

			try {
				if (!groupId) { // create group
					const userTargetLang = getTargetLanguage(state)?.code || '';
					const userNativeLang = getNativeLanguage(state)?.code || '';

					let targetLangCode = userTargetLang;
					let nativeLangCode = userNativeLang;
					const videoLangCode = VideoMetaInfo.getVideoPriorityTargetLang(videoLangCodes, userTargetLang, videoInfo);

					if (videoLangCode && videoLangCode !== nativeLangCode) {
						targetLangCode = videoLangCode;
					}

					let groupName = createGroupNameIndex(state, newListName || `${LangUtil.getLangNameByCode(targetLangCode)} / ${LangUtil.getLangNameByCode(nativeLangCode)}`);

					groupId = await UserGroupEffects.createGroup(groupName, targetLangCode, nativeLangCode);

					if (groupId) {
						await UserGroupEffects.loadGroup(groupId);
					}
				}

				// @ts-ignore
				const result: TAddVideoResult = await dispatch(addVideoEffect(groupId, YoutubeUrlUtil.getUrlById(videoId), '', false));
				if (result && result.videoId) {
					Navigator.openVideo(groupId, videoId, 0, true);
					UserGroupEffects.setLibraryGroupId(groupId);
				}
			} catch(e) {
				ErrorEffects.logError(e);
			}


			// new VideoCaptionsPunctuationCheckStarter(result.videoId, groupId).startWatch();

			setTimeout(() => { // show success
				dispatch(this.onClose());
			}, 2000);
			return Promise.resolve();
		}
	}

	public static onClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setAddVideoConfirmAction({ show: false }));
		}
	}


}