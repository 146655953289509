import { AUTH_CLIENT_ID } from '../constants';
import { IUserRestResponse, UserRest } from '../rest/user/userRest';
import { resetModelsAction } from '../../spa/store/models/actions';
import { prepareAfterOpen } from '../../spa/effects/prepareAfterOpen';
import { Navigator } from '../../spa/effects/navigator';
import { getDispatch } from '../../spa/store';

type TInitCodeClientResponse = {
  code: string
}

export class AuthManager {

  private static SCOPES = ['https://www.googleapis.com/auth/userinfo.profile', 'https://www.googleapis.com/auth/userinfo.email'];

  public static async autoLogin(): Promise<IUserRestResponse | null> {
    const token = TokenStorage.get();
    if (!token) return null;
    return UserRest.getUserByToken(token);
  }

  public static async login(): Promise<IUserRestResponse | null> {
    return new Promise(resolve => {
      const client = window.google.accounts.oauth2.initCodeClient({
        client_id: AUTH_CLIENT_ID,
        scope: AuthManager.SCOPES.join(' '),
        ux_mode: 'popup',
        callback: async(response: TInitCodeClientResponse) => {
          resolve(await AuthManager.onLogin(response.code));
        },
      });
      client.requestCode();
    });
  }

  public static async loginByEmail(email: string): Promise<IUserRestResponse | null> {
    const result = await UserRest.getUserByEmail(email);
    if (result && result.authUser) {
      TokenStorage.set(result.authUser.token);
    }
    return result;
  }

  public static updateUserToken(token: string) {
    TokenStorage.set(token);
  }

  public static async loadAnonymous(targetLangCode: string, nativeLangCode: string): Promise<IUserRestResponse> {
    return UserRest.createAnonymousUserAction(targetLangCode, nativeLangCode);
  }

  private static async onLogin(code: string): Promise<IUserRestResponse | null> {
    const result = await UserRest.getAuthUserByCode(code);
    if (result && result.authUser) {
      TokenStorage.set(result.authUser.token);
    }
    return result;
  }

  public static async logout() {
    TokenStorage.remove();
  }

}

class TokenStorage {

  private static KEY_NAME = 'user-token';

  public static get(): string {
    return sessionStorage.getItem(TokenStorage.KEY_NAME) || localStorage.getItem(TokenStorage.KEY_NAME);
  }

  public static set(value: string) {
    sessionStorage.setItem(TokenStorage.KEY_NAME, value);
    localStorage.setItem(TokenStorage.KEY_NAME, value);
  }

  public static remove() {
    sessionStorage.removeItem(TokenStorage.KEY_NAME);
    localStorage.removeItem(TokenStorage.KEY_NAME);
  }
}
