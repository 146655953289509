import { AppThunk } from '../../../../../store/types';
import { setCreatePlaylistModalOpenAction, setMainViewMode, setShowAddNewAudioPopupAction, setShowAddNewVideoPopupAction } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { Navigator } from '../../../../../effects/navigator';


export class MyLibEvents {

	public static onShowAddPlayList(
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(setCreatePlaylistModalOpenAction(true))
		}
	}

}