import * as React from 'react';
import { theme } from '../../../../../../../common/styles/theme';
import {
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	alpha,
	Box,
	Menu as MenuMUI, Modal
} from '@mui/material';
import { IMenuProps } from './types';
import { LogoLabel } from '../../../../../../../../icons/LogoLabel';
import { BookIcon } from '../../../../../../../../icons/BookIcon';
import { EMainMenuActive } from '../../../../../../store/general/types';
import { DeviceContext } from '../../../../../../../common/contexts/DeviceContext';
import { ProfilesListHOC } from '../../../../ProfilesList/hocs/ProfilesListHOC';
import { ArrowIcon } from '../../../../../../../../icons/ArrowIcon';
import { UsersIcon } from '../../../../../../../../icons/UsersIcon';
import { DailyGoalHOC } from '../../../DailyGoal/hocs/DailyGoalHOC';
import { Paper } from '../../../../../../../common/components/Paper/Paper';
import { Divider } from '../../../../../../../common/components/Divider/Divider';
import { LibraryIcon } from '../../../../../../../../icons/LibraryIcon';
import { LangMenuHOC } from '../../../LangMenu/hocs/LangMenuHOC';
import { RecentIcon } from '../../../../../../../../icons/RecentIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { ArrowDropIcon } from '../../../../../../../../icons/ArrowDropIcon';
import { AccountButtonHOC } from '../../../AccountButton/hocs/AccountButtonHOC';
import { PlaylistHOC } from '../../../Playlist/hocs/PlaylistHOC';
import { ChatGptIcon } from '../../../../../../../../icons/ChatGptIcon';
import { CommentIcon } from '../../../../../../../../icons/CommentIcon';
import { ChromeStoreIcon } from '../../../../../../../../icons/ChromeStoreIcon';
import { TelegramFilledBlueIcon } from '../../../../../../../../icons/TelegramFilledBlueIcon';
import { Shortcuts } from '../../../../Shortcuts/Shortcuts';
import { HelpIcon } from '../../../../../../../../icons/HelpIcon';
import { VideoLibraryRecentsHOC } from '../../../../VideoGroups/VideoLibraryRecents/hocs/VideoLibraryRecentsHOC';

export const Menu: React.FC<IMenuProps> = ({
	active,
	version,
	isLoadingProfiles,
	showDailyGoal,
	onClickChatGpt,
	onClickMyLibrary,
	onClickContent,
	onClickHelp,
	onClickHome,
	showRecents,
	showLangMenu,
	showAccountBtn,
	onExtension,
	onTelegram,
	onClickFeedback,
	onLoadProfiles,
	onClickRecent,
	onClose,
}) => {

	const className = 'menu';
	const {isMobile} = React.useContext(DeviceContext);

	const onClick = (callback: ()=>void) => {
		callback();
		if (onClose) onClose();
	}

	const [isShowMore, setShowMore] = React.useState(false);
	const onClickMore = () => {
		setShowMore(!isShowMore);
	}

	const [anchorProfiles, setAnchorProfiles] = React.useState<null | HTMLElement>(null);
	const openProfiles = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorProfiles(e.currentTarget);
		onLoadProfiles();
	}
	const closeProfiles = () => {
		setAnchorProfiles(null);
	}

	return (<>
		<Paper
			className={className}
			sx={{
				minWidth: theme.spacing(35),
				maxWidth: theme.spacing(35),
				height: 1,
				p: theme.spacing(0, 1),
				borderRadius: theme.spacing(.75),
				overflowX: 'hidden',
				overflowY: 'auto',

				'.mobile &': {
					height: '100%',
					m: 0,
					p: theme.spacing(1),
					borderRadius: 0,
				},
			}}
		>
			<Stack
				height={'100%'}
				justifyContent={'space-between'}
				gap={2}
				sx={{
					position: 'relative',
				}}
			>
				<Stack
					gap={theme.spacing(2)}
					sx={{
						p: `${theme.spacing(2)} 0`,
					}}
				>
					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
						gap={theme.spacing(1)}
					>
						<IconButton
							sx={{
								ml: '7px',
								p: 0,
							}} 
							onClick={onClickHome}
						>
							<LogoLabel sx={{ height: theme.spacing(3.25), width: 'auto' }} />
						</IconButton>

						{showLangMenu && <LangMenuHOC />}
					</Stack>

					<List
						disablePadding
						sx={{
							'.MuiButtonBase-root': {
								px: theme.spacing(1),
								borderRadius: theme.spacing(.75),

								'.MuiListItemIcon-root': {
									flex: `0 0 ${theme.spacing(3)}`,
									minWidth: theme.spacing(3),
									display: 'flex',
									justifyContent: 'center',
									mr: theme.spacing(1.25),
									color: theme.palette.grey[400],

									'.MuiSvgIcon-root': {
										width: theme.spacing(3),
										height: theme.spacing(3),
									}
								},

								'&:hover': {
									background: theme.palette.grey[50],
								},

								'&.Mui-selected': {
									'.MuiListItemIcon-root': {
										color: theme.palette.primary[400],
									}
								},
							},
							'.MuiListItemText-primary': {
								fontSize: theme.typography.h6,
								fontWeight: 600,
							},
						}}
					>
						{/* {isMobile &&
							<ListItem disablePadding>
								<ListItemButton onClick={()=>onClick(onClickPlaylists)} selected={active===EMainMenuActive.PLAYLISTS}>
									<ListItemIcon>
										<ListIcon/>
									</ListItemIcon>
									<ListItemText primary="Playlists" />
								</ListItemButton>
							</ListItem>
						} */}

						<ListItem disablePadding>
							<ListItemButton onClick={()=>onClick(onClickContent)} selected={active===EMainMenuActive.CONTENT_LIBRARY}>
								<ListItemIcon>
									<BookIcon
										sx={{
											width: `${theme.spacing(2.75)} !important`,
											height: `${theme.spacing(2.75)} !important`,
											color: theme.palette.secondary[200],
										}}
									/>
								</ListItemIcon>
								<ListItemText primary="Content Library" />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton onClick={()=>onClick(onClickMyLibrary)} selected={active===EMainMenuActive.MY_LIBRARY}>
								<ListItemIcon>
									<LibraryIcon
										sx={{
											color: theme.palette.primary.main,
										}}
									/>
								</ListItemIcon>
								<ListItemText primary="My Stuff" />
							</ListItemButton>
						</ListItem>

						{showRecents &&
						<ListItem disablePadding
							sx={{
								'.MuiButtonBase-root': {
									background: '',
								},
							}}
						>
							<ListItemButton
							 	selected={active===EMainMenuActive.RECENTS}
								onClick={onClickRecent}
							>
								<ListItemIcon>
									<RecentIcon
										sx={{
											width: `${theme.spacing(3.2)} !important`,
											height: `${theme.spacing(3.2)} !important`,
											color: theme.palette.secondary[300],
										}}
									/>
								</ListItemIcon>
								<ListItemText primary={'Recent'}/>
							</ListItemButton>
						</ListItem>
						}

						{!isShowMore &&
							<Stack
								flexDirection={'row'}
								justifyContent={'center'}
								sx={{
									mt: theme.spacing(2),
								}}
							>
								<Button
									className={isShowMore ? 'active' : ''}
									variant={'text'}
									endIcon={<ArrowDropIcon/>}
									onClick={onClickMore}
									sx={{
										height: theme.spacing(4),
										pl: theme.spacing(2),
										color: theme.palette.grey[400],

										'&.active': {
											color: theme.palette.grey[500],

											'.MuiSvgIcon-root': {
												transform: `rotate(180deg)`,
											},
										},
									}}
								>
									{isShowMore
										? 'Less'
										: 'More'
									}
								</Button>
							</Stack>
						}

						{isShowMore && <>
							<Divider />

							{!isMobile &&
								<ListItem disablePadding>
									<ListItemButton onClick={()=>onClick(onClickChatGpt)} selected={active===EMainMenuActive.CHATGPT_PROMPTS}>
										<ListItemIcon>
											<ChatGptIcon sx={{color: 'white'}} />
										</ListItemIcon>
										<ListItemText primary="ChatGPT Prompts" />
									</ListItemButton>
								</ListItem>
							}

							<ListItem disablePadding
								sx={{
									mt: theme.spacing(1),

									'.MuiButtonBase-root': {
										background: anchorProfiles ? theme.palette.grey[50] : '',
									},
								}}
							>
								<ListItemButton
									onClick={openProfiles}
								>
									<ListItemIcon>
										<UsersIcon />
									</ListItemIcon>
									<ListItemText primary={'Public profiles'} />

									{isLoadingProfiles
										? <CircularProgress
											size={theme.spacing(3)}
										/>
										: <ArrowIcon
											sx={{
												color: theme.palette.grey[400],
											}}
										/>
									}
								</ListItemButton>
							</ListItem>

							<ListItem disablePadding
								sx={{
									mt: theme.spacing(1),
								}}
							>
								<ListItemButton onClick={()=>onClick(onClickFeedback)} selected={active===EMainMenuActive.HELP}>
									<ListItemIcon>
										<CommentIcon
											sx={{
												width: `${theme.spacing(2.5)} !important`,
												height: `${theme.spacing(2.5)} !important`,
											}}
										/>
									</ListItemIcon>
									<ListItemText primary="Send Feedback" />
								</ListItemButton>
							</ListItem>

							{!isMobile &&
								<ListItem disablePadding
									// sx={linksSx}
								>
									<ListItemButton
										onClick={()=>onClick(onExtension)}
									>
										<ListItemIcon>
											<ChromeStoreIcon />
										</ListItemIcon>
										<ListItemText primary="Chrome Extension" />
									</ListItemButton>
								</ListItem>
							}

							<ListItem disablePadding
								// sx={linksSx}
							>
								<ListItemButton
									onClick={()=>onClick(onTelegram)}
								>
									<ListItemIcon>
										<TelegramFilledBlueIcon />
									</ListItemIcon>
									<ListItemText primary="Telegram Bot" />
								</ListItemButton>
							</ListItem>

							{!isMobile &&
								<Box
									sx={{
										mt: theme.spacing(2),
									}}
								>
									<Shortcuts />
								</Box>
							}

						</>}

					</List>
					
				</Stack>
				<Stack
					gap={2}
					sx={{
						position: 'sticky',
						bottom: 0,
						pb: theme.spacing(2),
						background: theme.palette.common.white,

						'&:before': {
							content: '""',
							position: 'absolute',
							left: 0,
							right: 0,
							top: theme.spacing(-4),
							height: theme.spacing(4),
							background: `linear-gradient(0deg, ${theme.palette.common.white} 25%, ${alpha(theme.palette.common.white, 0)} 100%)`,
						}
					}}
				>
					<PlaylistHOC />

					{showDailyGoal && <DailyGoalHOC />}

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
						gap={1}
					>					
						<Stack
							direction={'row'}
							gap={1}
						>
							{showAccountBtn &&
							<AccountButtonHOC
								closeMenu={onClose}
							/>}
							
						</Stack>
						<Stack
							direction={'row'}
							gap={1}
						>
							<IconButton
								sx={{
									color: theme.palette.grey[400],
									'.MuiSvgIcon-root': {
										width: theme.spacing(2.5),
										height: theme.spacing(2.5),
									},

									'&:hover': {
										color: theme.palette.grey[500],
									},
								}}
								title={'Help'}
								onClick={onClickHelp}
							>
								<HelpIcon />
							</IconButton>
							<Stack
								justifyContent={'center'}
								sx={{
									fontSize: theme.typography.subtitle2,
									opacity: .5,
								}}
							>
								LangMagic
								{version && <>
									{' '} v{version}
								</>}
								{/*<br/>
								<a href={AUDIO_PODCAST_FORM_URL} target={'_blank'}>Create audio-podcast</a>*/}
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Paper>

		{isMobile
			? <Modal
				isMobile
				open={!isLoadingProfiles && !!anchorProfiles}
				onClose={closeProfiles}
				PaperProps={{
					sx: {
						'& > .MuiStack-root': {
							gap: 0,
						},
					},
				}}
			>
				<ProfilesListHOC />
			</Modal>
			: <MenuMUI
				anchorEl={anchorProfiles}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={!isLoadingProfiles && !!anchorProfiles}
				onClose={closeProfiles}
				sx={{
					ml: theme.spacing(-2),

					'.MuiMenu-list': {
						display: 'flex',
						flexDirection: 'column',
						gap: theme.spacing(.5),
						maxWidth: theme.spacing(40),
					},
				}}
			>
				<ProfilesListHOC />
			</MenuMUI>
		}
	</>);
};