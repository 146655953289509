import { IState } from '../../../../../store/types';
import { getAuthUser } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { TeacherList } from '../components/TeacherList';
import { ITeacherListEvents, ITeacherListFields } from '../components/types';
import { getVideoTeacherShowNoteId, getVideoTeacherUserActiveId, getVideoTeacherUsers } from '../../../../../store/videos/selectors';
import { TeacherListEvents } from './events';
import { ProfileStorage } from '../../../../../../common/utils/local-storage/local-storage-profile';


const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): ITeacherListFields => {
		const teacherList = getVideoTeacherUsers(state).filter(t => t.user); // filter deleted users
		const activeId = getVideoTeacherUserActiveId(state);
		const teacherMode = getAuthUser(state)?.teacherMode;
		let openTeacherAnnotations = ProfileStorage.getOpenTeacherAnnotations();
		let teacherListOne;
		if (openTeacherAnnotations) {
			teacherListOne = teacherList.filter(t => t.user.id === openTeacherAnnotations);
			if (!teacherListOne.length) openTeacherAnnotations = 0;
		}

		const isNote = !!getVideoTeacherShowNoteId(state);

		return {
			show: !teacherMode && teacherList.length > 0,
			activeId,
			teacherList: openTeacherAnnotations ? teacherListOne : teacherList,
			openTeacherAnnotations,
			isNote,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): ITeacherListEvents => ({
	onTeacherSelect: id => dispatch(TeacherListEvents.onTeacherSelect(id)),
	onLoadNotes: id => dispatch(TeacherListEvents.onLoadNotes(id)),
	onViewProfile: id => dispatch(TeacherListEvents.onViewProfile(id)),
}
);

export const TeacherListHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(TeacherList);

TeacherListHOC.displayName = 'TeacherListHOC';