import React from 'react';
import { IPhraseDetailsTabPromptFormProps } from './types';
import { Box, Button, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { PhraseDetailsTabPromptFormVar } from './PhraseDetailsTabPromptFormVar';
import { IPhraseDetailsCustomPromptVar } from '../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { Paper } from "../../../../../../common/components/Paper/Paper";
import { PhraseDetailsTabPromptTestHOC } from '../../PhraseDetailsTabPromptTest/hocs/PhraseDetailsTabPromptTestHOC';
import { PHRASE_SAVE_BTN_LABEL, PHRASE_SAVE_PROCESSING_LABEL, } from './wordings';
import { PhraseSelectPopupHOC } from '../../../PhraseSelectPopup/hocs/PhraseSelectPopupHOC';

export const PhraseDetailsTabPromptForm: React.FC<IPhraseDetailsTabPromptFormProps> = ({
  id,
  show,
  title,
  prompt,
  description,
  processing,
  showSaveConfirm,
  vars,
  onSave,
  onValidate,
  onPromptChange,
  onShow,
}) => {

  if (!show) return null;

  const className = 'PhraseDetailsTabPromptForm';
  const classError = `${className}-error`;

  const [inputTitle, setInputTitle] = React.useState<string>('');
  const [inputPrompt, setInputPrompt] = React.useState<string>('');
  const [_inputPrompt, _setInputPrompt] = React.useState<string>(''); // for contentEditable
  const [inputDesc, setInputDesc] = React.useState<string>('');
  const [titleError, setTitleError] = React.useState<string>('');
  const [promptErrors, setPromptErrors] = React.useState<string[] | null>(null);

  const promptInputRef = React.useRef<HTMLDivElement>(null);
  const promptDescRef = React.useRef<HTMLDivElement>(null);

  const getPreparedPrompt = (text: string) => {
    vars.forEach(v => {
      let name = v.name.slice(1, v.name.length);
      const regex = new RegExp('\\$' + name + '(?![a-zA-Z])', 'g');
      text = text.replaceAll(regex, `<span class="prompt-param prompt-param_${name}">${v.name}</span>`);
      text = text.replaceAll(new RegExp('\n', 'g'), '<br/>');
    })
    return text;
  }

  React.useEffect(() => {
    onShow();
    _setInputPrompt(getPreparedPrompt(prompt));
  }, [id])

  React.useEffect(() => {
    setInputTitle(title);
  }, [title])

  React.useEffect(() => {
    setInputPrompt(prompt);
  }, [prompt])

  React.useEffect(() => {
    setInputDesc(description);
  }, [description])

  React.useEffect(() => {
    onPromptChange(inputPrompt);
  }, [inputPrompt])

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(e.target.value);
  }
  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.querySelectorAll("*:not(.prompt-param, br)").forEach((el) => { // delete pasted html tags
      el.replaceWith(...el.childNodes);
    });
  
    setInputPrompt(e.currentTarget.innerText || ''); // fix new lines
    //setInputPrompt(e.currentTarget.textContent || '');
  }

  const handleDescChange =  (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDesc(e.target.value);
  }

  const handleSave = () => {
    if (validate()) {
      onSave(inputTitle, inputPrompt, inputDesc);
    }
  }

  const validate = () => {
    setTitleError('');
    setPromptErrors(null);
    let result = true;
    let titleError;
    let promptErrors;

    if (!inputTitle.trim().length) {
      titleError = 'Button name is required';
      result = false;
    }
    const validateResult = onValidate(inputPrompt);
    if (!validateResult.success) {
      promptErrors = validateResult.errors || ['Unknown error'];
      result = false;
    }

    setTimeout(() => { // set error after timeout to animate flash
      if (titleError) setTitleError(titleError);

      if (promptErrors && promptErrors.length) setPromptErrors(promptErrors);

      if (!result) {
        const error = document.querySelector(`.${classError}`);
        if (error) error.scrollIntoViewIfNeeded({behavior: 'smooth', block: 'nearest'});
      }
    });

    return result;
  }

  function getCaretPosition(editableDiv: HTMLDivElement) {
    const selection = window.getSelection();
    let position = 0;

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(editableDiv);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
      const rangeText = preCaretRange.toString();
      const newLineCount = getNewLineCount(rangeText, editableDiv.innerText);
      position += newLineCount;
    }

    return position;
  }

  const getNewLineCount = (rangeText: string, fullText: string): number => {
    const fullTextList = fullText.split(' ')
    const rangeTextList = rangeText.split(' ')
    const count = rangeTextList.length < fullTextList.length ? rangeTextList.length : fullTextList.length;
    let result = 0;
    for(let i=0; i<count; i++) {
      if (fullTextList[i].indexOf("\n") >= 0) {
        const textLen = fullTextList[i].length;
        for(let n=0; n<textLen; n++) {
          if (fullTextList[i][n] === "\n") {
            result++;
          }
        }
      }
    }
    return result;
  }

  function getNodeIndexAtCaret(contentEditableElement) {
    const selection = window.getSelection();
    
    if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const { startContainer, startOffset } = range;

        // Check if the startContainer is a text node
        if (startContainer.nodeType === Node.TEXT_NODE) {
            let node = startContainer;
            if (startContainer.parentNode && startContainer.parentNode !== contentEditableElement) {
              node = startContainer.parentNode;
            }
            let index = Array.prototype.indexOf.call(contentEditableElement.childNodes, node);
            return {
                node: startContainer,
                index,
                offset: startOffset
            };
        } else {
            // If the caret is in an element node
            return {
                node: startContainer,
                index: Array.prototype.indexOf.call(contentEditableElement.childNodes, startContainer),
                offset: 0
            };
        }
    }

    return null; // No selection or range found
}

  const handleVarClick = (variable: IPhraseDetailsCustomPromptVar) => {
    const input = promptInputRef.current;
    if (input) {
      let varName = variable.name;
      if (variable.wrapQuotes) {
        varName = '`' + varName + '`  ';
      } else {
        varName = '`' + varName + '`  ';
      }
      input.focus();
      const el = document.activeElement;
      //@ts-ignore
      const selStart = getCaretPosition(/*el*/ promptInputRef.current)
      let node = getNodeIndexAtCaret(el);
      
      let val;
      if (selStart && node) {
        val = inputPrompt.slice(0, selStart) + varName + inputPrompt.slice(selStart);
        setTimeout(() => {
          const input = promptInputRef.current;
          if (input) {
            input.focus();
            const newRange = document.createRange();
            let n = input.childNodes[node.index + 2];
            let pos = n.length - 1;
            newRange.setStart(n, pos || 0);
            const selection = document.getSelection();
            if (selection) {
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          }
        })
      } else {
        val = inputPrompt + varName;
      }
      setInputPrompt(val);
      _setInputPrompt(getPreparedPrompt(val));
    }
  }

  const inputStyle = {
    '.MuiInputBase-input': {
      height: theme.spacing(3),
      fontSize: theme.typography.subtitle2
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  };

  const textareaStyle = {
    '.MuiInputBase-root': {
      padding: 0
    },
    '.MuiInputBase-input': {
      fontSize: theme.typography.subtitle2,
      overflow: 'auto !important',
      height: theme.spacing(25.75) + '!important',
    },
    '.MuiOutlinedInput-notchedOutline': { border: 'none' }
  };

  const paperStyle = {
    flex: `0 1 50%`,
    p: theme.spacing(2),
  };

  return (
    <Stack
      gap={theme.spacing(2)}
      sx={{
        position: 'relative',
        flex: `1 1 100%`,

        [`.${classError}`]: {
          color: theme.palette.error.main,
        },
      }}
    >
      <Stack
        gap={theme.spacing(2)}
        direction={'row'}
      >
        <Paper sx={paperStyle}>
          <Stack spacing={theme.spacing(3)} sx={{ maxHeight: '100%' }}>
            <Stack gap={theme.spacing(2)}>

              {showSaveConfirm &&
              <Text variant={'h6'} fontWeight={600} sx={{ color: 'green' }}>
                Prompt saved
              </Text>
              }

              <Stack gap={theme.spacing()}>
                <Text variant={'body1'} fontWeight={600}>
                  {titleError &&
                    <Text
                      className={`${classError} animate__animated animate__flash`}
                      variant={'body1'}
                    >
                      {titleError}
                    </Text>
                  }
                  Button name:
                </Text>
                <TextField
                    fullWidth
                    variant={'filled'}
                    autoFocus
                    value={inputTitle}
                    onChange={onTitleChange}
                    multiline={false}
                    sx={inputStyle}
                />
              </Stack>

              <Stack spacing={theme.spacing()}>
                <Text variant={'body1'} fontWeight={600}>
                  {promptErrors &&
                    <Stack spacing={theme.spacing(0.25)}>
                      {promptErrors.map(error => {
                        return (<Text
                          className={`${classError} animate__animated animate__flash`}
                          key={error}
                          variant={'body1'}
                        >{error}</Text>)
                      })}
                    </Stack>
                  }
                  Prompt:
                </Text>
                <Box
                  contentEditable
                  className={'prompt-input'}
                  ref={promptInputRef}
                  onInput={handlePromptChange}
                  sx={{
                    p: theme.spacing(2),
                    fontSize: theme.typography.subtitle2,
                    overflow: 'auto',
                    maxHeight: '70vh',
                    borderRadius: theme.spacing(.75),
                    background: theme.palette.grey[50],
                    color: theme.palette.grey[900],
                    textTransform: 'unset',
                    border: '2px solid transparent',
                    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                        background: theme.palette.grey[100],
                    },
                    '&:focus': {
                        background: theme.palette.grey[50],
                        borderColor: theme.palette.primary[400],
                        outline: 'none',
                    },

                    '.prompt-param': {
                      p: '4px 0',
                      lineHeight: theme.spacing(3.25),
                    },
                    '.prompt-param_Phrase': {
                      backgroundColor: theme.palette.info[100],
                    },
                    '.prompt-param_Context': {
                      backgroundColor: theme.palette.info[500],
                    },
                    '.prompt-param_TargetLanguage, .prompt-param_NativeLanguage, .prompt-param_PhraseList': {
                      backgroundColor: theme.palette.primary[100],
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: _inputPrompt }}
                />
              </Stack>

              <Stack spacing={theme.spacing()}>
                <Stack direction={'row'} gap={theme.spacing()}>
                  <Text fontWeight={600} variant={'body1'}>Variables</Text>
                  <Text variant={'body1'} sx={{ opacity: 0.5 }}>(click any buttons below to add them to the prompt)</Text>
                </Stack>
                <Stack direction={'row'} sx={{ gap: theme.spacing() }} flexWrap={'wrap'}>
                  {vars.map(variable => {
                    return <PhraseDetailsTabPromptFormVar
                        key={variable.name}
                        color={variable.color}
                        name={variable.name}
                        onClick={() => handleVarClick(variable)}
                    />
                  })}
                </Stack>
              </Stack>

              <Stack spacing={theme.spacing()}>
                <Text variant={'body1'} fontWeight={600}>Description:</Text>
                <TextField
                  className={'prompt-input'}
                  ref={promptDescRef}
                  variant={'filled'}
                  onChange={handleDescChange}
                  value={inputDesc}
                  fullWidth
                  multiline
                  sx={textareaStyle}
                />
              </Stack>

            </Stack>

          </Stack>
        </Paper>
        <PhraseDetailsTabPromptTestHOC />
      </Stack>

      <Button
          onClick={handleSave}
          disabled={processing}
          fullWidth
          variant={'contained'}
          sx={{
            position: 'sticky',
            zIndex: 100,
            bottom: 0,
            height: theme.spacing(6),

            '&:after': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: theme.spacing(-1),
              right: theme.spacing(-1),
              height: theme.spacing(2),
              background: theme.palette.grey[100],
            },
          }}
      >{processing ? PHRASE_SAVE_PROCESSING_LABEL : PHRASE_SAVE_BTN_LABEL}</Button>

      <PhraseSelectPopupHOC />
    </Stack>
  );



}

