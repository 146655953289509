import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Stack,
  Paper,
} from '@mui/material';
import { Text } from '../../../../../common/components/Text/Text';
import { ILangSelectProps, TLangSelectOption } from './types';
import { theme } from '../../../../../common/styles/theme';
import { LangFlag } from '../../../../../spa/components/dashboard/LangFlag/LangFlag';

export const LangSelect: React.FC<ILangSelectProps> = ({
  disabled,
  label,
  langCode,
  langs,
  highlightWithDefVideos,
  onChange,
}) => {

  const handleChange = (_: React.SyntheticEvent, newValue: TLangSelectOption | null) => {
    onChange(newValue?.code || '');
  };

    const [options, setOptions] = React.useState([{
        code: '',
        name: 'Select language',
        highlight: false,
    }]);
  React.useEffect(() => {
      const selectOption: TLangSelectOption = {
          code: '',
          name: 'Select language',
          highlight: false,
      };
      const langOptions = langs.map((l) => {
          return {
              code: l.code,
              name: l.name,
              highlight: highlightWithDefVideos && l.installUserGroupExist ? true : false,
          };
      });
      setOptions([selectOption, ...langOptions]);
  }, [langs]);

  const selectStyle = {
    minWidth: theme.spacing(24.75),
  };

  const dropdownStyle = {
    boxShadow: theme.shadows[8],
  };

  return (
    <FormControl
      sx={{
        width: 1
      }}
      disabled={disabled || !langs || !langs.length}
    >
      <InputLabel shrink htmlFor='lang-select'>
        {label}
      </InputLabel>
      <Autocomplete
        autoHighlight
        disabled={disabled || !langs || !langs.length}
        id='lang-select'
        options={options}
        getOptionLabel={(option) => option.name}
        value={options.find((option) => option.code === langCode) || options[0]}
        onChange={handleChange}
        sx={selectStyle}
        PaperComponent={({ children, ...other }) => (
          <Paper {...other} style={dropdownStyle}>
            {children}
          </Paper>
        )}
        renderInput={(params) => {
                return <TextField
                    {...params}
                    variant='filled'
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <React.Fragment>
                                {params.InputProps.startAdornment}
                                <LangFlag code={langCode} showCode={false} />
                            </React.Fragment>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />;
            }}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack direction='row' alignItems='center' gap={theme.spacing(1)}>
              <LangFlag code={option.code} showCode={false} />
              <Text variant={'subtitle2'}>
                {option.highlight ? <b>{option.name}</b> : option.name}
              </Text>
            </Stack>
          </li>
        )}
      />
    </FormControl>
  );
};
