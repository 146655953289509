import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from '../../../../../../common/styles/theme';
import { Box, MenuItem, Stack } from '@mui/material';
import { IVideoLibraryHeaderProps } from './types';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Text } from '../../../../../../common/components/Text/Text';
import LangFlag from '../../../LangFlag/LangFlag';
import { BookIcon } from '../../../../../../../icons/BookIcon';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';
import { EditGroupHOC } from '../../../PlayList/EditGroup/hocs/EditGroupHOC';
import { PlayListLevelTitles } from '../../../PlayList/PlayListLevelSelect/types';

export const VideoLibraryHeader: React.FC<IVideoLibraryHeaderProps> = ({
	group,
	// groups,
	// libraryGroupId,
	targetLangCode,
	nativeLangCode,
	onGroupChange,
	onEditPlayList,
	onCopyLink,
	// onClickAdd,
	onClickMyLibrary,
}) => {
	const { isMobile } = useContext(DeviceContext);

	const { groupId } = useParams();

	const [menuOpened, setMenuOpened] = React.useState(false);

	React.useEffect(() => {
		onGroupChange(parseInt(groupId));
	}, [groupId]);

	// React.useEffect(() => {
	// 	if (groups.length === 0) return;
	// 	const gId = parseInt(groupId);
	// 	if (gId && !libraryGroupId) {
	// 		onGroupChange(gId);
	// 	}
	// }, [groupId, groups, libraryGroupId]);

	return (<>
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<ButtonMenu
				buttonProps={{
					sx: {
						position: 'absolute',
						right: theme.spacing(-1),
						top: theme.spacing(-1),
					},
				}}
				menuProps={{
					sx: {
						'.MuiMenuItem-root': {
							px: theme.spacing(.5),
						},
					},
					open: menuOpened,
				}}
				onClose={() => setMenuOpened(false)}
				onOpen={() => setMenuOpened(true)}
			>
				<MenuItem
					disabled
					sx={{
						pl: theme.spacing(2),
						opacity: `.7 !important`,
					}}
				>
					<Stack
						direction={'row'}
						gap={2}
						alignItems={'center'}
						sx={{
							pl: theme.spacing(2),
						}}
					>
						<Stack
							direction={'row'}
							gap={1}
							alignItems={'center'}
						>
							<LangFlag code={targetLangCode} showCode />
							<LangFlag code={nativeLangCode} showCode />
						</Stack>

						{!!group?.level &&
							<Box
								sx={{
									mr: theme.spacing(1),
									p: theme.spacing(.25, .5),
									fontSize: theme.typography.fontSize,
									background: theme.palette.grey[50],
									borderRadius: theme.spacing(.5),
								}}
							>
								{PlayListLevelTitles[group.level]}
							</Box>
						}
					</Stack>
					
				</MenuItem>

				<IconMenuItem
					title={'Edit playlist'}
					onClick={() => {
						onEditPlayList();
						setMenuOpened(false);
					}}
				>
					<PencilIcon
						sx={{
							width: `${theme.spacing(2)} !important`,
							height: `${theme.spacing(2)} !important`,
						}}
					/>
				</IconMenuItem>

				<IconMenuItem
					title={'Share public link'}
					onClick={onCopyLink}
				>
					<LinkIcon />
				</IconMenuItem>

				<IconMenuItem
					title={'My Library'}
					onClick={onClickMyLibrary}
				>
					<BookIcon
						sx={{
							width: `${theme.spacing(2)} !important`,
							height: `${theme.spacing(2)} !important`,
						}}
					/>
				</IconMenuItem>

				{/* <Button
					variant={'contained'}
					startIcon={<PlusIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />}
					sx={{
						mt: theme.spacing(1),
						mb: theme.spacing(1),
						ml: theme.spacing(1.5),
					}}
					onClick={() => {
						onClickAdd();
						setMenuOpened(false);
					}}
					>
					Add Video
				</Button> */}

			</ButtonMenu>

			<Text
				sx={{
					color: theme.palette.grey[400],
				}}
			>
				Playlist
			</Text>

			<Box
				sx={{
					display: '-webkit-box',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					'-webkit-line-clamp': '2',
					'-webkit-box-orient': 'vertical',
					maxHeight: '2.6em',

					fontSize: theme.typography.subtitle2,
					fontWeight: 600,
				}}
			>
				<Box
					sx={{
						lineHeight: `1.3em !important`,
					}}
				>
					{group?.name}
				</Box>
			</Box>
		</Box>

		<EditGroupHOC />
	</>
	);
};
