import { AppThunk } from '../../../../../store/types';
import { setCreatePlaylistModalOpenAction, setMainViewMode, setShowAddNewAudioPopupAction, setShowAddNewVideoPopupAction } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';


export class MyLibEvents {

	public static onShowAddPlayList(
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(setCreatePlaylistModalOpenAction(true))
		}
	}

	public static onAddVideoLib(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setMainViewMode(EMainViewMode.CONTENT_LIBRARY));
			dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
		}
	}

	public static onAddVideoYT(
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setShowAddNewVideoPopupAction(true));
		}
	}

	public static onAddText(
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setShowAddNewAudioPopupAction(true))
		}
	}

}