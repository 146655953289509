import { EVocabularyPhraseType, IVocabularyPhrase, TNewVocabularyPhrase } from '../../types/common';
import { AppThunk, TAsyncDispatch } from '../../store/types';
import { getCurrentMovieKey } from '../../store/current-video/selectors';
import { getActiveGroupId, getGroupTargetLanguage, getUserGroupById } from '../../store/models/selectors';
import { createPhraseRest } from '../../../common/rest/video/createVideoPhrase';
import { decreasePhraseCount, increasePhraseCount } from '../phraseCountEffects';
import { IRestRemoveGroupVideo, removeGroupVideo } from '../../../common/rest/video/removeGroupVideo';
import { removeVideoFromGroupAction } from '../../store/models/actions';
import { updatePhrase } from '../../../common/rest/video/updatePhrase';
import { PhraseListSelectors } from '../../store/phrase-list/selectors';
import { EPhraseListMode } from '../../store/phrase-list/types';
import { PhraseListActions } from '../../store/phrase-list/actions';
import { getDispatch, getState } from '../../store';
import { PhraseEffects } from './PhraseEffects';
import { phrasesPartLoadSize } from '../../components/dashboard/Vocabulary/vocabulary/components/types';
import { addVideoPhraseAction, updateVideoPhraseAction } from '../../store/videos/actions';
import { EventsRouter } from '../../../common/events/eventsRouter';
import { Events } from '../../../common/events/types';
import { PhraseDetailsEffects } from '../phrase-details/phrase-details-effects';
import { setOnboardingAction, setPhrasesExistAction } from '../../store/general/actions';
import { VocabularyEffects } from './vocabulary-effects';
import { getAuthUser, getOnboarding } from '../../store/general/selectors';

export const addPhraseByHotKey = async (time: number) => {
  const dispatch = getDispatch();

  EventsRouter.trackEvent(Events.SAVE_PHRASE_BUTTON_CLICKED);
  dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
  const phrase: IVocabularyPhrase | null = await PhraseEffects.saveFromHotKey(time);
  if (phrase) {
    PhraseDetailsEffects.saveForPhrase(phrase.id);
    dispatch(setPhrasesExistAction(true));
    dispatch(VocabularyEffects.flashPhrase(phrase.id));
    EventsRouter.trackEvent(Events.SAVE_PHRASE);
  }
};

export const addPhraseToVocabularyEffect = (
  phrase: TNewVocabularyPhrase,
): AppThunk => async(
  dispatch: TAsyncDispatch,
  getState
): Promise<IVocabularyPhrase | null> => {
  const videoId = phrase.videoKey;
  const activeGroupId = phrase.userGroupId;

  if (videoId && activeGroupId) {

    const createdPhrase = await dispatch(createPhraseRest(phrase, getAuthUser(getState()).teacherMode));
    const inList = checkShowPhraseInList(createdPhrase);
    if (!inList) {
      dispatch(PhraseListActions.setListMode(EPhraseListMode.CURRENT_VIDEO));
      await PhraseEffects.loadPhrases(0, phrasesPartLoadSize, false);
    }
    //dispatch(PhraseListActions.addPhrase(createdPhrase));
    addPhrase(createdPhrase);
    dispatch(addVideoPhraseAction(createdPhrase));
    if (phrase.type === EVocabularyPhraseType.WORD_SELECTED) {
      dispatch(increasePhraseCount(activeGroupId));
    }

    const onboarding = getOnboarding(getState());
    if (onboarding &&
      onboarding.isActive &&
      onboarding.step !== undefined &&
      onboarding.step < 7
    ) {
      dispatch(setOnboardingAction({ step: 7 }))
    }

    return createdPhrase;
  }
  return null;
};

const addPhrase = (phrase: IVocabularyPhrase) => {
  const state = getState();
  const dispatch = getDispatch();
  const list: IVocabularyPhrase[] = [...PhraseListSelectors.getList(state)];
  const exist = list.findIndex(p => p.id === phrase.id) >= 0;
  if (exist) return;
  if (PhraseListSelectors.getListMode(state) === EPhraseListMode.CURRENT_VIDEO) {
    const ps = list.findIndex(p => p.startTime > phrase.startTime);
    if (ps < 0) {
      list.push(phrase);
    } else {
      list.splice(ps, 0, phrase);
    }
  } else {
    list.push(phrase);
  }
  dispatch(PhraseListActions.setPhraseList(list));
}

const checkShowPhraseInList = (phrase: IVocabularyPhrase) => {
  const state = getState();
  const mode: EPhraseListMode = PhraseListSelectors.getListMode(state);
  if (mode === EPhraseListMode.ALL)
    return true;
  if (mode === EPhraseListMode.CURRENT_VIDEO) {
    const videoId = getCurrentMovieKey(state);
    return videoId === phrase.videoKey;
  }
  if (mode === EPhraseListMode.CURRENT_GROUP) {
    const groupId = getActiveGroupId(state);
    return groupId === phrase.userGroupId;
  }
  if (mode === EPhraseListMode.LANG) {
    const lang = PhraseListSelectors.getListModeLang(state);
    const group = getUserGroupById(state, phrase.userGroupId || 0);
    if (lang && group) {
      const groupLang = getGroupTargetLanguage(state, group);
      return !!(groupLang && groupLang.code === lang);
    }
    return false;
  }
}


export const updatePhraseEffect = (
  phrase: IVocabularyPhrase,
): AppThunk => async(
  dispatch: TAsyncDispatch,
  getState
): Promise<any> => {
  await dispatch(updatePhrase(phrase));
  dispatch(PhraseListActions.updatePhrase(phrase));
  dispatch(updateVideoPhraseAction(phrase));
};

export const removeVideoFromGroup = (
  videoKey: string,
  userGroupId: number
): AppThunk => async(
  dispatch: TAsyncDispatch,
  getState
) => {
  const state = getState();
  if (videoKey && userGroupId) {
    const currentVideoId = getCurrentMovieKey(state);
    const removeRest: IRestRemoveGroupVideo = await dispatch(removeGroupVideo(videoKey, userGroupId));
    if (removeRest && removeRest.phraseCount) {
      dispatch(decreasePhraseCount(userGroupId, removeRest.phraseCount));
    }
    dispatch(removeVideoFromGroupAction(videoKey, userGroupId));
  }
}
