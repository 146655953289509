import React from 'react';
import { IPhraseTranslationProps } from './types';
import { alpha, Box, Button, CircularProgress, Fade, Menu, Stack, SxProps, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { UserLangsTranslationColors } from '../../../PhraseDetailsTabsEditor/PhraseDetailsTabTranslation/helpers/userLangsTranslationColors';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { CLASS_ONBOARDING_NOEVENT, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { PhraseTranslationSettingsHOC } from '../../../PhraseDetailsTabsEditor/PhraseDetailsTabTranslation/PhraseTranslationSettings/hocs/PhraseTranslationSettingsHOC';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { DisketteIcon } from '../../../../../../../icons/DisketteIcon';
import { OneTimeTooltipTranslationsHOC } from '../../../OneTimeTooltip/hocs/OneTimeTooltipTranslationsHOC/OneTimeTooltipTranslationsHOC';
import { ArrowIcon } from '../../../../../../../icons/ArrowIcon';

export const PhraseTranslation: React.FC<IPhraseTranslationProps> = ({
	active,
	text,
	translateResults,
	processing,
	translateLangs,
	isTranslateContext,
	showTranslationsHint,
	storyFromTranslatedId,
	isPreview,
	isNotePopup,
	onLoad,
	onRefresh,
	onCopyNote,
	onOpenNote,
	onHideTranslationsHint,
}) => {
	if (!text) return null;

	const className = 'phrase-translation';
	const {isMobile} = React.useContext(DeviceContext);

	const [settingsEl, setSettingsEl] = React.useState<null | HTMLElement>(null);
	const [translateKey, setTranslateKey] = React.useState<string>(null);
	const [isShowButtons, setShowButtons] = React.useState(true);
	const showButtonsTimer = React.useRef(null);
	const [isShowSaveArea, setShowSaveArea] = React.useState(false);
	const isMouseIn = React.useRef(false);
	const [isSaved, setSaved] = React.useState(false);

	const buttonStyle: SxProps = {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		color: theme.palette.grey[400],
		
		'&:hover': {
			color: theme.palette.grey[700],
			background: alpha(theme.palette.grey[50], .5),
		},

		'.MuiSvgIcon-root': {
			width: theme.spacing(2),
			height: theme.spacing(2),
		},
	};

	const onClickSave = () => {
		if (isSaved) {
			if (!isNotePopup) {
				onOpenNote();
				setSaved(false);
			}
		} else {
			onCopyNote();
			if (!isPreview) setSaved(true);
			if (isNotePopup) {
				setTimeout(() => {
					setSaved(false);
				}, 3000);
			}
		}
	};

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSettingsEl(event.currentTarget);
		onHideTranslationsHint();
	};
	const handleSettingsClose = () => {
		setSettingsEl(null);
	};

	const onMouseIn = () => {
		if (isMobile) return;
		if (showButtonsTimer.current) clearTimeout(showButtonsTimer.current);
		showButtonsTimer.current = setTimeout(() => {
			setShowButtons(true);
		}, 300) as any;
		isMouseIn.current = true;
	};
	const onMouseOut = () => {
		if (isMobile) return;
		if (showButtonsTimer.current) clearTimeout(showButtonsTimer.current);
		showButtonsTimer.current = setTimeout(() => {
			setShowButtons(false);
		}, 500) as any;
		isMouseIn.current = false;
	};

	const onSaveMouseIn = () => {
		if (isMobile) return;
		setShowSaveArea(true);
	};
	const onSaveMouseOut = () => {
		if (isMobile) return;
		setShowSaveArea(false);
	};

	React.useEffect(() => {
		if (!settingsEl) {
			setTranslateKey(text + '|' + translateLangs.map(l => l.code).join('-'));
		}
	}, [active, text, translateLangs, settingsEl]);

	React.useEffect(() => {
		setSaved(false);
		if (translateKey) { // avoid double onLoad trigger
			onLoad();
		}
	}, [translateKey, isTranslateContext]);

	React.useEffect(() => {
		if (isMobile) return;

		if (translateResults && translateResults.length) {
			setShowButtons(true);
			setTimeout(() => {
				if (!isMouseIn.current) setShowButtons(false);
			}, 1000);
		} else {
			setShowButtons(false);
		}
	}, [translateResults]);

	let isContext = false;

	return (processing
		? <Stack
			sx={{
				width: 1,
				mt: theme.spacing(3),
				alignItems: 'center',
			}}
		>
			<CircularProgress size={theme.spacing(3)} />
		</Stack>
		: !translateResults ? null :
			<Stack
				className={className}
				sx={{
					position: 'relative',
					width: 1,
					pl: theme.spacing(3),

					'.mobile &': {
						p: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(3)}`,
					},

					'.mobile .phrase-details-column &': {
						pr: 0,
					},
				}}
				onMouseEnter={onMouseIn}
				onMouseLeave={onMouseOut}
			>
				<Stack
					className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step6
						${isShowSaveArea ? 'show-save-area' : ''}
					`}
					gap={theme.spacing(0.75)}

					sx={{
						position: 'relative',

						'&.show-save-area:before': {
							content: '""',
							position: 'absolute',
							left: theme.spacing(-.75),
							right: 0,
							top: theme.spacing(-.75),
							bottom: theme.spacing(-.75),
							background: alpha(theme.palette.warning[100], .2),
							borderRadius: theme.spacing(.75),
						},
					}}
				>
					{translateResults.map((result, index) => {
						let isContextBlock = !isContext && result.isContext;
						isContext = !!result.isContext;
						return <>
							{isContextBlock &&
								<Box
									sx={{
										mt: theme.spacing(1),
									}}
								/>
							}
							<Stack
								direction={'row'}
								key={result.langCode}
							>
								<TextWithSpeakerHOC
									key={result.langCode}
									textSx={{
										color: UserLangsTranslationColors.getColor(result.langCode as string),
									}}
									lang={String(result.langCode)}
									text={result.success ? result.result : 'Unknown error'}
								/>
								{!result.success &&
									<Button
										sx={{
											ml: theme.spacing(1),
										}}
										onClick={() => onRefresh(result.langCode as string)}
									>
										Refresh
									</Button>
								}
							</Stack>
						</>
					})}
				</Stack>

				<Fade
					in={isShowButtons || !!settingsEl || showTranslationsHint || isSaved}
					timeout={{
						exit: 1000,
					}}
				>
					<Stack
						className={`${className}__buttons`}
						flexDirection={'row'}
						gap={theme.spacing(1)}
						sx={{
							mt: theme.spacing(1),
							ml: theme.spacing(-.5),
						}}
					>
						{!storyFromTranslatedId &&
							<Button
								className={isSaved ? 'saved' : ''}
								variant={'contained'}
								sx={{
									height: theme.spacing(4),
									p: `0 ${theme.spacing(1.25)}`,
									color: theme.palette.warning[900],
									background: theme.palette.warning[100],

									'&:hover, &:focus': {
										background: theme.palette.info[200],
									},

									'&.saved': {
										color: theme.palette.primary.main,
										background: theme.palette.primary.light,
									},
								}}
								onClick={onClickSave}
								onMouseMove={onSaveMouseIn}
								onMouseLeave={onSaveMouseOut}
							>
								{isSaved
									? <ArrowIcon
										sx={{
											ml: theme.spacing(-.5),
											mr: theme.spacing(.5),
											transform: 'scaleX(-1)',
										}}
									/>
									: <DisketteIcon
										sx={{
											mr: theme.spacing(.5),
										}}
									/>
								}
								{isSaved
									? isNotePopup
										? 'Saved'
										: 'Open saved'
									: 'Save'
								}
							</Button>
						}

						<Stack
							flexDirection={'row'}
							gap={theme.spacing(1)}
						>
							<Tooltip
								title={'Translation settings'}
							>
								<Button
									onClick={handleSettingsClick}
									sx={buttonStyle}
								>
									<SettingsIcon />
								</Button>
							</Tooltip>

							{isMobile
								? <Modal
									onClose={handleSettingsClose}
									open={!!settingsEl}
									isMobile={isMobile}
									>
									<PhraseTranslationSettingsHOC />
								</Modal>
								: <Menu
									anchorEl={settingsEl}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={!!settingsEl}
									onClose={handleSettingsClose}
									sx={{
										'& .MuiPaper-root': {
											width: theme.spacing(35),
											mt: theme.spacing(1),
											boxShadow: theme.shadows[16],
										},
									}}
								>
									<PhraseTranslationSettingsHOC />
								</Menu>
							}

						</Stack>

						<OneTimeTooltipTranslationsHOC />

					</Stack>
				</Fade>

			</Stack>
	);

}
