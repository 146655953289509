import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { Captions } from '../components/Captions';
import { ICaptionsEvents, ICaptionsFields } from '../components/types';
import { ICaptionsHOCOwnProps } from './types';
import {
  getCurrentMovieKey,
  getSelectedNativeCaption,
  getSelectedTargetCaption
} from '../../../../../store/current-video/selectors';
import { CaptionsEvents } from './events';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import {
  getAutoSync,
  getMainViewMode,
  getPlayerState,
  getShowNativeCaptionsIfAvailable,
  isShowNativeLanguageTooltip
} from '../../../../../store/general/selectors';
import {
  getActiveGroupId,
  getActiveGroupNativeLanguage,
  getActiveGroupTargetLanguage
} from '../../../../../store/models/selectors';
import { getNativeCaptions, getTargetCaptions, isVideoCaptionsEmpty } from '../../../../../store/videos/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import { TextSelectors } from '../../../../../store/text/selectors';

const mapStateToProps = (
  dispatch: any,
  {}: ICaptionsHOCOwnProps
) => {
  return (
    state: IState
  ): ICaptionsFields => {
    const targetCaptions = getTargetCaptions(state);
    const nativeCaptions = getNativeCaptions(state);
    const showNativeLangTooltip = (!!targetCaptions.length || !!nativeCaptions.length) && isShowNativeLanguageTooltip(state);
    const showNativeCaptions = getShowNativeCaptionsIfAvailable(state) &&
      getMainViewMode(state) !== EMainViewMode.PHRASES;

    const captionsEmpty = isVideoCaptionsEmpty(state);

    const groupTargetLangName = getActiveGroupTargetLanguage(state)?.name || '';
    const groupNativeLangName = getActiveGroupNativeLanguage(state)?.name || '';

    const storyFromTranslatedId = TextSelectors.getTextActiveId(state);

    return {
      playerState: getPlayerState(state),
      targetCaptions,
      nativeCaptions,
      captionsEmpty,
      targetLang: getSelectedTargetCaption(state)?.code,
      nativeLang: getSelectedNativeCaption(state)?.code,
      showSelectionTool: CaptionsSelectionPopupSelectors.isShow(state),
      autoSync: getAutoSync(state),
      groupId: getActiveGroupId(state) || 0,
      videoId: getCurrentMovieKey(state),
      showNativeLangTooltip,
      showNativeCaptions,
      groupTargetLangName,
      groupNativeLangName,
      storyFromTranslatedId,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ICaptionsEvents => ({
    onKeyDown: () => dispatch(CaptionsEvents.onKeyDown()),
    onVideoChange: () => dispatch(CaptionsEvents.onVideoChange()),
    onTargetCaptionsChange: () => dispatch(CaptionsEvents.onTargetCaptionsChange()),
    onNativeCaptionsChange: () => dispatch(CaptionsEvents.onNativeCaptionsChange()),
  }
);

export const CaptionsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Captions);

CaptionsHOC.displayName = 'CaptionsHOC';