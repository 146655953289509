import { getAuthUserLangCode } from '../general/selectors';
import { IState } from '../types';
import {
  EContentLibViewMode, IContentLibCopyGroupConfirm, IContentLibCopyVideoConfirm,
  IContentLibGroup,
  IContentLibSearchFilter,
  IContentLibGroupsSearchResult,
  IContentLibVideo, IContentLibVideoPopup, TContentLibLangs
} from './types';

export class ContentLibSelectors {

  public static getGroupsSearchResult(state: IState): IContentLibGroupsSearchResult {
    return state.contentLib.groupsSearchResult
  }

  public static getVideosSearchResult(state: IState): IContentLibVideo[] {
    return state.contentLib.videosSearchResult
  }

  public static getSearchFilter(state: IState): IContentLibSearchFilter {
    let searchFilter = {...state.contentLib.searchFilter};
    // if (!searchFilter.nativeLangCode) searchFilter.nativeLangCode = getNativeLanguage(state)?.code || '';
    if (!searchFilter.targetLangCode) searchFilter.targetLangCode = getAuthUserLangCode(state) || '';

    return searchFilter;
  }

  public static getVideoPopup(state: IState): IContentLibVideoPopup {
    return state.contentLib.videoPopup
  }

  public static findGroupById(state: IState, id: number): IContentLibGroup | undefined {
    return this.getGroupsSearchResult(state).groups?.find(g => g.id === id);
  }

  public static findVideo(state: IState, groupId: number, videoId: number): IContentLibVideo | undefined {
    const group = this.findGroupById(state, groupId);
    return group?.videos?.find(v => v.id === videoId);
  }

  public static getViewMode(state: IState): EContentLibViewMode {
    return state.contentLib.viewMode;
  }

  public static getCopyVideoConfirm(state: IState): IContentLibCopyVideoConfirm {
    return state.contentLib.copyVideoConfirm
  }

  public static getCopyGroupConfirm(state: IState): IContentLibCopyGroupConfirm {
    return state.contentLib.copyGroupConfirm
  }

  public static getSelectedGroupId(state: IState): number {
    return state.contentLib.selectedGroupId;
  }

  public static getLangs(state: IState): TContentLibLangs {
    return state.contentLib.langs;
  }

  public static isLockedMode(state: IState): boolean {
    return state.contentLib.lockedMode;
  }
}