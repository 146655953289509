import { IGroupByLang, IMyLibProps } from './types';
import React from 'react';
import { Box, Button, Stack, SxProps } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';
import { Text } from '../../../../../../common/components/Text/Text';
import { EditPlaylistItemHOC } from '../../../PlayList/EditPlayList/hocs/EditPlaylistItem/EditPlaylistItemHOC';
import { CreatePlaylistHOC } from '../../../PlayList/CreatePlaylist/hocs/CreatePlaylistHOC';
import { EditGroupHOC } from '../../../PlayList/EditGroup/hocs/EditGroupHOC';
import { MY_STUFF_NO_PLAYLISTS } from '../../wordings';
import { AddNewVideoPopupHOC } from '../../../AddNewVideoPopup/hocs/AddNewVideoPopupHOC';
import { AddNewAudioPopupHOC } from '../../../AddNewAudioPopup/hocs/AddNewAudioPopupHOC';
import { MyLibAddButtons } from '../../MyLibAddButtons/MyLibAddButtons';
import { TUserGroup } from '../../../../../store/models/types';
import { ELanguageStatus } from '../../../../../store/general/types';
import { LangUtil } from '../../../../../../common/utils/lang-util';

export function MyLib({
	groups,
	langCode,
	onShowAddPlaylist,
	onAddText,
	onAddVideoLib,
	onAddVideoYT,
}: IMyLibProps) {

	const className = 'mylib';

	const [groupsByLang, setGroupsByLang] = React.useState<IGroupByLang[]>([]);
	React.useEffect(() => {
		const groupsByLang: IGroupByLang[] = [];
		groups.forEach((g: TUserGroup) => {
			const target = g.languages.find(l => l.status === ELanguageStatus.TO_LEARN);
			let code = target?.code || '';
			let lang = groupsByLang.find(i => i.langCode === code);
			if (!lang) {
				lang = {
					groups: [],
					isCurrent: code === langCode,
					langCode: code,
					name: LangUtil.getLangNameByCode(code),
				};
				groupsByLang.push(lang);
			}
			lang.groups.push(g);
		});
		groupsByLang.sort((a, b) => {
			if (a.isCurrent) return -1;
			if (b.isCurrent) return 1;
			return a.name.localeCompare(b.name);
		});
		setGroupsByLang(groupsByLang);
	}, [groups]);

	return (
		<Stack
			gap={2}
			sx={{
				py: theme.spacing(2),
				minHeight: '50vh',
			}}
		>
			<Stack
				direction={'row'}
				gap={theme.spacing(2)}
			>
				<Button
					variant={'contained'}
					startIcon={<PlusIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />}
					onClick={onShowAddPlaylist}
				>
					Create Playlist
				</Button>

				<MyLibAddButtons
					onAddText={onAddText}
					onAddVideoLib={onAddVideoLib}
					onAddVideoYT={onAddVideoYT}
				/>
			</Stack>

			<Stack
				sx={{
					flex: `1 1 100%`,
					minHeight: 0,
				}}
			>
				{!groups || !groups.length
					? <Stack
						sx={{
							justifyContent: 'center',
							alignItems: 'center',
							p: theme.spacing(2),
							height: 1,
							minHeight: '50vh',
						}}
					>
						<Text
							sx={{
								fontSize: theme.typography.h5,
								color: theme.palette.grey[500],
							}}
						>
							{MY_STUFF_NO_PLAYLISTS}
						</Text>
					</Stack>
					: !!groupsByLang.length && groupsByLang.map((lang) => {
						return <>
							{groupsByLang.length > 1 &&
								<Text
									sx={{
										mt: 3,
										fontSize: theme.typography.subtitle2,
										fontWeight: 500,
										color: theme.palette.grey[500],
									}}
								>
									{lang.name}
								</Text>
							}
							<Box
								className={`${className}__groups
									${!lang.isCurrent && groupsByLang.length > 1 ? 'other' : ''}
								`}
							>
								{lang.groups.map((group) => {
									return (
										<EditPlaylistItemHOC groupId={group.id} key={group.id} />
									);
								})}
							</Box>
						</>;
					})
				}
			</Stack>

			<CreatePlaylistHOC />
			<EditGroupHOC />
			<AddNewVideoPopupHOC />
			<AddNewAudioPopupHOC />
		</Stack>
	);
}
