import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { LangMenuList } from '../components/LangMenuList';
import { ILangMenuListEvents, ILangMenuListFields } from '../components/types';
import { LangMenuListEvents } from './events';
import { getLanguages, getUserGroups } from '../../../../../store/models/selectors';
import { getAuthUserLangCode } from '../../../../../store/general/selectors';
import { ILangMenuListHOCProps } from './types';
import { TUserGroup } from '../../../../../store/models/types';
import { ELanguageStatus } from '../../../../../store/general/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): ILangMenuListFields => {
		const langCode = getAuthUserLangCode(state);
		const groups = getUserGroups(state)
		const groupLangs = groups.reduce((langs: string[], g: TUserGroup) => {
			const target = g.languages.find(l => l.status === ELanguageStatus.TO_LEARN);
			if (target && !langs.includes(target.code)) {
				langs.push(target.code);
			}
			return langs;
		}, [langCode]);

		const langs = getLanguages(state);

		return {
			groupLangs,
			langCode,
			langs,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any,
	props: ILangMenuListHOCProps,
): ILangMenuListEvents => {
	const { onClose } = props;
	return {
		onChange: (langCode) => dispatch(LangMenuListEvents.onChange(langCode)),
		onClose,
	}
};

export const LangMenuListHOC = connect(
	mapStateToProps,
	mapDispatchToProps,
)(LangMenuList);

LangMenuListHOC.displayName = 'LangMenuListHOC';
