import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { setAuthUserAction, setMainViewMode, setPublicPageAction } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { AuthManager } from '../../../../../../common/utils/auth-manager';
import { updateUserLanguages } from '../../../../../effects/updateUserLanguages';
import { updateUserGroups } from '../../../../../effects/updateUserGroups';
import { getAuthUser, getMainViewMode } from '../../../../../store/general/selectors';
import { restoreAppSettings } from '../../../../../effects/prepareAfterOpen';
import { VideoTutorialEffects } from '../../../../../effects/videoTutorialEffects';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { setUserGroupsLoadedAction } from '../../../../../store/models/actions';
import { getUserStateStorage } from '../../../../../../common/utils/local-storage/user-state';
import { Navigator } from '../../../../../effects/navigator';

export class ContentLibraryDashboardEvents {

  public static onLoad(
    mainViewMode?: EMainViewMode,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (!getAuthUser(getState())) { // url
        dispatch(setPublicPageAction(true));
        const result = await AuthManager.autoLogin();
        if (!result?.authUser && mainViewMode === EMainViewMode.MY_LIBRARY) {
          return Navigator.openContentLib();
        }
        if (result && result.authUser) {
          restoreAppSettings(result.appSettings);
          dispatch(setAuthUserAction(result.authUser));
          dispatch(updateUserLanguages());
          dispatch(updateUserGroups(true));
          PhraseDetailsTabEffects.load();
          VideoTutorialEffects.loadList();
        }
        dispatch(setUserGroupsLoadedAction(true));
        dispatch(setMainViewMode(mainViewMode || EMainViewMode.CONTENT_LIBRARY));
      } else {
        const mainViewModeState = getMainViewMode(getState());
        if (mainViewModeState !== EMainViewMode.CONTENT_LIBRARY &&
          mainViewModeState !== EMainViewMode.MY_LIBRARY
        ) {
          dispatch(setMainViewMode(mainViewMode || EMainViewMode.CONTENT_LIBRARY));
        }
        if (mainViewMode === EMainViewMode.CONTENT_LIBRARY) {
          getUserStateStorage().setContentLibPage();
        } else if (mainViewMode === EMainViewMode.MY_LIBRARY) {
          getUserStateStorage().setMyStuffPage();
        }
      }

      await ContentLibEffects.loadLangs();
    }
  }

  public static onUnLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setPublicPageAction(false));
      dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
    }
  }


}