
import { TPhraseDetailsTab } from '../../../../../store/models/types';

export interface IPhraseDetailsContainerFields {
  activeTab?: TPhraseDetailsTab,
  groupId: number,
  videoId: string,
  teacherMode: boolean
  text: string;
}

export interface IPhraseDetailsContainerEvents {
  onLoad: () => void;
  onSelection: (selection: Selection) => void;
  onPlayerPositionChange: (time: number) => void;
}

export interface IPhraseDetailsContainerProps extends
  IPhraseDetailsContainerFields,
  IPhraseDetailsContainerEvents
{}

export const PHRASE_DETAILS_COLUMN_CLASS_NAME = 'phrase-details-column';
export const CLASS_PHRASE_DETAILS_COLUMN_SCROLL = 'phrase-details-column__scroll';