import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { TApiResponse } from '../../../spa/effects/phrase-details/phraseDetailsService/phrase-details-service';


type TGptChatExecBodyRest = {
  prompt: string;
  hash?: string;
}

type TGptChatExecBatchBodyRest = {
  prompts: string[];
}

export class GptChatRest {

  private static GPT_CHAT_API_URL = API_URL + '/gptChartApi/exec';
  private static GPT_CHAT_BATCH_API_URL = API_URL + '/gptChartApi/execBatch';

  public static async exec(body: TGptChatExecBodyRest): Promise<TApiResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(GptChatRest.GPT_CHAT_API_URL, body));
  }

  public static async execBatch(body: TGptChatExecBatchBodyRest): Promise<TApiResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(GptChatRest.GPT_CHAT_BATCH_API_URL, body));
  }
}
