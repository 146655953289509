import { AppThunk } from '../../../../../store/types';
import { EVocabularyPhraseType, ICaptionsItem, PlayerStates } from '../../../../../types/common';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { CaptionsSelectionPopupActions } from '../../../../../store/captions-selection-popup/actions';
import {
  findVideoPhraseByCaption,
  findVideoPhrasesByType,
  getTargetCaptions
} from '../../../../../store/videos/selectors';
import { IPhraseSelectResult, PhraseSelectPreparator } from '../../../../../effects/phrase/utils/phrase-select-preparator';
import {
  getActiveGroupNativeLanguage,
  getActiveUserGroup,
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById
} from '../../../../../store/models/selectors';
import { getAuthUser, getPlayerState, isHoverAutoPause } from '../../../../../store/general/selectors';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { getSelectedNativeCaption, getSelectedTargetCaption } from '../../../../../store/current-video/selectors';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { SelectionPopupEffects } from '../../../../../effects/selectionPopupEffects';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { EShortcutsListenerContainer, setShortcutsListenerContainer } from '../../../../../effects/shortcutsListener';
import { TextSelectionPopupEvents } from '../../../TextSelectionPopup/hocs/events';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { setCurrentVideoClickedCaptionIndex } from '../../../../../store/current-video/actions';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { batch } from 'react-redux';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { EUserGroupType } from '../../../../../store/general/types';

export class TargetCaptionsEvents {

  public static onCaptionClick(
    index: number,
    caption: ICaptionsItem,
    wordIndex: number,
    isMobile?: boolean,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const state = getState();
      const phrase = findVideoPhraseByCaption(state, index, wordIndex);
      if (phrase) {
        const group = getUserGroupById(state, phrase.userGroupId);
        if (!group) return;
        const targetLang = getGroupTargetLanguage(state, group);
        if (!targetLang) return;
        const nativeLang = getGroupNativeLanguage(state, group);
        SelectionUtil.removeWindowSelection();
        SelectionPopupEffects.dismissSelectText();
        PhraseDetailsEffects.showFromSavedPhrase(targetLang, nativeLang, nativeLang, phrase.highlighted, phrase, false);
        dispatch(PhraseListActions.setTopPhraseId(phrase.id));
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(phrase.id));
        if (phrase) {
          dispatch(CaptionsSelectionPopupActions.setCurrentSelection({contextPhraseId: phrase.id, wordPhraseId: phrase.wordPhraseId}));
        }
      } else {
        const targetLang = getSelectedTargetCaption(state);
        const nativeLang = getSelectedNativeCaption(state);
        const activeGroupNativeLang = getActiveGroupNativeLanguage(state);
        PhraseDetailsEffects.showFromCaption(index, targetLang, nativeLang, activeGroupNativeLang, caption);
        batch(() => {
          dispatch(PhraseListActions.setPlayCaptionByPhraseId(0));
          dispatch(PhraseListActions.setCurrentPhraseId(0));
          dispatch(PhraseListActions.setPlayPhraseId(0));
        })
        SelectionUtil.removeWindowSelection();
        SelectionPopupEffects.dismissSelectText();
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({
          captionIndex: index,
          startTime: caption.startTime,
          endTime: caption.endTime
        }));
      }
      PlayerController.getInstance().pause();
      setShortcutsListenerContainer(EShortcutsListenerContainer.CAPTIONS);

      const selResult: IPhraseSelectResult = {
        text: caption.text,
        startTime: caption.startTime,
        endTime: caption.endTime,
        startCaptionIndex: index,
        endCaptionIndex: index,
        startPosition: 0,
        endPosition: caption.text.length,
        elementId: `caption-item-${index}`,
      };
      batch(() => {
        dispatch(TextSelectionPopupEvents.onPreviewPhrase(selResult));
        dispatch(CaptionsSelectionPopupActions.setShow(true));
        dispatch(CaptionsSelectionPopupActions.setSelectResult(selResult));
      })
    }
  }


  public static onCaptionPlay(caption: ICaptionsItem, isPause: boolean): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (isPause) {
        PlayerController.getInstance().pause();
      } else {
        PlayerController.getInstance().playCaption(caption, true);
      }
    }
  }

  public static onCaptionMouseDown(): AppThunk {
    return (
      dispatch,
      getState
    ) => {

      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }
    }
  }

  public static onMouseLeave(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (!isHoverAutoPause(getState())) return;
      PlayerController.getInstance().mouseLeave();
    }
  }

  public static onMouseEnter(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (!isHoverAutoPause(getState())) return;
      PlayerController.getInstance().mouseEnter();
    }
  }

  public static onSelectText(
    selection: Selection,
    elementId: string,
    isSelectPopup?: boolean,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (!selection) {
        dispatch(CaptionsSelectionPopupActions.setSelection(null as any));
        dispatch(CaptionsSelectionPopupActions.setSelectResult(null));
        return false;
      }
      EventsRouter.trackEvent(Events.TARGET_CAPTION_SELECTED);

      const state = getState();
      const targetLanguage = getSelectedTargetCaption(state);
      const nativeLanguage = getSelectedNativeCaption(state);

      const wordSelectPhrases = findVideoPhrasesByType(state, EVocabularyPhraseType.WORD_SELECTED);
      const contextPhrases = findVideoPhrasesByType(state, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);
      const useCheckSpanWords = !LangUtil.isHieroglyphLang(targetLanguage?.code || '');
      const selResult = new PhraseSelectPreparator(selection, getTargetCaptions(state), wordSelectPhrases, contextPhrases, elementId, useCheckSpanWords).execute();
      console.log('selResult', selResult)

      const selectResultPrev = CaptionsSelectionPopupSelectors.getSelectResult(state);
      if (selResult && selectResultPrev &&
        selResult.text === selectResultPrev.text &&
        selResult.startTime === selectResultPrev.startTime
      ) {
        return false; // no change
      }

      batch(() => {
        dispatch(CaptionsSelectionPopupActions.setShow(true));
        dispatch(CaptionsSelectionPopupActions.setSelection(selection));
        dispatch(CaptionsSelectionPopupActions.setSelectResult(selResult));
        dispatch(setCurrentVideoClickedCaptionIndex(-1));
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(0));
        dispatch(PhraseListActions.setCurrentPhraseId(0));
        dispatch(PhraseListActions.setPlayPhraseId(0));
        dispatch(PhraseDetailsActions.disActivate());
      })

      if (targetLanguage && selResult && selResult.text) {
        if (selResult.text) {
          const activeGroupNativeLang = getActiveGroupNativeLanguage(state);
          if (selResult.insideWordActiveId) {
            await PhraseDetailsEffects.showFromPhrase(targetLanguage, nativeLanguage, activeGroupNativeLang,
              selResult.insideWordActiveId, selResult.text);
          } else {
            await PhraseDetailsEffects.showFromSelectText(targetLanguage, nativeLanguage, activeGroupNativeLang, selResult);
          }

          if (!getAuthUser(state)?.teacherMode) {
            const group = getActiveUserGroup(state);
            let phrase;
            if (selResult.insideWordPhrases && selResult.insideWordPhrases.length) {
              phrase = selResult.insideWordPhrases.find(p => p.highlighted === selResult.text);
            }
            if (!phrase && group?.type !== EUserGroupType.PREVIEW)
              PhraseEffects.runSavePreviewPhrase(selResult);
          }
          await PlayerController.getInstance().pausePlay(true);
        }
      }

      if (selResult && isSelectPopup) {
        dispatch(TextSelectionPopupEvents.onPreviewPhrase(selResult));
      }

      return true;
    }
  }

  public static onDismissSelectText(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (document.body.classList.contains('onboarding-active')) return;

      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText();
    }
  }

  public static onChangeActiveIndex(index: number) : AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PhraseDetailsEffects.updateOnChangeActiveTargetIndex(index);
    }
  }



}
