import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { LangMenu } from '../components/LangMenu';
import { ILangMenuFields } from '../components/types';
import { getAuthUserLangCode } from '../../../../../store/general/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): ILangMenuFields => {
		const langCode = getAuthUserLangCode(state);

		return {
			langCode
		}
	};
}

export const LangMenuHOC = connect(
	mapStateToProps,
	null
)(LangMenu);

LangMenuHOC.displayName = 'LangMenuHOC';
