import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseChat } from '../components/PhraseChat';
import { IPhraseChatEvents, IPhraseChatFields } from '../components/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseChatEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseChatFields => {

    return {
      chat: PhraseDetailsSelectors.getPromptsChat(state),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseChatEvents => ({
  }
);

export const PhraseChatHOC = connect(
  mapStateToProps,
  null
)(PhraseChat);

PhraseChatHOC.displayName = 'PhraseChatHOC';
