import { TextUtils } from '../../../../../common/utils/text-utils';

export interface IPhraseDetailsPromptPrepareInput {
  prompt: string;
  phrase: string;
  context: string;
  phraseCssClass: string;
  contextCssClass: string;
  targetLanguage?: string;
  nativeLanguage?: string;
  savedPhrases?: string;
  translatedPhrases?: string;
}
export class PhraseDetailsPhrasePrepareHelper {

  public static format(input: IPhraseDetailsPromptPrepareInput) {
    const phrase = TextUtils.replaceNewLines(input.phrase);
    let context = TextUtils.replaceNewLines(input.context);
    let result = input.prompt.replaceAll("\n", '<br>');

    if (input.targetLanguage) {
      result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, input.targetLanguage, 'prompt-param_targetLanguage');
    }
    if (input.nativeLanguage) {
      result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, input.nativeLanguage, 'prompt-param_nativeLanguage');
    }
    if (input.savedPhrases) {
      result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, input.savedPhrases, 'prompt-param_savedPhrases');
    }
    if (input.translatedPhrases) {
      result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, input.translatedPhrases, 'prompt-param_translatedPhrases');
    }

    result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, context, input.contextCssClass);
    result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, phrase, input.phraseCssClass);
    return result;
  }

  private static formatPromptInputByParam(prompt: string, param: string, className: string) {
    if (!param) return prompt;
    const value = `<span class="${className}">` + param + '</span>';
    return prompt.replaceAll(param, value);
  }
}