import * as React from 'react';
import { theme } from '../../../../../../../common/styles/theme';
import { alpha, SwipeableDrawer, Tooltip } from '@mui/material';
import { IMenuButtonProps } from './types';
import { ButtonIconGrey } from '../../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { MenuHOC } from '../../hocs/MenuHOC';
import { MenuIcon } from '../../../../../../../../icons/MenuIcon';
import { LogoSmall } from '../../../../../../../../icons/LogoSmall';

export const MenuButton: React.FC<IMenuButtonProps> = ({
	isLogo
}) => {
	const className = 'menu';

	const [isMenuButtonOpened, setMenuButtonOpened] = React.useState(false);
	
	const toggleDrawer = (value?: boolean) => {
		if (value === undefined) value = !isMenuButtonOpened;
		setMenuButtonOpened(value);

		if (value) {
			const floatPanel = document.querySelector('.left-panel-float .MuiBackdrop-root') as HTMLElement;
			if (floatPanel) {
				floatPanel.click();
			}
		}
	}

	return (<>
		<Tooltip
			placement={'left'}
			title={isLogo ? `Menu` : null}
		>
			<ButtonIconGrey
				className={`${className}__button`}
				sx={{
					'.MuiSvgIcon-root': {
						width: theme.spacing(3),
						height: theme.spacing(3),
					},
				}}
				onClick={()=>toggleDrawer()}
			>
				{isLogo
					? <LogoSmall />
					: <MenuIcon />
				}
			</ButtonIconGrey>
		</Tooltip>
		<SwipeableDrawer
			className={'menu-drawer'}
			anchor={'left'}
			open={isMenuButtonOpened}
			onClose={()=>toggleDrawer(false)}
			onOpen={()=>toggleDrawer(true)}
			sx={{
				zIndex: theme.zIndex.modal,
				'.MuiDrawer-paper': {
					minWidth: theme.spacing(35),
					maxWidth: theme.spacing(35),
					height: `calc(100% - ${theme.spacing(4)})`,
					m: theme.spacing(2),
					borderRadius: theme.spacing(.75),
				},
				'.MuiBackdrop-root': {
					background: alpha(theme.palette.grey[900], .2),
				},
				'.mobile & .MuiDrawer-paper': {
					minWidth: theme.spacing(40),
					height: '100%',
					m: 0,
					p: theme.spacing(1),
					borderRadius: 0,
				},
			}}
		>
			<MenuHOC
				onClose={()=>toggleDrawer(false)}
			/>
		</SwipeableDrawer>
	</>);
};