import { AppThunk } from '../../../../../store/types';
import {
  setEditPlaylistModalIdAction,
  setMainViewMode,
  setSnackbarPanelAction
} from '../../../../../store/general/actions';

import { UserGroupEffects } from '../../../../../effects/userGroupEffects';
import { getLibraryGroupId } from '../../../../../store/general/selectors';
import { CopyGroupEffects } from '../../../../../effects/copyGroupEffects';
import { Navigator } from '../../../../../effects/navigator';
import { EMainViewMode } from '../../../../../store/general/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';

export class VideoLibraryHeaderEvents {

  public static onGroupChange(
    groupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (groupId) {
        UserGroupEffects.setLibraryGroupId(groupId);
      }
    }
  }

  public static onEditPlayList(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const groupId = getLibraryGroupId(getState()) || 0;
      dispatch(setEditPlaylistModalIdAction(groupId));
    }
  }

  public static onCopyLink(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = getLibraryGroupId(getState()) || 0;
      const {url} = await CopyGroupEffects.createCopyLink(groupId);
      await navigator.clipboard.writeText(url);
      dispatch(setSnackbarPanelAction(true, 'Link to public playlist copied to clipboard'));
    }
  }

  // public static onClickAdd(): AppThunk {
  //   return (
  //     dispatch,
  //     getState
  //   ) => {
  //     dispatch(setShowAddNewVideoPopupAction(true));
  //   }
  // }

  public static onClickMyLibrary (): AppThunk {
    return (
      dispatch
    ) => {
      Navigator.openContentLib();
      dispatch(setMainViewMode(EMainViewMode.MY_LIBRARY));
      dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST));
    }
  }



}
