import { AppThunk } from '../../../../../store/types';
import { getVideoTeacherShowNotePhraseId, getVideoTeacherUserActiveId } from '../../../../../store/videos/selectors';
import {
  getVideoTeacherUserNick
} from '../../../../../store/videos/selectors';

import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { Navigator } from '../../../../../effects/navigator';
import { UrlUtil } from '../../../../../../common/utils/url-util';
import { TeachersEffects } from '../../../../../effects/teachersEffects';

export class PhraseTeacherNotesEvents {

  public static onCopy(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const teacherId = getVideoTeacherUserActiveId(getState());
      const teacherPhraseId = getVideoTeacherShowNotePhraseId(getState());
      await PhraseEffects.copyTeacherPhrase(teacherId, teacherPhraseId);
    }
  }

  public static onClickAuthor(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const teacherId = getVideoTeacherUserActiveId(getState());
      const nick = getVideoTeacherUserNick(getState(), teacherId);
      const url = UrlUtil.getLinkProfile(nick);
      Navigator.openUrl(url);
    }
  }

  public static onBack(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      TeachersEffects.hidePhrases();
    }
  }

}