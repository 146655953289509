import React from 'react';
import { IContentLibraryProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Stack } from '@mui/material';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { ContentLibraryHeaderHOC } from '../../ContentLibraryHeader/hocs/ContentLibraryHeaderHOC';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibVideoPopupHOC } from '../../ContentLibVideoPopup/hocs/ContentLibVideoPopupHOC';
import { ContentLibCopyGroupConfirmHOC } from '../../ContentLibCopyGroupConfirm/hocs/ContentLibCopyGroupConfirmHOC';
import { ContentLibCopyVideoConfirmHOC } from '../../ContentLibCopyVideoConfirm/hocs/ContentLibCopyVideoConfirmHOC';
import { ContentLibVideoListHOC } from '../../ContentLibVideoList/hocs/ContentLibVideoListHOC';
import { ContentLibGroupListHOC } from '../../ContentLibGroupList/hocs/ContentLibGroupListHOC';
import { ContentLibraryFiltersHOC } from '../../ContentLibraryFilters/hocs/ContentLibraryFiltersHOC';
import { EMainViewMode } from '../../../../../store/general/types';
import { MyLibHOC } from '../../MyLib/hocs/MyLibHOC';
import { MyLibVideosHOC } from '../../MyLibVideos/hocs/MyLibVideosHOC';
import { AddVideoConfirmHOC } from '../../../AddVideoConfirm/hocs/AddVideoConfirmHOC';

export const ContentLibraryDashboard: React.FC<IContentLibraryProps> = ({
	viewMode,
	mainViewMode,
	onLoad,
	onUnLoad
}) => {

	const className = 'content-library';

	React.useEffect(() => {
		onLoad();
		return () => {
			onUnLoad();
		}
	}, [])

	return <Stack
		className={`
			${className}
		`}
		sx={{
			width: 1,
			height: 1,
			background: theme.palette.secondary.main,
		}}
	>
		<ContentLibraryHeaderHOC />

		<Stack
			gap={2}
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				p: 2,
	
				overflow: 'auto',
			}}
		>
			{mainViewMode === EMainViewMode.CONTENT_LIBRARY &&
				<ContentLibraryFiltersHOC />
			}

			<Paper
				sx={{
					width: 1,
					px: 3,
				}}
			>
				{mainViewMode === EMainViewMode.MY_LIBRARY
					? viewMode === EContentLibViewMode.GROUP_LIST
						? <MyLibHOC />
						: <MyLibVideosHOC />
						
					: viewMode === EContentLibViewMode.GROUP_LIST
						? <ContentLibGroupListHOC/>
						: <ContentLibVideoListHOC/>
				}
			</Paper>
		</Stack>
		<ContentLibVideoPopupHOC/>
		<ContentLibCopyVideoConfirmHOC/>
		<ContentLibCopyGroupConfirmHOC/>

		<AddVideoConfirmHOC />
	</Stack>
};
