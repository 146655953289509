import { EVocabularyPhraseType, ICaptionsItem, IPhraseNote, IVocabularyPhrase } from '../../../../../types/common';
import { getVideoPhraseNoteByPhraseId } from '../../../../../store/videos/selectors';
import { getState } from '../../../../../store';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';

type TVocabularyMap = {
  [captionStart: number]: IVocabularyPhrase[]
}

export type TTargetCaptionsPhraseInfo = {
  phrase: IVocabularyPhrase,
  note?: IPhraseNote,
  selectContext?: boolean,
  selectedContext: boolean,
  selectedWord: boolean
}

export class TargetCaptionsHelper {

  private phrases: IVocabularyPhrase[];
  private captions: ICaptionsItem[];

  private phraseMap: TVocabularyMap;
  private phraseCaptionIndexMap: TVocabularyMap;
  private wordSelPhrasesMap: TVocabularyMap;

  constructor(phrases: IVocabularyPhrase[], captions: ICaptionsItem[]) {
    this.phrases = phrases;
    this.captions = captions;
    this.groupPhrases();
  }

  public getPhrases(caption: ICaptionsItem, index: number, selectContextPhraseId: number): TTargetCaptionsPhraseInfo[] {
    const state = getState();
    let phrases = this.phraseMap[caption.startTime] ? [...this.phraseMap[caption.startTime]] : [];

    const phrasesIdList = phrases.map(p => p.id);
    const contextPhrases = this.phraseCaptionIndexMap[index];

    if (contextPhrases && contextPhrases.length) {
      contextPhrases.forEach(p => {
        if (!phrasesIdList.includes(p.id)) {
          phrases.push({...p});
          phrasesIdList.push(p.id);
        }
      });
    }
    const wordSelPhrases = this.wordSelPhrasesMap[index];
    if (wordSelPhrases && wordSelPhrases.length) {
      wordSelPhrases.forEach(p => {
        if (!phrasesIdList.includes(p.id)) {
          phrases.push({...p});
          phrasesIdList.push(p.id);
        }
      });
    }

    const currentSelection = CaptionsSelectionPopupSelectors.getCurrentSelection(state);

    const result: TTargetCaptionsPhraseInfo[] = phrases.map(phrase => {
      const selectedContext: boolean = phrase.id === currentSelection.contextPhraseId;
      const selectedWord: boolean = phrase.id === currentSelection.wordPhraseId;
      if (phrase.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED) {
        const note = phrase.wordPhraseId ? getVideoPhraseNoteByPhraseId(state, phrase.wordPhraseId) : undefined;
        const selectContext = selectContextPhraseId === phrase.id;
        return {phrase, note, selectContext, selectedContext, selectedWord};
      } else if (phrase.type === EVocabularyPhraseType.WORD_SELECTED) {
        const note = getVideoPhraseNoteByPhraseId(state, phrase.id);
        return {phrase, note, selectedContext, selectedWord};
      }
      return {phrase, selectedContext, selectedWord};
    })
    return result;
  }

  private groupPhrases() {
    if (this.phrases.length === 0) {
      this.phraseMap = {};
      this.phraseCaptionIndexMap = {};
      this.wordSelPhrasesMap = {};
      return;
    }

    const starts = this.phrases.reduce((accum, phrase: IVocabularyPhrase) => {
      //for multiple subtitle selection
      const selectedCaptions = this.captions.filter(targetCap => {
        return targetCap.startTime >= phrase.startTime && targetCap.startTime < phrase.endTime;
      });
      if (selectedCaptions) {
        selectedCaptions.forEach(selectedCaption => {
          const startTime = selectedCaption.startTime;
          if (!accum[startTime]) {
            accum[startTime] = [];
          }
          const existPhrase = accum[startTime].find(p => p.id === phrase.id);
          if (!existPhrase) {
            accum[startTime].push(phrase);
          }
        });
      }
      return accum;
    }, {} as TVocabularyMap);

    const res: TVocabularyMap = {};
    this.captions.forEach((caption, index) => {
      const captionTime = caption.startTime;
      const phrases = starts[captionTime];
      if (phrases && phrases.length) {
        res[captionTime] = phrases;
      }
    });
    this.phraseMap = res;
    this.phraseCaptionIndexMap =
      this.groupPhrasesByCaptions([EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED, EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED]);
    this.wordSelPhrasesMap =
      this.groupPhrasesByCaptions([EVocabularyPhraseType.WORD_SELECTED, EVocabularyPhraseType.PREVIEW_WORD_SELECTED]);
  }

  private groupPhrasesByCaptions(types: EVocabularyPhraseType[]):TVocabularyMap {
    const map: TVocabularyMap = {};
    this.phrases.filter(p => (p.type && types.includes(p.type))).forEach(phrase => {
      for(let index=phrase.startCaptionIndex; index<=phrase.endCaptionIndex; index++) {
        if (!map[index])
          map[index] = [];
        map[index].push(phrase);
      }
    })
    return map;
  }
}

export class TargetCaptionsFindPhrasesHelper {

  public static findFirstPhraseByPosition(phrases: TTargetCaptionsPhraseInfo[], i: number, index: number) {
    const list = this.findPhrases(phrases, i, index);
    return list.length > 0 ? list[0] : undefined;
  }

  public static findMaxLenPhraseByPosition(phrases: TTargetCaptionsPhraseInfo[], i: number, index: number) {
    const list = this.findPhrases(phrases, i, index);
    if (list.length < 1) return undefined;
    if (list.length === 1) return list[0];
    list.sort((p1, p2) => {
      return p2.highlighted.length - p1.highlighted.length;
    })
    return list[0];
  }

  private static findPhrases(phrases: TTargetCaptionsPhraseInfo[], i: number, index: number): IVocabularyPhrase[] {
    return phrases.map(pi => pi.phrase).filter(p => {
      if (p.startCaptionIndex === p.endCaptionIndex) {
        return i >= p.startPosition && i < p.endPosition;
      } else if (p.startCaptionIndex === index) {
        return p.startPosition <= i;
      } else if (p.endCaptionIndex === index) {
        return p.endPosition >= i;
      } else {
        return true;
      }
    });
  }
}

