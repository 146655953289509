import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { OneTimeTooltipSelectEvents } from './events';
import { OneTimeTooltipSelect } from '../../components/OneTimeTooltipSelect/OneTimeTooltipSelect';
import { IOneTimeTooltipSelectEvents, IOneTimeTooltipSelectFields } from '../../components/OneTimeTooltipSelect/types';
import { OneTimeTooltipSelectStorage } from '../../../../../../common/utils/local-storage/onetime-tooltip-select';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipSelectFields => {
		return {
			isShow: OneTimeTooltipSelectStorage.isShow(),
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipSelectEvents => ({
	onClose: () => dispatch(OneTimeTooltipSelectEvents.onClose()),
});

export const OneTimeTooltipSelectHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(OneTimeTooltipSelect);

OneTimeTooltipSelectHOC.displayName = 'OneTimeTooltipSelectHOC';