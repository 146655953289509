import React from 'react';
import { IPhraseChatProps } from './types';
import { Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { PhraseChatItemHOC } from '../../PhraseChatItem/hocs/PhraseChatItemHOC';

export const PhraseChat: React.FC<IPhraseChatProps> = ({
	chat,
}) => {
	const {isMobile} = React.useContext(DeviceContext);

	return (
		<Stack
			gap={theme.spacing(1.5)}
			sx={{
				position: 'relative',
				width: 1,
				pl: theme.spacing(3),
				pb: theme.spacing(2),
			}}
		>
			{chat && chat.length &&
				chat.map(i => {
					return <PhraseChatItemHOC
						key={'key' + i.id + 'type' + i.type}
						id={i.id}
						type={i.type}
					/>
				})
			}			
		</Stack>
	);

}

