import { AppThunk } from '../../../../store/types';
import { GptChatRest } from '../../../../../common/rest/gptChat/gptChatRest';
import { GptUtils } from '../../../../effects/gptUtils';
import { ICaptionsItem } from '../../../../types/common';
import { getActiveGroupTargetLanguage } from '../../../../store/models/selectors';
import { TextActions } from '../../../../store/text/actions';
import { TextSelectors } from '../../../../store/text/selectors';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';
import { ITextState } from '../../../../store/text/types';
import { getShowNativeCaptions } from '../../../../store/general/selectors';
import { UserSettingEffects } from '../../../../effects/userSettingEffects';
import DOMPurify from 'dompurify';
import { PhraseDetailsActions } from '../../../../store/phrase-details/actions';
import { PhraseContextEditorActions } from '../../../../store/phrase-context-editor/actions';
import { historyPush } from '../../../../../common/utils/historyHelper';

export class StoryFromTranslatedEvents {

	public static onGenerate (
		phraseList: string[],
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const targetLang = getActiveGroupTargetLanguage(state);
			if (!targetLang) return Promise.reject();

			const prompt = `Generate a story in ${targetLang.name} using these words: "${phraseList.join(', ')}".
				Separate each sentence with "<br>".
				Name the story at first line.
			`;
			const {result} = await GptChatRest.exec({
				prompt
			});

			const doc = new DOMParser().parseFromString(DOMPurify.sanitize(result), 'text/html');
			let p = doc.body.textContent || doc.body.innerText;

			let paragraphs = GptUtils.clear(p).split(/(?:\n|\n\n|<br>)/);
			let startTime = -1;
			let captions: ICaptionsItem[] = paragraphs.reduce((captions: ICaptionsItem[], p) => {
				if (!!p.trim()) {
					captions.push({
						startTime,
						endTime: 0,
						duration: 0,
						text: p,
					});
					startTime--;
				}
				return captions;
			}, []);
			
			const title = new Date().toLocaleString() + ' “' + paragraphs[0].replace(/<\/?[^>]+(>|$)/g, ' ').trim() + '”';
			const videoKey = getCurrentMovieKey(state);
			const textKey = videoKey + new Date().toISOString();
			const texts = TextSelectors.getText(state);
			const video = texts.videos ? texts.videos[videoKey] : {};
			const update: ITextState = {
				activeId: textKey,
				videos: {
					...texts.videos,
					[videoKey]: {
						...video,
						[textKey]: {
							title,
							captions,
						},
					}
				}
			}
			dispatch(TextActions.update(update));
			dispatch(this.afterOpen());
	
			return result;
		}
	}

	public static afterOpen (
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const state = getState();
			dispatch(PhraseDetailsActions.disActivate());
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({ phrases: [] }));

			const show = getShowNativeCaptions(state);
			if (show) UserSettingEffects.setShowNativeCaptions(false);

			historyPush({
				state: { page: 'Story' },
				title: 'Story',
				onpopstate: () => dispatch(this.onClose())
			});
		}
	}

	public static onClose (
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(TextActions.update({
				activeId: null as any,
			}));
			dispatch(PhraseDetailsActions.disActivate());
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({ phrases: [] }));
		}
	}

	public static onOpen (
		key: string,
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const state = getState();
			const targetLang = getActiveGroupTargetLanguage(state);
			if (!targetLang) return Promise.reject();

			dispatch(TextActions.update({
				activeId: key,
			}));
			dispatch(this.afterOpen());
		}
	}

	public static onDelete (
		key: string,
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const state = getState();
			const videoKey = getCurrentMovieKey(state);
			const texts = {...TextSelectors.getTextsByVideo(state, videoKey)};
			delete texts[key];
			dispatch(TextActions.updateVideos({
				[videoKey]: texts,
			}));
			if (TextSelectors.getTextActiveId(state) === key) {
				dispatch(this.onClose());
			}
		}
	}

}