import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { MenuWidgets } from '../components/MenuWidgets';
import { IMenuWidgetsEvents, IMenuWidgetsFields } from '../components/types';
import { MenuWidgetsEvents } from './events';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IMenuWidgetsFields => {

		const isTextSelectionPopup = CaptionsSelectionPopupSelectors.isShow(state);

		return {
			isEmpty: !getCurrentMovieKey(state),
			isTextSelectionPopup,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IMenuWidgetsEvents => ({
	onShowHelp: () => dispatch(MenuWidgetsEvents.onShowHelp()),
	onClickContent: () => dispatch(MenuWidgetsEvents.onClickContent()),
	onClickMyLibrary: () => dispatch(MenuWidgetsEvents.onClickMyLibrary()),
	onClickRecent: () => dispatch(MenuWidgetsEvents.onClickRecent()),
}
);

export const MenuWidgetsHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(MenuWidgets);

MenuWidgetsHOC.displayName = 'MenuWidgetsHOC';
