import { IState } from '../../../../store/types';
import { getPlayerState } from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { VideoWatch } from '../components/VideoWatch';
import { IVideoWatchEvents, IVideoWatchFields } from '../components/types';
import { PlayerStates } from '../../../../types/common';
import { VideoWatchEvents } from './events';

const mapStateToProps = (
  dispatch: any,
) => {
  return (
    state: IState
  ): IVideoWatchFields => {
    return {
      play: getPlayerState(state) === PlayerStates.PLAYING
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IVideoWatchEvents => ({
    onTime: (sec) => dispatch(VideoWatchEvents.onTime(sec)),
    onHidden: () => dispatch(VideoWatchEvents.onHidden())
  }
);

export const VideoWatchHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoWatch);

VideoWatchHOC.displayName = 'VideoWatchHOC';