import React from 'react';
import { IInstallLoginProps } from './types';
import { Stack } from '@mui/material';
import { Text } from '../../../../../common/components/Text/Text';
import { theme } from '../../../../../common/styles/theme';
import { LoginFeature } from './LoginFeature';
import { LOGIN_FEATURES } from './constants';
import background from './imgs/background.svg';
import { LogoLabel } from '../../../../../../icons/LogoLabel';
import { ButtonLogin } from './ButtonLogin/ButtonLogin';

export const InstallLogin: React.FC<IInstallLoginProps> = ({
  onDidMount,
  onLogin,
}) => {

  const containerStyle = {
    flexGrow: 1,
    p: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(8),
    bgcolor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(4),
      p: theme.spacing(3),
    }
  };

  const contentStyle = {
    alignItems: 'center',
    gap: theme.spacing(10),
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(6),
    }
  };

  const loginStyle = {
    position: 'relative',
    alignItems: 'center',
    gap: theme.spacing(5)
  };

  const textStyle = {
    alignItems: 'center',
    gap: theme.spacing(4)
  };

  const titleStyle = {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h2,
      fontWeight: 700
    }
  };

  const subtitleStyle = {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: 1.6,
  };

  const featuresStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    width: '100%',
    maxWidth: '1280px',
    gap: theme.spacing(4),
    
    '.login-feature__img img': {
      borderRadius: theme.spacing(1.5),
    },

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  };

  const bgStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    minHeight: '100vh',
    objectFit: 'cover',
    objectPosition: 'center',
  };

  React.useEffect(() => {
    if (onDidMount) onDidMount();
  }, [])

  return (
        <Stack sx={containerStyle}>
          <Stack component={'img'} src={background} sx={bgStyle} />
          <Stack sx={contentStyle}>
            <LogoLabel
              sx={{
                width: theme.spacing(30),
                height: 'auto',
              }}
            />
            <Stack sx={loginStyle}>
              <Stack sx={textStyle}>
                <Text fontWeight={700} variant={'h1'} sx={titleStyle}>Learn any language with YouTube & ChatGPT. <br />Magically!</Text>
                <Text variant={'h5'} sx={subtitleStyle}>
                  Unlock the potential of immersive language learning by combining <br />
                  YouTube's endless content with the power of ChatGPT
                </Text>
              </Stack>

              <ButtonLogin
                onLogin={onLogin}
              />

            </Stack>
          </Stack>

          <Stack direction={'row'} sx={featuresStyle}>
            {LOGIN_FEATURES.map(feature => (
                <LoginFeature key={feature.imgUrl} title={feature.title} imgUrl={feature.imgUrl} icon={feature.icon} />
            ))}
          </Stack>
        </Stack>
  );
}
