import { AppThunk } from '../store/types';
import { setLangsAction, setUserLanguages } from '../store/models/actions';
import { LanguageRest } from '../../common/rest/userLanguage/languageRest';
import { getLanguages } from '../store/models/selectors';

export const updateUserLanguages = (loadUserLangs: boolean = true): AppThunk => async (
  dispatch,
  getState
) => {
  if (!getLanguages(getState())?.length) {
    const langList = await LanguageRest.getAllLanguages();
    dispatch(setLangsAction(langList));
  }

  if (loadUserLangs) {
    const userLangs = await LanguageRest.fetchUserLanguages();
    dispatch(setUserLanguages(userLangs));
  }
}