import { Reducer } from 'redux';
import {
  IPhraseDetailsState,
  IPhrasesDetailCustomPrompHash,
  IPhrasesDetailCustomPromptResult,
  IPhrasesDetailTranslateLang,
  IPhrasesDetailTranslateLangResult,
  SET_PHRASE_DETAILS_ACTIVATE,
  SET_PHRASE_DETAILS_ACTIVE_TAB,
  SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH,
  SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT,
  SET_PHRASE_DETAILS_DISACTIVATE,
  SET_PHRASE_DETAILS_EXPLAIN_HASH,
  SET_PHRASE_DETAILS_EXPLAIN_RESULT,
  SET_PHRASE_DETAILS_HASH,
  SET_PHRASE_DETAILS_HIDE,
  SET_PHRASE_DETAILS_ID, SET_PHRASE_DETAILS_NOTE_LAST_PAUSE,
  SET_PHRASE_DETAILS_NOTE_PHRASE_ID,
  SET_PHRASE_DETAILS_NOTE_PREVIEW_MODE,
  SET_PHRASE_DETAILS_NOTE_TEXT,
  SET_PHRASE_DETAILS_POPUP_MENU,
  SET_PHRASE_DETAILS_RESET_ALLOW_SAVE_RESULTS,
  SET_PHRASE_DETAILS_TRANSLATE_LANG_RESULT,
  SET_PHRASE_DETAILS_TRANSLATE_RESULT,
  SET_PHRASE_DETAILS_USAGE_HASH,
  SET_PHRASE_DETAILS_USAGE_RESULT,
  SET_PHRASE_DETAILS_PROMPTS_CHAT,
  TPhrasesDetailsActions
} from './types';

const initialState: IPhraseDetailsState = {
  hash: ''
};

export const phraseDetailsReducer: Reducer<IPhraseDetailsState, TPhrasesDetailsActions> = (
  state = initialState,
  action
) => {

  switch (action.type) {
    case SET_PHRASE_DETAILS_ACTIVE_TAB:
      return {
        ...state,
        prevTab: state.activeTab,
        activeTab: action.activeTab,
      };
    case SET_PHRASE_DETAILS_HIDE:
      return { ...state, ...initialState };
    case SET_PHRASE_DETAILS_ACTIVATE:
      return {
        ...state,
        text: action.text,
        context: action.context,
        captionLineContext: action.captionLineContext,
        selectText: action.selectText,
        fromLang: action.fromLang,
        toLang: action.toLang,
        phraseId: action.phraseId,
        activeTab: action.activeTab,
        hash: action.hash,
        disableNotes: action.notesDisable,
        promptsChat: null,
      };
    case SET_PHRASE_DETAILS_DISACTIVATE:
      return {
        ...initialState
      };
    case SET_PHRASE_DETAILS_HASH:
      return { ...state, hash: action.hash };
    case SET_PHRASE_DETAILS_TRANSLATE_RESULT:
      return {
        ...state,
        ...{
          translate: {
            ...state.translate,
            ...action.data
          }
        }
      }
    case SET_PHRASE_DETAILS_TRANSLATE_LANG_RESULT:
      return setTranslateLangResult(state, action);
    case SET_PHRASE_DETAILS_EXPLAIN_RESULT:
      return { ...state, explain: {...state.explain, ...{resultText: action.resultText, processing: action.processing, allowSaveResult: action.allowSaveResult}}};
    case SET_PHRASE_DETAILS_EXPLAIN_HASH:
      return { ...state, explain: {...state.explain, ...{hash: action.hash}}};
    case SET_PHRASE_DETAILS_USAGE_RESULT:
      return { ...state, usage: {...state.usage, ...{resultText: action.resultText, processing: action.processing, allowSaveResult: action.allowSaveResult}}};
    case SET_PHRASE_DETAILS_USAGE_HASH:
      return { ...state, usage: {...state.usage, ...{hash: action.hash}}};
    case SET_PHRASE_DETAILS_ID:
      return { ...state, phraseId: action.phraseId };
    case SET_PHRASE_DETAILS_RESET_ALLOW_SAVE_RESULTS:
      return handleResetAllowSaveResults(state, action.allowSave);
    case SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT:
      return setCustomPromptResult(state, action);
    case SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH:
      return setCustomPromptHash(state, action);
    case SET_PHRASE_DETAILS_NOTE_PHRASE_ID:
      return { ...state, note: {...state.note, ...{phraseId: action.phraseId}}};
    case SET_PHRASE_DETAILS_NOTE_TEXT:
      return { ...state, note: {...state.note, ...{resultText: action.text}}};
    case SET_PHRASE_DETAILS_NOTE_LAST_PAUSE:
      return { ...state, note: {...state.note, ...{lastPauseId: action.noteId}}};
    case SET_PHRASE_DETAILS_NOTE_PREVIEW_MODE:
      return { ...state, notePreviewMode: action.notePreviewMode};
    case SET_PHRASE_DETAILS_POPUP_MENU:
      return { ...state, popupMenu: {...state.popupMenu, ...action.popupMenu}}
    case SET_PHRASE_DETAILS_PROMPTS_CHAT:
      return {
        ...state,
        promptsChat: action.promptsChat,
      }

    default:
      return state;
  }
};

const handleResetAllowSaveResults = (state: IPhraseDetailsState, allowSave: boolean): IPhraseDetailsState => {
  const result = {...state};
  if (result.translate) {
    result.translate.allowSaveResult = allowSave;
  }
  if (result.explain) {
    result.explain.allowSaveResult = allowSave;
  }
  if (result.usage) {
    result.usage.allowSaveResult = allowSave;
  }
  if (result.note) {
    result.note.allowSaveResult = allowSave;
  }
  return result;
}

const setCustomPromptResult = (state: IPhraseDetailsState, action: IPhrasesDetailCustomPromptResult): IPhraseDetailsState => {
  const customPrompts = state.customPrompts ? {...state.customPrompts} : {};
  const savePromptData = {
    allowSaveResult: action.allowSaveResult,
    processing: action.processing,
    resultText: action.resultText,
  }
  if (!customPrompts[action.id]) {
    customPrompts[action.id] = {...savePromptData, ...{hash: ''}}
  } else {
    customPrompts[action.id] = {...customPrompts[action.id], ...savePromptData}
  }
  return {...state, ...{customPrompts}};
}

const setCustomPromptHash = (state: IPhraseDetailsState, action: IPhrasesDetailCustomPrompHash): IPhraseDetailsState => {
  const customPrompts = state.customPrompts ? {...state.customPrompts} : {};
  if (customPrompts[action.id]) {
    customPrompts[action.id] = {...customPrompts[action.id], ...{hash: action.hash}}
  }
  return {...state, ...{customPrompts}};
}

const setTranslateLangResult = (state: IPhraseDetailsState, action: IPhrasesDetailTranslateLang): IPhraseDetailsState => {
  if (state.translate && state.translate.results) {
    const results: IPhrasesDetailTranslateLangResult[] = [...state.translate.results];
    const index = results.findIndex(r => r.langCode === action.result.langCode);
    if (index >= 0) {
      results[index] = {...action.result};
    }
    return { ...state, translate: {...state.translate, ...{results}}};
  }
  return state;
}
