import { IState } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { connect } from 'react-redux';
import { ContentLibGroupInfo } from '../components/ContentLibGroupInfo';
import { IContentLibGroupInfoEvents, IContentLibGroupInfoFields } from '../components/types';
import { IContentLibGroupInfoHOCProps } from './types';
import { ContentLibGroupInfoEvents } from './events';
import { findLanguageById } from '../../../../../store/models/selectors';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';

const mapStateToProps = (
  dispatch: any,
  {groupId}: IContentLibGroupInfoHOCProps
) => {
  return (
    state: IState
  ): IContentLibGroupInfoFields => {
    const group = ContentLibSelectors.findGroupById(state, groupId);
    let {
      id = 0,
      title = '',
      description = '',
      targetLangId = 0,
      nativeLangId = 0,
      videoCount = 0,
      tags = []
    } = group || {};
    const targetLang = findLanguageById(state, targetLangId)?.code || '';
    const nativeLang = findLanguageById(state, nativeLangId)?.code || '';
    const isSelected = ContentLibSelectors.getViewMode(state) === EContentLibViewMode.GROUP_VIDEO_LIST;
    return {
      id,
      title,
      description,
      targetLang,
      nativeLang,
      videoCount,
      tags,
      isSelected,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId}: IContentLibGroupInfoHOCProps
): IContentLibGroupInfoEvents => ({
    onCopyGroup: () => dispatch(ContentLibGroupInfoEvents.onCopyGroup(groupId)),
    onSelectGroup: () => dispatch(ContentLibGroupInfoEvents.onSelectGroup(groupId)),
    onBack: () => dispatch(ContentLibGroupInfoEvents.onBack(groupId)),
  }
);

export const ContentLibGroupInfoHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibGroupInfo);

ContentLibGroupInfoHOC.displayName = 'ContentLibGroupInfoHOC';
