import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { AddNewAudioPopup } from '../components/AddNewAudioPopup';
import { IAddNewAudioPopupEvents, IAddNewAudioPopupFields } from '../components/types';
import { AddNewAudioPopupEvents } from './events';
import { isShowNewAudioPopup } from '../../../../store/general/selectors';
import { IAddNewAudioPopupHOCProps } from './types';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAddNewAudioPopupFields => {
    return {
      isOpen: isShowNewAudioPopup(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  props: IAddNewAudioPopupHOCProps,
): IAddNewAudioPopupEvents => {
  const { groupId } = props; 
  return {
    onCreate: (text, langCode) => dispatch(AddNewAudioPopupEvents.onCreate(text, langCode, groupId)),
    onTextChange: text => dispatch(AddNewAudioPopupEvents.onTextChange(text)),
    onClose: () => dispatch(AddNewAudioPopupEvents.onClose()),
  }
};

export const AddNewAudioPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewAudioPopup);

AddNewAudioPopupHOC.displayName = 'AddNewAudioPopupHOC';