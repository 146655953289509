import React from 'react';
import { IPhraseNotesPopupProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { PhraseNotesHOC } from '../../PhraseNotes/hocs/PhraseNotesHOC';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { Box, Button, Stack } from '@mui/material';
import { CloseIconLight } from '../../../../../../../icons/CloseIconLight';

export const PhraseNotesPopup: React.FC<IPhraseNotesPopupProps> = ({
	isShow,
	onClose,
}) => {
	if (!isShow) return null;

	return (
		<Paper
			className={'note-popup'}
			sx={{
				position: 'absolute',
				zIndex: 200,
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				display: 'flex',
				flexDirection: 'column',
				gap: theme.spacing(1),
				p: theme.spacing(1),
				boxShadow: theme.shadows[16],
			}}
		>
			<Stack
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<Box
					sx={{
						fontSize: theme.typography.h6,
						fontWeight: 600,
					}}
				>
					Note
				</Box>
				<Button
					onClick={onClose}
					sx={{
							width: theme.spacing(3),
							height: theme.spacing(3),
							color: theme.palette.grey[400],
							
							'&:hover': {
								color: theme.palette.grey[700],
							},
					
							'.MuiSvgIcon-root': {
								width: theme.spacing(2.5),
								height: theme.spacing(2.5),
							},
						}}
				>
					<CloseIconLight />
				</Button>
			</Stack>

			<PhraseNotesHOC />
		</Paper>
	);

}
