import { EGpPromptGroupType, TPromptBase } from '../models/types';

export const SET_PHRASE_DETAILS_TABS_EDITOR_VIEW_MODE = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_VIEW_MODE';
export const SET_PHRASE_DETAILS_TABS_EDITOR_NAVIGATION_MODE = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_NAVIGATION_MODE';
export const SET_PHRASE_DETAILS_TABS_EDITOR_EDIT_TAB_ID = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_EDIT_TAB_ID';
export const SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_PROCESSING = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_PROCESSING';
export const SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_SHOW_LIST = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_SHOW_LIST';

export const SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROMPT = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROMPT';
export const SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RAW_PROMPT = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RAW_PROMPT';
export const SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RESULT = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RESULT';
export const SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROCESSING = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROCESSING';
export const SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_LIBS = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_LIBS';
export const SET_PHRASE_DETAILS_TABS_EDITOR_OPEN_PROMPT_LIB_ID = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_OPEN_PROMPT_LIB_ID';
export const SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_TUTORIAL = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_TUTORIAL';
export const SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_FAVOR_TOOLTIP = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_FAVOR_TOOLTIP';
export const SET_PHRASE_DETAILS_TABS_EDITOR_LAST_SAVE_TAB_ID = 'PHRASE_DETAILS_TABS_EDITOR_POPUP/SET_PHRASE_DETAILS_TABS_EDITOR_LAST_SAVE_TAB_ID';

export type TLibGptPrompt = TPromptBase & {
  orderNum: number;
}

export enum EPhraseDetailsTabsEditorViewMode {
  MAIN, CREATE, LIBS, TRANSLATIONS
}

export enum EPhraseDetailsTabsEditorNavigationMode {
  MAIN, PROMPT_EDITOR
}

export interface IPhraseDetailsTabsEditorPromptTest {
  prompt: string,
  rawPrompt: string,
  result: string,
  processing: boolean
}

export interface IPhraseDetailsTabsEditorPopupState {
  viewMode: EPhraseDetailsTabsEditorViewMode;
  navigationMode: EPhraseDetailsTabsEditorNavigationMode;
  editTabId: number;
  processing: boolean;
  promptTest: IPhraseDetailsTabsEditorPromptTest;
  promptLibs: TLibGptPrompt[] | null;
  openPromptLibId: number;
  showTutorial: boolean;
  showFavoritePromptTooltip: boolean;
  lastSaveTabId: number;
}

export interface IPhraseDetailsTabsEditorPopupViewMode {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_VIEW_MODE;
  viewMode: EPhraseDetailsTabsEditorViewMode;
}

export interface IPhraseDetailsTabsEditorPopupNavigationMode {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_NAVIGATION_MODE;
  navigationMode: EPhraseDetailsTabsEditorNavigationMode;
}

export interface IPhraseDetailsTabsEditorPopupEditTabId {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_EDIT_TAB_ID;
  editTabId: number;
}



export interface IPhraseDetailsTabsEditorPopupProcessing {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_PROCESSING;
  processing: boolean
}

export interface IPhraseDetailsTabsEditorPopupShowList {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_SHOW_LIST;
  activeTab: EGpPromptGroupType;
}


export interface IPhraseDetailsTabsEditorPromptTestPrompt {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROMPT;
  prompt: string
}

export interface IPhraseDetailsTabsEditorPromptTestRawPrompt {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RAW_PROMPT;
  rawPrompt: string
}

export interface IPhraseDetailsTabsEditorPromptTestResult {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RESULT;
  result: string
}

export interface IPhraseDetailsTabsEditorPromptTestProcessing {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROCESSING;
  processing: boolean
}

export interface IPhraseDetailsTabsEditorPromptLibs {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_LIBS;
  promptLibs: TLibGptPrompt[]
}

export interface IPhraseDetailsTabsEditorOpenPromptLibId {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_OPEN_PROMPT_LIB_ID;
  openPromptLibId: number
}

export interface IPhraseDetailsTabsEditorShowTutorial {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_TUTORIAL;
  showTutorial: boolean
}

export interface IPhraseDetailsTabsEditorShowFavoritePromptTooltip {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_FAVOR_TOOLTIP;
  show: boolean
}

export interface IPhraseDetailsTabsEditorLastSaveTabId {
  type: typeof SET_PHRASE_DETAILS_TABS_EDITOR_LAST_SAVE_TAB_ID;
  lastSaveTabId: number
}



export type IPhraseDetailsTabsEditorPopupActions =
  | IPhraseDetailsTabsEditorPopupViewMode
  | IPhraseDetailsTabsEditorPopupNavigationMode
  | IPhraseDetailsTabsEditorPopupEditTabId
  | IPhraseDetailsTabsEditorPopupProcessing
  | IPhraseDetailsTabsEditorPopupShowList
  | IPhraseDetailsTabsEditorPromptTestPrompt
  | IPhraseDetailsTabsEditorPromptTestRawPrompt
  | IPhraseDetailsTabsEditorPromptTestResult
  | IPhraseDetailsTabsEditorPromptTestProcessing
  | IPhraseDetailsTabsEditorPromptLibs
  | IPhraseDetailsTabsEditorOpenPromptLibId
  | IPhraseDetailsTabsEditorShowTutorial
  | IPhraseDetailsTabsEditorShowFavoritePromptTooltip
  | IPhraseDetailsTabsEditorLastSaveTabId