import React from 'react';
import { IVideoWatchProps } from './types';

export const VideoWatch: React.FC<IVideoWatchProps> = ({
  play,
  onTime,
  onHidden
}) => {

  const [startWatchTime, setStartWatchTime] = React.useState<number>(0);
  const [totalTime, setTotalTime] = React.useState<number>(0);

  const totalTimeRef = React.useRef<number>(totalTime);
  totalTimeRef.current = totalTime;

  const startWatchTimeRef = React.useRef<number>(startWatchTime);
  startWatchTimeRef.current = startWatchTime;


  React.useEffect(() => {
    const onVisibilitychange = () => {
      if (document.hidden) {
        onHidden();
      }
    }
    if (play) {
      setStartWatchTime(new Date().getTime());
      document.addEventListener('visibilitychange', onVisibilitychange);
    } else {
      const _totalTime = checkTotalTime(startWatchTime, totalTime, true);
      if (_totalTime) {
        onTime(Math.round(_totalTime / 1000));
      }
    }

    return () => {
      document.removeEventListener('visibilitychange', onVisibilitychange);
    }
  }, [play]);


  const checkTotalTime = (startWatchTime: number, totalTime: number, reset: boolean): number => {
    let result = 0;
    if (startWatchTime > 0) {
      const time = new Date().getTime() - startWatchTime;
      if (time > 0) {
        result = totalTime + time
        setTotalTime(result);
      }
      setStartWatchTime(reset ? 0 : new Date().getTime());
    }
    return result;
  }

  React.useEffect(() => {
    const interval1 = setInterval(() => {
      checkTotalTime(startWatchTimeRef.current, totalTimeRef.current, false);
    },  10 * 1000);

    const interval2 = setInterval(() => {
      if (totalTimeRef.current > 0) {
        onTime(Math.round(totalTimeRef.current / 1000));
        setTotalTime(0);
        if (startWatchTime > 0) {
          setStartWatchTime(new Date().getTime());
        }
      }
    }, 60 * 1000);

    return () => {
			clearInterval(interval1);
      clearInterval(interval2);
		}
  }, []);


  return null;
}
