import { AppThunk } from '../../../../../store/types';
import { setCreatePlaylistModalOpenAction } from '../../../../../store/general/actions';


export class MyLibEvents {

	public static onShowAddPlayList(
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(setCreatePlaylistModalOpenAction(true))
		}
	}

}