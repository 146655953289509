import { IState } from '../types';
import { ITextState, ITextVideoText, TTextVideoTexts } from './types';

export class TextSelectors {

	public static getText (state: IState): ITextState {
		return state.text;
	}

	public static getTextActiveId (state: IState): string {
		return this.getText(state).activeId || null as any;
	}

	public static getTextsByVideo (
		state: IState,
		videoKey: string,
	): TTextVideoTexts {
		const videos = this.getText(state).videos;
		if (!videos) return null as any;

		return videos[videoKey];
	}

	public static getTextByKey (
		state: IState,
		videoKey: string,
		textKey: string,
	): ITextVideoText {
		const video = this.getTextsByVideo(state, videoKey);
		if (!video) return null as any;

		return video[textKey];
	}
}
