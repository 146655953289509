import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { IContentLibGroupProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { ContentLibGroupInfoHOC } from '../../ContentLibGroupInfo/hocs/ContentLibGroupInfoHOC';


export function ContentLibGroup({
	id,
	videos,
	onClick,
}: IContentLibGroupProps) {

	const className = 'content-lib-group';

	let v0: string = '',
		v1: string = '',
		v2: string = '',
		v3: string = '';
	
	if (videos.length) {
		v0 = videos[0].youtubeThumbUrl;

		if (videos.length > 1) {
			v1 = videos[1].youtubeThumbUrl;

			if (videos.length > 2) {
				v2 = videos[2].youtubeThumbUrl;

				if (videos.length > 3) {
					v3 = videos[3].youtubeThumbUrl;
				}
			}
		}
	}

	return (
		<Stack
			className={`content-lib-group-${id}`}
			direction={'row'}
			gap={3}
			sx={{
				alignItems: 'center',
				py: 2,

				'& + &': {
					borderTop: `2px solid ${theme.palette.grey[200]}`
				},

				'.content-lib-group-info__vertical': {
					gap: 2,
				},

				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column',

					[`.${className}__video`]: {
						flexBasis: 0,
					},
				},
			}}
		>

			<Box
				className={`${className}__video`}
				sx={{
					alignSelf: 'flex-start',
					flex: `1 1 256px`,
					width: 1,
					position: 'relative',
					mt: theme.spacing(1),
					cursor: 'pointer',

					[`&:before, &:after`]: {
						content: '""',
						position: 'absolute',
						zIndex: 1,
						aspectRatio: 16/9,
						backgroundPosition: '50% 50%',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundImage: v0 ? `url(${v0})` : theme.palette.grey[400],
						borderRadius: theme.spacing(1),
					},

					'&:before': {
						top: theme.spacing(-1.5),
						left: theme.spacing(-1.5),
						right: theme.spacing(1.5),
						background: `rgb(252,252,254)`,
					},

					'&:after': {
						top: theme.spacing(-.75),
						left: theme.spacing(-.75),
						right: theme.spacing(.75),
						background: theme.palette.grey[200],
					},
				}}
				onClick={onClick}
			>
				<Box
					sx={{
						position: 'relative',
						zIndex: 2,
						aspectRatio: 16/9,
						background: v0 ? `url(${v0})` : theme.palette.grey[400],
						backgroundPosition: '50% 50%',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						borderRadius: theme.spacing(1),
						overflow: 'hidden',
					}}
				>
					{v1 &&
						<Grid container
							sx={{
								width: 1,
								height: 1,

								'.MuiGrid-root': {
									width: .5,
									height: .5,
									backgroundPosition: '50% 50%',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
								}
							}}
						>
							<Grid
								sx={{
									backgroundImage: `url(${v0})`,
								}}
							/>
							<Grid
								sx={{
									backgroundImage: `url(${v1})`,
								}}
							/>
							{v2 &&
								<Grid
									sx={{
										backgroundImage: `url(${v2})`,
									}}
								/>
							}
							{v3 &&
								<Grid
									sx={{
										backgroundImage: `url(${v3})`,
									}}
								/>
							}
						</Grid>
					}
				</Box>
			</Box>
			
			<ContentLibGroupInfoHOC
				groupId={id}
			/>

		</Stack>
	)
};