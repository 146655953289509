import { AppThunk } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibRest } from '../../../../../../common/rest/contentLib/contentLibRest';
import { EContentLibViewMode, IContentLibVideo } from '../../../../../store/contentLib/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { Navigator } from '../../../../../effects/navigator';
import { getAuthUser } from '../../../../../store/general/selectors';
import { AuthManager } from '../../../../../../common/utils/auth-manager';
import { resetModelsAction } from '../../../../../store/models/actions';
import { prepareAfterOpen } from '../../../../../effects/prepareAfterOpen';
import { findLanguageById } from '../../../../../store/models/selectors';
import { updateUserLanguages } from '../../../../../effects/updateUserLanguages';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { ErrorEffects } from '../../../../../effects/errorEffects';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';


export class ContentLibVideoListEvents {

  public static onLoadVideos(startIndex: number, count: number): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IContentLibVideo[]> => {
      const groupId = ContentLibSelectors.getSelectedGroupId(getState());
      const {text: searchText, targetLangCode, nativeLangCode} = ContentLibSelectors.getSearchFilter(getState());
      let videos;

      let promises: Promise<any>[] = [
        ContentLibEffects.searchVideos({
          groupId, searchText, targetLangCode, nativeLangCode, startIndex, count
        }).then((result) => {
          videos = result;
        }),
      ];

      if (!groupId) {
        promises.push(ContentLibRest.searchGroups({
            targetLangCode,
            nativeLangCode,
            searchText,
            maxVideoCount: 0,
          }).then((groups) => {
            dispatch(ContentLibActions.setGroupsSearchResult({ groups }));
          })
        )
      }
      
      return Promise.all(promises).then(() => videos);
    }
  }

  public static onVideoClick(video: IContentLibVideo | any): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setLockedMode(true));
      try {
        const groupId = ContentLibSelectors.getSelectedGroupId(getState()) || video.groupId;
        if (video) {
          //  dispatch(ContentLibActions.setVideoPopup({groupId, videoId: video.id, video}));
          if (!getAuthUser(getState())) {
            dispatch(resetModelsAction());
            await dispatch(updateUserLanguages(false))
            const contentLibGroup = ContentLibSelectors.findGroupById(getState(), groupId);
            const targetLangCode = findLanguageById(getState(), contentLibGroup?.targetLangId || 0)?.code || 'en';
            let nativeLangCode = LangUtil.checkLangCode(navigator.language);
            if (nativeLangCode === targetLangCode) {
              nativeLangCode = targetLangCode === 'en' ? 'fr' : 'en';
            }
            const result = await AuthManager.loadAnonymous(targetLangCode, nativeLangCode);
            if (result && result.authUser) {
              await dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
            }
          }
          const result = await ContentLibEffects.previewVideo(groupId, video.id, video?.url || '');
          Navigator.openVideo(result.groupId, result.videoId);
        }
      } catch(e) {
        ErrorEffects.logError(e)
      } finally {
        dispatch(ContentLibActions.setLockedMode(false));
      }
    }
  }

  public static onClickMyStuff (): AppThunk {
    return (
      dispatch,
      getState,
    ) => {
      Navigator.openMyStuff();
      dispatch(setMainViewMode(EMainViewMode.MY_LIBRARY));
      dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST));
    }
  }

}