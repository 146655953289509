import { Reducer } from 'redux';
import { ILoginPopupState, LOGIN_POPUP_SET_ACTION, TLoginPopupActions } from './types';

const initialState: ILoginPopupState = {
	show: false,
};

export const loginPopupReducer: Reducer<ILoginPopupState, TLoginPopupActions> = (
	state = initialState,
	action
) => {

	switch (action.type) {
		case LOGIN_POPUP_SET_ACTION:
			return {
				...state,
				...action.value,
			};

		default:
			return state;
	}
};