import { AppThunk } from '../../../../store/types';
import { setAddAudioConfirmAction } from '../../../../store/general/actions';
import { getAddAudioConfirm } from '../../../../store/general/selectors';
import { createGroupNameIndex, getNativeLanguage } from '../../../../store/models/selectors';
import { UserGroupEffects } from '../../../../effects/userGroupEffects';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { AudioEffects } from '../../../../effects/audioEffects';
import { TAudio } from '../../../../store/models/types';

export class AddAudioConfirmEvents {

	public static onChange(
		groupId: number,
		newListName?: string,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const {
				text,
				langCode = '',
			} = getAddAudioConfirm(state) || {};
			if (!text) {
				dispatch(this.onClose());
				return Promise.reject();
			}

			if (!groupId) { // create group
				const nativeLangCode = getNativeLanguage(state)?.code || '';
				let groupName = createGroupNameIndex(state, newListName || `${LangUtil.getLangNameByCode(langCode)} / ${LangUtil.getLangNameByCode(nativeLangCode)}`);
				
				groupId = await UserGroupEffects.createGroup(groupName, langCode, nativeLangCode);

				if (groupId) {
					await UserGroupEffects.loadGroup(groupId);
				}
			}
			// @ts-ignore
			const result: TAudio = await AudioEffects.add(text, langCode, groupId);

			setTimeout(() => { // show success
				dispatch(this.onClose());
			}, 1000);
			return Promise.resolve();
		}
	}

	public static onClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setAddAudioConfirmAction({ show: false }));
		}
	}


}