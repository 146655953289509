import React, { ChangeEvent } from 'react';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { theme } from '../../../../../common/styles/theme';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { Box, Button, CircularProgress, FormGroup, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField } from '@mui/material';
import { IAddNewAudioPopupProps } from './types';
import { IRestAudioLang } from '../../../../../common/rest/audio/audioRest';
import { ArrowIcon } from '../../../../../../icons/ArrowIcon';
import { UploadIcon } from '../../../../../../icons/UploadIcon';
import DOMPurify from 'dompurify';

enum EInputType {
	TEXT, FILE
}

enum EStep {
	SELECT_TEXT, SELECT_LANG
}

export const AddNewAudioPopup: React.FC<IAddNewAudioPopupProps> = ({
	isOpen,
	onCreate,
	onTextChange,
	onClose
}) => {
	if (!isOpen) return null;

	const { isMobile } = React.useContext(DeviceContext);

	const [step, setStep] = React.useState<EStep>(EStep.SELECT_TEXT);
	const [text, setText] = React.useState<string>('');
	const [progress, setProgress] = React.useState<boolean>(false);
	const [inputType, setInputType] = React.useState<EInputType>(EInputType.TEXT);
	const [langs, setLangs] = React.useState<IRestAudioLang[]>([]);
	const [selectLangCode, setSelectLangCode] = React.useState<string>(-1);

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
	}

	const handleAddAudio = async () => {
		setProgress(true);
		try {
			await onCreate(text, selectLangCode);
		} catch (e) {
			console.error(e);
		} finally {
			setProgress(false);
		}
		onClose();
	}

	const showSelectLang = async () => {
		setProgress(true);
		try {
			const langResult = await onTextChange(text);
			const langIndex = langResult?.findIndex || -1;
			const lang = langResult.langs[langIndex];
			setLangs(langResult?.langs || []);
			setSelectLangCode(lang?.code || '');
			setStep(EStep.SELECT_LANG);
		} catch (e) {
			console.error(e);
		} finally {
			setProgress(false);
		}
	}

	const onFileUploadChange = async (evt: ChangeEvent<HTMLInputElement>) => {
		if (evt?.target?.files && evt?.target?.files?.length > 0) {
			const file = evt.target.files[0];
			const reader: FileReader = new FileReader();
			reader.onload = async (e: ProgressEvent<FileReader>) => {
				let result = e.target?.result?.toString() || '';
				if (file.type.includes('html')) {
					let html = DOMPurify.sanitize(result);
					const doc = new DOMParser().parseFromString(html, 'text/html');
                    result = doc.body.textContent || doc.body.innerText;
				}
				setText(result);
				setInputType(EInputType.TEXT);
			};
			reader.readAsText(file);
		}
	}

	const handleSelectLangChange = ({ target }: any) => {
		setSelectLangCode(target.value);
	};

	return (
		<Modal
			onClose={onClose}
			open={isOpen}
			title={'Convert text to audio'}
			isMobile={isMobile}
			PaperProps={{
				sx: {
					width: 1,
					maxWidth: `${theme.spacing(100)} !important`,

					'& > .MuiStack-root': {
                        pb: `0 !important`,
                    },
				}
			}}
		>
			<Stack>

				{step === EStep.SELECT_TEXT &&
					<Stack
						gap={2}
						sx={{
							position: 'relative',
						}}
					>

						<Tabs
							value={inputType}
							onChange={(e, value) => {
								setInputType(value)
							}}
							sx={{
								m: theme.spacing(-1, -3, 0),
								p: theme.spacing(0, 3),
								borderBottom: `1px solid ${theme.palette.grey[100]}`,

								'.MuiTabs-flexContainer': {
									gap: 2,
								},

								'.MuiTab-root': {
									p: 0,
									minWidth: 0,
									maxWidth: 'none',
								},
							}}
						>
							<Tab
								label={'Insert text'}
							/>
							<Tab
								label={'Text file'}
							/>
						</Tabs>

						{inputType === EInputType.TEXT &&
							<TextField
								fullWidth
								value={text}
								onChange={handleTextChange}
								multiline={true}
								variant={'filled'}
								autoFocus
								disabled={progress}
								sx={{
									'.MuiInputBase-root.MuiInputBase-multiline': {
										p: theme.spacing(1),
									},
									'.MuiInputBase-input': {
										minHeight: theme.spacing(8),
									},
								}}
							/>
						}

						{inputType === EInputType.FILE &&
							<Box>
								<form encType="multipart/form-data">
									
								</form>
								<Button
									component="label"
									role={undefined}
									variant="contained"
									tabIndex={-1}
									startIcon={<UploadIcon />}
								>
									Open text file
									<input
										id="upload"
										type="file"
										accept="text/*,.txt"
										name="files"
										onChange={onFileUploadChange}
										style={{
											position: 'absolute',
											opacity: 0,
											pointerEvents: 'none',
										}}
									/>
								</Button>
							</Box>
						}

						<Stack
							flexDirection={'row'}
							justifyContent={'flex-end'}
							sx={{
								position: 'sticky',
								bottom: 0,
								mt: theme.spacing(-2),
								pt: theme.spacing(2),
								pb: theme.spacing(3),
								background: theme.palette.common.white,
							}}
						>
							<Button
								disabled={!text.length || progress}
								onClick={showSelectLang}
								variant={'contained'}
							>
								Next
							</Button>
						</Stack>
					</Stack>
				}


				{step === EStep.SELECT_LANG &&
					<FormGroup
					>
						<InputLabel
							sx={{
								mb: theme.spacing(1),
							}}
						>
							Text language:
						</InputLabel>
						<Select
							disabled={progress}
							value={selectLangCode}
							onChange={handleSelectLangChange}
							variant={'filled'}
							fullWidth
							displayEmpty
						>
							{
								langs.map((lang) => {
									return (
										<MenuItem
											key={lang.code}
											value={lang.code}
										>
											{lang.title}
										</MenuItem>
									);
								})
							}
						</Select>

						<Stack
							flexDirection={'row'}
							justifyContent={'space-between'}
							sx={{
								mt: theme.spacing(2),
								mb: theme.spacing(3),
							}}
						>
							<Button
								disabled={progress}
								onClick={()=>setStep(EStep.SELECT_TEXT)}
								variant={'text'}
								startIcon={<ArrowIcon
									sx={{
										width: theme.spacing(2),
										height: theme.spacing(2),
										transform: 'scaleX(-1)',
									}}
								/>}
								sx={{
									color: theme.palette.grey[400],
								}}
							>
								Back
							</Button>
							<Button
								disabled={!selectLangCode || progress}
								onClick={handleAddAudio}
								variant={'contained'}
							>
								Add Audio
							</Button>
						</Stack>
					</FormGroup>
				}

				{progress &&
					<CircularProgress
						size={theme.spacing(3)}
						sx={{
							position: 'absolute',
							left: `50%`,
							bottom: theme.spacing(4),
							transform: `translateX(${theme.spacing(-1.5)})`,
						}}
					/>
				}

			</Stack>
		</Modal>
	);
}
