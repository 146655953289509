import { AppThunk, IState } from '../../../../../../store/types';
import { History } from 'history';
import {
  getActiveGroupId,
  getUserGroupById,
  getUserGroupLangToLearn,
  getUserGroups,
  getUserGroupWithLangCode
} from '../../../../../../store/models/selectors';
import { ELanguageStatus } from '../../../../../../store/general/types';
import { TUserGroup } from '../../../../../../store/models/types';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { removeUserGroups } from '../../../../../../../common/rest/userGroup/removeUserGroups';
import {
  removeGroupAction,
} from '../../../../../../store/models/actions';
import { getLibraryGroupId } from '../../../../../../store/general/selectors';
import { EExtMessageType, ExtMessageSender } from '../../../../../../../common/utils/ext-message-sender';
import { UserGroupEffects } from '../../../../../../effects/userGroupEffects';
import { LANG_CODE_AUTO_GEN_SUBSTR } from '../../../../../../types/common';
import { PhraseListActions } from '../../../../../../store/phrase-list/actions';
import { setEditPlaylistModalIdAction,setSnackbarPanelAction } from '../../../../../../store/general/actions';
import { EContentLibViewMode } from '../../../../../../store/contentLib/types';
import { CopyGroupEffects } from '../../../../../../effects/copyGroupEffects';
import { ContentLibEvents } from '../../../../ContentLibrary/effects';

export class EditPlaylistItemEvents {

  public static onDelete(groupId: number, history: History): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const group = getUserGroupById(state, groupId);

      if (group) {
        const delGroupIds = [group.id];
        await this.removeGroup(dispatch, group.id);
        const targetLang = getUserGroupLangToLearn(state, group);
        if (targetLang) {
          if (targetLang.code.indexOf(LANG_CODE_AUTO_GEN_SUBSTR) < 0) {
            const autoGenTargetLangGroup = getUserGroupWithLangCode(state, ELanguageStatus.TO_LEARN, targetLang.code + LANG_CODE_AUTO_GEN_SUBSTR);
            if (autoGenTargetLangGroup) {
              await this.removeGroup(dispatch, autoGenTargetLangGroup.id);
              delGroupIds.push(autoGenTargetLangGroup.id);
            }
          }
        }

        const groups: TUserGroup[] = getUserGroups(getState());
        if (groups && groups.length > 0) {
          UserGroupEffects.setLibraryGroupId(groups[0].id);
        } else {
          UserGroupEffects.setLibraryGroupId(0);
        }

        const activeGroupId = getActiveGroupId(state);
        const libGroupId = getLibraryGroupId(state);
        const activeGroupDeleted: boolean = Boolean(activeGroupId && delGroupIds.includes(activeGroupId));
        const libGroupDeleted: boolean = Boolean(libGroupId && delGroupIds.includes(libGroupId));
        if (libGroupDeleted && !activeGroupDeleted) {
          UserGroupEffects.setLibraryGroupId(activeGroupId || 0);
        }
        ExtMessageSender.send({
          type: EExtMessageType.SET_USER_GROUPS,
          payload: {userGroups: getUserGroups(getState())}
        });
      }

    }
  }

  private static async removeGroup(
    dispatch: ThunkDispatch<IState, unknown, Action<string>>,
    groupId: number)
  {
    await dispatch(removeUserGroups(groupId));
    dispatch(removeGroupAction(groupId));
    dispatch(PhraseListActions.removeByGroup(groupId));
    dispatch(ContentLibEvents.closeGroup(groupId, EContentLibViewMode.GROUP_LIST));
  }

  public static onEdit(
    groupId: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalIdAction(groupId));
    }
  }

  public static onCopyLink(
    groupId: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const {url} = await CopyGroupEffects.createCopyLink(groupId);
      await navigator.clipboard.writeText(url);
      dispatch(setSnackbarPanelAction(true, 'Link to public playlist copied to clipboard'));
    }
  }

  public static onSelectGroup(groupId: number): AppThunk {
      return async (
        dispatch,
        getState
      ) => {
        dispatch(ContentLibEvents.openGroup(groupId));
      }
  }
  
  public static onBack(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibEvents.closeGroup(groupId, EContentLibViewMode.GROUP_LIST));
    }
  }


}
