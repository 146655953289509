import { AppThunk } from '../../../../../store/types';
import { EVocabularyPhraseSaveType, EVocabularyPhraseType, ICaptionsItem, PlayerStates } from '../../../../../types/common';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { CaptionsSelectionPopupActions } from '../../../../../store/captions-selection-popup/actions';
import { findVideoPhraseById, findVideoPhrasesByType, getTargetCaptions } from '../../../../../store/videos/selectors';
import { PhraseSelectPreparator } from '../../../../../effects/phrase/utils/phrase-select-preparator';
import { getActiveGroupNativeLanguage, getActiveUserGroup } from '../../../../../store/models/selectors';
import { getAuthUser, getPlayerState, isHoverAutoPause, isLoggedUser } from '../../../../../store/general/selectors';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { getSelectedNativeCaption, getSelectedTargetCaption } from '../../../../../store/current-video/selectors';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { SelectionPopupEffects } from '../../../../../effects/selectionPopupEffects';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { TextSelectionPopupEvents } from '../../../TextSelectionPopup/hocs/events';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { setCurrentVideoClickedCaptionIndex } from '../../../../../store/current-video/actions';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { batch } from 'react-redux';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { EUserGroupType } from '../../../../../store/general/types';
import { LoginPopupActions } from '../../../../../store/loginPopup/actions';
import { TextSelectors } from '../../../../../store/text/selectors';
import { TargetCaptionsEffects } from '../../../AudioCaptions/TargetCaptions/effects/TargetCaptionsEffects';


export class TargetCaptionsEvents {

  public static onCaptionClick(
    index: number,
    caption: ICaptionsItem,
    wordIndex: number,
    phraseId?: number,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      TargetCaptionsEffects.selectCaption(index, caption, wordIndex, phraseId);
    }
  }


  public static onCaptionPlay(caption: ICaptionsItem, isPause: boolean): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (isPause) {
        PlayerController.getInstance().pause();
      } else {
        PlayerController.getInstance().playCaption(caption, true);
      }
    }
  }

  public static onCaptionMouseDown(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }
    }
  }

  public static onMouseLeave(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (!isHoverAutoPause(getState())) return;
      PlayerController.getInstance().mouseLeave();
    }
  }

  public static onMouseEnter(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (!isHoverAutoPause(getState())) return;
      PlayerController.getInstance().mouseEnter();
    }
  }

  public static onSelectText(
    selection: Selection,
    elementId: string,
    isSelectPopup?: boolean,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (!isLoggedUser(getState())) {
        return dispatch(LoginPopupActions.set({
          show: true,
          text: 'Please login to save phrase'
        }));
      }

      if (!selection) {
        dispatch(CaptionsSelectionPopupActions.setSelection(null as any));
        dispatch(CaptionsSelectionPopupActions.setSelectResult(null));
        return false;
      }
      EventsRouter.trackEvent(Events.TARGET_CAPTION_SELECTED);

      const state = getState();
      const storyFromTranslatedId = TextSelectors.getTextActiveId(state);
      const targetLanguage = getSelectedTargetCaption(state);
      const nativeLanguage = getSelectedNativeCaption(state);

      const wordSelectPhrases = storyFromTranslatedId ? [] : findVideoPhrasesByType(state, EVocabularyPhraseType.WORD_SELECTED);
      const contextPhrases = storyFromTranslatedId ? [] : findVideoPhrasesByType(state, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);
      const useCheckSpanWords = !LangUtil.isHieroglyphLang(targetLanguage?.code || '');
      const selResult = new PhraseSelectPreparator(selection, getTargetCaptions(state), wordSelectPhrases, contextPhrases, elementId, useCheckSpanWords).execute();

      const selectResultPrev = CaptionsSelectionPopupSelectors.getSelectResult(state);
      if (selResult && selectResultPrev &&
        selResult.text === selectResultPrev.text &&
        selResult.startTime === selectResultPrev.startTime
      ) {
        return false; // no change
      }

      batch(() => {
        dispatch(CaptionsSelectionPopupActions.setShow(true));
        dispatch(CaptionsSelectionPopupActions.setSelection(selection));
        dispatch(CaptionsSelectionPopupActions.setSelectResult(selResult));
        dispatch(setCurrentVideoClickedCaptionIndex(-1));
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(0));
        dispatch(PhraseListActions.setCurrentPhraseId(0));
        dispatch(PhraseListActions.setPlayPhraseId(0));
        dispatch(PhraseDetailsActions.disActivate());
      })

      if (targetLanguage && selResult?.text) {
        if (selResult.text) {
          const activeGroupNativeLang = getActiveGroupNativeLanguage(state);
          if (selResult.insideWordActiveId) {
            await PhraseDetailsEffects.showFromPhrase(targetLanguage, nativeLanguage, activeGroupNativeLang,
              selResult.insideWordActiveId, selResult.text);
          } else {
            await PhraseDetailsEffects.showFromSelectText(targetLanguage, nativeLanguage, activeGroupNativeLang, selResult);
          }

          if (!getAuthUser(state)?.teacherMode) {
            const group = getActiveUserGroup(state);
            let phrase;
            if (selResult.insideWordPhrases && selResult.insideWordPhrases.length) {
              phrase = selResult.insideWordPhrases.find(p => p.highlighted === selResult.text);
            }
            if (!phrase && !storyFromTranslatedId) {
              if (group?.type !== EUserGroupType.PREVIEW) {
                PhraseEffects.runSavePreviewPhrase(selResult);
              } else {
                PhraseEffects.flashTab(EVocabularyPhraseSaveType.TRANSLATE);
              }
            }
          }
          await PlayerController.getInstance().pausePlay(true);
        }
      }

      if (selResult && isSelectPopup) {
        dispatch(TextSelectionPopupEvents.onPreviewPhrase(selResult));
      }

      return true;
    }
  }

  public static onDismissSelectText(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (document.body.classList.contains('onboarding-active')) return;

      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText();
    }
  }

  public static onChangeActiveIndex(index: number) : AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PhraseDetailsEffects.updateOnChangeActiveTargetIndex(index);
    }
  }

  public static onGetPhraseTitle(phraseId: number) : AppThunk {
    return (
      dispatch,
      getState
    ): string => {
      if (phraseId) {
        return findVideoPhraseById(getState(), phraseId)?.translated || '';
        /*const contextPhrase = findVideoPhraseById(getState(), phraseId);
        if (contextPhrase?.wordPhraseId) {
          const phrase = findVideoPhraseById(getState(), contextPhrase?.wordPhraseId);
          return phrase?.translated || '';
        }*/
      }
      return '';
    }
  }



}
