import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { IPhraseDetailsTabsEditorContainerProps } from './types';
import { PhraseDetailsTabListContainerHOC } from '../../PhraseDetailsTabListContainer/hocs/PhraseDetailsTabListContainerHOC';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { Text } from '../../../../../../common/components/Text/Text';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { VideoTutorialTooltipPromptsHOC } from '../../../VideoTutorial/VideoTutorialTooltip/hocs/VideoTutorialTooltipPrompts/VideoTutorialTooltipPromptsHOC';
import { PhraseDetailsTabHeaderHOC } from '../../PhraseDetailsTabHeader/hocs/PhraseDetailsTabHeaderHOC';


export const PhraseDetailsTabsEditorContainer: React.FC<IPhraseDetailsTabsEditorContainerProps> = ({
  onCreateNew,
  onAddFromLib,
  onReset
}) => {

  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [resetProcessing, setResetProcessing] = React.useState<boolean>(false);

  const handleReset = async () => {
    setAnchor(null);
    setResetProcessing(true);
    try {
      await onReset();
    } catch (e) {
      console.error(e);
    }
    setResetProcessing(false);
  };

    const containerStyle = {
      position: 'sticky',
      top: 0,
      width: '33%',
      minWidth: theme.spacing(38),
      maxWidth: theme.spacing(50),
      height: 1,
      p: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
    };

  return (
    <Paper sx={containerStyle}>
      <Stack sx={{
        height: '100%',
      }}>
        <Stack>
          <PhraseDetailsTabHeaderHOC />

          <Text variant={'h5'} fontWeight={600} sx={{
            mb: theme.spacing(1),
          }}>
              My Prompts
          </Text>
        </Stack>

        <Box sx={{
          flex: `1 1 100%`,
          mx: theme.spacing(-2),
          overflow: 'auto',
        }}>
          <Stack
            gap={theme.spacing(3)}
            sx={{
              maxWidth: `calc(100% - ${theme.spacing(2)})`,
              pl: theme.spacing(2),
              pb: theme.spacing(2),
            }}
          >
            
            <PhraseDetailsTabListContainerHOC />

            {resetProcessing &&
                <CircularProgress sx={{ margin: 'auto'}} />
            }
            {!resetProcessing &&
                <>
                    <Button sx={{ alignSelf: 'flex-start' }} onClick={e => setAnchor(e.currentTarget)}>
                        Reset to default
                    </Button>
                    <ConfirmPopup
                        anchor={anchor}
                        lines={['All custom prompts created by you will be deleted!', 'Reset prompts?']}
                        onConfirm={handleReset}
                        onClose={() => setAnchor(null)}
                    />
                </>
            }
          </Stack>
            <VideoTutorialTooltipPromptsHOC />
        </Box>
      </Stack>
    </Paper>
  );

}

