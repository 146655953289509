import {
  IPhrasesDetailCustomPrompHash,
  IPhrasesDetailCustomPromptResult,
  IPhrasesDetailExplainHash,
  IPhrasesDetailExplainResult,
  IPhrasesDetailPopupMenu,
  IPhrasesDetailsActivate,
  IPhrasesDetailsDisActivate,
  IPhrasesDetailSetNoteLastPause,
  IPhrasesDetailSetNotePhraseId,
  IPhrasesDetailSetNoteText,
  IPhrasesDetailsNotePreviewMode,
  IPhrasesDetailsPopupMenu,
  IPhrasesDetailTranslate, IPhrasesDetailTranslateAction,
  IPhrasesDetailTranslateLang,
  IPhrasesDetailTranslateLangResult,
  IPhrasesDetailUsageHash,
  IPhrasesDetailUsageResult,
  ISetPhrasesDetailHash,
  ISetPhrasesDetailId,
  ISetPhrasesDetailsActiveTab,
  ISetPhrasesDetailsHide,
  ISetPhrasesDetailsPromptsChat,
  ISetPhrasesResetAllowSaveResults,
  PHRASE_DETAILS_RESULT_CUSTOM_PREF,
  PHRASE_DETAILS_RESULT_LIB_PREF,
  SET_PHRASE_DETAILS_ACTIVATE,
  SET_PHRASE_DETAILS_ACTIVE_TAB,
  SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH,
  SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT,
  SET_PHRASE_DETAILS_DISACTIVATE,
  SET_PHRASE_DETAILS_EXPLAIN_HASH,
  SET_PHRASE_DETAILS_EXPLAIN_RESULT,
  SET_PHRASE_DETAILS_HASH,
  SET_PHRASE_DETAILS_HIDE,
  SET_PHRASE_DETAILS_ID,
  SET_PHRASE_DETAILS_NOTE_LAST_PAUSE,
  SET_PHRASE_DETAILS_NOTE_PHRASE_ID,
  SET_PHRASE_DETAILS_NOTE_PREVIEW_MODE,
  SET_PHRASE_DETAILS_NOTE_TEXT,
  SET_PHRASE_DETAILS_POPUP_MENU,
  SET_PHRASE_DETAILS_PROMPTS_CHAT,
  SET_PHRASE_DETAILS_RESET_ALLOW_SAVE_RESULTS,
  SET_PHRASE_DETAILS_TRANSLATE_LANG_RESULT,
  SET_PHRASE_DETAILS_TRANSLATE_RESULT,
  SET_PHRASE_DETAILS_USAGE_HASH,
  SET_PHRASE_DETAILS_USAGE_RESULT,
  TPhrasesDetailPromptsChat
} from './types';
import { ILanguageItem } from '../general/types';
import { TPhraseDetailsTab } from '../models/types';


export class PhraseDetailsActions {
  public static activate(
    text: string,
    context: string,
    captionLineContext: string,
    selectText: string,
    fromLang: ILanguageItem,
    toLang: ILanguageItem,
    activeTab?: TPhraseDetailsTab,
    phraseId?: number,
    hash?: string,
    notesDisable?: boolean
  ): IPhrasesDetailsActivate {
    return {
      type: SET_PHRASE_DETAILS_ACTIVATE,
      text,
      context,
      captionLineContext,
      selectText,
      fromLang,
      toLang,
      activeTab,
      phraseId,
      hash,
      notesDisable
    }
  }

  public static disActivate(): IPhrasesDetailsDisActivate {
    return {
      type: SET_PHRASE_DETAILS_DISACTIVATE,
    }
  }

  public static setHash(hash: string): ISetPhrasesDetailHash {
    return {
      type: SET_PHRASE_DETAILS_HASH,
      hash
    }
  }

  public static setActiveTab(activeTab: TPhraseDetailsTab): ISetPhrasesDetailsActiveTab {
    return {
      type: SET_PHRASE_DETAILS_ACTIVE_TAB,
      activeTab
    }
  }

  public static hide(): ISetPhrasesDetailsHide {
    return {
      type: SET_PHRASE_DETAILS_HIDE
    }
  }

 /* public static setTranslateResult(results: IPhrasesDetailTranslateLangResult[] | null, processing: boolean, allowSave: boolean): IPhrasesDetailTranslate {
    return {
      type: SET_PHRASE_DETAILS_TRANSLATE_RESULT,
      results,
      processing,
      allowSaveResult: allowSave
    }
  }*/

  public static setTranslateResult(data: Partial<IPhrasesDetailTranslate>): IPhrasesDetailTranslateAction {
    return {
      type: SET_PHRASE_DETAILS_TRANSLATE_RESULT,
      data
    }
  }

  public static setTranslateLangResult(result: IPhrasesDetailTranslateLangResult): IPhrasesDetailTranslateLang {
    return {
      type: SET_PHRASE_DETAILS_TRANSLATE_LANG_RESULT,
      result
    }
  }

  public static setExplainResult(text: string, processing: boolean, allowSave: boolean): IPhrasesDetailExplainResult {
    return {
      type: SET_PHRASE_DETAILS_EXPLAIN_RESULT,
      resultText: text,
      processing,
      allowSaveResult: allowSave
    }
  }

  public static setExplainHash(hash: string): IPhrasesDetailExplainHash {
    return {
      type: SET_PHRASE_DETAILS_EXPLAIN_HASH,
      hash
    }
  }

  public static setUsageResult(text: string, processing: boolean, allowSave: boolean): IPhrasesDetailUsageResult {
    return {
      type: SET_PHRASE_DETAILS_USAGE_RESULT,
      resultText: text,
      processing,
      allowSaveResult: allowSave
    }
  }

  public static setUsageHash(hash: string): IPhrasesDetailUsageHash {
    return {
      type: SET_PHRASE_DETAILS_USAGE_HASH,
      hash
    }
  }

  public static setNotePhraseId(phraseId: number): IPhrasesDetailSetNotePhraseId {
    return {
      type: SET_PHRASE_DETAILS_NOTE_PHRASE_ID,
      phraseId
    }
  }

  public static setNotePhraseText(text: string): IPhrasesDetailSetNoteText {
    return {
      type: SET_PHRASE_DETAILS_NOTE_TEXT,
      text
    }
  }

  public static setNoteLastPauseId(noteId: number): IPhrasesDetailSetNoteLastPause {
    return {
      type: SET_PHRASE_DETAILS_NOTE_LAST_PAUSE,
      noteId
    }
  }

  public static setPhraseId(phraseId: number): ISetPhrasesDetailId {
    return {
      type: SET_PHRASE_DETAILS_ID,
      phraseId
    }
  }

  public static resetAllowSaveResults(allowSave: boolean): ISetPhrasesResetAllowSaveResults {
    return {
      type: SET_PHRASE_DETAILS_RESET_ALLOW_SAVE_RESULTS,
      allowSave
    }
  }

  public static setCustomPromptResult(id: number, text: string, processing: boolean, allowSave: boolean): IPhrasesDetailCustomPromptResult {
    return {
      type: SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT,
      id: PHRASE_DETAILS_RESULT_CUSTOM_PREF + id,
      resultText: text,
      processing,
      allowSaveResult: allowSave
    }
  }

  public static setCustomPromptHash(id: number, hash: string): IPhrasesDetailCustomPrompHash {
    return {
      type: SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH,
      id: PHRASE_DETAILS_RESULT_CUSTOM_PREF + id,
      hash
    }
  }

  public static setLibPromptResult(id: number, text: string, processing: boolean, allowSave: boolean): IPhrasesDetailCustomPromptResult {
    return {
      type: SET_PHRASE_DETAILS_CUSTOM_PROMPT_RESULT,
      id: PHRASE_DETAILS_RESULT_LIB_PREF + id,
      resultText: text,
      processing,
      allowSaveResult: allowSave
    }
  }

  public static setLibPromptHash(id: number, hash: string): IPhrasesDetailCustomPrompHash {
    return {
      type: SET_PHRASE_DETAILS_CUSTOM_PROMPT_HASH,
      id: PHRASE_DETAILS_RESULT_LIB_PREF + id,
      hash
    }
  }

  public static setNotePreviewMode(notePreviewMode: boolean): IPhrasesDetailsNotePreviewMode {
    return {
      type: SET_PHRASE_DETAILS_NOTE_PREVIEW_MODE,
      notePreviewMode
    }
  }

  public static setPopupMenu(popupMenu: Partial<IPhrasesDetailPopupMenu>): IPhrasesDetailsPopupMenu {
    return {
      type: SET_PHRASE_DETAILS_POPUP_MENU,
      popupMenu
    }
  }

  public static setPromptsChat (
    promptsChat: TPhrasesDetailPromptsChat
  ): ISetPhrasesDetailsPromptsChat {
    return {
      type: SET_PHRASE_DETAILS_PROMPTS_CHAT,
      promptsChat,
    }
  }


}