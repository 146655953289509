import { SyncStorage } from './local-storage-helpers';

export class OneTimeTooltipSelectStorage extends SyncStorage {

	private static LS_KEY = 'onetime-tooltip-select';

	public static getItem(): number {
		return Number(window.localStorage.getItem(this.LS_KEY));
	}

	public static setItem(value: number) {
		return window.localStorage.setItem(this.LS_KEY, String(value));
	}

	public static isShow(): boolean {
		let item = this.getItem();
		
		return !item;
	}

	public static disable() {
		this.setItem(1);
	}

}
