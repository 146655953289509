import { IState } from '../types';
import { EPhraseListMode, EPhraseListPlayMode, IPhrasePlayOffset } from './types';
import { EVocabularyPhraseType, IVocabularyPhrase } from '../../types/common';

export class PhraseListSelectors {

  public static getPlayMode(state: IState): EPhraseListPlayMode {
    return state.phraseList.playMode;
  }

  public static getPauseDelay(state: IState): number {
    return state.phraseList.pauseDelay;
  }

  public static getCurrentPhraseId(state: IState): number {
    return state.phraseList.currentPhraseId;
  }

  public static getPlayPhraseId(state: IState): number {
    return state.phraseList.playPhraseId;
  }

  public static getForwardPhraseId(state: IState): number {
    return state.phraseList.forwardPhraseId;
  }

  public static getPlayCaptionByPhraseId(state: IState): number {
    return state.phraseList.playCaptionByPhraseId;
  }

  public static getTopPhraseId(state: IState): number {
    return state.phraseList.topPhraseId;
  }

  public static getListMode(state: IState): EPhraseListMode {
    return state.phraseList.listMode;
  }

  public static getListModeLang(state: IState): string | undefined{
    return state.phraseList.listModeLang;
  }

  public static getPlayOffset(state: IState): IPhrasePlayOffset {
    return state.phraseList.playOffset;
  }

  public static getList(state: IState): IVocabularyPhrase[] {
    return state.phraseList.phraseList;
  }

  public static findPhraseById(state: IState, phraseId: number) {
    return PhraseListSelectors.getList(state).find(p => p.id === phraseId)
  }

  public static findPhraseByVideoId(state: IState, videoId: string) {
    return PhraseListSelectors.getList(state).filter(p => p.videoKey === videoId);
  }

  public static findPhraseByVideoIdAndType(state: IState, videoId: string, type: EVocabularyPhraseType) {
    return PhraseListSelectors.findPhraseByVideoIdAndTypes(state, videoId, [type]);
  }

  public static findPhrasesByType(state: IState, type: EVocabularyPhraseType) {
    return PhraseListSelectors.getList(state).filter(p => p.type === type);
  }

  public static findPhrasesByTypes(state: IState, types: EVocabularyPhraseType[]) {
    return PhraseListSelectors.getList(state).filter(p => p.type !== undefined && types.includes(p.type));
  }

  public static findPhraseByWordId(state: IState, wordPhraseId: number) {
    return PhraseListSelectors.getList(state).find(p => p.wordPhraseId === wordPhraseId);
  }

  public static findPhraseByVideoIdAndTypes(state: IState, videoId: string, types: EVocabularyPhraseType[]) {
    return PhraseListSelectors.getList(state).filter(p => p.videoKey === videoId && types.includes(p.type || 0));
  }

  public static findPhrasesByVideoAndTime(state: IState, videoId: string, types: EVocabularyPhraseType[],
                                          startTime: number, endTime: number) {
    return PhraseListSelectors.findPhraseByVideoIdAndTypes(state, videoId, types).filter((phrase: IVocabularyPhrase) => {
      return
        ((phrase.startTime >= startTime && phrase.startTime < endTime) ||
          (phrase.endTime >= startTime && phrase.endTime < endTime) ||
          (phrase.startTime < startTime && phrase.endTime > endTime)
        );
    });
  }

  public static findPhrasesByVideoWithFilterOrphans(state: IState, videoId: string) {
    const allPhrases = PhraseListSelectors.findPhraseByVideoId(state, videoId);
    return allPhrases.filter(p => {
      if (p.type === EVocabularyPhraseType.WORD_SELECTED) {
        return !!allPhrases.find(p2 => p2.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED && p2.wordPhraseId === p.id);
      }
      return true;
    })
  }

  public static findLastPhraseByVideoAndType(state: IState, videoId: string, type: EVocabularyPhraseType) {
    const list = PhraseListSelectors.findPhraseByVideoIdAndType(state, videoId, type);
    return list && list.length ? list[list.length - 1] : null;
  }

  public static findPhrasesWithExcludePreviewTypes(state: IState) {
    return PhraseListSelectors.findPhrasesWithExcludeTypes(state,
      [EVocabularyPhraseType.PREVIEW_WORD_SELECTED, EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED]);
  }

  public static findPhrasesWithExcludeTypes(state: IState, excludeTypes: EVocabularyPhraseType[]) {
    return PhraseListSelectors.getList(state).filter(p => !p.type || !excludeTypes.includes(p.type))
  }

  public static getPhraseListViewType(
    state: IState
  ) {
    return state.phraseList.viewType;
  }

  public static getNoteId(state: IState): number {
    return state.phraseList.noteId;
  }

  public static getIsNotePopup(state: IState): boolean {
    return state.phraseList.isNotePopup;
  }


}
