import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { AuthUserEffects } from '../../../../../effects/authUserEffects';

export class ContentLibraryFiltersEvents {

	public static onTextChange(text: string): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(ContentLibActions.setSearchFilter({ text }));
		}
	}

	public static onChangeTargetLangCode(targetLangCode: string): AppThunk {
		return (
			dispatch,
			getState
		) => {
			if (ContentLibSelectors.getViewMode(getState()) === EContentLibViewMode.GROUP_VIDEO_LIST) {
				dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
			}

			dispatch(ContentLibActions.setSearchFilter({ targetLangCode }));
			AuthUserEffects.setLang(targetLangCode);
		}
	}

	public static onChangeNativeLangCode(nativeLangCode: string): AppThunk {
		return (
			dispatch,
			getState
		) => {
			if (ContentLibSelectors.getViewMode(getState()) === EContentLibViewMode.GROUP_VIDEO_LIST) {
				dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
			}

			dispatch(ContentLibActions.setSearchFilter({ nativeLangCode }));
		}
	}

}