import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { DailyGoalEvents } from './events';
import { IDailyGoalEvents, IDailyGoalFields } from '../components/types';
import { DailyGoal } from '../components/DailyGoal';
import { DAILY_GOAL_MIN_DEFAULT } from '../constants';
import {
	getAuthUser,
	getAuthUserLangCode,
	getMainViewMode,
	getPlayerState,
	getVideoWatchToday
} from '../../../../../store/general/selectors';
import { IDailyGoalHOCProps } from './types';
import { EMainViewMode } from '../../../../../store/general/types';
import { PlayerStates } from '../../../../../types/common';

const mapStateToProps = (
	dispatch: any,
) => {
	return (
		state: IState,
		props: IDailyGoalHOCProps,
	): IDailyGoalFields => {
		const { isSmall } = props;
		const mainViewMode = getMainViewMode(state);
		const isTotal = mainViewMode === EMainViewMode.CONTENT_LIBRARY ||
			mainViewMode === EMainViewMode.MY_LIBRARY;

		return {
			isShow: !!getAuthUser(state),
			goal: DAILY_GOAL_MIN_DEFAULT,
			today: getVideoWatchToday(state),
			isSmall,
			isTotal,
			langCode: getAuthUserLangCode(state),
			play: getPlayerState(state) === PlayerStates.PLAYING
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IDailyGoalEvents => ({
	onClick: () => dispatch(DailyGoalEvents.onClick()),
	onLoad: () => dispatch(DailyGoalEvents.onLoad()),
		onUpdateTime: sec => dispatch(DailyGoalEvents.onUpdateTime(sec)),
}
);

export const DailyGoalHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(DailyGoal);

DailyGoalHOC.displayName = 'DailyGoalHOC';
