import { IContentLibVideo } from '../../../../../store/contentLib/types';

export interface IContentLibVideoListFields {
  groupId: number,
  searchText: string,
  targetLangCode: string,
  targetLangId: number;
  nativeLangCode: string
  userVideosLine: string;
  lockedMode: boolean;
}

export interface IContentLibVideoListEvents {
  onLoadVideos: (startIndex: number, count: number) => Promise<IContentLibVideo[]>
  onVideoClick: (video: IContentLibVideo) => Promise<any>;
  onClickMyStuff: () => void;
}

export interface IContentLibVideoListProps extends
  IContentLibVideoListFields,
  IContentLibVideoListEvents
{}

export const contentLibVideosLoadPartSize = 200;
export const CONTENT_LIB_USER_VIDEOS_SEPARATOR = '|||';