import { EPlayerControllerMode, PlayerController } from '../../../effects/player/manager/playerController';
import { getPlayerState } from '../../../store/general/selectors';
import { PhraseListSelectors } from '../../../store/phrase-list/selectors';
import { IState } from '../../../store/types';
import { PhraseDetailsSelectors } from '../../../store/phrase-details/selectors';
import { IVocabularyPhrase, PlayerStates } from '../../../types/common';
import { findVideoPhraseById, findVideoPhraseByWordId } from '../../../store/videos/selectors';
import { PhraseContextEditorSelectors } from '../../../store/phrase-context-editor/selectors';

export class PhraseDetailsPanelSelectors {

	public static getPhraseId (
		state: IState
	): number {
		let phraseId;

		const playMode = PlayerController.getInstance().getMode();
		if (playMode === EPlayerControllerMode.PLAY_PHRASE_LIST || playMode === EPlayerControllerMode.PLAY_PHRASE) {
			phraseId = PhraseListSelectors.getCurrentPhraseId(state);
		} else if (getPlayerState(state) === PlayerStates.PLAYING) {
			phraseId = PhraseDetailsSelectors.getNotePhraseId(state) || 0;
		} else {
			phraseId = PhraseDetailsSelectors.getPhraseId(state) || 0;
		}
		return phraseId;
	}

	public static getFullPhrase (
		state: IState,
		phraseId: number,
	): IVocabularyPhrase {
		let phrase;

		if (phraseId && phraseId > 0) phrase = findVideoPhraseById(state, phraseId);
		if (phrase && !phrase.wordPhraseId) { // find fullPhrase by wordPhrase
			phrase = findVideoPhraseByWordId(state, phraseId);
		}
		return phrase;
	}

	public static getText (
		state: IState,
	): string {
		let phraseId = this.getPhraseId(state);
		let phrase = this.getFullPhrase(state, phraseId);
    	let text;

		if (phrase) {
			text = PhraseDetailsSelectors.getSelectText(state) || phrase.highlighted || '';
		} else {
			text = PhraseContextEditorSelectors.getPhraseText(state)?.highlighted || '';
		}

		return text;
	}

}