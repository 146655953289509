import { AppThunk } from '../../../../../store/types';
import { setSnackbarPanelAction } from '../../../../../store/general/actions';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { Navigator } from '../../../../../effects/navigator';
import { getUserStateStorage } from '../../../../../../common/utils/local-storage/user-state';
import { removeVideoFromGroup } from '../../../../../effects/phrase/phraseVocabularyEffect';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { getUserGroupById } from '../../../../../store/models/selectors';
import { CopyVideoEffects } from '../../../../../effects/copyVideoEffects';


export class MyLibVideosEvents {

	public static onClickVideo(
		groupId: number,
		videoId: string,
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const userStorage = getUserStateStorage();
			userStorage.setVideoInfo(videoId, groupId, 0);
			userStorage.setLibraryGroupId(groupId);

			PlayerController.getInstance().resetMode();
			Navigator.openVideo(groupId, videoId);
		}
	}

	public static onVideoDelete(
		groupId: number,
		videoId: string,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			await dispatch(removeVideoFromGroup(videoId, groupId));
			dispatch(PhraseListActions.removeByVideoGroup(videoId, groupId));

		}
	}

	public static onVideoCopyLink(
		groupId: number,
		videoId: string,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			if (!getUserGroupById(getState(), groupId)) {
				return dispatch(setSnackbarPanelAction(true, 'Unable create copy link: invalid playlist'));
			}
			const url = await CopyVideoEffects.createCopyLink(videoId, groupId);
			await navigator.clipboard.writeText(url);
			dispatch(setSnackbarPanelAction(true, 'Link to public video copied to clipboard'));
		}
	}

}