import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, IconButton, Stack, Tooltip, alpha } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import {Paper} from '../../../../../common/components/Paper/Paper';
import { IVideoProps } from './types';
import { IPlayerApiProps } from '../../../../effects/player/player-api-context';
import { ResizeIcon } from '../../../../../../icons/ResizeIcon';
import { ytElementId } from '../../../../types/common';
import { CaptionsHOC } from '../../CaptionsContainer/Captions/hocs/CaptionsHOC';
import { CLASS_ONBOARDING_BLUR } from '../../Onboarding/constants';
import { VideoWatchHOC } from '../../VideoWatch/hocs/VideoWatchHOC';
import { Tabs } from '../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../common/components/Tab/Tab';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { PhraseListHOC } from '../../PhraseList/hocs/PhraseListHOC';
import { EPhraseDetailsTabType } from '../../../../store/models/types';
import { PhraseTranslationHOC } from '../../PhraseDetails/PhraseTranslation/hocs/PhraseTranslationHOC';
import { CloseIcon } from '../../../../../../icons/CloseIcon';
import { CLASS_TABS_SAVED, CLASS_PLAY_CONTROL, CLASS_MAIN_VIDEO, CLASS_TABS_TRANSLATED } from './constants';
import { PhraseTextHOC } from '../../PhraseDetails/PhraseText/hocs/PhraseTextHOC';
import { SAVED_PHRASES_PANEL_TITLE } from '../../../../../common/wordings';
import { FullScreen } from '../../../../effects/player/fullScreen';
import { EPhrasesColumnType } from '../../../../store/general/types';
import { OneTimeTooltipFullscreenHOC } from '../../OneTimeTooltip/hocs/OneTimeTooltipFullscreenHOC/OneTimeTooltipFullscreenHOC';
import { FullscreenIcon } from '../../../../../../icons/FullscreenIcon';
import { FullscreenOffIcon } from '../../../../../../icons/FullscreenOffIcon';
import { OneTimeTooltipSelectArrowHOC } from '../../OneTimeTooltip/hocs/OneTimeTooltipSelectArrowHOC/OneTimeTooltipSelectArrowHOC';

export const Video: React.FC<IVideoProps> = ({
  activeGroupId,
  activeTabType,
  layoutType,
  phrasesColumn,
  isTextSelected,
  onInit,
  onLoad,
  onUnload,
  onUpdateGroupId,
  onUpdateVideoId,
  onKeyUp,
  onKeyDown,
  onChangeTab,
  openNotes,
  onCloseTranslation,
  onClick,
}) => {
  let { videoId, groupId, phraseId, pause } = useParams();

  const [playerInited, setPlayerInited] = React.useState<boolean>(false);
  const isResized = React.useRef(false);

  const history = useHistory();

  const [windowWidth, setWindowWidth] = React.useState<number | null>(null);
  const [isMouseDown, setMouseDown] = React.useState(false);

  const resizeRef = React.useRef<HTMLElement | null>(null);
  const videoRef = React.useRef<HTMLElement | null>(null);
  const paddingTop = 24;
  const captionMinHeight = theme.spacing(40);
  const minHeight = theme.spacing(20);

  const { isFullScreen, isMobile } = React.useContext(DeviceContext);
  const [activeTab, setActiveTab] = React.useState(0);

  const [isTranslation, setTranslation] = React.useState(false);
  React.useEffect(() => {
    if (!isMobile) return;

    const isTranslation = !!isTextSelected && activeTabType === EPhraseDetailsTabType.TRANSLATION;
    if (isTranslation || // turn on
      activeTabType === null // turn off only if disactivate PhraseDetails
    ) setTranslation(isTranslation);

    if (activeTabType === EPhraseDetailsTabType.NOTES) openNotes();
  }, [activeTabType, isTextSelected]);

  React.useEffect(() => {
    if (!playerInited) return;

    let pos: number;

    const onMouseUp = () => {
      document.body.style.cursor = 'unset';
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onResize);
      isResized.current = true;
      setMouseDown(false);
    };

    const onResize = (e: MouseEvent) => {
      const video = videoRef.current as HTMLElement;
      if (video.firstChild && video.firstChild === e.target) onMouseUp();
      if (pos && video) {
        const newHeight = e.y - paddingTop;
        pos = e.y;
        video.style.height = newHeight + 'px';
      }
    };

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      setMouseDown(true);
      document.body.style.cursor = 'ns-resize';
      pos = e.y;
      document.addEventListener('mousemove', onResize);
      document.addEventListener('mouseup', onMouseUp);
    };

    const onDoubleClick = () => {
      initVideoHeight();
    };

    const resize = resizeRef.current as HTMLElement;
    if (resize) {
      resize.addEventListener('mousedown', onMouseDown);
      resize.addEventListener('dblclick', onDoubleClick);
      document.addEventListener('mouseup', onMouseUp);
    }

    const onClickDoc = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (!target.closest(`.${CLASS_PLAY_CONTROL}`)) {
        onClick();
      }
    }
    document.addEventListener('click', onClickDoc);

    return () => {
      document.removeEventListener('click', onClickDoc);
      if (resize) {
        resize.removeEventListener('mousedown', onMouseDown);
        resize.removeEventListener('dblclick', onDoubleClick);
        document.removeEventListener('mouseup', onMouseUp);
      }
    };
  }, [playerInited]);

  const checkPlayerFocus = () => {
    const activeElem = document.activeElement as HTMLElement;
    if (activeElem && activeElem.id === ytElementId) {
      activeElem.blur();
    }
  };

  React.useEffect(() => {
    if (!playerInited) return;
    const onKeyUpHandler = (event: KeyboardEvent) => {
      onKeyUp(event);
    };
    const onKeyDownHandler = (event: KeyboardEvent) => {
      onKeyDown(event);
    };
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (activeGroupId) {
        onUnload(activeGroupId);
      }
    };

    document.addEventListener('keyup', onKeyUpHandler);
    document.addEventListener('keydown', onKeyDownHandler);
    window.addEventListener('beforeunload', onBeforeUnload);
    const timer = setInterval(checkPlayerFocus, 1000);
    return () => {
      document.removeEventListener('keyup', onKeyUpHandler);
      document.removeEventListener('keydown', onKeyDownHandler);
      window.removeEventListener('beforeunload', onBeforeUnload);
      clearInterval(timer);
    };
  }, [playerInited]);

  React.useEffect(() => {
    if (!activeGroupId && activeGroupId == groupId) {
      history.push('/');
    }
  }, [activeGroupId]);

  React.useEffect(() => {
    onUpdateGroupId(parseInt(groupId));
  }, [groupId]);

  React.useEffect(() => {
    onUpdateVideoId(videoId);
  }, [videoId]);

  React.useEffect(() => {
    onInit(videoId).then((playerApi: IPlayerApiProps) => {
      setPlayerInited(true);
    });
  }, []);

  React.useEffect(() => {
    if (playerInited) {
      onLoad(videoId, parseInt(groupId) | 0, parseInt(phraseId) | 0, pause === '1');
    }
  }, [videoId, groupId, phraseId, playerInited]);

  React.useEffect(() => {
    return () => {
      if (activeGroupId) {
        onUnload(activeGroupId);
      }
    };
  }, [videoId, activeGroupId]);

  React.useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const initVideoHeight = () => {
    const video = videoRef.current as HTMLElement;
    if (video) {
      const newHeight = (video.offsetWidth / 16) * 9;
      video.style.height = newHeight + 'px';
    }
  };

  React.useEffect(() => {
    if (!isResized.current) initVideoHeight();
  }, [playerInited, videoId, windowWidth]);

  React.useEffect(() => {
    initVideoHeight();
  }, [layoutType]);

  const resizeContainerStyle = {
    minHeight: theme.spacing(1),
    py: theme.spacing(1),
    width: 1,
    cursor: 'ns-resize',
    '&:hover': {
      '.MuiBox-root': {
        background: theme.palette.grey[400],
        opacity: 1,
      },
    },
    '&:active': {
      '.MuiBox-root': {
        opacity: 1,
      },
    },
  };

  const resizeStyle = {
    position: 'relative',
    height: theme.spacing(0.25),
    width: 1,
    borderRadius: theme.spacing(0.25),
    background: isMouseDown ? theme.palette.grey[400] : theme.palette.grey[200],
  };

  const handleStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    translate: '-50% -50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `${theme.palette.secondary.main} !important`,
    border: `2px solid ${theme.palette.grey[400]}`,
    borderRadius: '50%',
    opacity: 0,
  };

  const iconStyle = {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.grey[400],
  };

  return (
    <Stack
      className={`video-column
        ${isTranslation ? 'video-column--translation' : ''}
        ${CLASS_ONBOARDING_BLUR}
        ${phrasesColumn === EPhrasesColumnType.COLUMNS_1 ? 'phrases-column-1' : ''}
      `}
      sx={{
        position: 'relative',
        flex: `1 1 33%`,
        minWidth: 0,
        width: isMobile ? '100%' : 'auto',
        height: '100%',

        [`&.phrases-column-1`]: {
          flexBasis: '50%',
        },
      }}
    >
      <Paper
        sx={{
          background: 'none',

          '.root-inner.fullscreen &': {
              maxHeight: `none`,
              minHeight: `100% !important`,
              height: `100% !important`,
          },

          '.mobile .root-inner.fullscreen &': {
              position: 'fixed',
              zIndex: theme.zIndex.modal,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
          },
        }}
      >
        <Box
          className={CLASS_MAIN_VIDEO}
          ref={videoRef}
          sx={{
            position: 'relative',
            flex: `1 1 360px`,
            minHeight,
            maxHeight: `calc(100vh - ${captionMinHeight})`,
            pointerEvents: isMouseDown ? 'none' : 'all',

            '@media (max-height: 500px)': {
              minHeight: theme.spacing(13),
            },

            '.root-inner.fullscreen &': {
              maxHeight: `none`,
              minHeight: `100% !important`,
              height: `100% !important`,

              [`#${ytElementId}`]: {
                borderRadius: 0,
              }
            },
          }}
        >
          <Box
            id={ytElementId}
            sx={{
              width: 1,
              height: 1,
              borderRadius: theme.spacing(0.75),

              '.video-column--translation &': {
                opacity: 0,
                pointerEvents: 'none',
              },
            }}
          />
          {isTranslation &&
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: 10,
                borderRadius: theme.spacing(1),
                background: theme.palette.common.white,
              }}
            >
              <Box
                sx={{
                  height: 1,
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
                <Box sx={{
                  p: `${theme.spacing(1)} ${theme.spacing(.5)} 0 ${theme.spacing(3)}`,
                }}>
                  <PhraseTextHOC isTranslation />
                </Box>
                <PhraseTranslationHOC />
              </Box>
              <IconButton
                  onClick={() => {
                    setTranslation(false);
                    onCloseTranslation();
                  }}
                  color={'primary'}
                  sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: theme.palette.grey[400],
                      borderRadius: '50%',
                      background: alpha(theme.palette.common.white, .5),

                      '&:hover, &:active, &:focus': {
                          color: theme.palette.grey[500],
                      },
                      '.MuiSvgIcon-root': {
                        width: theme.spacing(2),
                        height: theme.spacing(2)
                      }
                  }}
              >
                  <CloseIcon />
              </IconButton>
            </Box>
          }

          <Tooltip
            title={isFullScreen
              ? 'Exit full screen (f)'
              : 'Full screen (f)'
            }
          >
          <IconButton
            onClick={()=>FullScreen.toggle()}
            color={'primary'}
            sx={{
                position: 'absolute',
                bottom: theme.spacing(.25),
                right: theme.spacing(1.75),
                color: theme.palette.primary.main,
                background: theme.palette.common.white,
                borderRadius: `50%`,

                '&:before': {
                  content: '""',
                  position: 'absolute',
                  left: '-8px',
                  right: '-14px',
                  top: 0,
                  bottom: '-2px',
                },
            }}
          >
            {isFullScreen
              ? <FullscreenOffIcon />
              : <FullscreenIcon />
            }
          </IconButton>
          </Tooltip>

          <OneTimeTooltipFullscreenHOC />
          <OneTimeTooltipSelectArrowHOC />

        </Box>
      </Paper>
      {playerInited && (
        <>
          {!isMobile &&
            <Box ref={resizeRef} sx={resizeContainerStyle}>
              <Box sx={resizeStyle}>
                <Box sx={handleStyle}>
                  <ResizeIcon sx={iconStyle} />
                </Box>
              </Box>
            </Box>
          }
          {isMobile &&
            <Tabs
              value={activeTab}
              onChange={(e, value) => {
                setActiveTab(value)
                onChangeTab(value)
              }}
              sx={{
                width: '100%',
                minHeight: theme.spacing(5),
                my: theme.spacing(1),
                p: `${theme.spacing(.75)} ${theme.spacing(1)}`,
                background: alpha(theme.palette.common.white, .5),

                '.MuiTabs-flexContainer': {
                  gap: theme.spacing(1),
                },

                '.MuiTab-root': {
                  flex: 1,
                  p: `0 ${theme.spacing(1.5)}`,
                  maxWidth: 'none',
                },

                [`.${CLASS_TABS_SAVED}.flash, .${CLASS_TABS_TRANSLATED}.flash`]: {
                  animation: 'animation_mobile_tabs_flash .5s 3',
                },

                '@keyframes animation_mobile_tabs_flash': {
                  '100%': { background: theme.palette.warning.light },
                },
              }}
            >
              <Tab label={'Subtitles'} />
              <Tab
                className={CLASS_TABS_SAVED}
                label={SAVED_PHRASES_PANEL_TITLE}
              />
            </Tabs>
          }
          {activeTab === 0 &&
              <CaptionsHOC />
          }
          {activeTab === 1 &&
            <PhraseListHOC />
          }
          <VideoWatchHOC/>
        </>
      )}
    </Stack>
  );
};
