import React from 'react';
import { useHistory } from 'react-router-dom';
import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../../../../../store/models/types';
import { theme } from '../../../../../../common/styles/theme';
import { IPhraseDetailsContainerHeaderProps, PHRASE_DETAILS_HEADER_CLASS_NAME } from './types';
import { Box, Button, IconButton, Menu, Stack, SxProps, alpha } from '@mui/material';
import { CLASS_ONBOARDING_NOEVENT, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { PromptsListHOC } from '../../../PhraseDetailsTabsEditor/PromptsList/hocs/PromptsListHOC';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';

export const PhraseDetailsContainerHeader: React.FC<IPhraseDetailsContainerHeaderProps> = ({
	tabs,
	activeTab,
	onShowGptPromptsPopup,
	onChangeTab,
	onSelectDefaultTab,
}) => {

	const history = useHistory();

	const tabStyle: SxProps = {
		maxWidth: theme.spacing(20),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
    	justifyContent: 'flex-start',
		height: theme.spacing(3.5),
		p: theme.spacing(1),

		color: theme.palette.grey[500],
		background: theme.palette.common.white,

		'&:hover': {
			color: theme.palette.grey[900],
			background: theme.palette.common.white,
		},

		'&:active': {
			color: theme.palette.grey[500],
		},
	};

	React.useEffect(() => {
		if (!activeTab && tabs && tabs.length) {
			onSelectDefaultTab();
		}
	}, []);

	const handleChange = (event: React.SyntheticEvent, tab: TPhraseDetailsTab) => {
		if (tab !== null) {
			onChangeTab(tab);
		}
	};

	const commonTabTypes = [EPhraseDetailsTabType.CUSTOM_PROMPT, EPhraseDetailsTabType.EXPLAIN, EPhraseDetailsTabType.USAGE, EPhraseDetailsTabType.LIB_PROMPT];
	const commonTabs: TPhraseDetailsTab[] = tabs.filter(t => commonTabTypes.includes(t.type));

	const [propmptsEl, setPropmptsEl] = React.useState<null | HTMLElement>(null);
	const handlePromptsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setPropmptsEl(event.currentTarget);
	};
	const handlePromptsClose = () => {
		setPropmptsEl(null);
	};

	const {isMobile} = React.useContext(DeviceContext);

	const prompts = !commonTabs.length
		? null
		: commonTabs.map((tab, idx) => {
			return (
				<Button
					className={`
						${CLASS_ONBOARDING_NOEVENT}
					`}
					onClick={(e) => handleChange(e, tab)}
					variant={'contained'}
					color={'secondary'}
					sx={tabStyle}
					key={tab.id}
				>
					{tab.title}
				</Button>
			)
		});

	const more = (<>
		<Button
			className={`${propmptsEl ? 'opened' : 'closed'}
				${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step4
				${CLASS_ONBOARDING_NOEVENT}
				more-prompts-button
			`}
			onClick={handlePromptsClick}
			variant={'contained'}
			color={'primary'}
			sx={{
				...tabStyle,

				color: theme.palette.primary.main,
				background: alpha(theme.palette.primary.main, .2),

				'&:hover': {
					background: alpha(theme.palette.primary.main, .3),
				},

				'.more-prompts-icon': {
					width: theme.spacing(2.5),
					height: theme.spacing(2.5),
					ml: theme.spacing(-.5),
					mr: theme.spacing(.5),
				},

				'&.closed .more-prompts-icon': {
					transform: 'scaleX(-1)',
				},
			}}
		>
			<PlusIcon className={'more-prompts-icon'} />
			Add
		</Button>

		<Menu
			className={`
				more-prompts-popup
			`}
			anchorEl={propmptsEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={!!propmptsEl}
			onClose={handlePromptsClose}
			sx={{
				'.MuiBackdrop-root': {
					background: alpha(theme.palette.grey[900], .2),
				},
				'.MuiPaper-root': {
					width: isMobile ? '60%' : '40%',
					maxWidth: theme.spacing(70),
					minWidth: theme.spacing(40),
					height: `calc(100% - ${theme.spacing(4)})`,
					maxHeight: `calc(100% - ${theme.spacing(4)})`,
					ml: theme.spacing(-1),
					boxShadow: theme.shadows[16],
				},
			}}
		>
			<Stack
				gap={theme.spacing(2)}
				sx={{
					px: theme.spacing(2),
					py: theme.spacing(1),
				}}
			>
				<Stack
					flexDirection={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Box
						sx={{
							fontSize: theme.typography.h5,
							fontWeight: 600,
						}}
					>
						Prompts
					</Box>
					<IconButton
						className={`
							${CLASS_ONBOARDING_NOEVENT}
						`}
						onClick={()=>onShowGptPromptsPopup(history)}
						color={'secondary'}
					>
						<SettingsIcon />
					</IconButton>
				</Stack>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						pointerEvents: 'none',
						height: '1px',
					}}
				>
					<Box
						className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step5`}
						sx={{
							position: 'fixed',
							pointerEvents: 'none',
							height: '1px',
							mt: '80px',
						}}
					/>
				</Box>

				<PromptsListHOC />
			</Stack>
		</Menu>
	</>);
	 

	return (
		<Stack
			sx={{
				position: 'relative',
				gap: theme.spacing(.75),
				mx: theme.spacing(1),
				p: theme.spacing(1, 1, 1, 1.5),
				background: alpha(theme.palette.primary.main, .05),
				borderRadius: theme.spacing(.75),
			}}
			className={`
				${PHRASE_DETAILS_HEADER_CLASS_NAME}
			`}
			gap={1}
		>
			<Box
				sx={{
					fontSize: theme.typography.subtitle2,
					fontWeight: 600,
					color: theme.palette.primary.main,
				}}
			>
				Ask AI:
			</Box>
			<Stack
				direction={'row'}
				flexWrap={'wrap'}
				gap={theme.spacing(.5)}
				alignItems={'center'}
			>
				{prompts}
				{!isMobile && more}

			</Stack>
		</Stack>
	);

}