import { v4 as uuidv4 } from 'uuid';

type TTextPart = {
  lines: string[]
}

export class EntireScriptPromptPreparator {

  private prompt: string;
  private textLines: string[];
  private partLineCount: number;

  constructor(prompt: string, textLines: string[], partLineCount: number) {
    this.prompt = prompt;
    this.textLines = textLines;
    this.partLineCount = partLineCount;
  }

  public prepare(): string[] {
    const promptsResult: string[] = [];
    const textParts = this.createTextParts();

    const hash = uuidv4();
    const firstPrompt = this.prepareFirstPrompt(hash, textParts.length);
    promptsResult.push(firstPrompt);

    let partNum = 1;
    for (const textPart of textParts) {
      const prompt = this.prepareTextPartPrompt(hash, partNum, textParts.length, textPart.lines);
      promptsResult.push(prompt)
      partNum++;
    }
    return promptsResult;
  }

  private createTextParts(): TTextPart[] {
    const results: TTextPart[] = [];
    let currentPartLines: string[] = [];
    this.textLines.forEach(line => {
      currentPartLines.push(line);
      if (currentPartLines.length >= this.partLineCount) {
        results.push({
          lines: [...currentPartLines]
        })
        currentPartLines = []
      }
    })
    if (currentPartLines.length > 0) {
      results.push({
        lines: [...currentPartLines]
      })
    }
    return results;
  }

  private prepareFirstPrompt(hash: string, partCount: number): string {
    return `The total length of the content that I want to send you is too large to send in only one piece.
For sending you that content, I will follow this rule:
[${hash} START PART 1/${partCount}]
this is the content of the part 1 out of ${partCount} in total
[${hash} END PART 1/${partCount}]
Then you just answer: "Received part 1/${partCount}"
And when I tell you "${hash} ALL PARTS SENT", then you can continue processing the data and answering my requests.`
  }

  private prepareTextPartPrompt(hash: string, partNum: number, partCount: number, textLines: string[]): string {
    const lastPart = partNum === partCount;
    let s = '';
    if (!lastPart) {
      s += `Do not answer yet. This is just another part of the text I want to send you. Just receive and acknowledge as "Part ${partNum}/${partCount} received" and wait for the next part.` + "\n";
    }
    s += `[${hash} START PART ${partNum}/${partCount}]`;
    s += "\n" + textLines.join("\n") + "\n";
    s += `[${hash} END PART ${partNum}/${partCount}]`;
    if (!lastPart) {
      s += "\n" + `Remember not answering yet. Just acknowledge you received this part with the message "Part ${partNum}/${partCount} received" and wait for the next part.`;
    }
    if (lastPart) {
      s += "\n" + `${hash} ALL PARTS SENT. ${this.prompt}`;
    }
    return s;
  }
}