import { AppThunk } from '../../../../store/types';
import { StatLogManager } from '../../../../../common/stats/statLogManager';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { getActiveGroupTargetLanguage } from '../../../../store/models/selectors';
import { PlayerController } from '../../../../effects/player/manager/playerController';

export class VideoWatchEvents {

  public static onTime(sec: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const langCode = LangUtil.checkLangCode(getActiveGroupTargetLanguage(getState())?.code);
      StatLogManager.logVideoWatch(sec, langCode);
    }
  }

  public static onHidden(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().pause();
    }
  }

}