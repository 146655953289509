import {
  CURRENT_VIDEO_SET_CAPTIONS_FIX_STATUS,
  CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX,
  CURRENT_VIDEO_SET_ID, EVideoLangCaptionsFixStatus,
  IAvailableCaptionsItem,
  ICurrentVideoSetCaptionsFixStatus,
  ICurrentVideoSetClickedCaptionIndex,
  ICurrentVideoSetId,
  ISetSelectedNativeCaptions,
  ISetSelectedTargetCaptions,
  SET_SELECTED_NATIVE_CAPTIONS,
  SET_SELECTED_TARGET_CAPTIONS
} from './types';

export const changeCurrentVideoId = (videoId: string): ICurrentVideoSetId => ({
  type: CURRENT_VIDEO_SET_ID,
  videoId,
  audioId: 0
});
export const changeCurrentAudioId = (audioId: number): ICurrentVideoSetId => ({
  type: CURRENT_VIDEO_SET_ID,
  audioId,
  videoId: ''
});

export const setSelectedNativeCaptions = (caption: IAvailableCaptionsItem | null): ISetSelectedNativeCaptions => ({
  type: SET_SELECTED_NATIVE_CAPTIONS,
  caption
})
export const setSelectedTargetCaptions = (caption: IAvailableCaptionsItem | null): ISetSelectedTargetCaptions => ({
  type: SET_SELECTED_TARGET_CAPTIONS,
  caption
})

export const setCurrentVideoClickedCaptionIndex = (clickedCaptionIndex: number): ICurrentVideoSetClickedCaptionIndex => ({
  type: CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX,
  clickedCaptionIndex
})

export const setCurrentVideoCaptionsFixStatus = (captionsFixStatus: EVideoLangCaptionsFixStatus): ICurrentVideoSetCaptionsFixStatus => ({
  type: CURRENT_VIDEO_SET_CAPTIONS_FIX_STATUS,
  captionsFixStatus
})

