import { ITextAction, ITextState, TEXT_STATE_LOCAL_STORAGE, TEXT_UPDATE_ACTION, VIDEOS_UPDATE_ACTION } from './types';

const ls = localStorage.getItem(TEXT_STATE_LOCAL_STORAGE);
const initialState: ITextState = ls
	? JSON.parse(ls)
	: {};

const _textReducer = (
	state = initialState,
	action: ITextAction,
): ITextState => {

	switch (action.type) {
		case TEXT_UPDATE_ACTION:
			return {
				...state,
				...action.text,
			};

		case VIDEOS_UPDATE_ACTION:
			return {
				...state,
				videos: {
					...state.videos,
					...action.videos,
				}
			};

		default:
			return state;
	}
};

export const textReducer = (
    state = initialState,
	action: ITextAction,
): ITextState => {
	const newState = _textReducer(state, action);
	localStorage.setItem(TEXT_STATE_LOCAL_STORAGE, JSON.stringify({
		...newState,
		activeId: null,
	}));
	return newState;
};
