import React from 'react';
import { IStoryFromTranslatedButtonsProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Box, Button, CircularProgress, Stack } from '@mui/material';

export const StoryFromTranslatedButtons: React.FC<IStoryFromTranslatedButtonsProps> = ({
	onGenerateAudio,
	onClose,
}) => {

	const [progress, setProgress] = React.useState(false);
	
	const onGenerate = () => {
		setProgress(true);
		onGenerateAudio()
			.finally(() => {
				setProgress(false);
			});
	}

	return (
		<Stack
			flexDirection={'row'}
			gap={theme.spacing(1)}
			sx={{
				p: theme.spacing(2, 0, 0, 4.5),
			}}
		>
			<Box
				sx={{
					position: 'relative',
				}}
			>
				<Button
					disabled={progress}
					onClick={onGenerate}
					variant={'contained'}
					sx={{
						height: theme.spacing(4),
					}}
				>
					Generate audio
				</Button>

				{progress &&
					<CircularProgress
						size={theme.spacing(3)}
						sx={{
							position: 'absolute',
							left: '50%',
							ml: theme.spacing(-1.5),
							mt: theme.spacing(.5),
						}}
					/>
				}
			</Box>

			<Button
				disabled={progress}
				onClick={onClose}
				variant={'outlined'}
				sx={{
					height: theme.spacing(4),
				}}
			>
				Close
			</Button>
		</Stack>
	);
};
