import React from 'react';
import { EPublicProfileViewMode, IPublicProfileProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, CardMedia, CircularProgress, IconButton, Stack } from '@mui/material';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { LogoLabel } from '../../../../../../../icons/LogoLabel';
import background from '../../../../install/Login/components/imgs/background.svg';
import { Text } from '../../../../../../common/components/Text/Text';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';
import { CreatePublicProfileModalHOC } from '../../CreatePublicProfileModal/hocs/CreatePublicProfileModalHOC';
import { ProfileGroupInfoHOC } from '../../ProfileGroupInfo/hocs/ProfileGroupInfoHOC';
import { CheckIcon } from '../../../../../../../icons/CheckIcon';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { Tabs } from '../../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../../common/components/Tab/Tab';
import { TTeacherPhraseNoteVideoInfo } from '../../../../../../common/rest/user/userProfileRest';
import { ProfileNoteInfo } from '../../ProfileNoteInfo/components/ProfileNoteInfo';

export const PublicProfile: React.FC<IPublicProfileProps> = ({
	userName = '',
	loading,
	canEdit,
	profile,
	groups,
	notes,
	onClickBack,
	onClickLogo,
	onLoad,
	onUnload,
	onCopyPublicLink
}) => {
	const { isMobile } = React.useContext(DeviceContext);


	const className = 'public-profile';

	const [openEdit, setOpenEdit] = React.useState(false);
	const [copied, setCopied] = React.useState(false);
	const [viewMode, setViewMode] = React.useState<EPublicProfileViewMode>(EPublicProfileViewMode.PLAYLISTS);
	const [videos, setVideos] = React.useState<TTeacherPhraseNoteVideoInfo[]>([]);

	const onCopy = () => {
		onCopyPublicLink(userName);
		setCopied(true);
  	};

	React.useEffect(() => {
		if (userName) {
			onLoad(userName);
		}
	}, [userName])

	React.useEffect(() => {
		if ((!groups || !groups.length) &&
			!!notes && !!notes.length) {
			setViewMode(EPublicProfileViewMode.NOTES);
		}
	}, [groups, notes])

	React.useEffect(() => {
		let videos: TTeacherPhraseNoteVideoInfo[] = [];

		if (!!notes && !!notes.length) {
			notes.forEach(group => {
				if (group.videos && group.videos.length) {
					group.videos.forEach(video => {
						videos.push({
							...video,
							targetLangCode: group.targetLangCode,
							nativeLangCode: group.nativeLangCode,
							groupId: group.groupId,
						});
					});
				}
			});
			setVideos(videos)
		}

	}, [notes])

	React.useEffect(() => {
		return () => {
			onUnload();
		}
	}, [])

	return <Stack
		className={`
			${className}
		`}
		sx={{
			width: 1,
			height: 1,
			alignItems: 'center',
			bgcolor: theme.palette.secondary.main,
			backgroundImage: `url(${background})`,
			backgroundSize: 'cover',
		}}
	>
		<Paper
			className={className}
			sx={{
				position: 'relative',
				zIndex: 100,
				flex: `0 0 ${theme.spacing(8)}`,
				width: 1,
				py: 1.5,
				px: 2,
				borderRadius: 0,
				boxShadow: theme.shadows[8],

				'.mobile &': {
					p: 1,
				}
			}}
		>
			<Stack
				direction={'row'}
				alignItems={'center'}
				gap={theme.spacing(2)}
				sx={{
					width: 1,
					height: 1,

					'.mobile &': {
						gap: 1,
						justifyContent: 'space-between',
					}
				}}
			>
				<ButtonIconGrey
					sx={{
						'.MuiSvgIcon-root': {
							width: theme.spacing(3),
							height: theme.spacing(3),
						},
					}}
					onClick={onClickBack}
				>
					<ArrowLightLeftIcon />
				</ButtonIconGrey>
				
				<IconButton
					sx={{ p: 0, }} 
					onClick={onClickLogo}
				>
					<LogoLabel sx={{ height: theme.spacing(4), width: 'auto' }} />
				</IconButton>

			</Stack>
		</Paper>

		<Stack
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				alignItems: 'center',
				p: 2,
	
				overflow: 'auto',
			}}
		>
			{loading
				? <Stack
					sx={{
						width: 1,
						height: 1,
					}}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<CircularProgress />
				</Stack>
				: profile && <Paper
					sx={{
						width: 1,
						maxWidth: theme.spacing(90),
						p: 3,
						fontSize: theme.typography.subtitle2,
						borderRadius: theme.spacing(1.25),
						boxShadow: theme.shadows[2],
					}}
				>
					<Stack
						flexDirection={'row'}
						gap={2}
						alignItems={'center'}
						sx={{
							position: 'relative',
						}}
					>
						{profile.showAvatar &&
							<CardMedia
								component='img'
								image={profile.avatar}
								alt={profile.name}
								sx={{
									flex: `0 0 ${theme.spacing(8)}`,
									height: theme.spacing(8),
									borderRadius: '50%',
								}}
							/>
						}
						<Stack
							gap={theme.spacing(.25)}
						>
							<Text
								sx={{
									fontSize: theme.typography.h3,
									fontWeight: 600,
								}}
							>
								{profile.name}
							</Text>
							<Text
								sx={{
									fontSize: theme.typography.subtitle2,
									color: theme.palette.grey[400],
								}}
							>
								@{profile.userName}
							</Text>
						</Stack>

						{canEdit &&
							<ButtonMenu
								buttonProps={{
									sx: {
										position: 'absolute',
										right: theme.spacing(-1),
										top: theme.spacing(-1),
									},
								}}
								menuProps={{
									sx: {
										'.MuiMenuItem-root': {
											px: theme.spacing(.5),
										},
									},
								}}
								onClose={() => setCopied(false)}
							>
								<IconMenuItem
									title={'Edit profile'}
									onClick={() => setOpenEdit(true)}
								>
									<PencilIcon />
								</IconMenuItem>
								<IconMenuItem
									title={'Copy public link'}
									onClick={onCopy}
								>
									{copied
										? <CheckIcon sx={{
											width: theme.spacing(1.5),
											mr: theme.spacing(-.5),
											color: `${theme.palette.success.main} !important`,
										}} />
										: <LinkIcon />
									}
								</IconMenuItem>
							</ButtonMenu>
						}
					</Stack>

					{profile.bio &&
						<Box
							dangerouslySetInnerHTML={{__html: profile.bio}}
							sx={{
								mt: theme.spacing(1),
								wordBreak: 'break-word',

								'figure, img': {
									maxWidth: '100% !important',
									maxHeight: '70vh !important',
								},
								'iframe': {
									maxWidth: '100% !important',
									maxHeight: '33vh !important',
								},
							}}
						/>
					}

					<Box
						sx={{
							mt: theme.spacing(2),
						}}
					>
						{!!groups && !!groups.length &&
							!!notes && !!notes.length &&
							<Tabs
								value={viewMode}
								onChange={(e, value) => {
									setViewMode(value);
								}}
								sx={{
									mb: theme.spacing(2),
									width: 'fit-content',
								}}
							>
								<Tab label={'Playlists'} />
								<Tab label={'Annotations'} />
							</Tabs>
						}

						{viewMode === EPublicProfileViewMode.PLAYLISTS &&
							!!groups && !!groups.length &&
							groups.map(group => {
								return <ProfileGroupInfoHOC
									key={group.id}
									id={group.id}
								/>
						})}

						{viewMode === EPublicProfileViewMode.NOTES &&
							!!videos && !!videos.length &&
							<ProfileNoteInfo
								videos={videos}
							/>
						}
					</Box>

				</Paper>
			}

			{!profile && !loading &&
			<Text
				sx={{
					fontSize: theme.typography.h3,
					fontWeight: 600,
				}}
			>
				This user has not created their profile yet.
			</Text>
			}
		</Stack>

		{openEdit &&
			<CreatePublicProfileModalHOC
				isShow={openEdit}
				onClose={() => {
					setOpenEdit(false);
					onLoad(userName);
				}}
			/>
		}
	</Stack>
};
