import React from 'react';
import { IPhraseChatItemProps } from './types';
import { alpha, Box, Button, CircularProgress, Fade, IconButton, Stack, SxProps, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { RedoIcon } from '../../../../../../../icons/RedoIcon';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { DisketteIcon } from '../../../../../../../icons/DisketteIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { NO_PHRASE_HINT } from './wordings';
import { ArrowIcon } from '../../../../../../../icons/ArrowIcon';

export const PhraseChatItem: React.FC<IPhraseChatItemProps> = ({
	active,
	prompt,
	processing,
	result,
	text,
	canExec,
	id,
	type,
	isNotePopup,
	onClickSettings,
	onLoad,
	onCopyNote,
	onOpenNote,
}) => {
	const className = 'phrase-chat-item';

	const {isMobile} = React.useContext(DeviceContext);

	const ref = React.useRef(null);
	const [isFlash, setFlash] = React.useState(false);
	const [isOpen, setOpen] = React.useState(false);
	const [isSaved, setSaved] = React.useState(false);
	
	const onClickSave = () => {
		if (isSaved) {
			if (!isNotePopup) {
				onOpenNote();
				setSaved(false);
			}
		} else {
			onCopyNote();
			setSaved(true);

			if (isNotePopup) {
				setTimeout(() => {
					setSaved(false);
				}, 3000);
			}
		}
	};

	React.useEffect(() => {
		if (active && result) {
			setFlash(true);
			setTimeout(() => {
				setFlash(false);
			}, 1000);
			if (ref.current) ref.current.scrollIntoViewIfNeeded();
		} else {
			setFlash(false);
		}
	}, [active, result]);

	React.useEffect(() => {
		setSaved(false);
	}, [result]);

	/*React.useEffect(() => {
		if (canExec) onLoad(true);
	}, [prompt]);*/

	React.useEffect(() => {
		onLoad(true);
	}, [id, type]);

	const buttonStyle: SxProps = {
		height: theme.spacing(4),
		width: theme.spacing(3),
		p: theme.spacing(1),
		fontSize: theme.typography.fontSize,
		color: theme.palette.grey[400],
	};

	const [isShowButtons, setShowButtons] = React.useState(true);
	const showButtonsTimer = React.useRef(null);
	const [isShowSaveArea, setShowSaveArea] = React.useState(false);
	const isMouseIn = React.useRef(false);

	const onMouseIn = () => {
		if (isMobile) return;
		if (showButtonsTimer.current) clearTimeout(showButtonsTimer.current);
		showButtonsTimer.current = setTimeout(() => {
			setShowButtons(true);
		}, 300) as any;
		isMouseIn.current = true;
	};
	const onMouseOut = () => {
		if (isMobile) return;
		if (showButtonsTimer.current) clearTimeout(showButtonsTimer.current);
		showButtonsTimer.current = setTimeout(() => {
			setShowButtons(false);
		}, 500) as any;
		isMouseIn.current = false;
	};

	const onSaveMouseIn = () => {
		if (isMobile) return;
		setShowSaveArea(true);
	};
	const onSaveMouseOut = () => {
		if (isMobile) return;
		setShowSaveArea(false);
	};

	React.useEffect(() => {
		if (isMobile) return;

		if (!processing) {
			setShowButtons(true);
			setTimeout(() => {
				if (!isMouseIn.current) setShowButtons(false);
			}, 1000);
		} else {
			setShowButtons(false);
		}
	}, [processing]);

	
	return (<Stack
			gap={theme.spacing(1.25)}
			sx={{
				position: 'relative',
				pr: theme.spacing(1),
			}}
			onMouseEnter={onMouseIn}
			onMouseLeave={onMouseOut}
		>
			<Stack
				flexDirection={'row'}
				gap={theme.spacing(.5)}
				sx={{
					ml: theme.spacing(-1),
				}}
			>
				<Box
					className={`${className}__prompt ${isOpen ? 'open' : 'close'}
						${!canExec ? 'cannotexec' : ''}
					`}
					sx={{
						flex: `0 1 calc(100% - 64px)`,
						p: theme.spacing(1),
						color: theme.palette.grey[500],
						borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`,
						background: theme.palette.grey[50],
						cursor: 'pointer',

						'&.cannotexec': {
							opacity: .3,
							cursor: 'default',
						},
					}}
					onClick={() => setOpen(!isOpen)}
				>
					<Box
						sx={{
							lineHeight: `1.3`,

							[`.${className}__prompt.close &`]: {
								display: '-webkit-box',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								'-webkit-line-clamp': '2',
								'-webkit-box-orient': 'vertical',
								maxHeight: '2.6em',
							}
						}}
					>
						{prompt}
					</Box>
				</Box>

				{canExec && !processing &&
					<Fade
						in={isShowButtons}
						timeout={{
							exit: 1000,
						}}
					>
						<Stack
							flexDirection={'row'}
							justifyContent={'flex-end'}
							gap={theme.spacing(.5)}
						>
							{!isMobile && <>
								<Tooltip
									title={'Edit prompt'}
								>
									<IconButton
										onClick={onClickSettings}
										sx={buttonStyle}
									>
										<SettingsIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }}/>
									</IconButton>
								</Tooltip>
								<Divider sx={{
									height: theme.spacing(2),
									width: '2px',
									m: `${theme.spacing(1)} 0 0`,
								}} />
							</>}
							<Tooltip
								title={'Refresh prompt'}
							>
								<IconButton
									onClick={() => onLoad(false)}
									sx={buttonStyle}
								>
									<RedoIcon sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }}/>
								</IconButton>
							</Tooltip>
						</Stack>
					</Fade>
				}
			</Stack>

			{processing
				? <Stack
					sx={{
						width: 1,
						my: theme.spacing(3),
						alignItems: 'center',
					}}
				>
					<CircularProgress size={theme.spacing(3)} />
				</Stack>
				: <Stack
					sx={{
						position: 'relative',
					}}
				>
					{!canExec
						? <Box
							className={`
								${className}__out key${id}type${type}
							`}
							sx={{
								ml: theme.spacing(-1),
								mt: theme.spacing(-1),
								mb: theme.spacing(2),
								p: theme.spacing(2),
								fontSize: theme.typography.subtitle2.fontSize,
								color: theme.palette.error.main,
								borderRadius: `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
								backgroundColor: alpha(theme.palette.error.main, .05),
							}}
						>
							{NO_PHRASE_HINT}
						</Box>
						: result &&
						<Box
							className={`
								${className}__out key${id}type${type}
								${isFlash ? 'flash' : ''}
								${isShowSaveArea ? 'show-save-area' : ''}
							`}
							ref={ref}
							sx={{
								position: 'relative',
								pr: theme.spacing(1),

								'&:before': {
									content: '""',
									position: 'absolute',
									left: theme.spacing(-1),
									right: 0,
									top: theme.spacing(-1),
									bottom: theme.spacing(-.5),
									borderRadius: `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
								},

								'&.flash:before': {
									animation: 'animation_chat_item_flash .5s 3 ease-in-out',
								},

								'@keyframes animation_chat_item_flash': {
									'100%': { background: theme.palette.primary[100] },
								},

								'&.show-save-area:before': {
									background: alpha(theme.palette.warning[100], .2),
								},
							}}
						>
							<TextWithSpeakerHOC
								text={result}
							/>
						</Box>
					}
					{canExec && result &&
						<Fade
							in={isShowButtons || isSaved}
							timeout={{
								exit: 1000,
							}}
						>
							<Stack
								flexDirection={'row'}
								sx={{
									position: 'sticky',
									bottom: theme.spacing(1),
									mt: theme.spacing(.75),
									ml: theme.spacing(-1),
								}}
							>
								<Button
									className={isSaved ? 'saved' : ''}
									variant={'contained'}
									sx={{
										height: theme.spacing(4),
										p: `0 ${theme.spacing(1.25)}`,
										color: theme.palette.warning[900],
										background: theme.palette.warning[100],
			
										'&:hover, &:focus': {
											background: theme.palette.info[200],
										},
			
										'&.saved': {
											color: theme.palette.primary.main,
											background: theme.palette.primary.light,
										},
									}}
									onClick={onClickSave}
									onMouseMove={onSaveMouseIn}
									onMouseLeave={onSaveMouseOut}
								>
									{isSaved
										? <ArrowIcon
											sx={{
												ml: theme.spacing(-.5),
												mr: theme.spacing(.5),
												transform: 'scaleX(-1)',
											}}
										/>
										: <DisketteIcon
											sx={{
												mr: theme.spacing(.5),
											}}
										/>
									}
									{isSaved
										? isNotePopup
											? 'Saved'
											: 'Open saved'
										: 'Save'
									}
								</Button>
							</Stack>
						</Fade>
					}
				</Stack>
			}
			
		</Stack>

	);

}

