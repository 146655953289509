import { AppThunk } from '../../../../store/types';
import { MainLayoutEffects } from '../../../../effects/mainLayoutEffects';
import { Navigator } from '../../../../effects/navigator';
import { setMainViewMode } from '../../../../store/general/actions';
import { EMainViewMode } from '../../../../store/general/types';
import { ContentLibActions } from '../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../store/contentLib/types';

export class MenuWidgetsEvents {

	public static onShowHelp(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(MainLayoutEffects.showHelp());
		}
	}

	public static onClickMyLibrary (): AppThunk {
		return (
		  dispatch,
		) => {
			Navigator.openMyStuff();
			dispatch(setMainViewMode(EMainViewMode.MY_LIBRARY));
			dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST));
		}
	}
	
	public static onClickContent (): AppThunk {
		return (
			dispatch,
		) => {
			Navigator.openContentLib();
			dispatch(setMainViewMode(EMainViewMode.CONTENT_LIBRARY));
			dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
		}
	}

	public static onClickRecent (): AppThunk {
		return async (
			dispatch,
		) => {
			Navigator.openVideoRecent();
     		dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
		}
	}

}