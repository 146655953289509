import { TCreateCopyResponse, UserGroupCopyRest } from '../../common/rest/userGroupCopy/userGroupCopyRest';
import { getHomeUrl } from '../../common/utils/getHomeUrl';
import { Navigator } from './navigator';
import { getDispatch } from '../store';
import { setSnackbarPanelAction } from '../store/general/actions';

export class CopyGroupEffects {

  private static COPY_SUB_URL = 'copyGroup';

  public static async createCopyLink(groupId: number): Promise<{
    url: string,
    hash: string
  }> {
    const hash = await UserGroupCopyRest.createCopyLink({
      groupId
    })
    return {
      url: CopyGroupEffects.getCopyBaseUrl() + hash,
      hash
    };
  }

  public static async copy(hash: string): Promise<TCreateCopyResponse | null> {
    try {
      const result = await UserGroupCopyRest.createCopy(hash);
      Navigator.openGroup(result.groupId);
      return result;
    } catch(e) {
      getDispatch()(setSnackbarPanelAction(true, 'Unable copy playlist, hash parameter is invalid'));
      return null;
    }
  }

  public static async copyPublic(groupId: number): Promise<TCreateCopyResponse> {
    const result = await UserGroupCopyRest.createPublicCopy(groupId);
    // getDispatch()(setSnackbarPanelAction(true, 'Playlist has been copied'));
    return result;
  }

  private static getCopyBaseUrl() {
    return getHomeUrl() + '/' + CopyGroupEffects.COPY_SUB_URL + '/';
  }
}