import { EPhraseDetailsCustomPromptType, PhraseDetailsCustomPromptVars } from './phraseDetailsCustomPromptVars';

export interface IPhraseDetailsPromptValidatorResult {
  success: boolean,
  errors?: string[]
}

export class PhraseDetailsPromptValidator {

  public static validate(prompt: string): IPhraseDetailsPromptValidatorResult {
    if (!prompt || !prompt.trim().length) {
      return {
        success: false, errors: ['Prompt is required']
      }
    }

    const phraseVar = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.PHRASE);
    const contextVar = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.CONTEXT);
    const entireVar = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.ENTIRE_SCRIPT);
    const phraseListVar = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.PHRASE_LIST);

    if (phraseVar && contextVar && entireVar && phraseListVar) {
      const phraseListVarsNames = PhraseDetailsCustomPromptVars.getVarListByType(EPhraseDetailsCustomPromptType.PHRASE_LIST).map(v => v.name)
      const valid =
        phraseListVarsNames.some(v => prompt.includes(v)) ||
        prompt.includes(phraseVar.name) ||
        prompt.includes(contextVar.name) ||
        prompt.includes(entireVar.name);
      if (!valid) {
        return {
          success: false, errors: [`To save the prompt please use ${phraseVar.name} or ${contextVar.name} variables, or both.`]
        }
      }
    }

    return {success: true}
  }
}
