import React from 'react';
import { IconButton, Menu, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { ILangMenuProps } from './types';
import LangFlag from '../../../LangFlag/LangFlag';
import { LangMenuListHOC } from '../hocs/LangMenuListHOC';

export function LangMenu({
	langCode,
}: ILangMenuProps) {

	const [langsEl, setLangsEl] = React.useState<null | HTMLElement>(null);
	const langsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setLangsEl(event.currentTarget);
	};
	const langsClose = () => {
		setLangsEl(null);
	};

	return (
		<>
			<IconButton
				sx={{
					height: theme.spacing(5),
				}}
				onClick={langsOpen}
			>
				<Stack
					direction='row'
					spacing={theme.spacing(1)}
					alignItems={'center'}
				>
					<LangFlag code={langCode} showCode={true}/>
					{/* <ArrowDropIcon /> */}
				</Stack>
			</IconButton>

			<Menu
				anchorEl={langsEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={!!langsEl}
				onClose={langsClose}
				sx={{
					'.MuiPaper-root': {
						width: theme.spacing(30),
						background: 'transparent',
						boxShadow: 'none',
					},
					'.MuiList-root': {
						p: 0,
					},
				}}
			>
				{!!langsEl &&
					<LangMenuListHOC
						onClose={langsClose}
					/>
				}
			</Menu>
		</>
	);
};
