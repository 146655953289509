import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../../../../../store/models/types';
import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode, EUserGroupType } from '../../../../../store/general/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { historyPush } from '../../../../../../common/utils/historyHelper';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { getActiveUserGroup } from '../../../../../store/models/selectors';
import { SaveVideoConfirmManager } from '../../../../../effects/saveVideoConfirmManager';

export class PhraseDetailsContainerHeaderEvents {

  public static onChangeTab(tab: TPhraseDetailsTab): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const currentTab = PhraseDetailsSelectors.getActiveTab(getState());
      if (currentTab?.id === tab.id) {
        // const tabId = tab.id;
        // const tabs = PhraseDetailsHeaderTabSelector.getTabs();
        // const flashTab = tabs.find(t => t.id === tabId);
        // dispatch(PhraseDetailsActions.setActiveTab(PhraseDetailsEffects.getDefaultTab() as any));
        // setTimeout(() => { // return tab to flash
        //   dispatch(PhraseDetailsActions.setActiveTab(flashTab as any));
        // }, 100)
        const el = document.querySelector(`.phrase-chat-item__out.key${tab.relationId}type${tab.type}`);
        if (el) {
          el.classList.remove('flash');
          el.classList.add('flash');
          setTimeout(() => {
            el.classList.remove('flash');
          }, 1000);
        }
        return;
      }
      //const group = getActiveUserGroup(getState());
      /*if (group?.type === EUserGroupType.PREVIEW) {
        SaveVideoConfirmManager.saveToMyStuff();
        return;
      }*/
      let tabOpen = {...tab};
      if (tab.type === EPhraseDetailsTabType.NOTES) {
        dispatch(PhraseDetailsActions.setNotePreviewMode(false));
        tabOpen.isNoteEdit = true;
      }

      dispatch(PhraseDetailsActions.setActiveTab(tabOpen));

      if (tab.type !== EPhraseDetailsTabType.TRANSLATION &&
        tab.type !== EPhraseDetailsTabType.NOTES) {
          EventsRouter.trackEvent(Events.PROMPT_BUTTON_CLICKED);

          dispatch(PhraseDetailsTabEffects.addPromptsChat({ type: tab.type, id: tab.relationId }));
      }
    }
  }

  public static onShowGptPromptsPopup(history: History): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.MAIN));
      // dispatch(PhraseDetailsTabsEditorPopupActions.setNavigationMode(EPhraseDetailsTabsEditorNavigationMode.PROMPT_EDITOR));
      dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
     // dispatch(PhraseDetailsActions.disActivate());

      historyPush({
        state: { page: 'settings' },
        title: 'Settings',
        onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
      });
    }
  }

  public static onSelectDefaultTab(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const tab = PhraseDetailsEffects.getDefaultTab();
      if (tab) {
        dispatch(PhraseDetailsActions.setActiveTab(tab));
      }
    }
  }


}