import { Reducer } from 'redux';
import {
  APPEND_PHRASE_LIST,
  EPhraseListMode,
  EPhraseListPlayMode,
  EPhraseListViewType,
  IPhraseListState,
  PHRASE_LIST_DELETE_PHRASE,
  PHRASE_LIST_REMOVE_BY_GROUP,
  PHRASE_LIST_REMOVE_BY_VIDEO_GROUP,
  PHRASE_LIST_UPDATE_PHRASE,
  PHRASE_LIST_VIEW_TYPE_LOCAL_STORAGE,
  SET_PHRASE_LIST,
  SET_PHRASE_LIST_CURRENT_PHRASE_ID,
  SET_PHRASE_LIST_FORWARD_PHRASE_ID,
  SET_PHRASE_LIST_IS_NOTE_POPUP,
  SET_PHRASE_LIST_LIST_MODE,
  SET_PHRASE_LIST_NOTE_ID,
  SET_PHRASE_LIST_PAUSE_DELAY,
  SET_PHRASE_LIST_PLAY_CAPTION_BY_PHRASE_ID,
  SET_PHRASE_LIST_PLAY_MODE,
  SET_PHRASE_LIST_PLAY_OFFSET,
  SET_PHRASE_LIST_PLAY_PHRASE_ID,
  SET_PHRASE_LIST_TOP_PHRASE_ID,
  SET_PHRASE_LIST_VIEW_TYPE,
  TPhraseListActions
} from './types';

const initialState: IPhraseListState = {
  currentPhraseId: 0,
  playPhraseId: 0,
  forwardPhraseId: 0,
  playCaptionByPhraseId: 0,
  playMode: EPhraseListPlayMode.STOP,
  listMode: EPhraseListMode.CURRENT_VIDEO,
  pauseDelay: 1,
  phraseList: [],
  playOffset: {start: 0, end: 0},
  topPhraseId: 0,
  viewType: localStorage.getItem(PHRASE_LIST_VIEW_TYPE_LOCAL_STORAGE) as EPhraseListViewType || EPhraseListViewType.DEFAULT,
};

export const phraseListReducer: Reducer<IPhraseListState, TPhraseListActions> = (
  state = initialState,
  action
) => {

  switch (action.type) {
    case SET_PHRASE_LIST_PLAY_MODE:
      return { ...state, playMode: action.playMode };
    case SET_PHRASE_LIST_LIST_MODE:
      return { ...state, listMode: action.listMode, listModeLang: action.listModeLang };
    case SET_PHRASE_LIST_PAUSE_DELAY:
      return { ...state, pauseDelay: action.pauseDelay };
    case SET_PHRASE_LIST_CURRENT_PHRASE_ID:
      return { ...state, currentPhraseId: action.currentPhraseId };
    case SET_PHRASE_LIST_PLAY_PHRASE_ID:
      return { ...state, playPhraseId: action.playPhraseId };
    case SET_PHRASE_LIST_FORWARD_PHRASE_ID:
      return { ...state, forwardPhraseId: action.forwardPhraseId };
    case SET_PHRASE_LIST_PLAY_CAPTION_BY_PHRASE_ID:
      return { ...state, playCaptionByPhraseId: action.playCaptionByPhraseId };
    case SET_PHRASE_LIST_TOP_PHRASE_ID:
      return { ...state, topPhraseId: action.topPhraseId };
    case SET_PHRASE_LIST:
      return { ...state, ...{phraseList: [...action.list]} };
    case APPEND_PHRASE_LIST:
      return { ...state, ...{phraseList: [...state.phraseList, ...action.list]} };
    case PHRASE_LIST_DELETE_PHRASE:
      return { ...state, phraseList: state.phraseList.filter(p => p.id !== action.phraseId) };
    case PHRASE_LIST_UPDATE_PHRASE:
      return { ...state, phraseList: state.phraseList.map(p => p.id == action.phrase.id ? {...action.phrase} : p) }
    case PHRASE_LIST_REMOVE_BY_VIDEO_GROUP:
      return { ...state, phraseList: state.phraseList.filter(p => !(p.videoKey == action.videoKey && p.userGroupId == action.groupId) ) };
    case PHRASE_LIST_REMOVE_BY_GROUP:
      return { ...state, phraseList: state.phraseList.filter(p => p.userGroupId !== action.groupId ) };
    case SET_PHRASE_LIST_PLAY_OFFSET:
      return {
        ...state,
        playOffset: {
          ...state.playOffset,
          ...action.offset,
        }
      }
    case SET_PHRASE_LIST_VIEW_TYPE: {
      localStorage.setItem(PHRASE_LIST_VIEW_TYPE_LOCAL_STORAGE, action.value);
      return {
          ...state,
          viewType: action.value
      }
    }
    case SET_PHRASE_LIST_NOTE_ID:
      return {
        ...state,
        noteId: action.noteId
      }
    case SET_PHRASE_LIST_IS_NOTE_POPUP:
      return {
        ...state,
        isNotePopup: action.isNotePopup,
      }
    default:
      return state;
  }
};