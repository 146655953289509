import React, { useContext } from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { Stack } from '@mui/material';
import { EditPlaylistHOC } from '../../../PlayList/EditPlayList/hocs/EditPlaylist/EditPlaylistHOC';
import { VideoLibraryHeaderHOC } from '../../VideoLibraryHeader/hocs/VideoLibraryHeaderHOC';
import { VideoLibraryGroupHOC } from '../../VideoLibraryGroup/hocs/VideoLibraryGroupHOC';
import { Shortcuts } from '../../../Shortcuts/Shortcuts';
import { IVideoLibraryProps } from './types';
import { NewFeaturesPopupHOC } from '../../../UpgradePopup/hocs/NewFeaturesPopupHOC';
import { PinExtensionPopupHOC } from '../../../PinExtensionPopup/hocs/PinExtensionPopupHOC';
import { TopBarPanelHOC } from '../../../ToolbarPanel/Toolbar/hocs/TopBarPanelHOC';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { CopyVideoConfirmHOC } from '../../../CopyVideo/CopyVideoConfirm/CopyVideoConfirmPopup/hocs/CopyVideoConfirmHOC';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { InstallExtensionPopupHOC } from '../../../InstallExtensionPopup/hocs/InstallExtensionPopupHOC';
import { OpenPublicProfilePopupHOC } from "../../../OpenPublicProfilePopup/hocs/OpenPublicProfilePopupHOC";
import { DailyGoalHOC } from '../../../ToolbarPanel/DailyGoal/hocs/DailyGoalHOC';


export const VideoLibrary: React.FC<IVideoLibraryProps> = ({
  selectedGroupId,
}) => {

  const {isMobile} = useContext(DeviceContext);

  return (
    <Paper
      sx={{
        flexGrow: 1,
        p: theme.spacing(2),
        overflowY: 'auto',
        overflowX: 'hidden',
        minWidth: isMobile ? '100%' : theme.spacing(42),
        maxWidth: isMobile ? '100%' : theme.spacing(42),
        height: 1,
        [theme.breakpoints.up('xl')]: {
          minWidth: theme.spacing(49),
          maxWidth: theme.spacing(49),
        },

        '.mobile &': {
          flex: '1 1 100%',
          minHeight: 0,
          p: 0,
          overflow: 'hidden',
        }
      }}
    >
      <Stack gap={theme.spacing(2)}
        sx={{
          position: 'relative',
          minHeight: 1,

          '.mobile &': {
            height: 1,
          }
        }}
      >
        <Stack
          sx={{
            flex: '1 1 100%',
            minHeight: 0,
          }}
        >
          <TopBarPanelHOC />
          {!isMobile && 
            <Divider
              sx={{
                mt: `${theme.spacing(2)} !important`,
              }}
            />
          }
          <Stack gap={theme.spacing()}
            sx={{
              '.mobile &': {
                flex: '1 1 100%',
                minHeight: 0,
                p: theme.spacing(1),
                background: theme.palette.secondary.main,
              }
            }}
          >
            <Stack gap={theme.spacing()}
              sx={{
                '.mobile &': {
                  flex: '1 1 100%',
                  minHeight: 0,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  p: theme.spacing(1.5),
                  background: theme.palette.common.white,
                  borderRadius: theme.spacing(1),
                }
              }}
            >
              <EditPlaylistHOC />
              <VideoLibraryHeaderHOC />
              {/* <AddVideoButtonHOC /> */}
              {!!selectedGroupId && <VideoLibraryGroupHOC groupId={selectedGroupId} />}
              <NewFeaturesPopupHOC />
              <PinExtensionPopupHOC />
              <OpenPublicProfilePopupHOC />
              <CopyVideoConfirmHOC />
              <InstallExtensionPopupHOC />
            </Stack>
          </Stack>
        </Stack>

        {!isMobile && 
          <DailyGoalHOC />
        }

        {!isMobile && 
          <Shortcuts />
        }
      </Stack>
    </Paper>
  );
};
