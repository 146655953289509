import React, { useRef, useState } from 'react';
import { IPhraseListTranslatedProps, phrasesPartLoadSize } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Box, CircularProgress, List, Stack } from '@mui/material';
import { Loader } from '../../../../../common/components/Loader/Loader';
import { VocabularyPhraseHOC } from '../../Vocabulary/vocabulary-phrase/hocs/VocabularyPhraseHOC';
import { Paper } from '../../../../../common/components/Paper/Paper';

import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { ArrowLightLeftIcon } from '../../../../../../icons/ArrowLightLeftIcon';

export const PhraseListTranslated: React.FC<IPhraseListTranslatedProps> = ({
  groupId,
  videoId,
  phraseList,
  text,
  onLoadPhrases,
  onAppendPhrases,
}) => {
  const {isMobile, scrollWidth = 0 } = React.useContext(DeviceContext);
  const className = 'phrases-list';


  const [allPhrasesLoaded, setAllPhrasesLoaded] = useState<boolean>(false);
  const allPhrasesLoadedRef = useRef(allPhrasesLoaded);
  allPhrasesLoadedRef.current = allPhrasesLoaded;

  const [phrasesLoadCount, setPhrasesLoadCount] = useState<number>(0);
  const phrasesLoadCountRef = useRef(phrasesLoadCount);
  phrasesLoadCountRef.current = phrasesLoadCount;

  React.useEffect(() => {
    if (groupId && videoId) {
      setPhrasesLoadCount(0);
      setAllPhrasesLoaded(true);
      const loadCount = phrasesPartLoadSize;
      onLoadPhrases(loadCount).then(allLoaded => {
        setAllPhrasesLoaded(allLoaded);
        setPhrasesLoadCount(phrasesPartLoadSize);
      });
    }
  }, [groupId, videoId]);

  const onListScrollToBottom = async () => {
    if (!allPhrasesLoadedRef.current) {
      const allLoaded = await onAppendPhrases(phrasesLoadCountRef.current, phrasesPartLoadSize);
      if (allLoaded) {
        setAllPhrasesLoaded(true);
      }
      setPhrasesLoadCount(phrasesLoadCountRef.current + phrasesPartLoadSize);
    }
  }

  const paperStyle = {
    position: 'relative',
    flex: '1 1 33%',
    minWidth: 0,
    height: 1,
    p: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
    display: 'flex',
    justifyContent: 'center',

    '.mobile &': {
      p: theme.spacing(1),
    },

  };

  return (
      <Paper
        className={`
          ${className}
        `}
        sx={paperStyle}
      >
          <Stack
            className={`shortcuts-listener shortcuts-listener--phrases`}
            sx={{
              position: 'relative',
              width: '100%',

              '&.shortcuts-listener--active': {
                ':after': {
                  content: '""',
                  position: 'absolute',
                  inset: '-22px',
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: '10px',
                }
              }
            }}
            gap={theme.spacing()}
          >
                        
            <List
              className={`phrase-list`}
              sx={{
                position: 'static',
                mr: theme.spacing(-2),
                overflowY: 'scroll',
                overflowX: 'hidden',
                '.onboarding-active &': {
                  overflowY: 'hidden',
                }
              }}
            >
              <Box
                sx={{
                  maxWidth: `calc(100% - ${parseInt(theme.spacing(2), 10) - scrollWidth}px)`,
                }}
              >
                  {!!phraseList.length && <>
                    {phraseList.map((phraseId, idx) => {
                        return <>
                          <VocabularyPhraseHOC
                            phraseId={phraseId}
                            key={phraseId}
                          />
                        </>
                    })}

                    {!allPhrasesLoaded &&
                      <Loader onShow={onListScrollToBottom}>
                        <CircularProgress size='small' sx={{ alignSelf: 'center' }}/>
                      </Loader>
                    }
                  </>}
              </Box>
            </List>

            {!phraseList.length &&
              <Stack
                gap={1}
                sx={{
                  p: theme.spacing(0, 3, 0, 3),
                  pt: '40vh',
                  color: theme.palette.grey[300],
                  fontSize: theme.typography.subtitle2,
                  fontWeight: 400,
                }}
              >
                <ArrowLightLeftIcon
                  sx={{
                    position: 'absolute',
                    ml: theme.spacing(-3),
                    transform: 'scaleX(-1)',
                  }}
                />
                <Box>
                  Your translated phrases <br/>
                  will be here
                </Box>
              </Stack>
            }

          </Stack>
      </Paper>
  );
};
