import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { EMainViewMode } from '../../../../../store/general/types';
import { setMainViewMode } from '../../../../../store/general/actions';
import { isAuthorised } from '../../../../../store/general/selectors';
import { Navigator } from '../../../../../effects/navigator';

export class ContentLibraryHeaderEvents {

  public static onChangeViewMode(viewMode: EContentLibViewMode): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setViewMode(viewMode));
      if (viewMode != EContentLibViewMode.GROUP_LIST) {
        dispatch(ContentLibActions.setGroupsSearchResult({ groups: [], loading: false }));
      }
    }
  }

  public static onBack(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setGroupsSearchResult({ groups: [], loading: false }));
      history.back();
    }
  }

  public static onClickLogo (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(MainLayoutEffects.backToDashboard());
      Navigator.openDefault();
    }
  }

  public static onClickHelp (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }

  public static onChangeMainViewMode (mainViewMode: EMainViewMode): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (isAuthorised(getState())) {
        dispatch(setMainViewMode(mainViewMode));
        if (mainViewMode === EMainViewMode.CONTENT_LIBRARY) {
          dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
        } else if (mainViewMode === EMainViewMode.MY_LIBRARY) {
          dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST));
        }
      } else {
        Navigator.openDefault();
      }
    }
  }



}