import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { IProfileGroupInfoProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { CopyNote } from '../../../../../../../icons/CopyNote';
import LangFlag from '../../../LangFlag/LangFlag';
import { ProfileGroupImg } from '../../ProfileGroupImg/components/ProfileGroupImg';
import { ProfileVideo } from '../../ProfileVideo/components/ProfileVideo';
import { ArrowDropIcon } from '../../../../../../../icons/ArrowDropIcon';

export function ProfileGroupInfo({
	id,
	title,
	description,
	nativeLang,
	targetLang,
	videos,
	onCopyGroup,
}: IProfileGroupInfoProps) {

	const className = 'profile-group-info';

	const [isShowVideos, setShowVideos] = React.useState(false);
	const onClick = () => {
		setShowVideos(!isShowVideos);
	}

	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

	const [copyProcess, setCopyProcess] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [title]);

	const titleElement = <Box
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',
			lineHeight: `1.3 !important`,

			fontSize: theme.typography.h5,
			fontWeight: 600,
			cursor: 'pointer',
		}}
		ref={ref1}
		onClick={onClick}
	>
		<span
			ref={ref2}
		>
			{title}
		</span>
	</Box>

	const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: '80vw',
		},
	});

	const handleCopy = async () => {
		setCopyProcess(true);
		try {
			await onCopyGroup();
		} finally {
			setCopyProcess(false);
		}
	}

	return (
		<Stack
			gap={3}
		>
			<Stack
				direction={'row'}
				gap={3}
				sx={{
					alignItems: 'center',
					py: 2,

					'& + &': {
						borderTop: `2px solid ${theme.palette.grey[200]}`
					},

					'.profile-group-info__vertical': {
						gap: 2,
					},

					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',

						[`.profile-group__img`]: {
							flexBasis: 0,
						},
					},
				}}
			>

				<ProfileGroupImg
					videos={videos}
				/>
				
				<Stack
					className={className}
					direction={'row'}
					gap={2}
					sx={{
						justifyContent: 'space-between',
						width: 1,

						[theme.breakpoints.down('sm')]: {
							flexDirection: 'column',
						},
					}}
				>
					<Stack
						className={`${className}__vertical`}
						gap={theme.spacing(1)}
						sx={{
							flex: `1 1 100%`,
						}}
					>
						{isLongTitle
							? <NoMaxWidthTooltip
								title={title}
								enterDelay={1000}
							>
								{titleElement}	
							</NoMaxWidthTooltip>
							: titleElement
						}

						{description &&
							<Box
								sx={{
									display: '-webkit-box',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									'-webkit-line-clamp': '5',
									'-webkit-box-orient': 'vertical',
									maxHeight: '7.7em',
									lineHeight: `1.3 !important`,
									fontSize: theme.typography.subtitle2,
								}}
							>
								{description}
							</Box>
						}
						<Stack
							direction={'row'}
							gap={3}
							rowGap={1}
							flexWrap={'wrap'}
						>
							<Stack
								direction={'row'}
								gap={1}
								sx={{
									height: theme.spacing(4),
								}}
							>
								<LangFlag code={targetLang} showCode={true} />
								<LangFlag code={nativeLang} showCode={true} />
							</Stack>


						</Stack>
					</Stack>

					<Stack
						gap={theme.spacing(1)}
						alignItems={'flex-start'}
					>
						<Button
							variant={'contained'}
							disabled={copyProcess}
							startIcon={
								<CopyNote sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }} />
							}
							onClick={handleCopy}
						>
							Copy playlist
						</Button>

						<Button
							className={isShowVideos ? 'active' : ''}
							variant={'text'}
							disabled={copyProcess}
							startIcon={<ArrowDropIcon/>}
							onClick={onClick}
							sx={{
								height: theme.spacing(4),
								pl: theme.spacing(2),
								color: theme.palette.grey[700],

								'&.active': {
									'.MuiSvgIcon-root': {
										transform: `rotate(180deg)`,
									},
								},
							}}
						>
							{isShowVideos
								? 'Hide videos'
								: 'Show videos'
							}
						</Button>

					</Stack>
				</Stack>

			</Stack>

			{isShowVideos && (
				!!videos.length
					? <Box
						sx={{
							position: 'relative',
							display: 'grid',
							gridTemplateColumns: `repeat(4, 1fr)`,
							gap: 2,
		
							[theme.breakpoints.down('md')]: {
								gridTemplateColumns: `repeat(3, 1fr)`,
							},
		
							[theme.breakpoints.down('sm')]: {
								gridTemplateColumns: `repeat(2, 1fr)`,
							}
						}}
					>
						{videos.map((video) => {
							return (
								<ProfileVideo
									groupId={id}
									showPopup={true}
									video={video}
									key={video.videoKey}
								/>
							)
						})}
					</Box>
					: 'No videos'
			)}
		</Stack>
	)
};