import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ContentLibraryHeader } from '../components/ContentLibraryHeader';
import { IContentLibraryHeaderEvents, IContentLibraryHeaderFields } from '../components/types';
import { ContentLibraryHeaderEvents } from './events';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibraryHeaderFields => {
    
    const mainViewMode = getMainViewMode(state) || EMainViewMode.CONTENT_LIBRARY;

    let selectedGroupTitle;
    const isSelected = ContentLibSelectors.getViewMode(state) === EContentLibViewMode.GROUP_VIDEO_LIST;
    if (isSelected) {
      const groupId = ContentLibSelectors.getSelectedGroupId(state);
      const group = ContentLibSelectors.findGroupById(state, groupId);
      selectedGroupTitle = group?.title;
    }

    return {
      viewMode: ContentLibSelectors.getViewMode(state),
      selectedGroupTitle,
      mainViewMode,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibraryHeaderEvents => ({
    onChangeViewMode: viewMode => dispatch(ContentLibraryHeaderEvents.onChangeViewMode(viewMode)),
    onBack: () => dispatch(ContentLibraryHeaderEvents.onBack()),
    onClickLogo: () => dispatch(ContentLibraryHeaderEvents.onClickLogo()),
    onClickHelp: () => dispatch(ContentLibraryHeaderEvents.onClickHelp()),
    onChangeMainViewMode: (mainViewMode) => dispatch(ContentLibraryHeaderEvents.onChangeMainViewMode(mainViewMode)),
  }
);

export const ContentLibraryHeaderHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibraryHeader);

ContentLibraryHeaderHOC.displayName = 'ContentLibraryHeaderHOC';
