import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IMyLibVideosEvents, IMyLibVideosFields } from '../components/types';
import { MyLibVideosEvents } from './events';
import { MyLibVideos } from '../components/MyLibVideos';
import { getUserGroupById, getUserOwnGroups } from '../../../../../store/models/selectors';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { getAuthUserLangCode } from '../../../../../store/general/selectors';
import { ELanguageStatus } from '../../../../../store/general/types';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';

const mapStateToProps = (
) => {
	return (
		state: IState
	): IMyLibVideosFields => {
		const groupId = ContentLibSelectors.getSelectedGroupId(state);
		const group = !!groupId && getUserGroupById(state, groupId) as any;
		const langCode = getAuthUserLangCode(state);
		let groups;
		let isEmpty;
		if (groupId) {
			groups = [group];
		} else {
			let userGroups = getUserOwnGroups(state);
			
			groups = userGroups.filter(g => {
				const target = g.languages.find(lang => lang.status === ELanguageStatus.TO_LEARN);
				return target?.code === langCode;
			});

			isEmpty = true;
			for (let i=0; i<userGroups.length; i++) {
				let g = userGroups[i];
				if (g.videos && g.videos.length ||
					g.audios && g.audios.length
				) {
					isEmpty = false;
					break;
				}
			}
		};
		const activeId = getCurrentMovieKey(state);
		
		return {
			groupId,
			groups,
			isEmpty,
			activeId,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IMyLibVideosEvents => ({
	onVideoClick: (groupId, videoId) => dispatch(MyLibVideosEvents.onVideoClick(groupId, videoId)),
	onVideoCopyLink: (groupId, videoId) => dispatch(MyLibVideosEvents.onVideoCopyLink(groupId, videoId)),
    onVideoDelete: (groupId, videoId) => dispatch(MyLibVideosEvents.onVideoDelete(groupId, videoId)),
	onAudioClick: (groupId, id) => dispatch(MyLibVideosEvents.onAudioClick(groupId, id)),
	onAudioDelete: (groupId, id) => dispatch(MyLibVideosEvents.onAudioDelete(groupId, id)),
	onAudioDownload: (groupId, id) => dispatch(MyLibVideosEvents.onAudioDownload(groupId, id)),
});

export const MyLibVideosHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(MyLibVideos);

MyLibVideosHOC.displayName = 'MyLibVideosHOC';
