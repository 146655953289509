import { TAction } from '../../../common/types';

export const SET_AVAILABLE_CAPTIONS = 'CURRENT_VIDEO/AVAILABLE_CAPTIONS';
export const SET_SELECTED_NATIVE_CAPTIONS = 'CURRENT_VIDEO/NATIVE_CAPTIONS';
export const SET_SELECTED_TARGET_CAPTIONS = 'CURRENT_VIDEO/TARGET_CAPTIONS';
export const CURRENT_VIDEO_SET_ID = 'CURRENT_VIDEO/CURRENT_VIDEO_SET_ID';
export const CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX = 'CURRENT_VIDEO/CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX';
export const CURRENT_VIDEO_SET_CAPTIONS_FIX_STATUS = 'CURRENT_VIDEO/CURRENT_VIDEO_SET_CAPTIONS_FIX_STATUS';

export enum EVideoLangCaptionsFixStatus {
  NOT_CHECK = 0,
  CHECKED = 1,
  FIXED = 2
}

export interface IAvailableCaptionsItem {
  name: string;
  code: string;
  isAsr: boolean;
  url: string;
}

export interface ICurrentVideoState {
  currentVideoId: string;
  currentAudioId: number;
  availableCaptions: IAvailableCaptionsItem[];
  selectedNativeCaption: IAvailableCaptionsItem | null;
  selectedTargetCaption: IAvailableCaptionsItem | null;
  clickedCaptionIndex: number;
  captionsFixStatus: EVideoLangCaptionsFixStatus;
}

export interface ICurrentVideoSetId extends TAction<typeof CURRENT_VIDEO_SET_ID> {
  videoId: string;
  audioId: number;
}

export interface ISetAvailableCaptions extends TAction<typeof SET_AVAILABLE_CAPTIONS> {
  captions: IAvailableCaptionsItem[];
}

export interface ISetSelectedNativeCaptions extends TAction<typeof SET_SELECTED_NATIVE_CAPTIONS> {
  caption: IAvailableCaptionsItem | null
}

export interface ISetSelectedTargetCaptions extends TAction<typeof SET_SELECTED_TARGET_CAPTIONS> {
  caption: IAvailableCaptionsItem
}

export interface ICurrentVideoSetClickedCaptionIndex extends TAction<typeof CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX> {
  clickedCaptionIndex: number
}

export interface ICurrentVideoSetCaptionsFixStatus extends TAction<typeof CURRENT_VIDEO_SET_CAPTIONS_FIX_STATUS> {
  captionsFixStatus: EVideoLangCaptionsFixStatus;
}

export type TCurrentVideoActions =
  | ISetAvailableCaptions
  | ISetSelectedNativeCaptions
  | ISetSelectedTargetCaptions
  | ICurrentVideoSetId
  | ICurrentVideoSetClickedCaptionIndex
  | ICurrentVideoSetCaptionsFixStatus
