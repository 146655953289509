import React from 'react';
import { IStoryFromTranslatedProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Box, Button, CircularProgress, IconButton, Stack } from '@mui/material';
import { TrashIcon } from '../../../../../../icons/TrashIcon';

export const StoryFromTranslated: React.FC<IStoryFromTranslatedProps> = ({
	activeId,
	phraseList,
	stories,
	onGenerate,
	onOpen,
	onDelete,
}) => {
	if (!phraseList || !phraseList.length) return null;

	const className = 'story-from-translated';

	const [progress, setProgress] = React.useState(false);

	const onClick = () => {
		setProgress(true);
		onGenerate(phraseList)
			.finally(() => {
				setProgress(false);
			});
	}

	return (
		<Stack
			className={className}
			gap={theme.spacing(1)}
			sx={{
				pb: theme.spacing(2),
			}}
		>
			<Box>
				<Button
					disabled={progress}
					onClick={onClick}
					variant={'contained'}
					sx={{
						height: theme.spacing(4),
					}}
				>
					Generate story from phrases
				</Button>

				{progress &&
					<CircularProgress
						size={theme.spacing(3)}
						sx={{
							position: 'absolute',
							ml: theme.spacing(1),
							mt: theme.spacing(.5),
						}}
					/>
				}
			</Box>

			{stories && !!stories.length &&
				<Stack>
					{stories.map(s => {
					return (
						<Stack
							className={`${className}__item ${activeId === s.key ? 'active' : ''}`}
							flexDirection={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}
							sx={{
								maxWidth: 1,
								mr: theme.spacing(1.5),
								px: theme.spacing(1),
								borderRadius: theme.spacing(.75),

								'&.active': {
									background: theme.palette.grey[50],
								},
							}}
						>
							<Button
								key={s.key}
								sx={{
									height: theme.spacing(4),
									alignItems: 'flex-start',
									display: '-webkit-box',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									'-webkit-line-clamp': '1',
									'-webkit-box-orient': 'vertical',
									lineHeight: theme.spacing(4),
									color: theme.palette.grey[500],

									[`.${className}__item.active &`]: {
										color: theme.palette.grey[900],
									},
								}}
								onClick={() => onOpen(s.key)}
							>
								{s.title}
							</Button>
							<IconButton
								onClick={() => onDelete(s.key)}
								color={'primary'}
								sx={{
									flex: `0 0 ${theme.spacing(4.5)}`,
									color: theme.palette.grey[400],
									opacity: 0,
									pointerEvents: 'none',

									[`.${className}__item:hover &, &:focus`]: {
										opacity: 1,
										pointerEvents: 'all',
									},

									[`&:hover`]: {
										color: theme.palette.error.main,
									},
								}}
							>
								<TrashIcon
									sx={{
										width: `${theme.spacing(2)} !important`,
										height: `${theme.spacing(2)} !important`,
									}}
								/>
							</IconButton>
						</Stack>
					);})
				}
				</Stack>
			}
		</Stack>
	);
};
