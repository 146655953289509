import { connect } from 'react-redux';
import { AddNewVideoPopup } from '../components/AddNewVideoPopup';
import { IState } from '../../../../store/types';
import { isShowNewVideoPopup } from '../../../../store/general/selectors';
import { IAddNewVideoPopupEvents, IAddNewVideoPopupFields } from '../components/types';
import { AddNewVideoPopupEvents } from './events';
import { getGroupTargetLanguage, getLibraryGroup, getUserGroupById } from '../../../../store/models/selectors';
import { IAddNewVideoPopupHOCProps } from './types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    props: IAddNewVideoPopupHOCProps,
  ): IAddNewVideoPopupFields => {
    const { groupId } = props;

    let targetLang = '';
		const group = groupId ? getUserGroupById(state, groupId) : getLibraryGroup(state);
		if (group) {
			targetLang = getGroupTargetLanguage(state, group)?.name || '';
		}

    return {
      isOpen: isShowNewVideoPopup(state),
      targetLang,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  props: IAddNewVideoPopupHOCProps,
): IAddNewVideoPopupEvents => {
  const { groupId, onClose: onCloseOwn } = props;
  return {
    onValidate: (url) => dispatch(AddNewVideoPopupEvents.onValidate(url)),
    onCreate: (url) => dispatch(AddNewVideoPopupEvents.onCreate(url, groupId)),
    onClose: () => dispatch(AddNewVideoPopupEvents.onClose(onCloseOwn)),
    onCheckInstalled: () => dispatch(AddNewVideoPopupEvents.onCheckInstalled()),
  }
};

export const AddNewVideoPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewVideoPopup);

AddNewVideoPopupHOC.displayName = 'AddNewVideoPopupHOC';