import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ContentLibraryDashboard } from '../components/ContentLibraryDashboard';
import { IContentLibraryDashboardEvents, IContentLibraryDashboardFields } from '../components/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibraryDashboardEvents } from './events';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import { IContentLibraryDashboardHOCProps } from './types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    props: IContentLibraryDashboardHOCProps,
  ): IContentLibraryDashboardFields => {
    const { mainViewMode: mainViewModeOwn } = props;
    const mainViewMode = mainViewModeOwn || getMainViewMode(state) || EMainViewMode.CONTENT_LIBRARY;
    return {
      viewMode: ContentLibSelectors.getViewMode(state),
      mainViewMode,
    }
  };
};

const mapDispatchToProps = (
  dispatch: any,
  props: IContentLibraryDashboardHOCProps,
): IContentLibraryDashboardEvents => {
    const { mainViewMode } = props;

    return {
      onLoad: () => dispatch(ContentLibraryDashboardEvents.onLoad(mainViewMode)),
      onUnLoad: () => dispatch(ContentLibraryDashboardEvents.onUnLoad())
    }
};

export const ContentLibraryDashboardHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibraryDashboard);

ContentLibraryDashboardHOC.displayName = 'ContentLibraryDashboardHOC';
