import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, getRequestWithoutToken, postRequest, postRequestWithoutToken } from '../../utils/requestService';
import { IContentLibGroup, IContentLibVideo } from '../../../spa/store/contentLib/types';
import { TLanguage, TUserGroup, TVideo } from '../../../spa/store/models/types';

export type TContentLibGroupsSearchRequest = {
  targetLangCode: string;
  nativeLangCode: string;
  searchText: string;
  maxVideoCount: number
};

export type TContentLibVideosSearchRequest = {
  groupId: number;
  searchText: string;
  targetLangCode: string;
  nativeLangCode: string;
  startIndex: number;
  count: number;
};


export type TContentLibLangsResult = {
  targetLangs: TLanguage[],
  nativeLangs: TLanguage[]
}

export type TContentLibVideoPreviewResult = {
  video: TVideo,
  groupId: number
}

export class ContentLibRest {

  private static SEARCH_GROUPS_URL = API_URL + '/contentLib/searchGroups';
  private static SEARCH_VIDEOS_URL = API_URL + '/contentLib/searchVideos';
  private static LANG_LIST_URL = API_URL + '/contentLib/langList';

  private static COPY_VIDEO_URL = API_URL + '/contentLibPrivate/copyVideo';
  private static PREVIEW_VIDEO_URL = API_URL + '/contentLibPrivate/previewVideo';
  private static COPY_GROUP_URL = API_URL + '/contentLibPrivate/copyGroup';

  public static async searchGroups(filter: TContentLibGroupsSearchRequest): Promise<IContentLibGroup[]> {
    return await postRequestWithoutToken(ContentLibRest.SEARCH_GROUPS_URL, filter);
  }

  public static async searchVideos(filter: TContentLibVideosSearchRequest): Promise<IContentLibVideo[]> {
    return await postRequestWithoutToken(ContentLibRest.SEARCH_VIDEOS_URL, filter);
  }

  public static async copyVideo(libVideoId: number, targetGroupId: number): Promise<TVideo | null> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.COPY_VIDEO_URL, {libVideoId, targetGroupId}));
  }

  public static async previewVideo(libVideoId: number, targetLangCode: string): Promise<TContentLibVideoPreviewResult> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.PREVIEW_VIDEO_URL, {libVideoId, targetLangCode}));
  }

  public static async copyGroup(libGroupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(ContentLibRest.COPY_GROUP_URL, {libGroupId}));
  }

  public static async getLangList(): Promise<TContentLibLangsResult> {
    const url = ContentLibRest.LANG_LIST_URL;
    return await getRequestWithoutToken(url);
  }

}