import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { IPhrasePlaySettingsEvents, IPhrasePlaySettingsFields } from '../components/types';
import { PhrasePlaySettings } from '../components/PhrasePlaySettings';
import { PhrasePlaySettingsEvents } from './events';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): IPhrasePlaySettingsFields => {
    const pause = PhraseListSelectors.getPauseDelay(state);
    const {start = 0, end = 0} = PhraseListSelectors.getPlayOffset(state);
    const viewType = PhraseListSelectors.getPhraseListViewType(state);
    return {
      pause,
      start,
      end,
      viewType,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
): IPhrasePlaySettingsEvents => ({
    onPauseChange: (value) => dispatch(PhrasePlaySettingsEvents.onPauseChange(value)),
    onStartChange: (value) => dispatch(PhrasePlaySettingsEvents.onStartChange(value)),
    onEndChange: (value) => dispatch(PhrasePlaySettingsEvents.onEndChange(value)),
    onChangeViewType: (value) => dispatch(PhrasePlaySettingsEvents.onChangeViewType(value)),
  }
);

export const PhrasePlaySettingsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhrasePlaySettings);

PhrasePlaySettingsHOC.displayName = 'PhrasePlaySettingsHOC';