import { AppThunk } from '../../../../store/types';
import { setShowAddNewVideoPopupAction } from '../../../../store/general/actions';
import { YoutubeUrlUtil } from '../../../../../common/utils/youtube-url-util';
import { IAddVideoFromUrlValidateResult } from '../components/types';
import { addVideoEffect, createVideoEffect, TAddVideoResult } from '../../../../effects/video_effects';
import { EventsRouter } from '../../../../../common/events/eventsRouter';
import { Events } from '../../../../../common/events/types';
import { Navigator } from '../../../../effects/navigator';
import { CopyVideoEffects } from '../../../../effects/copyVideoEffects';
import { ExtensionInstallChecker } from '../../../../effects/extension-install-checker';

export class AddNewVideoPopupEvents {

  public static onClose(
    onCloseOwn?: () => void,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setShowAddNewVideoPopupAction(false));
      if (onCloseOwn) onCloseOwn();
    }
  }

  public static onValidate(url: string): AppThunk {
    return (
      dispatch,
      getState
    ): IAddVideoFromUrlValidateResult => {
      if (CopyVideoEffects.isCopyUrl(url)) {
        return {success: true};
      }
      if (!YoutubeUrlUtil.isYoutubeUrl(url)) {
        return {
          success: false,
          error: 'Error: Wrong youtube url'
        }
      }
      return {
        success: true
      }
    }
  }

  public static onCreate(
    url: string,
    groupId?: number,
  ): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      if (CopyVideoEffects.isCopyUrl(url)) {
        const hash = CopyVideoEffects.getHashFromCopyUrl(url);
        await CopyVideoEffects.startCopy(hash, 0); // сделал пока просто выбор группы, иначе можно было скопировать любой язык
      } else {
        EventsRouter.trackEvent(Events.ADD_VIDEO_BUTTON_CLICKED);
        let result: TAddVideoResult;
        if (groupId) {
            result = await dispatch(createVideoEffect(groupId, url));
        } else {
          result = await dispatch(addVideoEffect(0, url, ''));
        }
        Navigator.openVideo(result.userGroupId, result.videoId, 0, true);
      }
    }
  }

  public static onCheckInstalled(): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<boolean> => {
      return ExtensionInstallChecker.check();
    }
  }

}