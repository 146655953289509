import React from 'react';
import { Button, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import Box from '@mui/material/Box';
import { IPhraseTeacherNotesProps } from './types';
import { PhraseNoteHTML } from '../../PhraseNoteHTML/components/PhraseNoteHTML';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';

export const PhraseTeacherNotes: React.FC<IPhraseTeacherNotesProps> = ({
	canEdit,
	text,
	saved,
	createUserName,
	onClickAuthor,
	onCopy,
	onBack,
}) => {

	const buttonTitle = saved ? 'Already saved' : 'Save to my notes';

	return (
		<Stack
			// className={PHRASE_DETAILS_NOTES_CLASS_NAME}
			gap={1.5}
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				pt: theme.spacing(1.5),
				justifyContent: 'space-between',
			}}
		>
			<PhraseNoteHTML text={text} />

			{canEdit && <>
				<Box
					sx={{
						height: theme.spacing(3),
						textAlign: 'right',
						fontSize: theme.typography.subtitle2,
						overflow: 'hidden',
					}}
				>
					Created by
					{' '}
					<Button
						variant={'text'}
						onClick={onClickAuthor}
						sx={{
							textDecoration: 'underline',
						}}
					>
						{createUserName}
					</Button>
				</Box>


				<Stack
					direction={'row'}
					alignItems={'center'}
					gap={2}
					sx={{
						width: 1,
						mt: theme.spacing(1),
					}}
				>
					<ButtonIconGrey
						sx={{
							width: 'auto',
							fontSize: theme.typography.subtitle2,

							'.MuiSvgIcon-root': {
								width: theme.spacing(3),
								height: theme.spacing(3),
							},
						}}
						onClick={onBack}
					>
						<ArrowLightLeftIcon /> Back
					</ButtonIconGrey>

					<Button
						variant={'contained'}
						disabled={saved}
						sx={{
							minWidth: '50%',
						}}
						onClick={onCopy}
					>
						{buttonTitle}
					</Button>
				</Stack>
			</>}
		</Stack>
	);

}