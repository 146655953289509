import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';

export class PhraseTranslationEditEvents {

  public static onChange(text: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsActions.setTranslateResult({
        edit: {
          enabled: true,
          text
        }
      }));
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsActions.setTranslateResult({
        edit: {
          enabled: false,
          text: ''
        }
      }));
    }
  }

}
