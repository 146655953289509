import { IState } from '../types';
import { getSelectedNativeCaption, getSelectedTargetCaption } from '../current-video/selectors';
import { ITeacher, ITeacherUser, IVideoItem } from './types';
import {
  EVocabularyPhraseSaveType,
  EVocabularyPhraseType,
  ICaptionsItem,
  IPhraseNote,
  IVocabularyPhrase
} from '../../types/common';
import { getState } from '../index';

export const getVideo = (state: IState): IVideoItem => {
  return state.videos || null;
};

export const getVideoPhrases = (state: IState) => {
  const video = getVideo(state);
  return video ? video.phrases : [];
}

export const isVideoCaptionsEmpty = (state: IState) => {
  return getVideo(state)?.captionsEmpty || false;
}

export const findVideoPhraseById = (state: IState, phraseId: number) => {
  return getVideoPhrases(state).find(p => p.id === phraseId);
}

export const findVideoPhraseByTeacherPhraseId = (state: IState, teacherPhraseId: number) => {
  return getVideoPhrases(state).find(p => p.srcTeacherPhraseId === teacherPhraseId);
}

export const findVideoPhrasesByType = (state: IState, type: EVocabularyPhraseType) => {
  return getVideoPhrases(state).filter(phrase => phrase.type === type);
}

export const findVideoPhraseByWordId = (state: IState, wordPhraseId: number) => {
  return getVideoPhrases(state).find(p => p.wordPhraseId === wordPhraseId);
}


export const getAllVideoPhrasesWithFilterOrphans = (state: IState) => {
  const allPhrases = getVideoPhrases(state);
  const ret = allPhrases.filter(p => {
    if (p.type === EVocabularyPhraseType.WORD_SELECTED) {
      return !!allPhrases.find(p2 => p2.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED && p2.wordPhraseId === p.id);
    }
    return true;
  })
  return ret;
}

export const getAllVideoPhrasesWordTextList = (state: IState): string[] => {
  return getAllVideoPhrasesWithFilterOrphans(state)
    .filter(p => p.type === EVocabularyPhraseType.WORD_SELECTED && p.saveType === EVocabularyPhraseSaveType.PERSIST)
    .map(p => p.highlighted);
}

export const getTargetCaptions = (state: IState) => {
  const captions = getVideoCaptions(state);
  const targetLanguage = getSelectedTargetCaption(state);
  return targetLanguage && captions ? captions[targetLanguage.code] || [] : [];
};

export const getTargetCaptionByIndex = (state: IState, index: number): ICaptionsItem | null => {
  const list = getTargetCaptions(state);
  return index >= 0 && index < list.length ? list[index] : null;
}

export const getTargetCaptionsByIndexRange = (state: IState, startIndex: number, endIndex: number): ICaptionsItem[] => {
  const list = getTargetCaptions(state);
  const result: ICaptionsItem[] = [];
  for(let index = startIndex; index <= endIndex; index++) {
    if (index >= 0 && index < list.length) {
      result.push(list[index]);
    }
  }
  return result;
}

export const getNativeCaptions = (state: IState) => {
  const captions = getVideoCaptions(state);
  const nativeLanguage = getSelectedNativeCaption(state);
  return nativeLanguage && captions ? captions[nativeLanguage.code] || [] : [];
};

const getVideoCaptions = (state: IState) => {
  const video = getVideo(state);
  return video ? video.captions : [];
};

export const getVideoPhraseNotes = (state: IState): IPhraseNote[] => {
  const video = getVideo(state);
  return video ? video.phraseNotes : [];
}

export const getVideoPhraseNoteByCaption = (state: IState, caption: ICaptionsItem): IPhraseNote | null => {
  const phrases = findVideoPhrasesByTime(state, caption.startTime, caption.endTime);
  if (!phrases || !phrases.length) return null;
  const phraseNotes = getVideoPhraseNotes(state);
  if (!phraseNotes || !phraseNotes.length) return null;

  const phraseTypePriority = [EVocabularyPhraseType.WORD_SELECTED, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED];

  let result: IPhraseNote | undefined = undefined;
  phraseTypePriority.forEach(type => {
    if (result) return;
    const phraseList = phrases.filter(phrase => phrase.type === type);
    if (phraseList && phraseList.length) {
      result = phraseNotes.find(phraseNote => {
        return phraseList[0].id === phraseNote.phraseId;
      })
    }
  })

  return result || null;
}

export const getVideoPhraseNoteByTime = (state: IState, time: number): IPhraseNote | null => {
  const caption = getTargetCaptionByTime(state, time);
  if (!caption) return null;
  return getVideoPhraseNoteByCaption(state, caption);
}

export const findMaxUnionVideoPhraseByTime = (
  state: IState,
  startTime: number,
  endTime: number,
  types: EVocabularyPhraseType[] = [EVocabularyPhraseType.WORD_SELECTED, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED]): IVocabularyPhrase | null => {
  const phrases = getVideoPhrases(state).filter(phrase => {
    return phrase.type && types.includes(phrase.type)
  })

  const foundPhrases: IVocabularyPhrase[] = [];
  const foundPhrasesDuration: number[] = [];

  phrases.forEach(phrase => {
    let duration = 0;
    if (phrase.startTime >= startTime && phrase.endTime <= endTime) {
      duration = phrase.endTime - phrase.startTime;
    } else if (phrase.startTime <= startTime && phrase.endTime >= endTime) {
      duration = endTime - startTime;
    } else if (phrase.startTime >= startTime && phrase.startTime <= endTime) {
      duration = endTime - phrase.startTime;
    } else if (phrase.endTime >= startTime && phrase.endTime <= endTime) {
      duration = phrase.endTime - startTime;
    }
    if (duration > 0) {
      foundPhrases.push(phrase);
      foundPhrasesDuration.push(duration);
    }
  })

  if (foundPhrases.length === 0) {
    return null;
  }
  if (foundPhrases.length === 1) {
    return foundPhrases[0];
  }

  let maxDurIndex = -1;
  let maxDur = 0;
  foundPhrasesDuration.forEach((dur: number, index: number) => {
    if (dur > maxDur) {
      maxDur = dur;
      maxDurIndex = index;
    }
  })
  return maxDurIndex >= 0 ? foundPhrases[maxDurIndex] : null;
}

export const findVideoPhrasesByTime = (
  state: IState,
  startTime: number,
  endTime: number,
  types: EVocabularyPhraseType[] = [EVocabularyPhraseType.WORD_SELECTED, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED]): IVocabularyPhrase[] => {

  return getVideoPhrases(state).filter((phrase: IVocabularyPhrase) => {
    return phrase.type && types.includes(phrase.type) &&
      ((phrase.startTime >= startTime && phrase.startTime < endTime) ||
        (phrase.endTime > startTime && phrase.endTime <= endTime) ||
        (phrase.startTime < startTime && phrase.endTime > endTime)
      )
  })
}

const findVideoPhrasesByCaptionAndWordIndex = (state: IState, captionIndex: number, wordIndex: number, types: EVocabularyPhraseType[]): IVocabularyPhrase[] => {
  const maxCaptionLen = 3000;
  const wordPs = captionIndex * maxCaptionLen + wordIndex;
  return getVideoPhrases(state)
    .filter(phrase => phrase.type && types.includes(phrase.type))
    .filter(phrase => {
      return phrase.startCaptionIndex <= captionIndex && phrase.endCaptionIndex >= captionIndex
    })
    .filter(phrase => {
      const phraseStartPs = phrase.startCaptionIndex * maxCaptionLen + phrase.startPosition;
      const phraseEndPs = phrase.endCaptionIndex * maxCaptionLen + phrase.endPosition;
      return phraseStartPs <= wordPs && phraseEndPs >= wordPs;
    })
}

export const findVideoPhraseByCaption = (state: IState, captionIndex: number, wordIndex: number): IVocabularyPhrase | null => {
  let phrases = findVideoPhrasesByCaptionAndWordIndex(state, captionIndex, wordIndex, [EVocabularyPhraseType.WORD_SELECTED]);
  if (phrases && phrases.length > 0) {
    const wordPhrase = phrases[0];
    return findVideoPhraseByWordId(state, wordPhrase.id);
  }
  phrases = findVideoPhrasesByCaptionAndWordIndex(state, captionIndex, wordIndex, [EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED, EVocabularyPhraseType.DEFAULT]);
  return phrases && phrases.length > 0 ? phrases[0] : null;
}


export const getVideoPhraseNoteById = (state: IState, id: number): IPhraseNote | undefined => {
  return getVideoPhraseNotes(state).find(n => n.id === id);
}

export const getVideoPhraseNoteByPhraseId = (state: IState, phraseId: number): IPhraseNote | undefined => {
  return getVideoPhraseNotes(state).find(n => n.phraseId === phraseId);
}

export const getTargetCaptionByTime = (state: IState, time: number) => {
  return getCaptionByTime(getTargetCaptions(state), time);
}

const getCaptionByTime = (captions: ICaptionsItem[], time: number): ICaptionsItem | undefined => {
  return captions.find(c => c.startTime <= time && c.endTime >= time)
}

export const getVideoPhraseNoteTimeScales = (state: IState) => {
  return getVideo(state).phraseNoteTimeScales;
}

export const getVideoTeacherUsers = (state: IState): ITeacherUser[] => {
  return getVideo(state).teachers?.list || []
}

export const getVideoTeacherUser = (
  state: IState,
  id: number,
): ITeacherUser => {
  return getVideoTeacherUsers(state).find(i => i?.user?.id === id);
}

export const getVideoTeacherUserNick = (
  state: IState,
  id: number,
): string => {
  return getVideoTeacherUser(state, id)?.nickName;
}

export const getVideoTeacherUserActiveId = (state: IState): number => {
  return getVideo(state).teachers?.activeId;
}

export const getVideoTeacherShowNoteId = (state: IState): number => {
  return getVideo(state).teachers?.showPhraseNoteId;
}

export const getVideoTeacherShowPhraseId = (state: IState): number => {
  return getVideo(state).teachers?.showPhraseId;
}

export const getVideoTeacherSelectPhraseId = (state: IState): number => {
  return getVideo(state).teachers?.selectPhraseId;
}

export const getVideoTeacherShowNotePhraseId = (state: IState): number => {
  const note = getVideoTeacherShowNote(state);
  return note?.phraseId || 0;
}

export const getVideoTeacherShowNote = (state: IState): IPhraseNote | undefined => {
  const userId = getVideoTeacherUserActiveId(state);
  const noteId = getVideoTeacherShowNoteId(state);
  if (!userId || !noteId) return undefined;
  const user = getVideoTeacherData(state, userId);
  return user?.phraseNotes?.find(n => n.id === noteId);
}

export const getVideoTeacherByNoteId = (state: IState, noteId: number): ITeacherUser | undefined => {
  return getVideoTeacherUsers(state)?.find(u => {
    return u.phraseNotes?.findIndex(n => n.id === noteId) >= 0;
  })
}

export const getVideoTeacherData = (state: IState, userId: number): ITeacherUser | undefined => {
  return getVideo(state).teachers.list.find(t => t.user?.id === userId)
}

export const getVideoTeacherUserInfo = (state: IState, userId: number): ITeacher | undefined => {
  return getVideo(state).teachers.list.find(t => t.user?.id === userId)?.user
}

export const isVideoTeacherPhrasesLoaded = (state: IState, userId: number): boolean => {
  return getVideoTeacherData(state, userId)?.phrasesLoaded;
}

export const getVideoTeacherPhrases = (state: IState, userId: number): IVocabularyPhrase[] | undefined => {
  return getVideo(state).teachers.list.find(t => t.user?.id === userId)?.phrases;
}

export const getVideoTeacherPhraseNotes = (state: IState, userId: number): IPhraseNote[] | undefined => {
  return getVideo(state).teachers.list.find(t => t.user?.id === userId)?.phraseNotes;
}

export const getVideoTeacherPhraseById = (state: IState, userId: number, phraseId: number): IVocabularyPhrase | undefined => {
  return getVideo(state).teachers.list.find(t => t.user?.id === userId)?.phrases?.find(p => p.id === phraseId);
}

export const getVideoTeacherPhraseByWordId = (state: IState, userId: number, wordPhraseId: number): IVocabularyPhrase | undefined => {
  return getVideo(state).teachers.list.find(t => t.user?.id === userId)?.phrases?.find(p => p.wordPhraseId === wordPhraseId);
}

export const getVideoTeacherNoteByPhraseId = (state: IState, userId: number, phraseId: number): IPhraseNote | undefined => {
  return getVideo(state).teachers.list.find(t => t.user?.id === userId)?.phraseNotes?.find(n => n.phraseId === phraseId);
}

export const findVideoTeacherNoteByPhrase = (state: IState, userId: number, phrase: IVocabularyPhrase): IPhraseNote | undefined => {
  if (!phrase) return null;
  let note = getVideoTeacherNoteByPhraseId(state, userId, phrase.id);
  if (!note && phrase.wordPhraseId) {
    note = getVideoTeacherNoteByPhraseId(state, userId, phrase.wordPhraseId);
  }
  return note;
}

export const isExistPhrasesFromTeacherPhrase = (state: IState, phraseId: number): boolean => {
  return !!findVideoPhraseByTeacherPhraseId(state, phraseId);
}

