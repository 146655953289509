import * as React from 'react';
import {Box, SxProps} from "@mui/material";

export type THighLightedSpanProps = {
    className?: string;
    isStart?: boolean;
    isEnd?: boolean;
    style?: SxProps;
    index?: number;
    phraseId? : number;
    word? : boolean;
    isSelect?: boolean;
    wordActive?: boolean;

    onMouseEnter?: ()=>void;
    onMouseLeave?: ()=>void;
}

export const HighLightedSpan: React.FC<THighLightedSpanProps> = ({
    className,
    children,
    isStart,
    isEnd,
    style,
    index,
    phraseId,
    word,
    isSelect,
  wordActive,
    onMouseEnter,
    onMouseLeave,
}) => {
    let text =  wordActive ? ('<b>' + children + '</b>') : ''+children;

    return <Box
        className={`highlighted-span ${isSelect ? 'selected': ''} ${className} ${isEnd ? 'end': ''}`}
        sx={{
            padding: '4px 0',
            fontWeight: 400,
            transition: `background .2s`,
            ...style,
        }}
        dangerouslySetInnerHTML={{ __html: text }}
        component='span'
        data-word-index={index}
        data-phrase-id={phraseId}
        data-word={word ? '1' : '0'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    />
};
