import { PlayerManager } from './playerManager';
import { ICaptionsItem, IVocabularyPhrase, PlayerStates } from '../../../types/common';
import { IPlayerManagerListener } from './playerManagerListener';
import { getDispatch, getState } from '../../../store';
import { getPlayerState, isMainLayoutPhrases2Columns } from '../../../store/general/selectors';
import { PlayPhraseListHandler } from './handlers/playPhraseListHandler';
import { PlayPhraseHandler } from './handlers/playPhraseHandler';
import { PlayCaptionHandler } from './handlers/playCaptionHandler';
import { PlayCommonHandler } from './handlers/playCommonHandler';
import { PlayCaptionCommandHandler } from './handlers/playCaptionCommandHandler';
import { CaptionsSelectionPopupSelectors } from '../../../store/captions-selection-popup/selectors';
import { PlayPhraseCommandHandler } from './handlers/playPhraseCommandHandler';
import { PlaySelectTextHandler } from './handlers/playSelectTextHandler';
import { IPhraseSelectResult } from '../../phrase/utils/phrase-select-preparator';
import { PlayTeacherPhraseHandler } from './handlers/playTeacherPhraseHandler';
import { getVideoPhraseNoteByCaption } from '../../../store/videos/selectors';
import { MainLayoutEffects } from '../../mainLayoutEffects';
import { EPhrasesColumnTab } from '../../../store/general/types';

export enum EPlayerControllerMode {
  PLAY_COMMON,
  PLAY_CAPTION,
  PLAY_PHRASE,
  PLAY_PHRASE_LIST,
  PLAY_SELECT_TEXT,
  PLAY_TEACHER_PHRASE
}

export class PlayerController implements IPlayerManagerListener {

  private static instance: PlayerController;

  public static init() {
    PlayerController.getInstance();
  }

  public static getInstance(): PlayerController {
    if (!PlayerController.instance)
      PlayerController.instance = new PlayerController();
    return PlayerController.instance;
  }

  private mode: EPlayerControllerMode = EPlayerControllerMode.PLAY_COMMON;
  private waitPauseTimer: any;
  private mousePause: boolean;
  private playCaptionClickLastTime: number;
  private playCaptionClickCancel: boolean;

  private playCommonHandler: PlayCommonHandler;
  private playPhraseHandler: PlayPhraseHandler;
  private playPhraseListHandler: PlayPhraseListHandler;
  private playCaptionHandler: PlayCaptionHandler;
  private playCaptionCommandHandler: PlayCaptionCommandHandler;
  private playPhraseCommandHandler: PlayPhraseCommandHandler;
  private playSelectTextHandler: PlaySelectTextHandler;
  private playTeacherPhraseHandler: PlayTeacherPhraseHandler;

  constructor() {
    PlayerManager.getInstance().addListener(this);
    this.playCommonHandler = new PlayCommonHandler();
    this.playPhraseHandler = new PlayPhraseHandler();
    this.playPhraseListHandler = new PlayPhraseListHandler();
    this.playCaptionHandler = new PlayCaptionHandler();
    this.playCaptionCommandHandler = new PlayCaptionCommandHandler();
    this.playPhraseCommandHandler = new PlayPhraseCommandHandler();
    this.playSelectTextHandler = new PlaySelectTextHandler();
    this.playTeacherPhraseHandler = new PlayTeacherPhraseHandler();
  }

  public setMode(mode: EPlayerControllerMode) {
    this.mode = mode;
  }

  public getMode(): EPlayerControllerMode {
    return this.mode;
  }

  public play(time?: number, targetIndex?: number) {
    if (targetIndex !== undefined) {
      PlayerManager.getInstance().setTargetIndex(targetIndex);
    }
    this.playCommonHandler.play(time);
  }

  public onNativePlayClicked() {
    this.playCommonHandler.onNativePlayClicked();
  }

  public onNativePauseClicked() {
    this.reset();
    PlayerManager.getInstance().onNativePauseClicked();
  }

  public pause() {
    this.reset();
    PlayerManager.getInstance().pausePlay();
  }

  public togglePlayPause() {
    const state = getPlayerState(getState());
    if (state === PlayerStates.PLAYING) {
      PlayerManager.getInstance().pausePlay();
    } else {
      if (this.isPhrasesPlayMode()) {
        this.playPhraseCommandHandler.resumePlay();
      } else {
        this.playCaptionCommandHandler.resumePlay();
      }
     // PlayerManager.getInstance().startPlay();
    }
  }

  public async playNext() {
    if (this.isPhrasesPlayMode()) {
      this.playPhraseCommandHandler.playNextPhrase();
    } else {
      this.setMode(EPlayerControllerMode.PLAY_CAPTION);
      this.playCaptionCommandHandler.playNext();
    }
  }

  public async playPrev() {
    if (this.isPhrasesPlayMode()) {
      this.playPhraseCommandHandler.playPrevPhrase();
    } else {
      this.setMode(EPlayerControllerMode.PLAY_CAPTION);
      this.playCaptionCommandHandler.playPrev();
    }
  }

  public async repeat() {
    if (this.isPhrasesPlayMode()) {
      this.playPhraseCommandHandler.repeatPhrase();
    } else {
      this.setMode(EPlayerControllerMode.PLAY_CAPTION);
      this.playCaptionCommandHandler.repeat();
    }
  }

  public async repeatPhrase() {
    if (this.getMode() == EPlayerControllerMode.PLAY_PHRASE) {
      this.playPhraseHandler.repeat();
    } else if (this.getMode() == EPlayerControllerMode.PLAY_PHRASE_LIST) {
      this.playPhraseListHandler.repeat();
    }
  }

  public playPrevPhrase() {
    if (this.getMode() == EPlayerControllerMode.PLAY_PHRASE) {
      this.playPhraseHandler.playPrev();
    } else if (this.getMode() == EPlayerControllerMode.PLAY_PHRASE_LIST) {
      this.playPhraseListHandler.playPrev();
    }
  }

  public playNextPhrase() {
    if (this.getMode() == EPlayerControllerMode.PLAY_PHRASE) {
      this.playPhraseHandler.playNext();
    } else if (this.getMode() == EPlayerControllerMode.PLAY_PHRASE_LIST) {
      this.playPhraseListHandler.playNext();
    }
  }

  public async playCaption(caption: ICaptionsItem, fromClick: boolean = false) {
    if (fromClick) {
      this.playCaptionClickLastTime = new Date().getTime();
      this.playCaptionClickCancel = false;
      const callback = this.playCaptionFromClickTimeOut.bind(this);
      setTimeout(() => { callback(caption) }, 500);
    } else {
      this.playCaptionHandler.play(caption);
    }

    let note = getVideoPhraseNoteByCaption(getState(), caption)?.text;
    if (note) note = note.replaceAll(/\n|<p><\/p>|<br\s*\/?>/gm, '').trim();
    let is2Columns = isMainLayoutPhrases2Columns(getState());
    if (note) {
      if (!is2Columns) {
        MainLayoutEffects.setPhrasesColumnTab(EPhrasesColumnTab.SAVED);
      }
    } else {
      if (!is2Columns) {
        MainLayoutEffects.setPhrasesColumnTab(EPhrasesColumnTab.TRANSLATE);
      }
    }
  }

  private playCaptionFromClickTimeOut(caption: ICaptionsItem) {
    if (!this.playCaptionClickCancel) {
      this.playCaptionHandler.play(caption);
    }
  }

  public async playPhrase(phrase: IVocabularyPhrase) {
    return this.playPhraseHandler.play(phrase);
  }

  public async playPhraseList(fromStart: boolean) {
    this.playPhraseListHandler.play(fromStart);
  }

  public async playTeacherPhraseList() {
    this.playTeacherPhraseHandler.playList();
  }

  public async playTeacherPhrase(phrase: IVocabularyPhrase) {
    this.playTeacherPhraseHandler.play(phrase);
  }

  public async stopPhraseList() {
    this.playPhraseListHandler.stop();
  }

  public async playSelectText(selectResult: IPhraseSelectResult) {
    this.playSelectTextHandler.play(selectResult);
  }

  public async pausePlay(fromSelectText: boolean = false) {
    if (fromSelectText) {
      const period = new Date().getTime() - this.playCaptionClickLastTime;
      if (period < 500) {
        this.playCaptionClickCancel = true;
      }
    }
    PlayerManager.getInstance().pausePlay();
  }

  public mouseEnter(): boolean {
    const state = getPlayerState(getState());
    if (state === PlayerStates.PLAYING) {
      PlayerManager.getInstance().pausePlay();
      this.mousePause = true;
      return true;
    }
    return false;
  }

  public mouseLeave() {
    const dispatch = getDispatch();
    if (this.mousePause) {
      const textSelect = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (!textSelect || !textSelect.text) {
        this.mousePause = false;
        PlayerManager.getInstance().startPlay();
      }
    }
  }


  public onDeselectText() {
    if (this.mousePause) {
      this.mousePause = false;
      PlayerManager.getInstance().startPlay();
    }
  }


  public resetMode() {
    this.mode = EPlayerControllerMode.PLAY_COMMON;
    this.resetWaitPauseTimer();
    this.mousePause = false;
    this.playPhraseListHandler.reset();
  }

  public reset() {
    this.resetWaitPauseTimer();
    this.mousePause = false;
    if (this.mode === EPlayerControllerMode.PLAY_PHRASE_LIST) {
      this.playPhraseListHandler.reset();
    }
  }

 /* public onTargetCaptionsScroll() {
    if (this.mode == EPlayerControllerMode.PLAY_COMMON) {
      PlayerManager.getInstance().pausePlay();
    }
  }*/

  onNativeIndexChange(index: number): void {
  }

  onTargetIndexChange(index: number): void {
    if (this.mode === EPlayerControllerMode.PLAY_CAPTION) {
      this.playCaptionHandler.onTargetIndexChange(index);
    } else if (this.mode === EPlayerControllerMode.PLAY_COMMON) {
      this.playCommonHandler.onTargetIndexChange(index);
    }
  }

  onTimerDone() {
    if (this.mode === EPlayerControllerMode.PLAY_PHRASE) {
      this.playPhraseHandler.onTimerDone();
    } else if (this.mode === EPlayerControllerMode.PLAY_PHRASE_LIST) {
      this.playPhraseListHandler.onTimerDone();
    } else if (this.mode === EPlayerControllerMode.PLAY_SELECT_TEXT) {
      this.playSelectTextHandler.onTimerDone();
    } else if (this.mode === EPlayerControllerMode.PLAY_TEACHER_PHRASE) {
      this.playTeacherPhraseHandler.onTimerDone();
    }
  }

  public startWaitTimer(time: number) {
    this.waitPauseTimer = setTimeout(this.resumeAfterPauseFinish.bind(this), time);
  }

  private resetWaitPauseTimer() {
    if (this.waitPauseTimer) {
      clearTimeout(this.waitPauseTimer);
      this.waitPauseTimer = null;
    }
  }

  public resumeAfterPauseFinish() {
    if (this.mode === EPlayerControllerMode.PLAY_COMMON || this.mode === EPlayerControllerMode.PLAY_CAPTION) {
      PlayerManager.getInstance().startPlay();
    } else if (this.mode === EPlayerControllerMode.PLAY_PHRASE_LIST) {
      this.playPhraseListHandler.onWaitPauseFinish();
    } else if (this.mode === EPlayerControllerMode.PLAY_PHRASE) {
      this.playPhraseHandler.onWaitPauseFinish();
    } else if (this.mode === EPlayerControllerMode.PLAY_TEACHER_PHRASE) {
      this.playTeacherPhraseHandler.onWaitPauseFinish();
    }
  }

  public isPhrasesPlayMode(): boolean {
    return [EPlayerControllerMode.PLAY_PHRASE, EPlayerControllerMode.PLAY_PHRASE_LIST].includes(PlayerController.getInstance().getMode());
  }

  public isCaptionsPlayMode(): boolean {
    return [EPlayerControllerMode.PLAY_COMMON, EPlayerControllerMode.PLAY_CAPTION].includes(PlayerController.getInstance().getMode());
  }

  public onStarPlay() {
    this.playCommonHandler.onStarPlay();
  }
}