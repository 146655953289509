import { PlayerManager } from '../playerManager';
import { getDispatch } from '../../../../store';
import { PhraseListActions } from '../../../../store/phrase-list/actions';
import { EPhraseListPlayMode } from '../../../../store/phrase-list/types';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { PlayPhraseBaseHandler } from './playPhraseBaseHandler';
import { EVocabularyPhraseSaveType } from '../../../../types/common';

export class PlayPhraseListHandler extends PlayPhraseBaseHandler {

  public async play(fromFirstPhrase: boolean) {
    const phrases = this.getPhraseList().filter(p => p.saveType === EVocabularyPhraseSaveType.PERSIST);
    if (!phrases || phrases.length < 1)
      return;

    const playerController = PlayerController.getInstance();
    const playerManager = PlayerManager.getInstance();
    playerController.reset();
    await playerManager.pausePlay();

    playerController.setMode(EPlayerControllerMode.PLAY_PHRASE_LIST);

    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPlayMode(EPhraseListPlayMode.PLAY));

    const phrase = this.getStartPlayPhrase(fromFirstPhrase, phrases);
    this.startPhrase(phrase);
  }


}