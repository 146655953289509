import React from 'react';
import {
  CLASS_PHRASE_DETAILS_COLUMN_SCROLL,
  IPhraseDetailsContainerProps,
  PHRASE_DETAILS_COLUMN_CLASS_NAME
} from './types';
import { PhraseTranslationHOC } from '../../PhraseTranslation/hocs/PhraseTranslationHOC';
import { Box, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { PhraseDetailsContainerHeaderHOC } from '../../PhraseDetailsContainerHeader/hocs/PhraseDetailsContainerHeaderHOC';
import { CLASS_ONBOARDING_BLUR, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { PhraseDetailsPopupMenuHOC } from '../../PhraseDetailsPopupMenu/hocs/PhraseDetailsPopupMenuHOC';
import { PhrasePromptHeader } from '../../PhrasePromptHeader/components/PhrasePromptHeader';
import { HtmlUtils } from '../../../../../../common/utils/html-utils';
import { PhraseTextHOC } from '../../PhraseText/hocs/PhraseTextHOC';
import { PlayerFactory } from '../../../../../effects/player/player-factory';
import { PlayerStates } from '../../../../../types/common';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Hint } from '../../../../../../common/components/Hint/Hint';
import { Text } from '../../../../../../common/components/Text/Text';
import { PhraseTeacherTextHOC } from '../../PhraseTeacherText/hocs/PhraseTeacherTextHOC';
import { PhraseChatHOC } from '../../PhraseChat/hocs/PhraseChatHOC';
import { PHRASE_DETAILS_HEADER_CLASS_NAME } from '../../PhraseDetailsContainerHeader/components/types';
import { PhraseTranslationEditHOC } from '../../PhraseTranslationEdit/hocs/PhraseTranslationEditHOC';
import { PHRASE_DETAILS_TRANSLATION_EDITOR_CLASS_NAME } from '../../PhraseTranslationEdit/components/types';

export const PhraseDetailsContainer: React.FC<IPhraseDetailsContainerProps> = ({
  activeTab,
  groupId,
  videoId,
  teacherMode,
  text,
  isChat,
  onLoad,
  onSelection,
  onPlayerPositionChange
}) => {
  const {isMobile} = React.useContext(DeviceContext);

  const classTab = 'phrase-details-tab';

  const isMobileNoPrompt = isMobile && activeTab && activeTab.type === EPhraseDetailsTabType.TRANSLATION;

  React.useEffect(() => {
    onLoad();
  }, [groupId, videoId]);

  const onMouseUp = () => {
    const selection = SelectionUtil.getSelectedText();
    if (selection?.toString()) {
      const elem = selection.anchorNode as any;
      if (elem) {
        if (HtmlUtils.findParentByClassName(elem, PHRASE_DETAILS_TRANSLATION_EDITOR_CLASS_NAME)) {
          return;
        }
        if (HtmlUtils.findParentByClassName(elem.parentElement, PHRASE_DETAILS_COLUMN_CLASS_NAME)) {
          onSelection(selection);
        }
      }

    }
  }

  React.useEffect(() => {
    let tm: number;
    PlayerFactory.getPlayerApi().then((player) => {
      // @ts-ignore
      tm = setInterval(async () => {
        const playerState = await player.player.getPlayerState();
        if (playerState === PlayerStates.PLAYING) {
          const time = await player.player.getCurrentTime();
          onPlayerPositionChange(time);
        }
      }, 500);
    })

    return () => {
      clearInterval(tm);
    }
  }, []);

  return (
    <Paper
      onMouseUp={onMouseUp}
      className={`
        ${PHRASE_DETAILS_COLUMN_CLASS_NAME}
        ${!text && !teacherMode ? 'notext' : ''}
        ${CLASS_ONBOARDING_BLUR}
      `}
      sx={{
        position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        flex: '1 1 33%',
        minWidth: 0,
        height: 1,
        p: `${theme.spacing(1)} 0 0 0`,
        zIndex: 10,

        '.one-time-tooltip': {
          mb: theme.spacing(2)
        },

        '&.notext': {
          [`.${PHRASE_DETAILS_HEADER_CLASS_NAME}`]: {
            display: 'none',
          }
        },
      }}
    >
          <PhraseDetailsPopupMenuHOC />
          {isMobile
            ? null
            : <PhraseDetailsContainerHeaderHOC />
          }

          <Box
            className={`${CLASS_PHRASE_DETAILS_COLUMN_SCROLL}`}
            sx={{
              position: 'relative',
              overflowY: 'auto',
              flex: `1 1 100%`,
              minHeight: 0,
              pt: theme.spacing(2),
            }}>

        
            <Box sx={{
              mb: theme.spacing(1),
              p: theme.spacing(1, 1, 1, 3),
            }}>
              {teacherMode
                ? <PhraseTeacherTextHOC />
                : <>
                  <PhraseTextHOC
                    isTranslation
                  />
                  <PhraseTranslationEditHOC />
                </>
              }
            </Box>

            <Box
              sx={{
                pr: theme.spacing(1),
                pb: theme.spacing(1),
              }}
            >
              <PhraseTranslationHOC
                showTranslationsHint={!isChat}
              />
            </Box>

            {isChat &&
              <PhrasePromptHeader />
            }

            {isChat &&
              <PhraseChatHOC />
            }

            {isMobileNoPrompt &&
              <Stack
                alignItems={'left'}
                margin={theme.spacing(2, 1, 2, 2)}
              >
                <Hint>
                  <Text>
                    Please, select a prompt from library.
                  </Text>
                </Hint>
              </Stack>
            }

          </Box>

          <Box
							className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step3-highlight`}
							sx={{
								position: 'absolute',
								pointerEvents: 'none',
								left: theme.spacing(-.5),
								right: theme.spacing(-.5),
								top: theme.spacing(-.5),
								bottom: theme.spacing(-.5),
								borderRadius: theme.spacing(1),
							}}
						/>
    </Paper>
  );

}

