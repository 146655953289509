import React from 'react';
import { IInstallExtensionWarningProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Box, Button, Stack } from '@mui/material';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { LogoSmall } from '../../../../../../icons/LogoSmall';

export const InstallExtensionWarning: React.FC<IInstallExtensionWarningProps> = ({
  onCheckInstalled,
  onInstall,
}) => {
  const [show, setShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    onCheckInstalled().then((result) => {
      setShow(!result);
    });
  }, []);

  if (!show) return null;

  const {isMobile} = React.useContext(DeviceContext);

  return (
    <Stack
      gap={theme.spacing(3)}
      sx={{
        fontSize: theme.typography.subtitle2,
      }}
    >
      {isMobile
        ? <span>
          You can add new videos from YouTube on a desktop, using
          {' '}
          <Button
            variant={'text'}
            onClick={onInstall}
          >
            LangMagic Chrome Extension
          </Button>
        </span>
        : <>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            To add videos from YouTube, you need to install
            <Box
              sx={{
                mt: theme.spacing(.5),
              }}
            >
              <LogoSmall sx={{
                height: theme.spacing(2.3),
                width: 'auto',
                mr: theme.spacing(.5),
                mb: '-3px',
              }} />
              LangMagic Chrome Extension!
            </Box>
          </Box>
          <Button
            onClick={onInstall}
            fullWidth
            variant={'contained'}
          >
            Get it from Chrome Web Store
          </Button>
        </>
      }
    </Stack>
  );
};
