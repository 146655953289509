import { EVocabularyPhraseType, ICaptionsItem, IVocabularyPhrase } from '../../../types/common';
import _ from 'lodash';
import { PhraseEffects } from '../PhraseEffects';

/*interface ISelectIndexes {
  startIndex: number,
  endIndex: number
}*/

interface ISelectIndexes {
  startCaptionIndex: number,
  endCaptionIndex: number,
  startPosition: number,
  endPosition: number
}

interface IPhraseInfo {
  time: number,
  position: number
}

export interface IPhraseSelectResult {
  elementId?: string;
  text: string,
  startTime: number,
  endTime: number,
  startCaptionIndex: number,
  endCaptionIndex: number,
  startPosition: number,
  endPosition: number,
  insideWordPhrases?: IWordPhrase[],
  insideWordActiveId?: number,
  externalContextPhrase?: IVocabularyPhrase
}

export interface IWordPhrase extends IVocabularyPhrase {
  title?: string
}

export class PhraseSelectPreparator {

  private static snapWordsCodes = [10, 13, 32, 160, 44, 46, 58, 59];

  private selection: Selection;
  private captions: ICaptionsItem[];
  private wordPhrases: IVocabularyPhrase[];
  private contextPhrases: IVocabularyPhrase[];
  private elementId: string | undefined;
  private useCheckSpanWords: boolean;

  public constructor(
    selection: Selection,
    captions: ICaptionsItem[],
    wordPhrases: IVocabularyPhrase[],
    contextPhrases: IVocabularyPhrase[],
    elementId: string | null,
    useCheckSpanWords: boolean
  ) {
    this.selection = selection;
    this.captions = captions;
    this.wordPhrases = wordPhrases;
    this.contextPhrases = contextPhrases;
    this.elementId = elementId || undefined;
    this.useCheckSpanWords = useCheckSpanWords;
  }

  public execute(): IPhraseSelectResult | null {
    const {startCaptionIndex, endCaptionIndex, startPosition, endPosition} = this.getSelectionStartWordIndex();
    if (startCaptionIndex < 0 || endCaptionIndex < 0 ||
      startCaptionIndex >= this.captions.length ||
      endCaptionIndex >= this.captions.length) {
      return null;
    }

    const oneLine = this.isOneLineSelected();
    let selectionText = this.selection?.toString() || '';

    selectionText = selectionText
      .replace(/\n\n/gm, '\n');

    if (oneLine) {
      const caption = this.captions[startCaptionIndex];
      //const {startPosition, endPosition} = this.getSelectionStartWordIndex();
      const startTime = this.calculateTimePhraseByPosition(selectionText, caption, startPosition);
      const endTime = this.calculateTimePhraseByPosition(selectionText, caption, endPosition);
      const result = { startTime, endTime,
        text: selectionText,
        startCaptionIndex,
        endCaptionIndex,
        startPosition,
        endPosition,
        insideWordPhrases: this.getInsideWordPhrases(startCaptionIndex, endCaptionIndex, startPosition, endPosition),
        externalContextPhrase: this.getExternalContextPhrase(startCaptionIndex, endCaptionIndex, startPosition, endPosition),
        elementId: this.elementId
      };
      return this.prepareResult(result);
    }

    if (startCaptionIndex === endCaptionIndex) {
      const caption = this.captions[startCaptionIndex];
      const {time: startTime, position: startPosition} = this.calculateStartTimePhrase(selectionText, caption);
      const {time: endTime, position: endPosition} = this.calculateEndTimePhrase(selectionText, caption);
      return this.prepareResult({ startTime, endTime, text: selectionText,
        startCaptionIndex,
        endCaptionIndex,
        insideWordPhrases: this.getInsideWordPhrases(startCaptionIndex, endCaptionIndex, startPosition, endPosition),
        externalContextPhrase: this.getExternalContextPhrase(startCaptionIndex, endCaptionIndex, startPosition, endPosition),
        startPosition, endPosition,
        elementId: this.elementId
      });
    }

    let caption = this.captions[startCaptionIndex];
    let startTime = caption.startTime;

    let ps = selectionText.indexOf('\n');
    if (ps >= 0) {
      const text = selectionText.substring(0, ps).trim();

      const phraseInfo = this.calculateStartTimePhrase(text, caption);
      startTime = phraseInfo.time;
    }

    caption = this.captions[endCaptionIndex];
    let endTime = caption.endTime;
    //let endPosition = -1;
    ps = selectionText.lastIndexOf('\n');
    if (ps >= 0) {
      const text = selectionText.substring(ps).trim();
      const phraseInfo = this.calculateEndTimePhrase(text, caption);
      endTime = phraseInfo.time;
    }

    if (startPosition >= 0 && endPosition >= 0) {
      return this.prepareResult({ startTime, endTime, text: selectionText,
        startCaptionIndex: startCaptionIndex,
        endCaptionIndex: endCaptionIndex,
        insideWordPhrases: this.getInsideWordPhrases(startCaptionIndex, endCaptionIndex, startPosition, endPosition),
        externalContextPhrase: this.getExternalContextPhrase(startCaptionIndex, endCaptionIndex, startPosition, endPosition),
        startPosition,
        endPosition,
        elementId: this.elementId
      });
    }

    return null;
  }

  private prepareSelectionText(result: IPhraseSelectResult): string {
    if (result.startCaptionIndex === result.endCaptionIndex)
      return result.text;
    const captions = this.captions;
    let text = result.text;
    let ps = text.indexOf('\n');
    const startLine = ps >= 0 ? text.substring(0, ps) : captions[result.startCaptionIndex];
    ps = text.lastIndexOf('\n');
    const endLine = ps >= 0 ? text.substring(ps + 1) : captions[result.endCaptionIndex];

    let resultText = startLine + '\n';
    for (let i=result.startCaptionIndex + 1; i < result.endCaptionIndex; i++) {
      resultText += captions[i].text + '\n';
    }
    resultText += endLine;
    return resultText;
  }

  private prepareResult(result: IPhraseSelectResult): IPhraseSelectResult | null {
    if (!result) return result;
    if (result && result.insideWordPhrases && result.insideWordPhrases.length) {
      result.insideWordPhrases.forEach(p => {
        if (p.startCaptionIndex <= result.startCaptionIndex &&
          p.endCaptionIndex >= result.endCaptionIndex
        ) {
          result.insideWordActiveId = p.id;
        }
      });
    }
    const ret = this.useCheckSpanWords ? this.checkSpanWords(result) : result;
    ret.text = this.prepareSelectionText(ret);
    return ret;
  }


  public checkSpanWords(result: IPhraseSelectResult): IPhraseSelectResult {

    const isSnapChar = (char: string) => {
      if (!char || char.length < 1) return false;
      return PhraseSelectPreparator.snapWordsCodes.includes(char.charCodeAt(0));
    }

    const startCaption = this.captions[result.startCaptionIndex].text;

    if (isSnapChar(startCaption[result.startPosition])) {
      result.text = result.text.substr(1, result.text.length - 1);
      result.startPosition++;
    } else {
      let startPos;
      for(let pos = result.startPosition - 1; pos >= 0; pos--) {
        if (isSnapChar(startCaption[pos])) {
          startPos = pos + 1;
          break;
        }
      }
      if (startPos === undefined) {
        startPos = 0;
      }
      if (startPos < result.startPosition) {
        result.text = startCaption.substring(startPos, result.startPosition) + result.text;
        result.startPosition = startPos;
      }
    }

    const endCaption = this.captions[result.endCaptionIndex].text;
    if (result.endPosition > 0 && isSnapChar(endCaption[result.endPosition - 1])) {
     /* result.text = result.text.substring(0, result.text.length - 1);*/
      result.text = result.text.substr(0, result.text.length - 1);
      result.endPosition--;
    } else {
      let endPos;
      for(let pos = result.endPosition; pos < endCaption.length; pos++) {
        if (isSnapChar(endCaption[pos])) {
          endPos = pos;
          break;
        }
      }
      if (endPos === undefined) {
        endPos = endCaption.length;
      }
      if (endPos > result.endPosition) {
        result.text = result.text + endCaption.substring(result.endPosition, endPos);
        result.endPosition = endPos;
      }
    }

    return result;
  }

  private getDataWordIndexByElement(elem: HTMLElement | null | undefined): {
    offset: number,
    captionIndex: number
  } | null {
    if (elem) {
      const value = elem.getAttribute('data-word-index');
      let captionIndex = elem.parentElement?.getAttribute('data-caption-index');
      if (captionIndex === null || captionIndex === undefined) {
        captionIndex = elem.parentElement?.parentElement?.getAttribute('data-caption-index');
      }
      if (value) {
        return {
          offset: +value,
          captionIndex: +captionIndex
        };
      }
    }
    return null;
  }

  private getSelectionStartWordIndex(): ISelectIndexes {
    if (this.selection.anchorNode) {
      const {offset: anchorOffset, captionIndex: anchorCaptionIndex} = this.getDataWordIndexByElement(this.selection.anchorNode.parentElement);
      const {offset: focusOffset, captionIndex: focusCaptionIndex} = this.getDataWordIndexByElement(this.selection.focusNode?.parentElement);

      if (anchorCaptionIndex == focusCaptionIndex) {
        return {
          startCaptionIndex: anchorCaptionIndex,
          endCaptionIndex: focusCaptionIndex,
          startPosition: anchorOffset < focusOffset ? anchorOffset : focusOffset,
          endPosition: (focusOffset > anchorOffset ? focusOffset : anchorOffset) +1
        }
      }

      const moveUp = anchorCaptionIndex > focusCaptionIndex;
      let startPosition, endPosition, startCaptionIndex, endCaptionIndex
      if (moveUp) {
        startCaptionIndex = focusCaptionIndex;
        endCaptionIndex = anchorCaptionIndex;
        startPosition = focusOffset;
        endPosition = anchorOffset + 1;
      } else {
        startCaptionIndex = anchorCaptionIndex;
        endCaptionIndex = focusCaptionIndex;
        startPosition = anchorOffset;
        endPosition = focusOffset + 1
      }
      return {
        startCaptionIndex,
        endCaptionIndex,
        startPosition,
        endPosition
      }
    }
  }

 /* private getSelectionStartWordIndex(): number {
    const defaultResult = this.selection.anchorOffset;

    console.log('anchorOffset', this.selection.anchorOffset);
    console.log('focusOffset', this.selection.focusOffset);

    if (!this.selection.anchorNode)
      return defaultResult;
    const elem = this.selection.anchorNode.parentElement;
    const focusElem = this.selection.focusNode?.parentElement;
    if (focusElem) {
      console.log('focusElem', focusElem.getAttribute('data-word-index') );
    }
    if (!elem)
      return defaultResult;
    const attr = elem.getAttribute('data-word-index');
    console.log('elem', elem.getAttribute('data-word-index'));
    return attr ? +attr : defaultResult;
  }*/

  private isOneLineSelected() {
    const s = this.selection.toString().trim();
    return s.indexOf('\n') < 0;
  }

  private getExternalContextPhrase(startCaptionIndex: number,
                                   endCaptionIndex: number,
                                   startPosition: number,
                                   endPosition: number): IVocabularyPhrase | undefined {
    return this.contextPhrases.find((phrase) => {
      const startAfter = (startCaptionIndex > phrase.startCaptionIndex) ||
        (startCaptionIndex == phrase.startCaptionIndex && startPosition >= phrase.startPosition);
      const endBefore = (endCaptionIndex < phrase.endCaptionIndex) ||
        (endCaptionIndex == phrase.endCaptionIndex && endPosition <= phrase.endPosition);
      return !!startAfter && !!endBefore;
    })
  }

  private getInsideWordPhrases(startCaptionIndex: number,
                               endCaptionIndex: number,
                               startPosition: number,
                               endPosition: number): IWordPhrase[] {
    const words: IVocabularyPhrase[] = this.wordPhrases.filter(phrase => {
      if (phrase.type !== EVocabularyPhraseType.WORD_SELECTED &&
        phrase.type !== EVocabularyPhraseType.PREVIEW_WORD_SELECTED) return false;
      return PhraseEffects.checkPhraseInterceptRange(startCaptionIndex, startPosition, endCaptionIndex, endPosition, phrase);
    })

    const isMutilWordExist = (words: IVocabularyPhrase[], text: string) => {
      return words.filter(w => w.highlighted === text).length > 1;
    }

    const calcWordRepeatCount = (words: IVocabularyPhrase[], text: string, indexBefore: number) => {
      let result = 0;
      for (let i = 0; i < indexBefore; i++) {
        if (words[i].highlighted === text) {
          result++;
        }
      }
      return result;
    }

    return words.map((wordPhrase: IVocabularyPhrase, index) => {
      const result: IWordPhrase = {...wordPhrase, ...{title: wordPhrase.highlighted}};
      if (isMutilWordExist(words, wordPhrase.highlighted)) {
        const count = calcWordRepeatCount(words, wordPhrase.highlighted, index);
        result.title = result.highlighted + ' (' + (count + 1) + ')';
      }
      return result;
    })
  }


  private calculateTimePhraseByPosition(phrase: string, caption: ICaptionsItem, phrasePos: number) {
    const captionText = this.getCaptionText(caption);
    const letterCount = captionText.length;
    const letterDuration = (caption.endTime - caption.startTime) / letterCount;
    return caption.startTime + letterDuration * phrasePos;
  }

  private calculateStartTimePhrase(phrase: string, caption: ICaptionsItem): IPhraseInfo {
    const captionText = this.getCaptionText(caption);
    const letterCount = captionText.length;
    const phrasePos = captionText.indexOf(phrase);
    if (phrasePos < 0)
      return {
        time: caption.startTime,
        position: 0
      }
    const letterDuration = (caption.endTime - caption.startTime) / letterCount;
    return {
      time: caption.startTime + letterDuration * phrasePos,
      position: phrasePos
    }
  }

  private calculateEndTimePhrase(phrase: string, caption: ICaptionsItem): IPhraseInfo {
    const captionText = this.getCaptionText(caption);
    const letterCount = captionText.length;
    const phrasePos = captionText.indexOf(phrase);
    if (phrasePos < 0) {
      return {
        time: caption.endTime,
        position: phrasePos
      }
    }
    const letterDuration = (caption.endTime - caption.startTime) / letterCount;
    return {
      time: caption.startTime + letterDuration * (phrasePos + phrase.length),
      position: phrasePos + phrase.length
    }
  }

  private getCaptionText(caption: ICaptionsItem) {
    if (!caption || !caption.text) return '';
    return _.unescape(caption.text)
      .replace(/\n\r/gm, ' ')
      .replace(/[\n\r]/gm, ' ')
      .replace(/ +/gm, ' ')
  }

  private getSelectNodeElement(el: HTMLElement | null): HTMLElement | null {
    if (!el) return null;
    if (el.dataset.start)
      return el;
    let parent = el.parentElement as HTMLElement;
    if (parent.dataset.start)
      return parent;
    parent = parent.parentElement as HTMLElement;
    if (parent.dataset.start)
      return parent;
    return null;
  }
/*
  private getSelectIndexes(): ISelectIndexes | null {
    if (this.selection.anchorNode && this.selection.focusNode) {
      let anchorElement = this.getSelectNodeElement(this.selection.anchorNode.parentElement as HTMLElement);
      let focusElement = this.getSelectNodeElement(this.selection.focusNode.parentElement as HTMLElement);
      if (anchorElement && focusElement) {
        let targetStart = parseFloat(anchorElement.dataset.start as string);
        let targetEnd = parseFloat(anchorElement.dataset.end as string);
        let focusStart = parseFloat(focusElement.dataset.start as string);
        let focusEnd = parseFloat(focusElement.dataset.end as string);

        const anchorCaptionIndex = this.captions.findIndex((item) => {
          return item.startTime === targetStart && item.endTime === targetEnd;
        });
        const focusCaptionIndex = this.captions.findIndex((item) => {
          return item.startTime === focusStart && item.endTime === focusEnd;
        });

        const result: ISelectIndexes = {startIndex: 0, endIndex: 0};
        if (focusCaptionIndex > anchorCaptionIndex) {
          result.startIndex = anchorCaptionIndex;
          result.endIndex = focusCaptionIndex;
        } else {
          result.startIndex = focusCaptionIndex;
          result.endIndex = anchorCaptionIndex;
        }
        return result;
      }
    }
    return null;
  }*/

}
