import React from 'react';

export const MOBILE_BREAKPOINT = 1025; // px
export const SMALL_BREAKPOINT = 1200; // px
export const MIN_WIDTH = 900; // px for desktop

export const DeviceContext = React.createContext<IDeviceContext>(null);

export interface IDeviceContext {
    isMobile?: boolean;
    isSmall?: boolean; // desktop
    isTouch?: boolean;
    scrollWidth?: number;
};
