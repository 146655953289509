import { AppThunk } from '../../../../../store/types';
import { EPhrasesColumnTab, EPhrasesColumnType } from '../../../../../store/general/types';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { TeachersEffects } from '../../../../../effects/teachersEffects';


export class PhrasesColumnEvents {

	public static setTab(tab: EPhrasesColumnTab): AppThunk {
		return (
			dispatch,
			getState
		) => {
			MainLayoutEffects.setPhrasesColumnTab(tab);
		}
	}

	public static setType(type: EPhrasesColumnType): AppThunk {
		return (
			dispatch,
			getState
		) => {
			MainLayoutEffects.setPhrasesColumn(type);
		}
	}

	public static onBackNote(): AppThunk {
		return async(
		  dispatch,
		  getState
		) => {
		  	dispatch(PhraseListActions.setNoteId(null as any));
			const tab = PhraseDetailsEffects.getDefaultTab();
			if (tab) {
				dispatch(PhraseDetailsActions.setActiveTab(tab));
			}
		}
	}

	public static onBackTeacherNote(): AppThunk {
		return async(
		  dispatch,
		  getState
		) => {
		  	TeachersEffects.hidePhrases();
		}
	}

}