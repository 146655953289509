import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { LoginPopupEvents } from './events';
import { ILoginPopupEvents, ILoginPopupFields } from '../../components/LoginPopup/types';
import { LoginPopup } from '../../components/LoginPopup/LoginPopup';
import { LoginPopupSelectors } from '../../../../../store/loginPopup/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): ILoginPopupFields => {
		const {show, text} = LoginPopupSelectors.getLoginPopup(state);

		return {
			isOpen: show,
			text,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): ILoginPopupEvents => ({
	onClose: () => dispatch(LoginPopupEvents.onClose()),
	onLogin: () => dispatch(LoginPopupEvents.onLogin()),
}
);

export const LoginPopupHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginPopup);

LoginPopupHOC.displayName = 'LoginPopupHOC';
