import {
  setVideoTeacherAction,
  setVideoTeacherShowNoteIdAction, setVideoTeacherShowPhraseIdAction,
  setVideoTeacherUserActiveIdAction
} from '../store/videos/actions';
import { getDispatch, getState } from '../store';
import { getActiveGroupId } from '../store/models/selectors';
import { getCurrentMovieKey } from '../store/current-video/selectors';
import { TeacherRest } from '../../common/rest/teacher/teacherRest';
import { EVocabularyPhraseType } from '../types/common';
import { getVideoTeacherData, isVideoTeacherPhrasesLoaded } from '../store/videos/selectors';
import { batch } from 'react-redux';

export class TeachersEffects {

  public static async showPhrases(userId: number) {
    const dispatch = getDispatch();

    dispatch(setVideoTeacherAction(userId, {
      phrasesLoading: true
    }));
    dispatch(setVideoTeacherUserActiveIdAction(userId));

    try {
      await this.loadPhrases(userId);
    } catch(e) {
      dispatch(setVideoTeacherAction(userId, {
        phrasesLoading: false
      }));
    }

  }

  public static async loadPhrases(userId: number) {
    const state = getState();
    const dispatch = getDispatch();
    const userGroupId = getActiveGroupId(state);
    const videoId = getCurrentMovieKey(state);

    const result = await TeacherRest.findPhrasesNotesLists({
      userId, videoId, userGroupId
    });
    const phraseCount = result.phrases.filter(p => p.type === EVocabularyPhraseType.PREVIEW_WORD_SELECTED).length
    dispatch(setVideoTeacherAction(userId, {
      phrases: result.phrases,
      phraseNotes: result.notes,
      phraseCount,
      phrasesLoading: false,
      phrasesLoaded: true
    }));
  }

  public static hidePhrases(
    hideUser?: boolean
  ) {
    const dispatch = getDispatch();
    batch(() => {
      if (hideUser) dispatch(setVideoTeacherUserActiveIdAction(0));
      dispatch(setVideoTeacherShowNoteIdAction(0));
      dispatch(setVideoTeacherShowPhraseIdAction(0));
    })
  }
}