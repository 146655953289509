import { AppThunk } from '../../../../../store/types';
import { ContentLibEvents } from '../../effects';

export class ContentLibGroupEvents {
    public static onClick(groupId: number): AppThunk {
        return async (
          dispatch,
          getState
        ) => {
            dispatch(ContentLibEvents.openGroup(groupId));
        }
    }
}