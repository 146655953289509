import { ICaptionsItem } from '../types/common';
import { GptChatRest } from '../../common/rest/gptChat/gptChatRest';
import { getCurrentCaptionsFixStatus, getSelectedTargetCaption } from '../store/current-video/selectors';
import { getDispatch, getState } from '../store';
import { getTargetCaptions, getVideo } from '../store/videos/selectors';
import { setCaptionsFixAiInfoAction } from '../store/general/actions';
import { getActiveGroupId, getVideoById } from '../store/models/selectors';
import { EVideoLangCaptionsFixStatus } from '../store/current-video/types';
import { updateVideoCaptionsFixStatus } from './video_effects';

type TTextHandlePart = {
  beforeLines: string[],
  lines: string[],
  afterLines: string[]
}


export type TOnVideoCaptionsPunctuationCheckProgress = (handledCount: number, total: number) => void;

export class VideoCaptionsPunctuationCheckStarter {

  private videoId: string;
  private groupId: number;

  constructor(videoId: string, groupId: number) {
    this.videoId = videoId;
    this.groupId = groupId;
  }

  public startWatch() {
    const startTime = new Date().getTime();
    const timer = setInterval(() => {
      if ((new Date().getTime() - startTime) > 10000) {
        return clearInterval(timer);
      }
      const state = getState();
      const video = getVideo(state);
      const activeGroupId = getActiveGroupId(state);
      if (video?.videoId === this.videoId && this.groupId === activeGroupId) {
        clearInterval(timer);
        this.checkNeedFixCaptions();
      }
    }, 1000);
  }

  private checkNeedFixCaptions() {
    const video = getVideoById(getState(), this.videoId);
    const langList = video?.info?.langList || [];
    const targetLangCode = getSelectedTargetCaption(getState())?.code || '';
    const captionsFixStatus = getCurrentCaptionsFixStatus(getState());
    if (captionsFixStatus !== EVideoLangCaptionsFixStatus.NOT_CHECK) return;
    let able = langList.includes(targetLangCode);
    let exist = new VideoCaptionsPunctuationCheck(getTargetCaptions(getState())).isPunctuationExist();
    if (able && !exist) {
      const dispatch = getDispatch();
      dispatch(setCaptionsFixAiInfoAction({
        groupId: this.groupId,
        videoId: this.videoId,
        showPopup: true
      }));
    } else {
      updateVideoCaptionsFixStatus(this.videoId, targetLangCode, EVideoLangCaptionsFixStatus.CHECKED);
    }
  }
}

export class VideoCaptionsPunctuationCheck {

  private static HANDLE_PART_LEN = 30;
  private static SEPARATION_WORD = 'ooo';

  private items: ICaptionsItem[];
  private onProgress: TOnVideoCaptionsPunctuationCheckProgress;
  private interruptStatus = false;


  constructor(items: ICaptionsItem[]) {
    this.items = items;
  }

  public async check(onProgress: TOnVideoCaptionsPunctuationCheckProgress): Promise<ICaptionsItem[]> {
    this.onProgress = onProgress;
    const regexp = /\n/gm;
    const textList = this.items.map(i => {
      return i.text ? i.text.replaceAll(regexp, ' ') : '';
    });
    const partList = this.getTextPartsList(textList);

    const resultCaptions: ICaptionsItem[] = [...this.items];
    let partIndex = 0;
    for await (const part of partList) {

      if (this.interruptStatus) {
        throw new Error('Process has been interrupted');
      }

      let successHandle = true;
      let resultLines;
      try {
        resultLines = await this.checkPunctuation(part);
      } catch(e) {
        successHandle = false;
      }

      const resultList: string[] = successHandle ? resultLines : part.lines;
      resultList.forEach((line, lineIndex) => {
        const i = partIndex * VideoCaptionsPunctuationCheck.HANDLE_PART_LEN + lineIndex;
        const text = successHandle ? this.checkHandledLine(line) : line;
        resultCaptions[i].text = text;
      })

      partIndex++;
      this.onProgress(VideoCaptionsPunctuationCheck.HANDLE_PART_LEN * partIndex, textList.length)
    }
    return resultCaptions;
  }

  public interrupt() {
    this.interruptStatus = true;
  }

  private checkHandledLine(line: string): string {
    const fixStartSymbols = [',', '.', '"'];
    let result = line?.trim() || '';
    const exist = fixStartSymbols.some(s => result.startsWith(s));
    if (exist) {
      result = result.substring(1).trim();
    }
    if (result.endsWith('"')) {
      result = result.substring(0, result.length - 1);
    }
    return result;
  }

  private getTextPartsList(list: string[]): TTextHandlePart[] {
    const partList: TTextHandlePart[] = [];
    const beforeAfterLines = 3;
    const partLen = VideoCaptionsPunctuationCheck.HANDLE_PART_LEN;
    const listLen = list.length;
    let index = 0;
    while (index < listLen) {
      const lines = list.slice(index, index + partLen);
      let beforeLines: string[] = [];
      let afterLines: string[] = [];
      if (lines.length) {
        let beforeStartIndex = index - beforeAfterLines;
        if (beforeStartIndex >= 0) {
          beforeLines = list.slice(beforeStartIndex, index);
        }
        const startAfterIndex = index + partLen;
        let afterEndIndex = startAfterIndex + beforeAfterLines;
        if (afterEndIndex >= listLen) {
          afterEndIndex = listLen;
        }
        if (afterEndIndex > startAfterIndex) {
          afterLines = list.slice(startAfterIndex, afterEndIndex);
        }
      }
      partList.push({ beforeLines, lines, afterLines })
      index += partLen;
    }
    return partList;
  }

  private extractResultByTextHandlePart(part: TTextHandlePart, str: string): string[] {
    const list = str.split(VideoCaptionsPunctuationCheck.SEPARATION_WORD);
    const startIndex = part.beforeLines.length;
    const ret = list.slice(startIndex, startIndex + part.lines.length);
    return ret;
  }

  private checkResultByTextHandlePart(part: TTextHandlePart, str: string): boolean {
    const resultLineCount = str.split("\n").length;
    const partLineCount = part.beforeLines.length + part.lines.length + part.afterLines.length;
    return partLineCount === resultLineCount;
  }

  public isPunctuationExist() {
    const chars = [',', '.'];
    let count = 0;
    this.items.forEach(i => {
      if (chars.some(c => i.text.includes(c))) count++;
    });
    return count > this.items.length / 10;
  }

  private async checkPunctuation(textPart: TTextHandlePart): Promise<string[]> {

    const maxAttemptCount = 10;

    return new Promise<string[]>((resolve, reject) => {
      let attemptNumber = 1;

      const _exec = async (callback: (resultLines: string[]) => void) => {
        const resultStr: string = await this.sendPrompt(textPart);
        const resultLines = this.extractResultByTextHandlePart(textPart, resultStr);
        callback(resultLines);
      }

      const _callback = (resultLines: string[]) => {
        if (resultLines.length === textPart.lines.length) {
          return resolve(resultLines);
        }
        if (attemptNumber >= maxAttemptCount) {
          return reject();
        }
        attemptNumber++;
        _exec(_callback);
      }

      _exec(_callback);
    })


  }

  private async sendPrompt(textPart: TTextHandlePart): Promise<string> {
      const list = [...textPart.beforeLines, ...textPart.lines, ...textPart.afterLines];
      const prompt = 'for the following text, fix punctuation, but ignore "' + VideoCaptionsPunctuationCheck.SEPARATION_WORD +
        '" word and keep "' + VideoCaptionsPunctuationCheck.SEPARATION_WORD +
        '" word on the same positions as in original text, the text is:' + "\n" +
        list.join(" " + VideoCaptionsPunctuationCheck.SEPARATION_WORD + "\n");
      const {result} = await GptChatRest.exec({
        prompt
      })

      return result;
  }

  /*private async checkPunctuation2(textPart: TTextHandlePart): Promise<string> {
    return new Promise<string>(resolve => {
      setTimeout(() => {
        const list =
          [...textPart.beforeLines, ...textPart.lines, ...textPart.afterLines];
        resolve(list.join("\n"));
      }, 100);
    })
  }*/



}