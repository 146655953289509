import { AppThunk } from '../../../../../store/types';
import { setMainViewMode, setShowAddNewAudioPopupAction, setShowAddNewVideoPopupAction, setSnackbarPanelAction } from '../../../../../store/general/actions';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { Navigator } from '../../../../../effects/navigator';
import { removeVideoFromGroup } from '../../../../../effects/phrase/phraseVocabularyEffect';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { getAudioById, getUserGroupById } from '../../../../../store/models/selectors';
import { CopyVideoEffects } from '../../../../../effects/copyVideoEffects';
import { AudioEffects } from '../../../../../effects/audioEffects';
import { EMainViewMode } from '../../../../../store/general/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';


export class MyLibVideosEvents {

	public static onVideoClick(
		groupId: number,
		videoId: string,
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			PlayerController.getInstance().resetMode();
			Navigator.openVideo(groupId, videoId);
		}
	}

	public static onVideoDelete(
		groupId: number,
		videoId: string,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			await dispatch(removeVideoFromGroup(videoId, groupId));
			dispatch(PhraseListActions.removeByVideoGroup(videoId, groupId));

		}
	}

	public static onVideoCopyLink(
		groupId: number,
		videoId: string,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			if (!getUserGroupById(getState(), groupId)) {
				return dispatch(setSnackbarPanelAction(true, 'Unable create copy link: invalid playlist'));
			}
			const url = await CopyVideoEffects.createCopyLink(videoId, groupId);
			await navigator.clipboard.writeText(url);
			dispatch(setSnackbarPanelAction(true, 'Link to public video copied to clipboard'));
		}
	}

	public static onAudioDelete(
		groupId: number,
		audioId: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			return AudioEffects.remove(audioId, groupId);
		}
	}

	public static onAudioDownload(
		groupId: number,
		audioId: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const audio = getAudioById(getState(), audioId);
			if (audio?.url) {
				window.open(audio.url);
			}
		}
	}

	public static onAudioClick(
		groupId: number,
		audioId: number,
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			PlayerController.getInstance().resetMode();
			Navigator.openAudio(groupId, audioId);
		}
	}

}