import { IMyLibElement, IMyLibVideosProps } from './types';
import React from 'react';
import { Stack } from '@mui/material';
import { ContentLibVideo } from '../../ContentLibVideo/components/ContentLibVideo';
import { theme } from '../../../../../../common/styles/theme';
import { EditPlaylistItemHOC } from '../../../PlayList/EditPlayList/hocs/EditPlaylistItem/EditPlaylistItemHOC';
import { EditGroupHOC } from '../../../PlayList/EditGroup/hocs/EditGroupHOC';
import { Text } from '../../../../../../common/components/Text/Text';
import { MY_STUFF_NO_ITEMS, MY_STUFF_NO_ITEMS_GROUP, MY_STUFF_NO_ITEMS_LANG } from '../../wordings';
import { AddNewVideoPopupHOC } from '../../../AddNewVideoPopup/hocs/AddNewVideoPopupHOC';
import { AddNewAudioPopupHOC } from '../../../AddNewAudioPopup/hocs/AddNewAudioPopupHOC';
import { MyLibAddButtonsHOC } from '../../MyLibAddButtons/hocs/MyLibAddButtonsHOC';

export function MyLibVideos({
	groupId,
	groups,
	isEmpty,
	activeId,
	onVideoClick,
	onVideoDelete,
  	onVideoCopyLink,
	onAudioClick,
	onAudioDelete,
	onAudioDownload,
}: IMyLibVideosProps) {

	const [items, setItems] = React.useState<IMyLibElement[]>([]);
	React.useEffect(() => {
        const items: IMyLibElement[] = [];
		groups && groups.length && groups.forEach(group => {
			group?.videos && group.videos.length && group.videos.forEach(video => {
				items.push({
					groupId: group.id,
					video,
				});
			});
			group?.audios && group.audios.length && group.audios.forEach(audio => {
				items.push({
					groupId: group.id,
					isAudio: true,
					audio,
				});
			});
		});

		items.sort(((a, b) => {
			let ai = a.isAudio ? a.audio : a.video;
			let bi = b.isAudio ? b.audio : b.video;
			let aTime = ai && ai.createdAt
				? new Date(ai.createdAt).getTime()
				: 0;
			let bTime = bi && bi.createdAt
				? new Date(bi.createdAt).getTime()
				: 0;
			let result = bTime - aTime;
			if (result === 0) {
				result = String(bi?.id).localeCompare(String(ai?.id));
			}
			return result;
		}));
		setItems(items);
    }, [groups]);

	return (<Stack
		gap={2}
		sx={{
			height: 1,
			minHeight: '50vh',
			py: theme.spacing(2),
		}}
	>
		{!!groupId && <>
			<EditPlaylistItemHOC groupId={groupId} key={groupId} />
			<EditGroupHOC />
		</>}

		<Stack
			direction={'row'}
			gap={theme.spacing(2)}
		>
			<MyLibAddButtonsHOC />
		</Stack>

		{!items.length
			? <Stack
				sx={{
					justifyContent: 'center',
					alignItems: 'center',
					p: theme.spacing(2),
					height: 1,
					minHeight: '50vh',
				}}
			>
				<Text
					sx={{
						fontSize: theme.typography.h5,
						color: theme.palette.grey[500],
					}}
				>
					{isEmpty
						? MY_STUFF_NO_ITEMS
						: !!groupId
							? MY_STUFF_NO_ITEMS_GROUP
							: MY_STUFF_NO_ITEMS_LANG
					}
				</Text>
			</Stack>
			: <Stack
				className={`${!!groupId ? 'stack' : 'grid'}`}
				sx={{
					py: theme.spacing(2),
					mx: theme.spacing(-1),

					'&.grid': {
						position: 'relative',
						display: 'grid',
						gridTemplateColumns: `repeat(5, 1fr)`,
	
						'@media (min-width: 1600px)': {
							gridTemplateColumns: `repeat(7, 1fr)`,
						},
	
						'@media (max-width: 1000px)': {
							gridTemplateColumns: `repeat(3, 1fr)`,
						},
	
						'@media (max-width: 600px)': {
							gridTemplateColumns: `repeat(2, 1fr)`,
						},
					},
				}}
			>
				{items.map((item) => {
					if (item.isAudio && item.audio) {
						let audio = item.audio;
						return (
							<ContentLibVideo
								isActive={activeId === `audio-${audio.key}` && groupId === item.groupId}
								isAudio
								isBig={!!groupId}
								key={item.groupId + '-' + audio.id}
								title={new Date(audio.createdAt).toLocaleString()}
								thumbUrl={''}
								channel={''}
								onClick={() => onAudioClick(item.groupId, audio.id)}
								onDelete={() => onAudioDelete(item.groupId, audio.id)}
								onDownload={() => onAudioDownload(item.groupId, audio.id)}
							/>
						)
					} else if (item.video) {
						let video = item.video;
						return (
							<ContentLibVideo
								isActive={activeId === video.videoKey && groupId === item.groupId}
								isBig={!!groupId}
								key={item.groupId + '-' + video.id}
								title={video.info.title}
								thumbUrl={video.info.thumb}
								channel={video.info.author}
								onClick={() => onVideoClick(item.groupId, video.videoKey)}
								onDelete={() => onVideoDelete(item.groupId, video.videoKey)}
								onGetCopyLink={() => onVideoCopyLink(item.groupId, video.videoKey)}
							/>
						)
					}
				})}
			</Stack>
		}

		<AddNewVideoPopupHOC groupId={groupId} />
		<AddNewAudioPopupHOC groupId={groupId} />
	</Stack>);
}
