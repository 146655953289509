
export interface IPhraseListTranslatedFields {
  groupId: number;
  videoId: string;
  phraseList: number[];
  isPreview?: boolean;
}

export interface IPhraseListTranslatedEvents {
  onLoadPhrases: (count: number) => Promise<boolean>;
  onAppendPhrases: (offset: number, count: number) => Promise<boolean>;
  onGetPhraseTitle: (phraseId: number) => string;
}

export interface IPhraseListTranslatedProps extends
  IPhraseListTranslatedFields,
  IPhraseListTranslatedEvents
{}

export const phrasesPartLoadSize = 50;
