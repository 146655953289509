import {
  CURRENT_VIDEO_SET_CAPTIONS_FIX_STATUS,
  CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX,
  CURRENT_VIDEO_SET_ID,
  EVideoLangCaptionsFixStatus,
  ICurrentVideoState,
  SET_AVAILABLE_CAPTIONS,
  SET_SELECTED_NATIVE_CAPTIONS,
  SET_SELECTED_TARGET_CAPTIONS,
  TCurrentVideoActions
} from './types';
import { Reducer } from 'redux';

const initialState: ICurrentVideoState = {
  currentVideoId: '',
  currentAudioId: 0,
  availableCaptions: [],
  selectedNativeCaption: null,
  selectedTargetCaption: null,
  clickedCaptionIndex: -1,
  captionsFixStatus: EVideoLangCaptionsFixStatus.NOT_CHECK
};

export const currentVideoReducer: Reducer<ICurrentVideoState, TCurrentVideoActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CURRENT_VIDEO_SET_ID:
      return {
        ...state,
        currentVideoId: action.videoId,
        currentAudioId: action.audioId
      }
    case SET_AVAILABLE_CAPTIONS:
      return {
        ...state,
        availableCaptions: action.captions
      }
    case SET_SELECTED_TARGET_CAPTIONS:
      return {
        ...state,
        selectedTargetCaption: action.caption
      }
    case SET_SELECTED_NATIVE_CAPTIONS:
      return {
        ...state,
        selectedNativeCaption: action.caption
      }
    case CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX:
      return {
        ...state,
        clickedCaptionIndex: action.clickedCaptionIndex
      }
    case CURRENT_VIDEO_SET_CAPTIONS_FIX_STATUS:
      return {
        ...state,
        captionsFixStatus: action.captionsFixStatus
      }
    default:
      return state;
  }
};
