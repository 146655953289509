import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IMyLibVideosEvents, IMyLibVideosFields } from '../components/types';
import { MyLibVideosEvents } from './events';
import { MyLibVideos } from '../components/MyLibVideos';
import { getUserOwnGroups } from '../../../../../store/models/selectors';

const mapStateToProps = (
) => {
	return (
		state: IState
	): IMyLibVideosFields => {
		const groups = getUserOwnGroups(state);
		
		return {
			groups,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IMyLibVideosEvents => ({
	onClickVideo: (groupId, videoId) => dispatch(MyLibVideosEvents.onClickVideo(groupId, videoId)),
	onVideoCopyLink: (groupId, videoId) => dispatch(MyLibVideosEvents.onVideoCopyLink(groupId, videoId)),
    onVideoDelete: (groupId, videoId) => dispatch(MyLibVideosEvents.onVideoDelete(groupId, videoId)),
});

export const MyLibVideosHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(MyLibVideos);

MyLibVideosHOC.displayName = 'MyLibVideosHOC';
