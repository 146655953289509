import { AudioRest, IRestAudioCaption, IRestAudioLangDetectResult } from '../../common/rest/audio/audioRest';
import { setSelectedTargetCaptions } from '../store/current-video/actions';
import { getDispatch, getState } from '../store';
import { addVideoAction, setVideoCaptionsAction } from '../store/videos/actions';
import { ICaptionsItem } from '../types/common';
import { AudioPlayerManager } from './audioPlayer/audioPlayerManager';
import { IVideoItem } from '../store/videos/types';
import { getAudioById } from '../store/models/selectors';
import { Navigator } from './navigator';
import { addAudioInGroup, removeAudioFromGroupAction } from '../store/models/actions';
import { setAddAudioConfirmAction } from '../store/general/actions';
import { getAuthUserLangCode } from '../store/general/selectors';
import { AuthUserEffects } from './authUserEffects';

export class AudioEffects {

  public static async load(
    audioId: number,
    groupId: number
  ) {

    const audio = getAudioById(getState(), audioId);
    if (!audio || !audio.language) return;

    const dispatch = getDispatch();
    const code = audio.language.code;
    dispatch(setSelectedTargetCaptions({
      code,
      name: audio.language.name,
      isAsr: false,
      url: ''
    }));
    const audioRest = await AudioRest.fetch(audioId, groupId);

    const storeVideo: IVideoItem = {
      author: '', thumbnail: '', title: '',
      videoId: audioRest.key,
      phrases: audioRest && audioRest.phrases || [],
      phraseNotes: audioRest && audioRest.phraseNotes || [],
      captions: {},
      captionsEmpty: false
    };
    dispatch(addVideoAction(storeVideo));

    const captions = AudioEffects.prepareCaptions(await AudioRest.getCaptions(audioId));
    dispatch(setVideoCaptionsAction(audio.language.code, captions));
    AudioPlayerManager.getInstance().setCaptions(captions)

    if (code) {
      AuthUserEffects.setLang(code);
    }
  }

  private static prepareCaptions(list: IRestAudioCaption[]): ICaptionsItem[] {
    return list.map(c => {
      const text: string = c.captionText || c.words.map(w => w.text).join('');
      return {
        startTime: c.startTime,
        endTime: c.endTime,
        duration: c.endTime - c.startTime,
        words: c.words,
        text
      }
    })
  }

  public static async add(
    text: string,
    langCode?: string,
    groupId?: number,
  ) {
    const dispatch = getDispatch();
    if (!groupId) {
      dispatch(setAddAudioConfirmAction({
        show: true,
        langCode,
        text,
      }));
      return;
    }
    try {
      const _text = this.prepareAudioText(text);
      const audio = await AudioRest.addAudio(_text, groupId, langCode);
      dispatch(addAudioInGroup(audio, groupId));
      Navigator.openAudio(groupId, audio.id);
      return audio;
    } catch (e) {
      console.error(e);
    }
  }

  public static async remove(audioId: number, groupId: number) {
    const dispatch = getDispatch();
    try {
      await AudioRest.removeAudio(audioId, groupId)
      dispatch(removeAudioFromGroupAction(audioId, groupId))
    } catch (e) {
      console.error(e);
    }
  }

  public static async detectLang(text: string): Promise<IRestAudioLangDetectResult> {
    return AudioRest.detectLang(text)
  }

  private static prepareAudioText(text: string): string {
    return text.replaceAll(' -', '')
      .replaceAll("\n", "\n\n")
  }
}

