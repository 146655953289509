import { IState } from '../../../../../store/types';
import { getLibraryGroup, getUserGroupLangKnown, getUserGroupLangToLearn, getUserGroups, getUserGroupsWithCheckedLangs } from '../../../../../store/models/selectors';
import { connect } from 'react-redux';
import { VideoLibraryHeader } from '../components/VideoLibraryHeader';
import { IVideoLibraryHeaderEvents, IVideoLibraryHeaderFields } from '../components/types';
import { getLibraryGroupId } from '../../../../../store/general/selectors';
import { VideoLibraryHeaderEvents } from './events';
import { LangUtil } from '../../../../../../common/utils/lang-util';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IVideoLibraryHeaderFields => {
    const group = getLibraryGroup(state);
    let nativeLang;
    let targetLang;
    if (group) {
      nativeLang = getUserGroupLangKnown(state, group);
      targetLang = getUserGroupLangToLearn(state, group);
    }
    // const libraryGroupId = getLibraryGroupId(state) || 0;
    // const groups = getUserGroupsWithCheckedLangs(state);
    return {
      group,
      // groups,
      // libraryGroupId,
      nativeLangCode: LangUtil.checkLangCode(nativeLang?.code),
      targetLangCode: LangUtil.checkLangCode(targetLang?.code),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IVideoLibraryHeaderEvents => ({
    onGroupChange: (groupId: number) => dispatch(VideoLibraryHeaderEvents.onGroupChange(groupId)),
    onEditPlayList: () => dispatch(VideoLibraryHeaderEvents.onEditPlayList()),
    onCopyLink: () => dispatch(VideoLibraryHeaderEvents.onCopyLink()),
    // onClickAdd: () => dispatch(VideoLibraryHeaderEvents.onClickAdd()),
    onClickMyLibrary: () => dispatch(VideoLibraryHeaderEvents.onClickMyLibrary()),
  }
);

export const VideoLibraryHeaderHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoLibraryHeader);

VideoLibraryHeaderHOC.displayName = 'VideoLibraryHeaderHOC';