import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { OneTimeTooltipSelectArrow } from '../../components/OneTimeTooltipSelectArrow/OneTimeTooltipSelectArrow';
import { IOneTimeTooltipSelectArrowEvents, IOneTimeTooltipSelectArrowFields } from '../../components/OneTimeTooltipSelectArrow/types';
import { PhraseDetailsPanelSelectors } from '../../../PhraseDetails/selectors';
import { getVideoTeacherUserActiveId } from '../../../../../store/videos/selectors';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { getMainLayoutPhrasesColumn, getMainLayoutPhrasesColumnTab } from '../../../../../store/general/selectors';
import { EPhrasesColumnTab, EPhrasesColumnType } from '../../../../../store/general/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipSelectArrowFields => {
		const phrasesColumn = getMainLayoutPhrasesColumn(state);
		const phrasesColumnTab = getMainLayoutPhrasesColumnTab(state);
		let show = phrasesColumn === EPhrasesColumnType.COLUMNS_2 ||
			phrasesColumn === EPhrasesColumnType.COLUMNS_1 &&
			phrasesColumnTab === EPhrasesColumnTab.TRANSLATE;
		const text = PhraseDetailsPanelSelectors.getText(state);
		if (text) {
			show = false;
		} else {
			const teacherId = getVideoTeacherUserActiveId(state);
			if (teacherId > 0) {
				show = false;
			} else {
				const activeTab = PhraseDetailsSelectors.getActiveTab(state);
				if (activeTab && activeTab.type === EPhraseDetailsTabType.NOTES) {
					show = false;
				}
			}
		}
		
		return {
			show,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipSelectArrowEvents => ({
});

export const OneTimeTooltipSelectArrowHOC = connect(
	mapStateToProps,
	null,
)(OneTimeTooltipSelectArrow);

OneTimeTooltipSelectArrowHOC.displayName = 'OneTimeTooltipSelectArrowHOC';
