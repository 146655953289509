import { AppThunk } from '../../../../store/types';
import { setMainViewMode, setSnackbarPanelAction } from '../../../../store/general/actions';
import { CaptionsSelectionPopupActions } from '../../../../store/captions-selection-popup/actions';
import { IPhraseSelectResult } from '../../../../effects/phrase/utils/phrase-select-preparator';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';
import { SelectionUtil } from '../../../../../common/utils/selection-util';
import { getSelectedTargetCaption } from '../../../../store/current-video/selectors';
import { PhraseDetailsEffects } from '../../../../effects/phrase-details/phrase-details-effects';
import { SelectionPopupEffects } from '../../../../effects/selectionPopupEffects';
import { PlayerController } from '../../../../effects/player/manager/playerController';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';
import {
  getActiveGroupTargetLanguage,
  getActiveUserGroup,
  getPhraseDetailsTabByType,
  getTargetLanguage
} from '../../../../store/models/selectors';
import { textSpeaker } from '../../../../effects/textSpeaker';
import { LangUtil } from '../../../../../common/utils/lang-util';
import {
  ECaptionsSelectionPopupActionButton,
} from '../../../../store/captions-selection-popup/types';
import { TextSelectionPopupUtils } from './utils';
import { getAuthUser, getMainViewMode } from '../../../../store/general/selectors';
import { EMainViewMode, EUserGroupType } from '../../../../store/general/types';
import { historyPush } from '../../../../../common/utils/historyHelper';
import { UserTranslateEffects } from '../../../../effects/userTranslateEffects';
import { findVideoPhraseById } from '../../../../store/videos/selectors';
import { PhraseDetailsActions } from '../../../../store/phrase-details/actions';
import { EPhraseDetailsTabType } from '../../../../store/models/types';
import { PhraseContextEditorSelectors } from '../../../../store/phrase-context-editor/selectors';
import { SaveVideoConfirmManager } from '../../../../effects/saveVideoConfirmManager';
import { EVocabularyPhraseSaveType } from '../../../../types/common';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';

export class TextSelectionPopupEvents {

 
  public static onPreviewPhrase(selectResult: IPhraseSelectResult, translate?: boolean): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
      const phraseId: number = dispatch(PhraseEffects.savePreviewPhrase(selectResult, translate));
      dispatch(CaptionsSelectionPopupActions.setPreviewPhraseId(phraseId));
      const phrase = findVideoPhraseById(getState(), phraseId);
      if (phrase) {
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({contextPhraseId: phraseId, wordPhraseId: phrase.wordPhraseId}));
      }
      SelectionUtil.removeWindowSelection();
    }
  }

  public static onSelectText(selectResult: IPhraseSelectResult | null): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(CaptionsSelectionPopupActions.setLastSelectText(selectResult ? selectResult.text : undefined));
      if (selectResult) {
        UserTranslateEffects.save(selectResult);
      }
    }
  }

  public static onSavePhrase(selectResult: IPhraseSelectResult): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const group = getActiveUserGroup(state);
      if (group?.type === EUserGroupType.PREVIEW) {
        SaveVideoConfirmManager.saveToMyStuff();
        return;
      }

      if (getAuthUser(state)?.teacherMode) {
        await PhraseEffects.runSavePhrase(selectResult);
      } else {
        const {wordPhraseId, contextPhraseId} = CaptionsSelectionPopupSelectors.getCurrentSelection(state);
        let wordPhrase = !!wordPhraseId && wordPhraseId > 0 && findVideoPhraseById(state, wordPhraseId);
        let contextPhrase = !!contextPhraseId && contextPhraseId > 0 && findVideoPhraseById(state, contextPhraseId);
        
        if (!wordPhrase && !contextPhrase) {
          const lastSelectResult = CaptionsSelectionPopupSelectors.getLastSelectResult(state);
          const lastText = lastSelectResult?.text;
          wordPhrase = lastSelectResult?.insideWordPhrases?.find(i => i.highlighted === lastText);
        }
        
        if (wordPhrase && wordPhrase.saveType === EVocabularyPhraseSaveType.TRANSLATE) {
          const contextPhrase = PhraseListSelectors.findPhraseByWordId(state, wordPhrase.id);
          PhraseEffects.saveTranslatedPhrases(wordPhrase, contextPhrase as any);
          PhraseEffects.flashTab();
        }
      }
    }
  }

  public static onCheckCanSelect(selectResult: IPhraseSelectResult | null | undefined): AppThunk {
    return (
      dispatch,
      getState
    ): boolean => {
      if (!selectResult) return false;

     /* const insidePhrasesSel = selectResult.insideWordPhrases && selectResult.insideWordPhrases.length;
      if (!insidePhrasesSel) {
        const videoId = getCurrentVideoId(getState());
        const phrase =
          PhraseEffects.findInterceptPhrase(videoId, [EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED],
            selectResult.startCaptionIndex, selectResult.startPosition, selectResult.endCaptionIndex, selectResult.endPosition);
        if (phrase) {
          return false;
        }
      }*/
      return true;
    }
  }

  public static onDismiss(unboundClick: boolean = false): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText(unboundClick);
    }
  }

  public static onMouseEnter(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().mouseEnter();
    }
  }

  public static onMouseLeave(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().mouseLeave();
    }
  }

  public static onSpeechText(stopOnPlay: boolean): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const state = getState();
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(state);
      const targetLanguage = getSelectedTargetCaption(state) || getTargetLanguage(state);
      const lang = LangUtil.checkLangCode(targetLanguage?.code || '');
      if (selectResult?.text) {
        const text = selectResult?.text; //TextSelectionPopupUtils.getSpeechTextFromSelect(selectResult);
        if (text) {
          const priorityLang = getActiveGroupTargetLanguage(getState())?.code;
          textSpeaker.speak(text, lang, stopOnPlay, null, priorityLang);
        }
      }
    }
  }

  public static onPlay(): AppThunk { // play from selected phrase
    return (
      dispatch,
      getState
    ) => {
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (selectResult && selectResult.startTime) {
        PlayerController.getInstance().play(selectResult.startTime);
      }
    }
  }

  public static onUpdateActionButton(actionButton: ECaptionsSelectionPopupActionButton | null): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(CaptionsSelectionPopupActions.setActionButton(actionButton));
    }
  }

  public static onReplay(): AppThunk { // play selected phrase and stop
    return (
      dispatch,
      getState
    ) => {
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (selectResult) {
        PlayerController.getInstance().playSelectText(selectResult);
      }
    }
  }

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      textSpeaker.stop();
    }
  }

  public static onStartPlay(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText();
    }
  }

  public static onCopyText(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
      if (selectResult?.text) {
        await navigator.clipboard.writeText(selectResult?.text);
        dispatch(setSnackbarPanelAction(true, 'Text copied to clipboard'));
      }
    }
  }

  public static onOpenPrompts(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const mainViewModePrev = getMainViewMode(getState());
      dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS));
      dispatch(PhraseDetailsActions.setActiveTab(PhraseDetailsEffects.getDefaultTab() as any));

      historyPush({
        state: { page: 'Prompts' },
        title: 'Prompts',
        onpopstate: () => dispatch(setMainViewMode(mainViewModePrev))
      });
    }
  }

  public static onSaveNote(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const mainViewModePrev = getMainViewMode(getState());
      dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS));
      const noteTab = getPhraseDetailsTabByType(getState(), EPhraseDetailsTabType.NOTES);
      if (noteTab) {
        dispatch(PhraseDetailsActions.setActiveTab({
          ...noteTab,
          isNoteEdit: true,
        }));
        dispatch(PhraseDetailsActions.setNotePreviewMode(true));
      }

      historyPush({
        state: { page: 'Notes' },
        title: 'Notes',
        onpopstate: () => dispatch(setMainViewMode(mainViewModePrev))
      });
    }
  }

  public static onEscape(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText();
    }
  }

}
