import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { setAuthUserAction, setMainViewMode, setPublicPageAction } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { AuthManager } from '../../../../../../common/utils/auth-manager';
import { updateUserLanguages } from '../../../../../effects/updateUserLanguages';
import { updateUserGroups } from '../../../../../effects/updateUserGroups';

export class ContentLibraryDashboardEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setPublicPageAction(true));
      const result = await AuthManager.autoLogin();
      if (result && result.authUser) {
        dispatch(setAuthUserAction(result.authUser));
      }
      dispatch(updateUserLanguages());
      dispatch(updateUserGroups(false));
      await ContentLibEffects.loadLangs();
    }
  }

  public static onUnLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setPublicPageAction(false));
      dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
    }
  }


}