import { AppThunk } from '../../store/types';
import { IPhraseSelectResult } from './utils/phrase-select-preparator';
import {
  EVocabularyPhraseSaveType,
  EVocabularyPhraseType,
  ICaptionsItem,
  IVocabularyPhrase,
  TNewVocabularyPhrase
} from '../../types/common';
import { addPhraseToVocabularyEffect, updatePhraseEffect } from './phraseVocabularyEffect';
import {
  getCurrentAudioId,
  getCurrentMovieKey,
  getSelectedNativeCaption,
  getSelectedTargetCaption,
  isAudioMovieKey
} from '../../store/current-video/selectors';
import {
  findLanguageById,
  getActiveGroupId, getActiveGroupNativeLanguage,
  getActiveGroupTargetLanguage,
  getActiveUserGroup,
  getPhraseDetailsTabById
} from '../../store/models/selectors';
import {
  findVideoPhraseById,
  findVideoPhraseByWordId,
  getNativeCaptions,
  getTargetCaptions,
  getVideoPhrases,
  getVideoTeacherNoteByPhraseId,
  getVideoTeacherPhraseById,
  getVideoTeacherPhraseByWordId,
  getVideoTeacherPhrases,
  getVideoTeacherShowNote,
  getVideoTeacherUserInfo,
  isExistPhrasesFromTeacherPhrase
} from '../../store/videos/selectors';
import { getDispatch, getState } from '../../store';
import { CaptionsSelectionPopupSelectors } from '../../store/captions-selection-popup/selectors';
import { CaptionsSelectionPopupActions } from '../../store/captions-selection-popup/actions';
import {
  EFindPhrasesOrder,
  fetchAllVideoPhrases,
  TFindPhraseBody
} from '../../../common/rest/video/fetchAllVideoPhrases';
import { PhraseListSelectors } from '../../store/phrase-list/selectors';
import { EPhraseListMode } from '../../store/phrase-list/types';
import { PhraseListActions } from '../../store/phrase-list/actions';
import { deleteVideoPhraseAction, setPhrasesNotesListsAction, updateVideoPhraseAction } from '../../store/videos/actions';
import { PhraseDetailsTranslateManager } from '../phrase-details/translate/phraseDetailsTranslateManager';
import { PhraseContextEditorActions } from '../../store/phrase-context-editor/actions';
import { PhraseContextEditorSelectors } from '../../store/phrase-context-editor/selectors';
import { PhraseDetailsActions } from '../../store/phrase-details/actions';
import { PhraseDetailsSelectors } from '../../store/phrase-details/selectors';
import { PhraseDetailsTabCustomPromptFormEvents } from '../../components/dashboard/PhraseDetailsTabsEditor/PhraseDetailsTabPromptForm/hocs/custom/events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabEffects } from '../phraseDetailsTabEffects';
import { PhraseNoteEffects } from '../phraseNoteEffects';
import { getAuthUser } from '../../store/general/selectors';
import { fetchPhrasesNotesLists } from '../../../common/rest/video/fetchPhrasesNotesLists';
import { PhraseNoteTimeScaleEffects } from './phraseNoteTimeScaleEffects';
import { phrasesPartLoadSize } from '../../components/dashboard/PhraseList/components/types';
import { TeachersEffects } from '../teachersEffects';
import { StatLogManager } from '../../../common/stats/statLogManager';
import { EventsRouter } from '../../../common/events/eventsRouter';
import { Events } from '../../../common/events/types';
import { VocabularyEffects } from './vocabulary-effects';
import { PhraseDetailsEffects } from '../phrase-details/phrase-details-effects';
import {
  setPhrasesExistAction,
  setShowTelegramBotPopupAction,
  setSnackbarPanelAction
} from '../../store/general/actions';
import { PlayerController } from '../player/manager/playerController';
import { getGeneralStorage } from '../../../common/utils/local-storage/local-storage-helpers';
import { CLASS_TABS_SAVED, CLASS_TABS_TRANSLATED } from '../../components/dashboard/Video/components/constants';
import { TApiResponse } from '../phrase-details/phraseDetailsService/phrase-details-service';
import { PhraseTranslateRest } from '../../../common/rest/phraseTranslate/phraseTranslateRest';
import { LangUtil } from '../../../common/utils/lang-util';
import { batch } from 'react-redux';

export class PhraseEffects {

  public static async runSavePhrase(
    selectResult:  IPhraseSelectResult,
  ) {
    const dispatch = getDispatch();
    EventsRouter.trackEvent(Events.SAVE_PHRASE_BUTTON_CLICKED);

    const isContext = PhraseContextEditorSelectors.getIsContext(getState());

    let phrase: IVocabularyPhrase | null;

    if (PhraseContextEditorSelectors.getPhraseContext(getState())) {
      phrase = await PhraseEffects.saveFromContextEditor();
    } else {
      phrase = await PhraseEffects.saveFromSelectResult(selectResult, true, isContext, EVocabularyPhraseSaveType.PERSIST);
    }
    dispatch(PhraseEffects.deleteCurrentPreviewPhrase());

    if (phrase) {
      PhraseDetailsEffects.saveForPhrase(phrase.id);
      dispatch(setPhrasesExistAction(true));
      const contextPhrase = findVideoPhraseByWordId(getState(), phrase.id);
      if (contextPhrase) {
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({wordPhraseId: phrase.id, contextPhraseId: contextPhrase.id}));
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(contextPhrase.id));
      }
      dispatch(VocabularyEffects.flashPhrase(phrase.id));
      EventsRouter.trackEvent(Events.SAVE_PHRASE);
      const langCode = getActiveGroupTargetLanguage(getState())?.code || '';
      StatLogManager.logPhraseSave(langCode);
    }
    PlayerController.getInstance().onDeselectText();
    dispatch(CaptionsSelectionPopupActions.updateCaptionsSelectionPopupAction({
      selectResult: null as any,
      show: false,
    }));

    if (!getGeneralStorage().getData().showTelegramBotPopup) {
      dispatch(setShowTelegramBotPopupAction(true));
      getGeneralStorage().setShowTelegramBotPopup(true);
    }
    this.flashTab();
  }

  public static async runSavePreviewPhrase(selectResult: IPhraseSelectResult) {

    const copyPhrase = async (srcPhrase: IVocabularyPhrase, destPhrase: IVocabularyPhrase) => {
      await dispatch(updatePhraseEffect({
        ...srcPhrase,
        ...{
          highlighted: destPhrase.highlighted,
          fullPhrase: destPhrase.fullPhrase,
          startCaptionIndex: destPhrase.startCaptionIndex,
          endCaptionIndex: destPhrase.endCaptionIndex,
          startPosition: destPhrase.startPosition,
          endPosition: destPhrase.endPosition,
          startTime: destPhrase.startTime,
          endTime: destPhrase.endTime
        }
      }));
    }

    const dispatch = getDispatch();
    const state = getState();

    let selectedPhraseId = selectResult.insideWordActiveId;
    if (!selectedPhraseId && selectResult.insideWordPhrases?.length) {
      selectedPhraseId = selectResult.insideWordPhrases.find(p => p.saveType === EVocabularyPhraseSaveType.TRANSLATE)?.id;
    }

    const contextPreviewPhrase = PhraseContextEditorSelectors.getPhraseContext(state);
    const wordPreviewPhrase = findVideoPhraseById(state, contextPreviewPhrase?.wordPhraseId);

    if (!contextPreviewPhrase || !wordPreviewPhrase) return;

    let phrase;
    let contextPhrase;
    let createNew = false;
    if (selectedPhraseId) {
      const selectedPhrase = findVideoPhraseById(state, selectedPhraseId);
      const selectedContextPhrase = findVideoPhraseByWordId(state, selectedPhrase?.id);
      if (selectedPhrase && selectedContextPhrase) {
        if (selectedPhrase.saveType === EVocabularyPhraseSaveType.TRANSLATE) {
          await Promise.all([
            copyPhrase(selectedPhrase, wordPreviewPhrase),
            copyPhrase(selectedContextPhrase, contextPreviewPhrase)
          ]);
        } else {
          createNew = true;
        }
      }
    } else {
      createNew = true;
    }

    if (createNew) {
      phrase = await PhraseEffects.clonePhrase(wordPreviewPhrase, {
        type: EVocabularyPhraseType.WORD_SELECTED,
        saveType: EVocabularyPhraseSaveType.TRANSLATE
      })
      contextPhrase = await PhraseEffects.clonePhrase(contextPreviewPhrase, {
        type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
        saveType: EVocabularyPhraseSaveType.TRANSLATE,
        wordPhraseId: phrase.id
      })
      this.flashTab(phrase.saveType);
    }

    dispatch(PhraseEffects.deleteCurrentPreviewPhrase());

    if (phrase) {
      PhraseDetailsEffects.saveForPhrase(phrase.id);
      dispatch(setPhrasesExistAction(true));
      if (contextPhrase) {
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({wordPhraseId: phrase.id, contextPhraseId: contextPhrase.id}));
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(contextPhrase.id));
      }
      dispatch(VocabularyEffects.flashPhrase(phrase.id));

      const langCode = getActiveGroupTargetLanguage(getState())?.code || '';
      StatLogManager.logPhraseSave(langCode);
    }
    PlayerController.getInstance().onDeselectText();

  }

  public static flashTab(
    saveType: EVocabularyPhraseSaveType = EVocabularyPhraseSaveType.PERSIST,
  ) {
    let selector = saveType === EVocabularyPhraseSaveType.TRANSLATE
      ? CLASS_TABS_TRANSLATED
      : CLASS_TABS_SAVED;
    const tabsSaved = document.querySelector(`.${selector}`);
    if (tabsSaved) {
      tabsSaved.classList.add('flash');
      setTimeout(() => {
        tabsSaved.classList.remove('flash');
      }, 3000);
    }
  }


  public static async runSaveTranslatePhrase(
    selectResult:  IPhraseSelectResult,
  ) {
    const dispatch = getDispatch();

    const contextPreviewPhrase = PhraseContextEditorSelectors.getPhraseContext(getState());
    const wordPreviewPhrase = findVideoPhraseById(getState(), contextPreviewPhrase?.wordPhraseId);

    const phrase = await PhraseEffects.clonePhrase(wordPreviewPhrase, {
      type: EVocabularyPhraseType.WORD_SELECTED,
      saveType: EVocabularyPhraseSaveType.TRANSLATE
    })
    const contextPhrase = await PhraseEffects.clonePhrase(contextPreviewPhrase, {
      type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
      saveType: EVocabularyPhraseSaveType.TRANSLATE,
      wordPhraseId: phrase.id
    })
    /*
      const isContext = PhraseContextEditorSelectors.getIsContext(getState());

      let phrase: IVocabularyPhrase | null;
      if (PhraseContextEditorSelectors.getPhraseContext(getState())) {
        phrase = await PhraseEffects.saveFromContextEditor();
      } else {
        phrase = await PhraseEffects.saveFromSelectResult(selectResult, true, isContext, EVocabularyPhraseSaveType.TRANSLATE);
      }*/

    dispatch(PhraseEffects.deleteCurrentPreviewPhrase());

    if (phrase) {
      PhraseDetailsEffects.saveForPhrase(phrase.id);
      dispatch(setPhrasesExistAction(true));
      //   const contextPhrase = findVideoPhraseByWordId(getState(), phrase.id);
      if (contextPhrase) {
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({wordPhraseId: phrase.id, contextPhraseId: contextPhrase.id}));
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(contextPhrase.id));
      }
      dispatch(VocabularyEffects.flashPhrase(phrase.id));
      //  EventsRouter.trackEvent(Events.SAVE_PHRASE);
      const langCode = getActiveGroupTargetLanguage(getState())?.code || '';
      StatLogManager.logPhraseSave(langCode);
    }
    PlayerController.getInstance().onDeselectText();
    this.flashTab(EVocabularyPhraseSaveType.TRANSLATE);
  }

  public static async saveFromSelectResult(
    selectResult: IPhraseSelectResult,
    createTranslateNote: boolean,
    useContextPhrase: boolean,
    saveType: EVocabularyPhraseSaveType
  ): Promise<IVocabularyPhrase | null> {
    const state = getState();
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    if (videoKey && userGroupId) {
      const translate = await this.getTranslateForSelectResult(selectResult, useContextPhrase);
 //     const translate = PhraseEffects.getNativeTextBySelectResult(selectResult);
      const wordPhrase = await PhraseEffects.saveWordPhraseFromSelect(selectResult, videoKey, userGroupId, saveType);
      if (wordPhrase) {
        if (useContextPhrase) {
          await PhraseEffects.saveContextPhraseFromContextEditor(wordPhrase, translate);
        } else {
          await PhraseEffects.clonePhrase(wordPhrase, {
            type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
            wordPhraseId: wordPhrase.id,
            fullPhrase: wordPhrase.highlighted
          })
        }

        if (translate && createTranslateNote) {
          await PhraseNoteEffects.save(wordPhrase.id, false, translate);
        }
      }
      return wordPhrase;
    }
    return null;
  }


  public static async saveFromContextEditor(): Promise<IVocabularyPhrase | null> {
    const state = getState();
    const contextPreviewPhrase = PhraseContextEditorSelectors.getPhraseContext(state);
    const wordPreviewPhrase = findVideoPhraseById(state, contextPreviewPhrase?.wordPhraseId);
    if (!contextPreviewPhrase || !wordPreviewPhrase)
      return null;
    const wordPhrase = await PhraseEffects.clonePhrase(wordPreviewPhrase, {
      type: EVocabularyPhraseType.WORD_SELECTED,
      wordPhraseId: 0
    });

        if (!PhraseContextEditorSelectors.getIsContext(state)) {
          contextPreviewPhrase.fullPhrase = contextPreviewPhrase.highlighted;
        }

      await PhraseEffects.clonePhrase(contextPreviewPhrase, {
        type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
        wordPhraseId: wordPhrase.id
      });


    return wordPhrase;
  }

  public static async saveFromCaption(
    caption: ICaptionsItem,
    captionIndex: number
  ): Promise<IVocabularyPhrase | null> {
    const state = getState();
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    if (videoKey && userGroupId) {
      const wordPhrase = await PhraseEffects.savePhraseFromCaption(caption, captionIndex, videoKey, userGroupId, 0, EVocabularyPhraseType.WORD_SELECTED);
      if (wordPhrase) {
        await PhraseEffects.savePhraseFromCaption(caption, captionIndex, videoKey, userGroupId, wordPhrase.id, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);
      }
      return wordPhrase;
    }
    return null;
  }

  public static async saveFromTeacherPhrase(teacherId: number, phraseId: number): Promise<IVocabularyPhrase | null>  {
    const state = getState();
    const dispatch = getDispatch();
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    const teacherWordPhrase = getVideoTeacherPhraseById(state, teacherId, phraseId);
    const teacherContextPhrase = getVideoTeacherPhraseByWordId(state, teacherId, teacherWordPhrase?.id);
    if (videoKey && userGroupId && teacherWordPhrase && teacherContextPhrase) {

      let phraseData: TNewVocabularyPhrase = {
        ...teacherWordPhrase,
        ...{
          id: undefined,
          videoKey,
          userGroupId,
          srcTeacherPhraseId: teacherWordPhrase.id
        }
      };
      const wordPhrase = await dispatch(addPhraseToVocabularyEffect(phraseData));

      phraseData = {
        ...teacherContextPhrase,
        ...{
          id: undefined,
          videoKey,
          userGroupId,
          wordPhraseId: wordPhrase.id,
          srcTeacherPhraseId: teacherContextPhrase.id
        }
      };
      await dispatch(addPhraseToVocabularyEffect(phraseData));
      return wordPhrase;
    }
  }

  private static async getTranslateForSelectResult(selectResult: IPhraseSelectResult, useContext: boolean): Promise<string> {
    const text = useContext
      ? this.getTextFromSelect(selectResult)
      : selectResult.text;
   //   return this.getNativeTextBySelectResult(selectResult);

    const targetLang = getActiveGroupTargetLanguage(getState());
    const nativeLang = getActiveGroupNativeLanguage(getState());
    if (targetLang && nativeLang) {
      const resp: TApiResponse = await PhraseTranslateRest.translateApi({
        text,
        fromLang: LangUtil.checkLangCode(targetLang.code),
        toLang: LangUtil.checkLangCode(nativeLang.code),
        hash: ''
      });
      return resp.result
    }
    return '';
  }

  /*private static getNativeTextBySelectResult(selectResult: IPhraseSelectResult): string {
    const state = getState();

    const targetCaptions: ICaptionsItem[] = getTargetCaptions(state);
    const nativeCaptions: ICaptionsItem[] = getNativeCaptions(state);
    if (!nativeCaptions || nativeCaptions.length < 1) return '';

    const targetCaptionsMiddleTimes: number[] = [];
    for (let i = selectResult.startCaptionIndex; i <= selectResult.endCaptionIndex; i++) {
      const targetCaption = targetCaptions[i];
      targetCaptionsMiddleTimes.push(targetCaption.startTime + ((targetCaption.endTime - targetCaption.startTime) / 2));
    }

    const textList = nativeCaptions.filter(c => {
      return targetCaptionsMiddleTimes.some(targetMiddleTime => {
        return c.startTime < targetMiddleTime && c.endTime > targetMiddleTime;
      })
    }).map(c => c.text);

    return textList.join(' ');
  }*/

  private static async savePhraseFromCaption(
    caption: ICaptionsItem,
    captionIndex: number,
    videoKey: string,
    userGroupId: number,
    wordPhraseId: number,
    type: EVocabularyPhraseType
  ) {
    const dispatch = getDispatch();
    const phrase: TNewVocabularyPhrase = {
      highlighted: caption.text,
      fullPhrase: caption.text,
      translated: '',
      startTime: caption.startTime,
      endTime: caption.endTime,
      type,
      startPosition: 0,
      endPosition: caption.text.length,
      startCaptionIndex: captionIndex,
      endCaptionIndex: captionIndex,
      wordPhraseId,
      videoKey,
      userGroupId
    };
    return dispatch(addPhraseToVocabularyEffect(phrase));
  }

  private static async saveWordPhraseFromSelect(selectResult: IPhraseSelectResult, videoKey: string, userGroupId: number, saveType: EVocabularyPhraseSaveType): Promise<IVocabularyPhrase | null> {
    const dispatch = getDispatch();
    const phrase: TNewVocabularyPhrase = {
      highlighted: selectResult.text,
      fullPhrase: '',
      translated: '',
      startTime: selectResult.startTime,
      endTime: selectResult.endTime,
      type: EVocabularyPhraseType.WORD_SELECTED,
      startPosition: selectResult.startPosition,
      endPosition: selectResult.endPosition,
      startCaptionIndex: selectResult.startCaptionIndex,
      endCaptionIndex: selectResult.endCaptionIndex,
      wordPhraseId: 0,
      saveType,
      videoKey,
      userGroupId
    };
    return dispatch(addPhraseToVocabularyEffect(phrase));
  }

  private static async clonePhrase(phrase: IVocabularyPhrase, data: Partial<IVocabularyPhrase>): Promise<IVocabularyPhrase> {
    const newPhrase: TNewVocabularyPhrase = {
      ...phrase,
      ...{
        id: undefined,
      },
      ...data
    }
    const dispatch = getDispatch();
    return dispatch(addPhraseToVocabularyEffect(newPhrase));
  }

  private static getTextFromSelect(selectResult: IPhraseSelectResult) {
    const targetCaption: ICaptionsItem[] = getTargetCaptions(getState());
    const captions: ICaptionsItem[] = [];
    for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
      if (i < targetCaption.length) {
        captions.push(targetCaption[i]);
      }
    }
    return captions.map(c => c.text).join('\n');
  }

  private static async saveContextPhraseFromContextEditor(
    wordPhrase: IVocabularyPhrase,
    translated: string
  ): Promise<IVocabularyPhrase | null> {
    const state = getState();
    const dispatch = getDispatch();

    const {
      id,
      ...context
    } = PhraseContextEditorSelectors.getPhraseContext(state);

    const phrase: TNewVocabularyPhrase = {
          ...context,
          translated,
          type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
          wordPhraseId: wordPhrase.id,
        };
    return await dispatch(addPhraseToVocabularyEffect(phrase));
  }

  public static deleteContext(phraseId: number) {
    const state = getState();
    const dispatch = getDispatch();
    const contextPhrase = findVideoPhraseById(state, phraseId);
    const wordPhrase = findVideoPhraseById(state, contextPhrase?.wordPhraseId);
    if (contextPhrase && wordPhrase) {
      contextPhrase.fullPhrase = wordPhrase.highlighted;
      contextPhrase.startCaptionIndex = wordPhrase.startCaptionIndex;
      contextPhrase.endCaptionIndex = wordPhrase.endCaptionIndex;
      contextPhrase.startPosition = wordPhrase.startPosition;
      contextPhrase.endPosition = wordPhrase.endPosition;
      dispatch(updatePhraseEffect(contextPhrase));
    }
  }


  public static modifyContext( // on saved phrase
    selectResult: IPhraseSelectResult,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IVocabularyPhrase | null | undefined> => {
      const state = getState();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);
      if (selectResult.insideWordPhrases && selectResult.insideWordPhrases.length && videoKey && userGroupId) {
        const wordPhrase = selectResult.insideWordPhrases[0];
        if (wordPhrase) {
          const contextPhrase = findVideoPhraseByWordId(state, wordPhrase.id);
          if (contextPhrase) {
            const translated = await PhraseEffects.getTranslateForSaveContext(selectResult);
            contextPhrase.fullPhrase = selectResult.text;
            contextPhrase.startTime = selectResult.startTime;
            contextPhrase.endTime = selectResult.endTime;
            contextPhrase.startPosition = selectResult.startPosition;
            contextPhrase.endPosition = selectResult.endPosition;
            contextPhrase.startCaptionIndex = selectResult.startCaptionIndex;
            contextPhrase.endCaptionIndex = selectResult.endCaptionIndex;
            contextPhrase.translated = translated;
            contextPhrase.contextModified = true;
            dispatch(updatePhraseEffect(contextPhrase));
          }
          return contextPhrase;
        }
      }
    }
  }

  private static async getTranslateForSaveContext(selectResult: IPhraseSelectResult): Promise<string> {
    const state = getState();
    const targetLangCode = getSelectedTargetCaption(state)?.code;
    const nativeLangCode = getSelectedNativeCaption(state)?.code;
    if (targetLangCode && nativeLangCode) {
      return await PhraseDetailsTranslateManager.translate(
        {code: targetLangCode, name: ''},
        {code: nativeLangCode, name: ''},
        selectResult.text) || '';
    }
    return '';


  }

  private static generatePhraseId(): number {
    let id = -1;
    PhraseListSelectors.getList(getState()).forEach(p => {
      if (p.id < id)
        id = p.id;
    })
    return id;
  }

  public static updatePreviewContextPhrase(
    selectResult: IPhraseSelectResult,
    previewContextPhrase: IVocabularyPhrase,
    previewWordPhrase: IVocabularyPhrase,
    translate?: boolean): number {
    const dispatch = getDispatch();

    const phraseId = PhraseEffects.generatePhraseId();
    const contextPhraseId = phraseId - 1;

    const wordPhrase: IVocabularyPhrase = {
      ...previewWordPhrase,
      ...{
        id: phraseId,
        translate
      }
    };
    const contextPhrase: IVocabularyPhrase = {
      ...previewContextPhrase,
      ...{
        id: contextPhraseId,
        fullPhrase: selectResult.text,
        startCaptionIndex: selectResult.startCaptionIndex,
        endCaptionIndex: selectResult.endCaptionIndex,
        startPosition: selectResult.startPosition,
        endPosition: selectResult.endPosition,
        startTime: selectResult.startTime,
        endTime: selectResult.endTime,
        wordPhraseId: phraseId,
        selected: true
      }
    };

    dispatch(updateVideoPhraseAction(wordPhrase));
    dispatch(updateVideoPhraseAction(contextPhrase));
    dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
      phrases: [
        wordPhrase,
        contextPhrase,
      ],
    }));

    return contextPhraseId;
  }

  public static savePreviewPhrase(selectResult: IPhraseSelectResult, translate?: boolean): AppThunk {
    return (
      dispatch,
      getState
    ): number => {
      const state = getState();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);
      const targetCaption = getTargetCaptions(state);
      const captions: ICaptionsItem[] = [];
      for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
        if (i < targetCaption.length) {
          captions.push(targetCaption[i]);
        }
      }

      const phraseId = PhraseEffects.generatePhraseId();
      const contextPhraseId = phraseId - 1;

      if (selectResult && videoKey && userGroupId && captions.length) {
        const phrase: IVocabularyPhrase = {
          id: phraseId,
          highlighted: selectResult.text,
          fullPhrase: '',
          translated: '',
          startTime: selectResult.startTime,
          endTime: selectResult.endTime,
          type: EVocabularyPhraseType.PREVIEW_WORD_SELECTED,
          startPosition: selectResult.startPosition,
          endPosition: selectResult.endPosition,
          startCaptionIndex: selectResult.startCaptionIndex,
          endCaptionIndex: selectResult.endCaptionIndex,
          wordPhraseId: 0,
          videoKey,
          userGroupId,
          translate
        };

        const fullPhrase = captions.map(c => c.text).join('\n');

        const contextPhrase: IVocabularyPhrase = {
          id: contextPhraseId,
          highlighted: selectResult.text,
          fullPhrase,
          translated: '',
          type: EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED,
          startTime: captions[0].startTime,
          endTime: captions[captions.length-1].endTime,
          startPosition: 0,
          endPosition: captions[captions.length-1].text.length,
          startCaptionIndex: selectResult.startCaptionIndex,
          endCaptionIndex: selectResult.endCaptionIndex,
          wordPhraseId: phraseId,
          videoKey,
          userGroupId
        };

        dispatch(updateVideoPhraseAction(phrase));
        dispatch(updateVideoPhraseAction(contextPhrase));
        dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
          phrases: [
            phrase,
            contextPhrase,
          ],
          isContext: true,
        }));
      }
      return contextPhraseId;
    }
  }

  public static savePreviewContextByWordPhrase(selectResult: IPhraseSelectResult, wordPhraseId: number): AppThunk {
    return (
      dispatch,
      getState
    ): IVocabularyPhrase | null => {
      const state = getState();
      const phraseId = PhraseEffects.generatePhraseId();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);

      const targetCaption = getTargetCaptions(state);
      const captions: ICaptionsItem[] = [];
      for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
        if (i < targetCaption.length) {
          captions.push(targetCaption[i]);
        }
      }
      if (selectResult && videoKey && userGroupId && captions.length) {
        const fullPhrase = captions.map(c => c.text).join('\n');

        const contextPhrase: IVocabularyPhrase = {
          id: phraseId,
          highlighted: selectResult.text,
          fullPhrase,
          translated: '',
          type: EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED,
          startTime: selectResult.startTime,
          endTime: selectResult.endTime,
          startPosition: selectResult.startPosition,
          endPosition: selectResult.endPosition,
          startCaptionIndex: selectResult.startCaptionIndex,
          endCaptionIndex: selectResult.endCaptionIndex,
          wordPhraseId,
          videoKey,
          userGroupId
        };
        return contextPhrase;
      }
      return null;
    }
  }

  public static deleteCurrentPreviewPhrase(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const state = getState();
      batch(() => {
        const previewPhrases = getVideoPhrases(state)
          .filter(p => p.type === EVocabularyPhraseType.PREVIEW_WORD_SELECTED ||
            p.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED ||
            p.id < 0
          );
        if (previewPhrases && previewPhrases.length) {
          previewPhrases.forEach(p =>
            dispatch(deleteVideoPhraseAction(p.id))
          );
        }
        dispatch(CaptionsSelectionPopupActions.setPreviewPhraseId(0));
      })
    }
  }

  public static findInterceptPhrase(
    videoId: string,
    phraseTypes: EVocabularyPhraseType[],
    startIndex: number,
    startPos: number,
    endIndex: number,
    endPos: number) {
    const state = getState();
    const lineLen = 10000;
    const phrases: IVocabularyPhrase[] = PhraseListSelectors.findPhraseByVideoId(state, videoId);
    //  getVideoPhrases(state, videoId, true);

    const startValue = startIndex * lineLen + startPos;
    const endValue = endIndex * lineLen + endPos;

    return phrases.find(phrase => {
      if (!phrase.type || !phraseTypes.includes(phrase.type))
        return false;

      const phraseStartValue = phrase.startCaptionIndex * lineLen + phrase.startPosition;
      const phraseEndValue = phrase.endCaptionIndex * lineLen + phrase.endPosition;

      return (startValue >= phraseStartValue && startValue <= phraseEndValue) || (endValue >= phraseStartValue && endValue <= phraseEndValue)
        || (startValue < phraseStartValue && endValue > phraseEndValue);
    })

  }

  public static checkPhraseInterceptRange(
    startIndex: number,
    startPos: number,
    endIndex: number,
    endPos: number,
    phrase: IVocabularyPhrase
  ): boolean {
    const lineLen = 10000;

    const startValue = startIndex * lineLen + startPos;
    const endValue = endIndex * lineLen + endPos;
    const phraseStartValue = phrase.startCaptionIndex * lineLen + phrase.startPosition;
    const phraseEndValue = phrase.endCaptionIndex * lineLen + phrase.endPosition;

    return (startValue >= phraseStartValue && startValue <= phraseEndValue) || (endValue >= phraseStartValue && endValue <= phraseEndValue)
      || (startValue < phraseStartValue && endValue > phraseEndValue);
  }

  public static async loadPhrases(offset: number, count: number, append: boolean) {
    const dispatch = getDispatch();
    const state = getState();
    const listMode = PhraseListSelectors.getListMode(state);
    const {teacherMode} = getAuthUser(state);
    const userGroupId = getActiveGroupId(state);
    const body: TFindPhraseBody = {
      offset,
      count,
      teacherMode,
      order: EFindPhrasesOrder.CREATE_DATE
    }
    if (listMode === EPhraseListMode.CURRENT_GROUP) {
      body.groupId = getActiveGroupId(state) || undefined;
    } else if (listMode === EPhraseListMode.LANG) {
      body.targetLangCode = PhraseListSelectors.getListModeLang(state);
    } else { // EPhraseListMode.CURRENT_VIDEO || TEACHER
      if (isAudioMovieKey(getCurrentMovieKey(state))) {
        body.audioId = getCurrentAudioId(state);
      } else {
        body.videoId = getCurrentMovieKey(state);
      }
      body.videoGroupId = getActiveGroupId(state) || undefined;
      body.order = EFindPhrasesOrder.START_TIME;
    }
    if (listMode !== EPhraseListMode.CURRENT_VIDEO) {
      body.saveType = EVocabularyPhraseSaveType.PERSIST;
    }
    let list: IVocabularyPhrase[] = await dispatch(fetchAllVideoPhrases(body));

    if (teacherMode) {
      list = list.map(p => {
        if (p.userGroupId) return p;
        return {
          ...p,
          ...{userGroupId}
        }
      })
    }

  /*  let allLoaded = !!(list.length <= count);
    if (list.length > count) {
      list = list.splice(0, count);
    }*/

    if (append) {
      dispatch(PhraseListActions.appendPhraseList(list));
    } else {
      dispatch(PhraseListActions.setPhraseList(list));
    }

    return list.length === 0;
  }


  public static async saveFromHotKey(playerTime: number): Promise<IVocabularyPhrase | null> {
      const state = getState();
      const dispatch = getDispatch();

      const targetCaptions: ICaptionsItem[] = getTargetCaptions(state);
      const nativeCaptions: ICaptionsItem[] = getNativeCaptions(state);

      const targetCaptionIndex = targetCaptions.findIndex(caption => caption.startTime < playerTime && caption.endTime > playerTime);
      const nativeCaptionIndex = nativeCaptions.findIndex(caption => caption.startTime < playerTime && caption.endTime > playerTime);
      const targetCaption = targetCaptionIndex >= 0 ? targetCaptions[targetCaptionIndex] : null;
      const nativeCaption = nativeCaptionIndex >= 0 ? nativeCaptions[nativeCaptionIndex] : null;

      if (!targetCaption) return null;
    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    if (!userGroupId) return null;

      const text = targetCaption.text;
      const lastPhrase = PhraseListSelectors.findLastPhraseByVideoAndType(state, videoKey, EVocabularyPhraseType.WORD_SELECTED);
      if (lastPhrase && lastPhrase.highlighted === text) return;

      const wordPhrase: TNewVocabularyPhrase = {
        highlighted: text,
        fullPhrase: '',
        translated: nativeCaption ? nativeCaption.text : '',
        startTime: targetCaption.startTime,
        endTime: targetCaption.endTime,
        type: EVocabularyPhraseType.WORD_SELECTED,
        startPosition: 0,
        endPosition: text.length,
        startCaptionIndex: targetCaptionIndex,
        endCaptionIndex: targetCaptionIndex,
        wordPhraseId: 0,
        videoKey,
        userGroupId
      };
      const wPhrase: IVocabularyPhrase = await dispatch(addPhraseToVocabularyEffect(wordPhrase));

      const contextPhrase = {...wordPhrase, ...{
          fullPhrase: text,
          wordPhraseId: wPhrase.id,
          type: EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED
        }};
      return await dispatch(addPhraseToVocabularyEffect(contextPhrase));
  }

  public static savePreviewPhraseModifiedContext(
    selectResult: IPhraseSelectResult,
    previewContextPhrase: IVocabularyPhrase,
    previewWordPhrase: IVocabularyPhrase,
    promptsMode?: boolean // call from prompts window
  ) {
      const state = getState();
      const dispatch = getDispatch();
      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);

      let contextPhraseId;
      if (selectResult && videoKey && userGroupId) {
        contextPhraseId = this.updatePreviewContextPhrase(selectResult, previewContextPhrase, previewWordPhrase);
        const contextPhrase = findVideoPhraseById(getState(), contextPhraseId);
        const phrase = findVideoPhraseById(getState(), contextPhrase?.wordPhraseId);

       if (promptsMode && contextPhrase && phrase) {
         TeachersEffects.hidePhrases();
         const tabId = PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state);
         const libId = PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLibId(state);
         if (tabId || libId) {
           let prompt: any;
           if (tabId) {
             const tab = getPhraseDetailsTabById(state, tabId);
             prompt = tab && PhraseDetailsTabEffects.getCustomPromptByTab(tab);
           } else if (libId) {
             prompt = PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLib(state);
           }
           if (prompt) {
            setTimeout(() => {
              dispatch(PhraseDetailsTabCustomPromptFormEvents.onPromptChange(prompt.prompt));
            }, 100)
           }
         }
       }

      }

      return contextPhraseId;
  }

  public static async reloadPhrasesNotes() {
    const state = getState();
    const dispatch = getDispatch();
    const {teacherMode} = getAuthUser(state);
    const videoId = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    const result = await fetchPhrasesNotesLists({ teacherMode, videoId, userGroupId });

    const phrases = result.phrases.map(p => {
      if (p.userGroupId) return p;
      return {
        ...p,
        ...{userGroupId}
      }
    })

    dispatch(setPhrasesNotesListsAction(phrases, result.notes));
    PhraseNoteTimeScaleEffects.load();

    dispatch(PhraseListActions.setListMode(EPhraseListMode.CURRENT_VIDEO, ''));
    await PhraseEffects.loadPhrases(0, phrasesPartLoadSize, false);
    PhraseNoteTimeScaleEffects.load();
  }

  public static async copyAllTeacherPhrases(teacherId: number): Promise<number> { // todo LNG-793 не копируются фразы типа LESSON
    const dispatch = getDispatch();
    const teacherPhrases = getVideoTeacherPhrases(getState(), teacherId) || [];
    let copyCount = 0;
    const state = getState();
    if (teacherPhrases.length > 0) {
      const teacher = getVideoTeacherUserInfo(getState(), teacherId);
      for await(const teacherPhrase of teacherPhrases) {
        if (isExistPhrasesFromTeacherPhrase(getState(), teacherPhrase.id)) continue;
        const wordPhrase = await PhraseEffects.saveFromTeacherPhrase(teacherId, teacherPhrase.id);
        if (wordPhrase) {
          const teacherNote = getVideoTeacherNoteByPhraseId(getState(), teacherId, teacherPhrase.id);
          if (teacherNote) {
            await PhraseNoteEffects.saveFromTeacherNote(teacherNote, wordPhrase.id, teacher);
          }
          EventsRouter.trackEvent(Events.SAVE_PHRASE);
          const langCode = wordPhrase.targetLangId && findLanguageById(state, wordPhrase.targetLangId)?.code || '';
          StatLogManager.logPhraseSave(langCode);
          copyCount++;
        }
      }

      dispatch(setPhrasesExistAction(true));
    }
    return copyCount;
  }

  public static async copyTeacherPhrase(teacherId: number, teacherPhraseId: number) {
    const dispatch = getDispatch();
    const wordPhrase = await PhraseEffects.saveFromTeacherPhrase(teacherId, teacherPhraseId);
    if (wordPhrase) {
      const teacherNote = getVideoTeacherShowNote(getState());
      const teacher = getVideoTeacherUserInfo(getState(), teacherId);
      await PhraseNoteEffects.saveFromTeacherNote(teacherNote, wordPhrase.id, teacher);
      dispatch(PhraseDetailsActions.setPhraseId(wordPhrase.id));
      TeachersEffects.hidePhrases();

      dispatch(setPhrasesExistAction(true));
      const contextPhrase = findVideoPhraseByWordId(getState(), wordPhrase.id);
      if (contextPhrase) {
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({
          wordPhraseId: wordPhrase.id,
          contextPhraseId: contextPhrase.id
        }));
        dispatch(PhraseListActions.setForwardPhraseId(contextPhrase.id));
      }
      dispatch(VocabularyEffects.flashPhrase(wordPhrase.id));
      EventsRouter.trackEvent(Events.SAVE_PHRASE);
      const langCode = wordPhrase.targetLangId && findLanguageById(getState(), wordPhrase.targetLangId)?.code || '';
      StatLogManager.logPhraseSave(langCode);
    }
  }

  public static onVideoLoaded() {
    const listMode = PhraseListSelectors.getListMode(getState());
    if (listMode === EPhraseListMode.TEACHER) {
      const dispatch = getDispatch();
      dispatch(PhraseListActions.setListMode(EPhraseListMode.CURRENT_VIDEO));

    }
  }

  public static addAnnotationPhrase(
    prevId?: number,
    nextId?: number,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IVocabularyPhrase | null> => {
      const state = getState();

      const prev = prevId && PhraseListSelectors.findPhraseById(state, prevId);
      const next = nextId && PhraseListSelectors.findPhraseById(state, nextId);

      let startTime = -.1;
      if (prev && next) {
        startTime = prev.startTime + (next.startTime - prev.startTime) / 2;
      } else if (next) { // before the first
        startTime = next.startTime / 2;
      } else if (prev) { // after the last
        startTime = prev.endTime || prev.startTime * 2;
      }

      const videoKey = getCurrentMovieKey(state);
      const userGroupId = getActiveGroupId(state);
      if (!videoKey || !userGroupId) return null;

      const phrase = await dispatch(addPhraseToVocabularyEffect({
        fullPhrase: '',
        highlighted: '',
        translated: '',
        startTime,
        endTime: 0,
        startCaptionIndex: 0,
        endCaptionIndex: 0,
        startPosition: 0,
        endPosition: 0,
        wordPhraseId: 0,
        videoKey,
        userGroupId,
        type: EVocabularyPhraseType.LESSON,
        saveType: EVocabularyPhraseSaveType.PERSIST
      }));

      PhraseDetailsEffects.showNoteFromSavedPhraseAnnotation(phrase);

      return phrase;
    }
  }

  public static addAnnotationWithoutPhrase(
    isLast?: boolean,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IVocabularyPhrase | null> => {
      const state = getState();
      let phrases = PhraseListSelectors.findPhraseByVideoId(state, getCurrentMovieKey(state));
      if (!phrases || !phrases.length) {
        return dispatch(this.addAnnotationPhrase());
      }

      let phrasesSorted = phrases.sort((a, b) => {
        return +(a.startTime > b.startTime)
      });
      if (isLast) {
        return dispatch(this.addAnnotationPhrase(phrasesSorted[phrasesSorted.length - 1].id));
      } else {
        return dispatch(this.addAnnotationPhrase(null, phrasesSorted[0].id));
      }
    }
  }

  public static isContextExist(phrase: IVocabularyPhrase): boolean {
    return phrase.highlighted !== phrase.fullPhrase;
  }

  public static saveTranslatedPhrases(wordPhrase: IVocabularyPhrase, contextPhrase: IVocabularyPhrase) {
    const dispatch = getDispatch();
    batch(() => {
      if (wordPhrase) dispatch(updatePhraseEffect({
        ...wordPhrase,
        saveType: EVocabularyPhraseSaveType.PERSIST,
      }));
      if (contextPhrase) dispatch(updatePhraseEffect({
        ...contextPhrase,
        saveType: EVocabularyPhraseSaveType.PERSIST,
      }));
    });


  }

}
