import { getDispatch, getState } from '../store';
import { getVideoWatchToday } from '../store/general/selectors';
import { setVideoWatchTodayAction } from '../store/general/actions';

export class GeneralEffects {

	public static async addVideoWatchToday (
		sec: number = 0,
	) {    
		const today = getVideoWatchToday(getState()) || 0;
		getDispatch()(setVideoWatchTodayAction(today + sec));
	}

}