export const TRANSCRIPT_SHOW_CREATE_ACTION_POPUP_ACTION = 'TRANSCRIPT/TRANSCRIPT_SHOW_CREATE_ACTION_POPUP_ACTION';
export const TRANSCRIPT_SET_ACTIONS_ACTION = 'TRANSCRIPT/TRANSCRIPT_SET_ACTIONS_ACTION';
export const TRANSCRIPT_SET_ACTIVE_ACTION = 'TRANSCRIPT/TRANSCRIPT_SET_ACTIVE_ACTION';
export const TRANSCRIPT_UPDATE_ACTIVE_ACTION = 'TRANSCRIPT/TRANSCRIPT_UPDATE_ACTIVE_ACTION';
export const TRANSCRIPT_SET_TASKS_ACTION = 'TRANSCRIPT/TRANSCRIPT_SET_TASKS_ACTION';
export const TRANSCRIPT_SET_TASK_RESULT_ACTION = 'TRANSCRIPT/TRANSCRIPT_SET_TASK_RESULT_ACTION';
export const TRANSCRIPT_UPDATE_TASK_ACTION = 'TRANSCRIPT/TRANSCRIPT_UPDATE_TASK_ACTION';


export interface ITranscriptShowCreateActionPopupAction {
  type: typeof TRANSCRIPT_SHOW_CREATE_ACTION_POPUP_ACTION;
  show: boolean
}

export interface ITranscriptSetActionsAction {
  type: typeof TRANSCRIPT_SET_ACTIONS_ACTION;
  actions: ITranscriptAction[]
}

export interface ITranscriptSetActiveAction {
  type: typeof TRANSCRIPT_SET_ACTIVE_ACTION;
  activeAction?: ITranscriptActionInfo
}

export interface ITranscriptUpdateActiveAction {
  type: typeof TRANSCRIPT_UPDATE_ACTIVE_ACTION;
  activeAction: Partial<ITranscriptActionInfo>
}

export interface ITranscriptSetTasksAction {
  type: typeof TRANSCRIPT_SET_TASKS_ACTION;
  tasks: ITranscriptTask[]
}

export interface ITranscriptSetTaskResultAction {
  type: typeof TRANSCRIPT_SET_TASK_RESULT_ACTION;
  result: ITranscriptResult
}

export interface ITranscriptUpdateTaskAction {
  type: typeof TRANSCRIPT_UPDATE_TASK_ACTION;
  task: Partial<ITranscriptTask>
}


export interface ITranscriptAction {
  id: number,
  createdAt: Date
}

export interface ITranscriptResult {
  id: number;
  taskId: number;
  langId: number;
  result: string;
  audioUrl: string;
}

export interface ITranscriptActionInfo {
  id: number,
  input: string,
  results: ITranscriptResult[],
  activeTaskId: number
}

export interface ITranscriptTask {
  id: number,
  title: string,
  prompt: string,
  allowAudio: boolean
}

export interface ITranscriptState {
  showCreateActionPopup: boolean,
  actions: ITranscriptAction[],
  tasks: ITranscriptTask[],
  activeAction?: ITranscriptActionInfo
}

export type TTranscriptActions =
  | ITranscriptShowCreateActionPopupAction
| ITranscriptSetActionsAction
  | ITranscriptUpdateActiveAction
| ITranscriptSetActiveAction
| ITranscriptSetTasksAction
| ITranscriptSetTaskResultAction
| ITranscriptUpdateTaskAction