import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { IContentLibVideoProps } from './type';
import Stack from '@mui/material/Stack';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';

export function ContentLibVideo({
	title,
	thumbUrl,
	channel,
	onClick,
	onDelete,
	onGetCopyLink,
}: IContentLibVideoProps) {

	const className = 'content-lib-video';
	const classControl = className + '__control';

	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [title]);

	const titleElement = <Text
		lineHeight={`1.3 !important`}
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',

			fontSize: theme.typography.subtitle2,
			fontWeight: 600,
		}}
		ref={ref1}
	>
		<span
			ref={ref2}
		>
			{title}
		</span>
	</Text>

	const menuBtnRef = React.useRef<HTMLButtonElement | null>(null);
	const [deleteAnchor, setDeleteAnchor] = React.useState<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = React.useState(false);
	const handleMenuOpen = () => {
		setMenuOpen(true);
	}
	const handleMenuClose = () => {
		setMenuOpen(false);
	}
	const handleConfirmDelete = () => {
		handleMenuClose();
		setDeleteAnchor(menuBtnRef.current);
	}
	const handleDelete = () => {
		setDeleteAnchor(null);
		!!onDelete && onDelete();
	}
	const handeGetCopyLink = () => {
		handleMenuClose();
		!!onGetCopyLink && onGetCopyLink();
	}

	const handeClick = (e: React.MouseEvent) => {
		const target = e.target as HTMLElement;
		if (target.closest(`.${classControl}`)) return;

		onClick();
	}

	return (
		<Stack
			sx={{
				position: 'relative',
				flex: `1 1 100%`,
				minWidth: 0,
				cursor: 'pointer',
			}}
			gap={1}
			onClick={handeClick}
		>
			<Box
				sx={{
					aspectRatio: 16/9,
					backgroundPosition: '50% 50%',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${thumbUrl})`,
					borderRadius: theme.spacing(1),
				}}
			/>

			{(!!onDelete || !!onGetCopyLink) && <>
				<ButtonMenu
					buttonProps={{
						className: classControl,
						ref: menuBtnRef,
						sx: {
							position: 'absolute',
							right: theme.spacing(-1),
							top: theme.spacing(-1),
							height: theme.spacing(5),
							width: theme.spacing(5),
							background: theme.palette.common.white,
						},
					}}
					menuProps={{
						className: classControl,
						keepMounted: true,
						open: !!menuOpen,
					}}
					onOpen={handleMenuOpen}
					onClose={handleMenuClose}
				>
					{!!onGetCopyLink &&
						<IconMenuItem
							title={'Share public link'}
							onClick={handeGetCopyLink}
						>
							<LinkIcon />
						</IconMenuItem>
					}
					{!!onDelete &&
						<IconMenuItem
							title={'Delete'}
							onClick={handleConfirmDelete}
						>
							<TrashIcon />
						</IconMenuItem>
					}
				</ButtonMenu>

				<ConfirmPopup
					anchor={deleteAnchor}
					text={'Delete video?'}
					onConfirm={handleDelete}
					onClose={() => setDeleteAnchor(null)}
					popoverProps={{
						className: classControl,
					}}
				/>
			</>}

			<Stack
				gap={.25}
			>
				{isLongTitle
					? <Tooltip
						enterDelay={1000}
						title={title}
					>
						{titleElement}	
					</Tooltip>
					: titleElement
				}

				<Text
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						fontSize: theme.typography.subtitle2,
						color: theme.palette.grey[500],
						whiteSpace: 'nowrap',
					}}
				>
					{channel}
				</Text>
			</Stack>
		</Stack>

	)
};