import { ELogRecordQueueType, StatRest, TStatDateRange, TStatsResponse } from '../rest/stats/statRest';
import { IDateRange } from '../../spa/components/dashboard/ToolbarPanel/Statisitcs/components/types';
import { GeneralEffects } from '../../spa/effects/generalEffects';

export class StatLogManager {

  public static async logTranslation(langCode?: string) {
    return StatRest.sendLog({type: ELogRecordQueueType.TRANSLATION, langCode});
  }

  public static async logPromptAccess(langCode?: string) {
    return StatRest.sendLog({type: ELogRecordQueueType.PROMPT_ACCESS, langCode});
  }

  public static async logPhraseSave(langCode?: string) {
    return StatRest.sendLog({type: ELogRecordQueueType.PHRASE_SAVE, langCode});
  }

  public static async logVideoWatch(sec: number, langCode?: string) {
    GeneralEffects.addVideoWatchToday(sec);

    return StatRest.sendLog({type: ELogRecordQueueType.VIDEO_WATCH, value: sec, langCode});
  }

  public static async getStat(dateRanges: IDateRange[]): Promise<TStatsResponse> {
    const reqDateRanges: TStatDateRange[] = dateRanges.map(dt => {
      return {
        id: dt.id,
        fromDate: this.getDateParam(dt.from),
        toDate: this.getDateParam(dt.to)
      }
    })
    return StatRest.getStat(reqDateRanges)
  }

  private static getDateParam(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

}