import { DetectLangRest } from '../../common/rest/detectLang/detectLangRest';
import { LangUtil } from '../../common/utils/lang-util';
import { getState } from '../store';
import { PhraseDetailsSelectors } from '../store/phrase-details/selectors';
import { PhraseDetailsCache } from './phrase-details/phraseDetailsService/phrase-details-cache';
import { getActiveGroupTargetLanguage } from '../store/models/selectors';

type TOptimizeParagraphsLangs = {
  resultParagraphs: string[],
  resultLangs: string[]
}


export class TextSpeaker {

  text: string;

  private lastStopTime: Date | null;

  public async speak(_text: string, lang?: string, stopOnPlay?: boolean, onStop?: () => void, priorityLang?: string): Promise<boolean> {
    const text = this.prepareText(_text);
    const paragraphs = this.getTextParagraphs(text);
    if (paragraphs.length > 1) {
      this.speakParagraphs(paragraphs, lang, stopOnPlay, onStop, priorityLang);
    } else {
      if (lang) {
        return this.speakByLang(text, lang, stopOnPlay, onStop, priorityLang);
      }
      const langCode = await TextSpeaker.getLangByText(text);
      return this.speakByLang(text, langCode, stopOnPlay, onStop, priorityLang);
    }
  }

  private prepareText(text: string): string {
    return text.replaceAll(/`/g, "");
  }

  private async detectParagraphsLangs(paragraphs: string[], priorityLang?: string): Promise<string[]> {
    try {
      return await DetectLangRest.execBatch(paragraphs, priorityLang);
    } catch(e) {
      const lang = getActiveGroupTargetLanguage(getState());
      const langCode = lang?.code || 'en';
      const result = [];
      for(let i=0; i<paragraphs.length; i++)
        result.push(langCode);
      return result;
    }
  }



  private optimizeParagraphsLangs(paragraphs: string[], langs: string[]): TOptimizeParagraphsLangs {
    const resultParagraphs: string[] = [];
    const resultLangs: string[] = [];
    const len = Math.min(paragraphs.length, langs.length);
    let prevLang;
    let currentParagraph = '';
    for (let i=0; i<len; i++) {
      if (langs[i] === prevLang) {
        currentParagraph += ' ' + paragraphs[i];
      } else {
        if (currentParagraph) {
          resultParagraphs.push(currentParagraph.trim());
          resultLangs.push(prevLang);
        }
        currentParagraph = paragraphs[i];
        prevLang = langs[i];
      }
    }
    if (currentParagraph) {
      resultParagraphs.push(currentParagraph.trim());
      resultLangs.push(prevLang);
    }
    return {
      resultParagraphs, resultLangs
    }
  }

  private async speakParagraphs(_paragraphs: string[], langCode?: string, stopOnPlay?: boolean, onStop?: () => void, priorityLang?: string) {
    if (!speechSynthesis) return false;
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      if (stopOnPlay) return true;
    }
    let index = 0;
    const _langCodes: string[] = !!langCode ? [] : await this.detectParagraphsLangs(_paragraphs, priorityLang);
    const {resultParagraphs: paragraphs, resultLangs: langCodes} = this.optimizeParagraphsLangs(_paragraphs, _langCodes);

    const onStopCallback = () => {
      if (onStop)
        onStop();
    }

    const _onStop = () => {
      const lastStopInterval = new Date().getTime() - (this.lastStopTime?.getTime() || 0);
      if (lastStopInterval < 100) {
        return onStopCallback();
      }

      index++;
      if (index >= paragraphs.length) {
        return onStopCallback();
      }
      playParagraph();
    }

    const playParagraph = async () => {
      const text = paragraphs[index];
      this.text = text ? TextSpeaker.prepareText(text) : '';
      const _langCode = langCode || (index < langCodes.length ? langCodes[index] : await TextSpeaker.getLangByText(text));
      TextSpeaker.playText(this.text, _langCode, _onStop);
    }

    playParagraph();
    return true;
  }

  private speakByLang(text: string, lang: string, stopOnPlay?: boolean, onStop?: () => void, priorityLang?: string): boolean {
    if (!speechSynthesis) return false;

    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      if (stopOnPlay &&
        text === this.text
      ) return true;
    }

    this.text = TextSpeaker.prepareText(text);
    TextSpeaker.playText(this.text, lang, onStop);
    return true;
  }

  public stop() {
    if (!speechSynthesis) return false;
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      this.text = '';
      this.lastStopTime = new Date();
    }
  }

  private static async getLangByText(text: string): Promise<string> {
    const primaryKey = 'langByText';
    let langCode = PhraseDetailsCache.get([primaryKey, text]);
    if (langCode) {
      return langCode;
    }
    try {
      langCode = await DetectLangRest.exec(TextSpeaker.prepareText(text));
    } catch (e) {}
    if (!langCode) {
      langCode = LangUtil.checkLangCode(PhraseDetailsSelectors.getFromLang(getState())?.code);
    }
    PhraseDetailsCache.put([primaryKey, text], langCode);
    return langCode;
  }

  private static prepareText(text: string): string {
    let result = text.replace(/<\/?[^>]+(>|$)/g, ' ');
    result = result.replace(/\d+\./g, ' ');
    result = result.replace(/"|'/g, '');
    return result;
  }

  private getTextParagraphs(text: string): string[] {
    const separateChars = ['"', '(', ')', ':', '\n'];
    const separateMark = '[separator]'
    let result = text
      .replaceAll(/<br\/?>/g, "\n");
    separateChars.forEach(sep => {
      result = result.replaceAll(sep, separateMark)
    });
    return result.split(separateMark).map(s => s.trim())
      .filter(s => s.length > 0 && s !== ',' && s!== '.')
  }

  public static playText(text: string, lang: string, onStop?: () => void) {
    let isPlaying = false;

    const landCode = LangUtil.getIsoLangCode(lang);
    let msg = new SpeechSynthesisUtterance();
    msg.text = text;
    msg.lang = landCode;
    const voice = VoiceSelector.getVoice(landCode);
    if (voice) {
      msg.voice = voice;
    }
    msg.onerror = () => {
      isPlaying = false;
      clearInterval(synthesisInterval);
      if (onStop) onStop();
    }
    msg.onend = () => {
      isPlaying = false;
      clearInterval(synthesisInterval);
      if (onStop) onStop();
    }

    speechSynthesis.speak(msg);
    isPlaying = true;

    // fix chrome bug  https://stackoverflow.com/questions/21947730/chrome-speech-synthesis-with-longer-texts
    const synthesisInterval = setInterval(() => {
      if (isPlaying) {
        speechSynthesis.pause();
        speechSynthesis.resume();
      } else {
        clearInterval(synthesisInterval);
      }
    }, 10000);
  }
}

class VoiceSelector {

  private static voices: Record<string, SpeechSynthesisVoice> = null;

  public static getVoice(lang: string): SpeechSynthesisVoice | null {
    if (this.voices === null) {
      this.selectLangVoices();
    }
    return this.voices && this.voices[lang] ? this.voices[lang] : null;
  }

  private static selectLangVoices() {
    if (!speechSynthesis) return;
    const voices = speechSynthesis.getVoices();
    this.voices = {};
    voices.forEach(voice => {
      if (voice.name.toLocaleLowerCase().indexOf('google') >= 0) {
        const langCode = LangUtil.checkLangCode(voice.lang);
        if (!this.voices[langCode])
          this.voices[langCode] = voice;
      }
    })
  }
}

export const textSpeaker = new TextSpeaker();
