import { AuthUserEffects } from '../../../../../effects/authUserEffects';
import { AppThunk } from '../../../../../store/types';

export class LangMenuListEvents {

	public static onChange (langCode: string): AppThunk {
		return (
			dispatch,
			getState
		) => {
			AuthUserEffects.setLang(langCode);
		}
	}

}