import React from 'react';
import { IconButton, Stack, SxProps, } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { IContentLibraryHeaderProps } from './types';
import { BookIcon } from '../../../../../../../icons/BookIcon';
import { Tabs } from '../../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../../common/components/Tab/Tab';
import { ListIcon } from '../../../../../../../icons/ListIcon';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { Logo } from '../../../../../../../icons/Logo';
import { HelpIcon } from '../../../../../../../icons/HelpIcon';
import { UserIcon } from '../../../../../../../icons/UserIcon';
import { VideoItemIcon } from '../../../../../../../icons/VideoItemIcon';
import { EMainViewMode } from '../../../../../store/general/types';

export function ContentLibraryHeader({
	viewMode,
	selectedGroupTitle,
	mainViewMode,
	onChangeViewMode,
	onBack,
	onClickLogo,
	onClickHelp,
	onChangeMainViewMode,
}: IContentLibraryHeaderProps) {

	const className = 'content-library-header';

	const {isMobile, isSmall} = React.useContext(DeviceContext);

	const tabsSx: SxProps = {
		p: theme.spacing(.5, .75),
		bgcolor: theme.palette.grey[50],
		overflow: 'visible',

		'.MuiTabs-flexContainer': {
			gap: theme.spacing(.75),
		},

		'.MuiTab-root': {
			minWidth: theme.spacing(5),
			minHeight: theme.spacing(5),
			padding: theme.spacing(0, 1),

			'.MuiSvgIcon-root': {
				opacity: .6,
			},

			'&.Mui-selected': {
				'.MuiSvgIcon-root': {
					color: theme.palette.primary.main,
					opacity: 1,
				}
			},

			'.mobile &': {
				minHeight: theme.spacing(4),
			},

			[theme.breakpoints.down('md')]: {
				// minWidth: theme.spacing(4),
				// maxWidth: theme.spacing(4),
				// pl: theme.spacing(.75),
				// justifyContent: 'flex-start',
			},
		},
	};

	return (
		<Paper
			className={className}
			sx={{
				position: 'relative',
				zIndex: 100,
				flex: `0 0 ${theme.spacing(8)}`,
				py: 1,
				px: 2,
				borderRadius: 0,
				boxShadow: theme.shadows[8],

				[theme.breakpoints.down('md')]: {
					p: 1,

					[`.${className}__stack`]: {
						gap: 1,
					},
				},
			}}
		>
			<Stack
				className={`${className}__stack ${selectedGroupTitle ? 'group-selected' : ''}`}
				direction={'row'}
				alignItems={'center'}
				gap={2}
				sx={{
					width: 1,
					height: 1,

					[`&.group-selected .${className}__title`]: {
						flex:  '1 1 100%',
						minWidth: 0,
					},
				}}
			>
				<Stack
					direction={'row'}
					alignItems={'center'}
					gap={1}
				>
					<ButtonIconGrey
						sx={{
							'.MuiSvgIcon-root': {
								width: theme.spacing(3),
								height: theme.spacing(3),
							},
						}}
						onClick={onBack}
					>
						<ArrowLightLeftIcon />
					</ButtonIconGrey>

					<IconButton
						sx={{
							p: 0,

							'@media (max-width: 370px)': {
								display: 'none',
							}
						}}
						onClick={onClickLogo}
					>
						<Logo sx={{ height: theme.spacing(4), width: 'auto' }} />
					</IconButton>

					<ButtonIconGrey
						sx={{
							'.MuiSvgIcon-root': {
								width: theme.spacing(2.5),
								height: theme.spacing(2.5),
							},
						}}
						title={'Help'}
						onClick={onClickHelp}
					>
						<HelpIcon />
					</ButtonIconGrey>
				</Stack>

				{!selectedGroupTitle && <>
					<Tabs
						sx={{
							...tabsSx,
							ml: theme.spacing(2),

							'@media (max-width: 600px)': {
								'.MuiTab-root': {
									minWidth: theme.spacing(4),
									maxWidth: theme.spacing(4),
									pl: theme.spacing(.75),
									justifyContent: 'flex-start',
								},
							}
						}}
						value={mainViewMode}
						onChange={(e, value) => {
							onChangeMainViewMode(value);
						}}
					>
						<Tab
							value={EMainViewMode.CONTENT_LIBRARY}
							icon={<BookIcon />}
							iconPosition={'start'}
							label={'Common content'}
						/>
						<Tab
							value={EMainViewMode.MY_LIBRARY}
							icon={<UserIcon />}
							iconPosition={'start'}
							label={'My Library'}
						/>
					</Tabs>

					<Tabs
						sx={{...tabsSx,

							'@media (max-width: 720px)': {
								'.MuiTab-root': {
									minWidth: theme.spacing(4),
									maxWidth: theme.spacing(4),
									pl: theme.spacing(.75),
									justifyContent: 'flex-start',
								},
							}
						}}
						value={viewMode}
						onChange={(e, value) => {
							onChangeViewMode(value);
						}}
					>
						<Tab
							value={EContentLibViewMode.VIDEO_LIST}
							icon={<VideoItemIcon />}
							iconPosition={'start'}
							label={'Videos'}
						/>
						<Tab
							value={EContentLibViewMode.GROUP_LIST}
							icon={<ListIcon />}
							iconPosition={'start'}
							label={'Playlists'}
						/>
					</Tabs>
				</>}

			</Stack>
		</Paper>
	);
};
