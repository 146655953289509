import { AppThunk } from '../../../../../store/types';
import { CopyGroupEffects } from '../../../../../effects/copyGroupEffects';
import { updateUserGroups } from '../../../../../effects/updateUserGroups';
import { isAuthorised } from '../../../../../store/general/selectors';
import { setCopyGroupOnLoginAction } from '../../../../../store/general/actions';
import { Navigator } from '../../../../../effects/navigator';
import { getUserGroupById } from '../../../../../store/models/selectors';
import { getUserStateStorage } from '../../../../../../common/utils/local-storage/user-state';

export class ProfileGroupInfoEvents {

  public static onCopyGroup(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (!isAuthorised(getState())) {
        dispatch(setCopyGroupOnLoginAction({groupId}));
        Navigator.openDefault();
      } else {
        const result = await CopyGroupEffects.copyPublic(groupId);
        if (result) {
          getUserStateStorage().setVideoInfo(null as any, result.groupId, 0);
          await dispatch(updateUserGroups(true));
          const videos = getUserGroupById(getState(), result.groupId)?.videos;
          const videoId = videos && videos.length && videos[0].videoKey;
          if (videoId) {
            Navigator.openVideo(result.groupId, videoId);
          } else { 
            Navigator.openGroup(result.groupId);
          }
        }
      }

    }
  }

}