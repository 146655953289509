import { connect } from 'react-redux';
import { AddAudioConfirm } from '../components/AddAudioConfirm';
import { IState } from '../../../../store/types';
import { getAddAudioConfirm } from '../../../../store/general/selectors';
import { IAddAudioConfirmEvents, IAddAudioConfirmFields } from '../components/types';
import { AddAudioConfirmEvents } from './events';
import { createGroupNameIndex, getGroupTargetLanguage, getNativeLanguage, getUserOwnGroups } from '../../../../store/models/selectors';
import { TUserGroup } from '../../../../store/models/types';
import { LangUtil } from '../../../../../common/utils/lang-util';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IAddAudioConfirmFields => {
		const {
			show: isOpen = false,
			langCode,
		} = getAddAudioConfirm(state) || {};

		if (!isOpen) return {
			isOpen,
			groups: [],
			newListName: '',
		}

		let groups: TUserGroup[] = [];
		const userGroups = getUserOwnGroups(state);
		if (userGroups) {
			groups = userGroups.reduce((arr, item) => {
				const targetLang = getGroupTargetLanguage(state, item)?.code;
				if (targetLang &&
					targetLang === langCode
				) {
					arr.push(item);
				}
				return arr;
			}, groups);
		}

		const nativeLangCode = getNativeLanguage(state)?.code || '';
		let newListName = createGroupNameIndex(state, `${LangUtil.getLangNameByCode(langCode || '')} / ${LangUtil.getLangNameByCode(nativeLangCode)}`);

		return {
			isOpen,
			groups,
			newListName,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IAddAudioConfirmEvents => ({
	onChange: (groupId, newListName) => dispatch(AddAudioConfirmEvents.onChange(groupId, newListName)),
	onClose: () => dispatch(AddAudioConfirmEvents.onClose()),
});

export const AddAudioConfirmHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAudioConfirm);

AddAudioConfirmHOC.displayName = 'AddAudioConfirmHOC';
