import { Box, Button, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { IContentLibGroupInfoProps } from './types';
import { ContentLibGroupTag } from '../../ContentLibGroupTag/ContentLibGroupTag';
import { theme } from '../../../../../../common/styles/theme';
import { CopyNote } from '../../../../../../../icons/CopyNote';
import LangFlag from '../../../LangFlag/LangFlag';
import { VideoIcon } from '../../../../../../../icons/VideoIcon';
import { Text } from '../../../../../../common/components/Text/Text';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';

export function ContentLibGroupInfo({
	id,
	title,
	description,
	nativeLang,
	targetLang,
	videoCount,
	tags,
	isSelected,
	onCopyGroup,
	onSelectGroup,
	onBack,
}: IContentLibGroupInfoProps) {

	const className = 'content-lib-group-info';
	const classControl = className + '__control';

	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

	const handeClick = (e: React.MouseEvent) => {
		const target = e.target as HTMLElement;
		if (target.closest(`.${classControl}`)) return;

		onSelectGroup();
	}

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [title]);

	const titleElement = <Box
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',
			lineHeight: `1.3 !important`,
			
			maxWidth: '80%',
			fontSize: theme.typography.h5,
			fontWeight: 600,
		}}
		ref={ref1}
	>
		<span
			ref={ref2}
		>
			{title}
		</span>
	</Box>

	const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: '80vw',
		},
	});

	return (
		<Stack
			className={`${className}
				${isSelected ? 'selected': ''}
			`}
			direction={'row'}
			gap={2}
			sx={{
				position: 'relative',
				justifyContent: 'space-between',
				width: 1,

				'&:not(.selected)': {
					cursor: 'pointer',
				},

				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column',
				},
			}}
			onClick={handeClick}
		>
			<Stack
				className={`${className}__vertical`}
				gap={theme.spacing(1)}
				sx={{
					flex: `1 1 100%`,
				}}
			>
				<Stack
					direction={'row'}
					alignItems={'center'}
					gap={1}
				>
					{isSelected &&
						<ButtonIconGrey
							className={classControl}
							sx={{
								'.MuiSvgIcon-root': {
									width: theme.spacing(3),
									height: theme.spacing(3),
								},
							}}
							onClick={onBack}
						>
							<ArrowLightLeftIcon />
						</ButtonIconGrey>
					}
					{isLongTitle
						? <NoMaxWidthTooltip
							title={title}
							enterDelay={1000}
						>
							{titleElement}	
						</NoMaxWidthTooltip>
						: titleElement
					}
				</Stack>

				{description &&
					<Box
						sx={{
							display: '-webkit-box',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							'-webkit-line-clamp': '5',
							'-webkit-box-orient': 'vertical',
							maxHeight: '7.7em',
							lineHeight: `1.3 !important`,
							fontSize: theme.typography.subtitle2,
						}}
					>
						{description}
					</Box>
				}
				<Stack
					direction={'row'}
					gap={3}
					rowGap={1}
					flexWrap={'wrap'}
				>
					{/* <User
						name={'testAuthor'}
						photo={'https://i.ytimg.com/vi/9gidul7vqOw/default.jpg'}
					/> */}
					<Stack
						direction={'row'}
						gap={1}
						sx={{
							height: theme.spacing(4),
							'.lang-flag__code': {
								color: theme.palette.grey[500],
							},
						}}
					>
						<LangFlag code={targetLang} showCode={true} />
						<LangFlag code={nativeLang} showCode={true} />
					</Stack>

					<Stack
						direction='row'
						spacing={theme.spacing()}
						alignItems={'center'}
						sx={{
							minWidth: theme.spacing(4.5),
							maxWidth: theme.spacing(4.5),
						}}
					>
						<VideoIcon
							sx={{
								width: theme.spacing(2),
								color: theme.palette.grey[300]
							}}
						/>
						<Text
							sx={{ color: theme.palette.grey[500] }}
						>
							{videoCount}
						</Text>
					</Stack>

					{!!tags && !!tags.length &&
						<Stack
							direction={'row'}
							alignItems={'center'}
							gap={.5}
							flexWrap={'wrap'}
						>
							{tags.map(tag => {
								return <ContentLibGroupTag
									name={tag}
									key={tag}
								/>
							})}
						</Stack>
					}
				</Stack>
			</Stack>

			{isSelected
				? <Button
					className={classControl}
					variant={'contained'}
					startIcon={
						<CopyNote sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }} />
					}
					onClick={onCopyGroup}
				>
					Copy playlist
				</Button>
				: <ButtonMenu
					buttonProps={{
						className: classControl,
						sx: {
							position: 'absolute',
							right: theme.spacing(-1),
							top: theme.spacing(-1),
							height: theme.spacing(5),
							width: theme.spacing(5),
							background: theme.palette.common.white,
						},
					}}
					menuProps={{
						className: classControl,
						keepMounted: true,
					}}
				>
					<>
					<IconMenuItem
						title={'Copy playlist'}
						onClick={onCopyGroup}
					>
						<CopyNote sx={{
							color: theme.palette.primary.main + ' !important',
						}} />
					</IconMenuItem>
					<IconMenuItem
						title={'Open playlist'}
						onClick={onSelectGroup}
					>
						<ArrowLightLeftIcon sx={{
							transform: 'scaleX(-1)',
						}} />
					</IconMenuItem>
					</>
				</ButtonMenu>
			}
		</Stack>
	)
};