import { IState } from '../../../../store/types';
import { getActiveGroupId } from '../../../../store/models/selectors';
import { getCurrentMovieKey } from '../../../../store/current-video/selectors';
import { connect } from 'react-redux';
import { IPhraseListTranslatedEvents, IPhraseListTranslatedFields } from '../components/types';
import { PhraseListTranslatedEvents } from './events';
import { EVocabularyPhraseSaveType, EVocabularyPhraseType } from '../../../../types/common';
import { PhraseDetailsPanelSelectors } from '../../PhraseDetails/selectors';
import { PhraseListSelectors } from '../../../../store/phrase-list/selectors';
import { PhraseListTranslated } from '../components/PhraseListTranslated';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseListTranslatedFields => {
    const PhraseListTranslated = PhraseListSelectors.findPhrasesByTypes(state, [
        EVocabularyPhraseType.DEFAULT,
        EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
      ])
        .reduce((arr: number[], phrase) => {
          if (phrase.saveType === EVocabularyPhraseSaveType.TRANSLATE &&
            phrase.fullPhrase?.trim()?.length)
          {
            arr.push(phrase.id);
          }
          return arr;
        }, []);

    const text = PhraseDetailsPanelSelectors.getText(state);

    return {
      groupId: getActiveGroupId(state) || 0,
      videoId: getCurrentMovieKey(state),
      phraseList: PhraseListTranslated,
      text,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseListTranslatedEvents => ({
    onLoadPhrases: (count) => dispatch(PhraseListTranslatedEvents.onLoadPhrases(count)),
    onAppendPhrases: (offset, count) => dispatch(PhraseListTranslatedEvents.onAppendPhrases(offset, count)),
  }
);

export const PhraseListTranslatedHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseListTranslated);

PhraseListTranslatedHOC.displayName = 'PhraseListTranslatedHOC';
