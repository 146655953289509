import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { IContentLibVideoProps } from './type';
import Stack from '@mui/material/Stack';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';
import { AudioIcon } from '../../../../../../../icons/AudioIcon';
import { DownloadIcon } from '../../../../../../../icons/DownloadIcon';

export function ContentLibVideo({
	title,
	thumbUrl,
	channel,
	isAudio,
	isBig,
	onClick,
	onDelete,
	onGetCopyLink,
	onDownload
}: IContentLibVideoProps) {

	const className = 'content-lib-video';
	const classControl = className + '__control';

	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);
	const [locked, setLocked] = React.useState(false);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [title]);

	const titleElement = <Text
		lineHeight={`1.3 !important`}
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',

			fontSize: theme.typography.subtitle2,
			fontWeight: 600,
		}}
		ref={ref1}
	>
		<span
			ref={ref2}
		>
			{title}
		</span>
	</Text>

	const menuBtnRef = React.useRef<HTMLButtonElement | null>(null);
	const [deleteAnchor, setDeleteAnchor] = React.useState<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = React.useState(false);

	const handleMenuOpen = () => {
		setMenuOpen(true);
	}
	const handleMenuClose = () => {
		setMenuOpen(false);
	}
	const handleConfirmDelete = () => {
		handleMenuClose();
		setDeleteAnchor(menuBtnRef.current);
	}
	const handleDelete = () => {
		setDeleteAnchor(null);
		!!onDelete && onDelete();
	}
	const handeGetCopyLink = () => {
		handleMenuClose();
		!!onGetCopyLink && onGetCopyLink();
	}

	const handeClick = async (e: React.MouseEvent) => {
		if (locked) return;
		const target = e.target as HTMLElement;
		if (target.closest(`.${classControl}`)) return;

		setLocked(true)
		try {
			await onClick();
		} finally {
			setLocked(false)
		}
	}

	const img = isAudio
		? <Stack
			justifyContent={'center'}
			alignItems={'center'}
			sx={{
				aspectRatio: 16/9,
				background: theme.palette.grey[50],
				borderRadius: theme.spacing(1),

				[`.${className}.big &`]: {
					minWidth: theme.spacing(16),
				},
			}}
		>
			<AudioIcon
				sx={{
					width: theme.spacing(5),
					height: theme.spacing(5),
					color: theme.palette.grey[400],
				}}
			/>
		</Stack>
		: <Box
			sx={{
				aspectRatio: 16/9,
				backgroundPosition: '50% 50%',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundImage: `url(${thumbUrl})`,
				borderRadius: theme.spacing(1),

				[`.${className}.big &`]: {
					minWidth: theme.spacing(16),
				},
			}}
		/>;

	const text = <Stack
		gap={.25}
		sx={{
			pr: theme.spacing(4),
		}}
	>
		{isLongTitle
			? <Tooltip
				enterDelay={1000}
				title={title}
			>
				{titleElement}	
			</Tooltip>
			: titleElement
		}

		<Text
			sx={{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				fontSize: theme.typography.subtitle2,
				color: theme.palette.grey[500],
				whiteSpace: 'nowrap',
			}}
		>
			{channel}
		</Text>
	</Stack>;

	const menu = (!!onDelete || !!onGetCopyLink || !!onDownload) && <>
		<ButtonMenu
			buttonProps={{
				className: `${className}__menu ${classControl} ${menuOpen ? 'opened' : ''}`,
				ref: menuBtnRef,
				sx: {
					position: 'absolute',
					right: theme.spacing(-1),
					top: theme.spacing(-1),
					height: theme.spacing(5),
					width: theme.spacing(5),
					background: theme.palette.common.white,
					opacity: 0,
					transition: 'opacity .3s ease',

					'&.opened': {
						opacity: 1,
					},

					[`.${className}.big &`]: {
						top: theme.spacing(.5),
						opacity: 1,
					},
				},
			}}
			menuProps={{
				className: classControl,
				keepMounted: true,
				open: !!menuOpen,
			}}
			onOpen={handleMenuOpen}
			onClose={handleMenuClose}
		>
			<>
			{!!onGetCopyLink &&
				<IconMenuItem
					title={'Share public link'}
					onClick={handeGetCopyLink}
				>
					<LinkIcon />
				</IconMenuItem>
			}
			{!!onDownload &&
			<IconMenuItem
				title={'Download'}
				onClick={onDownload}
			>
				<DownloadIcon />
			</IconMenuItem>
			}
			{!!onDelete &&
				<IconMenuItem
					title={'Delete'}
					onClick={handleConfirmDelete}
				>
					<TrashIcon />
				</IconMenuItem>
			}
			</>
		</ButtonMenu>

		<ConfirmPopup
			anchor={deleteAnchor}
			text={'Delete video?'}
			onConfirm={handleDelete}
			onClose={() => setDeleteAnchor(null)}
			popoverProps={{
				className: classControl,
			}}
		/>
	</>;

	return (
		<Stack
			className={`${className} ${isBig ? 'big' : ''}`}
			sx={{
				position: 'relative',
				flex: `1 1 100%`,
				minWidth: 0,
				cursor: 'pointer',

				[`&:hover .${className}__menu`]: {
					opacity: 1,
				},

				'&.big': {
					flexDirection: 'row',
					alignItems: 'center',
					gap: 2,
				},
			}}
			gap={1}
			onClick={handeClick}
		>
			{img}
			{menu}
			{text}
		</Stack>

	)
};