import { AppThunk } from '../../../../../store/types';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { AuthUserEffects } from '../../../../../effects/authUserEffects';
import { LoginPopupActions } from '../../../../../store/loginPopup/actions';
import { LoginPopupSelectors } from '../../../../../store/loginPopup/selectors';

export class LoginPopupEvents {

	public static onClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(LoginPopupActions.set({show: false}));
		}
	}

	public static onLogin(): AppThunk {
		return async (
			dispatch,
			getState
		): Promise<boolean> => {
			EventsRouter.trackEvent(Events.SIGN_IN_CLICKED);
			const result = await AuthUserEffects.loginProcess(AuthUserEffects.newUserDefaultHandler, '', '', true);
			const ret = !!result && !!result.authUser;
			if (ret) {
				if (!result.authUser.isNew) {
					const {onLogin} = LoginPopupSelectors.getLoginPopup(getState());
					if (onLogin) onLogin();
				}
				dispatch(this.onClose());
				
			}
			return ret;
		}
	}

}