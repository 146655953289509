import { AppThunk } from '../../../../../store/types';
import { getActiveGroupId, getActiveGroupTargetLanguage } from '../../../../../store/models/selectors';
import { TextActions } from '../../../../../store/text/actions';
import { TextSelectors } from '../../../../../store/text/selectors';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';
import { AudioEffects } from '../../../../../effects/audioEffects';
import DOMPurify from 'dompurify';

export class StoryFromTranslatedButtonsEvents {

	public static onGenerateAudio (
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const state = getState();
			const videoKey = getCurrentMovieKey(state);
			const textKey = TextSelectors.getTextActiveId(state);
			const captions = TextSelectors.getTextByKey(state, videoKey, textKey);
			const text = captions?.captions && captions?.captions.length &&
				captions?.captions.reduce((text, caption) => {
					const doc = new DOMParser().parseFromString(DOMPurify.sanitize(caption.text), 'text/html');
					let p = doc.body.textContent || doc.body.innerText;
					return text + p + '\n\n';
				}, '');
			const groupId = getActiveGroupId(state);
			const langCode = getActiveGroupTargetLanguage(state)?.code;
			return AudioEffects.add(text || '', langCode, groupId || 0);
		}
	}

	public static onClose (
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(TextActions.update({
				activeId: null as any,
			}));
		}
	}

}