import { AppThunk } from '../../../../../store/types';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { IPhraseNote } from '../../../../../types/common';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import { Navigator } from '../../../../../effects/navigator';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { getCurrentVideoClickedCaptionIndex } from '../../../../../store/current-video/selectors';
import { PhraseNoteImageEffects } from '../../../../../effects/phrase/phraseNoteImageEffects';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';

export class PhraseNotesEvents {

  public static onSave(
    note: Partial<IPhraseNote>
  ): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const mainViewMode = getMainViewMode(getState());
      if (mainViewMode === EMainViewMode.PHRASE_DETAILS) {
        history.back();
      }

      let currentNote = PhraseDetailsSelectors.getCurrentNote(getState());
      if (currentNote) {
        return PhraseNoteEffects.updateNote(currentNote.id, note.text, note.pause, '');
      }
      if (note.text) {
        const selectResult = CaptionsSelectionPopupSelectors.getSelectResult(getState());
        const clickedCaptionIndex = getCurrentVideoClickedCaptionIndex(getState());
        return PhraseNoteEffects.saveNote(note.text, note.pause as boolean, selectResult, clickedCaptionIndex, true);
      }
      return false; // cannot save without text
    }
  }

  public static onUpdate(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
    }
  }

  public static onClickAuthor(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const note = PhraseDetailsSelectors.getCurrentNote(getState());
      const id = note?.authorInfo?.id;
      if (id) {
        Navigator.openProfile(id);
      }
    }
  }

  public static onLoadImage(data: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return await PhraseNoteImageEffects.upload(data);
    }
  }

  public static onBack(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseListActions.setNoteId(null as any));
      const tab = PhraseDetailsEffects.getDefaultTab();
      if (tab) {
        dispatch(PhraseDetailsActions.setActiveTab(tab));
      }
      // const prevTab = PhraseDetailsSelectors.getPrevTab(getState());
      // if (prevTab && prevTab.type !== EPhraseDetailsTabType.NOTES) {
      //   dispatch(PhraseDetailsActions.setActiveTab(prevTab));
      // } else {
      //   const tab = PhraseDetailsEffects.getDefaultTab();
      //   if (tab) {
      //     dispatch(PhraseDetailsActions.setActiveTab(tab));
      //   }
      // }
    }
  }

}