import { AppThunk } from '../../../../../store/types';
import { History } from 'history';
import { AuthManager } from '../../../../../../common/utils/auth-manager';
import { prepareAfterOpen } from '../../../../../effects/prepareAfterOpen';
import { getAuthUser, getPublicProfile } from '../../../../../store/general/selectors';
import { setAuthUserAction, setCreatePublicProfileModalOpenAction } from '../../../../../store/general/actions';
import { EExtMessageType, ExtMessageSender } from '../../../../../../common/utils/ext-message-sender';
import { ADMIN_PANEL_URL } from '../../../../../../common/constants';
import { Navigator } from '../../../../../effects/navigator';
import { resetModelsAction } from '../../../../../store/models/actions';
import { UserRest } from '../../../../../../common/rest/user/userRest';

export class AccountButtonEvents {

  public static onSwitchAccount(
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const result = await AuthManager.login();
      if (result && result.authUser) {
        dispatch(resetModelsAction());
        dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
        Navigator.openDefault();
      }
    }
  }

  public static onSignOut(
  ): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const user = getAuthUser(getState());
      if (user) {
        await AuthManager.logout();
        dispatch(setAuthUserAction(null));
        await ExtMessageSender.send({
          type: EExtMessageType.SET_USER_GROUPS,
          payload: {userGroups: []}
        });
        await ExtMessageSender.send({
          type: EExtMessageType.SET_AUTH_USER,
          payload: {authUser: null}
        });
        Navigator.openDefault();
      }
    }
  }

  public static onShowAdminPanel(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      await UserRest.loginAdmin();
      window.open(ADMIN_PANEL_URL);
    }
  }

  public static onLoginAs(
    email: string
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const result = await AuthManager.loginByEmail(email);
      if (result && result.authUser) {
        dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
        Navigator.openDefault();
      }
    }
  }

  public static onOpenPublicAccount(
  ): AppThunk {
    return async (
        dispatch,
        getState
    ) => {
      const publicProfile = getPublicProfile(getState());
      if (publicProfile && publicProfile.userName) {
        Navigator.openPublicProfile(publicProfile.userName);
      } else {
        dispatch(setCreatePublicProfileModalOpenAction(true));
      }
    }
  }

}
