import { IMyLibProps } from './types';
import React from 'react';
import { Button, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';
import { Text } from '../../../../../../common/components/Text/Text';
import { EditPlaylistItemHOC } from '../../../PlayList/EditPlayList/hocs/EditPlaylistItem/EditPlaylistItemHOC';
import { CreatePlaylistHOC } from '../../../PlayList/CreatePlaylist/hocs/CreatePlaylistHOC';
import { EditGroupHOC } from '../../../PlayList/EditGroup/hocs/EditGroupHOC';

export function MyLib({
	groups,
	onShowAddPlaylist
}: IMyLibProps) {

	return (
		<Stack
			gap={2}
			sx={{
				py: theme.spacing(2),
			}}
		>
			<Stack sx={{
				alignItems: 'center',
			}}>
				<Button
					variant={'contained'}
					startIcon={<PlusIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />}
					onClick={onShowAddPlaylist}
					sx={{
						width: 1,
						maxWidth: theme.spacing(50),
					}}
				>
					Create Playlist
				</Button>
			</Stack>

			<Stack
				gap={4}
			>
				{!groups || !groups.length
					? <Stack sx={{ alignItems: 'center', p: theme.spacing(2) }}>
						<Text fontWeight={600}>
							You have no playlists yet
						</Text>
					</Stack>
					: groups.map((group, index) => {
						return (
							<Stack
								key={group.id}
							>
								<EditPlaylistItemHOC groupId={group.id} />
							</Stack>
						);
					})
				}
			</Stack>

			<CreatePlaylistHOC />
			<EditGroupHOC />
		</Stack>
	);
}
