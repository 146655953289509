import { getDispatch, getState } from '../store';
import { setLibraryGroupIdAction } from '../store/general/actions';
import { getUserStateStorage } from '../../common/utils/local-storage/user-state';
import { TUserGroup, TUserLanguage } from '../store/models/types';
import { LangUtil } from '../../common/utils/lang-util';
import { ELanguageStatus, EUserLevel } from '../store/general/types';
import { createUserGroup } from '../../common/rest/userGroup/createUserGroup';
import {
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById,
  getUserOwnGroups
} from '../store/models/selectors';
import { updateUserGroup } from '../../common/rest/userGroup/updateUserGroup';
import { addGroup, refreshGroupAction, updateGroupAction } from '../store/models/actions';
import { fetchUserGroup } from '../../common/rest/userGroup/fetchUserGroup';
import { IVideoMetaInfo, VideoMetaInfo } from '../../common/utils/videoMetaInfo';
import { IYoutubeVideoInfo } from '../types/common';
import { YoutubeUrlUtil } from '../../common/utils/youtube-url-util';

export class UserGroupEffects {

  public static setLibraryGroupId(groupId: number) {
    const dispatch = getDispatch();
    dispatch(setLibraryGroupIdAction(groupId));
    getUserStateStorage().setLibraryGroupId(groupId);
  }

  public static async createGroup(title: string, targetLangCode: string, nativeLangCode: string, createDefVideos: boolean = false, publicGroup: boolean = false,
                                  level: EUserLevel = EUserLevel.UNKNOWN, description: string = ''): Promise<number> {
    const dispatch = getDispatch();
    const nativeLanguage: TUserLanguage = {name: LangUtil.getLangNameByCode(nativeLangCode), code: nativeLangCode, status: ELanguageStatus.KNOW };
    const targetLanguage: TUserLanguage = {name: LangUtil.getLangNameByCode(targetLangCode), code: targetLangCode, status: ELanguageStatus.TO_LEARN };
    return dispatch(createUserGroup(title, [nativeLanguage, targetLanguage], createDefVideos, publicGroup, level, description));
  }

  public static async updateGroup(
    groupId: number,
    name: string,
    description?: string,
    targetLangCode?: string,
    nativeLangCode?: string,
    publicGroup?: boolean,
    level: EUserLevel = EUserLevel.UNKNOWN
  ) {
    const dispatch = getDispatch();
    const group = getUserGroupById(getState(), groupId);
    if (group && (group.name != name || group.description != description || targetLangCode || nativeLangCode || group.public != publicGroup || group.level != level)) {
      await dispatch(updateUserGroup({
        id: groupId,
        name: name,
        description,
        targetLangCode,
        nativeLangCode,
        publicGroup,
        level
      }));
      let languages = undefined;
      if (targetLangCode && nativeLangCode) {
        const targetLang: TUserLanguage = {
          ...LangUtil.getLanguageItemByCode(targetLangCode),
          status: ELanguageStatus.TO_LEARN
        }
        const nativeLang: TUserLanguage = {
          ...LangUtil.getLanguageItemByCode(nativeLangCode),
          status: ELanguageStatus.KNOW
        }
        languages = [targetLang, nativeLang]
      }
      dispatch(updateGroupAction(groupId, name, description, languages, publicGroup, level));
    }
  }


  public static async loadGroup(groupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    const createdGroup = await dispatch(fetchUserGroup(groupId));
    dispatch(addGroup(createdGroup));
    return createdGroup;
  }

  public static async refreshGroup(groupId: number): Promise<TUserGroup> {
    const dispatch = getDispatch();
    const group = await dispatch(fetchUserGroup(groupId));
    dispatch(refreshGroupAction(group));
    return group;
  }

  public static async findCorrectGroupIdByVideo(videoUrl: string, metaInfo: IVideoMetaInfo, videoLangCodes: string[]): Promise<number> {
    const defLangCode = LangUtil.checkLangCode(metaInfo?.videoInfo?.defaultAudioLanguage);
    if (!defLangCode || !videoLangCodes.includes(defLangCode))
      return null;

    const state = getState();
    const groups = getUserOwnGroups(state).map(group => {
      const targetLang = getGroupTargetLanguage(state, group)?.code;
      const nativeLang = getGroupNativeLanguage(state, group)?.code;
      return {
        groupId: group.id,
        targetLang,
        nativeLang
      }
    })

    const result = groups.find(g => {
        return g.targetLang === defLangCode;
    })
    return result?.groupId || 0;
  }
}
