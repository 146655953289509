export interface IPhraseTranslationEditFields {
  show: boolean,
  text: string
}

export interface IPhraseTranslationEditEvents {
  onChange: (text: string) => void;
  onClose: () => void;
}

export interface IPhraseTranslationEditProps extends
  IPhraseTranslationEditFields,
  IPhraseTranslationEditEvents
{}

export const PHRASE_DETAILS_TRANSLATION_EDITOR_CLASS_NAME = 'phrase-translation-editor';