import { TLanguageCode } from '../../../../store/models/types';
import { AppThunk } from '../../../../store/types';
import { Navigator } from '../../../../effects/navigator';
import { LoginUserEffects } from '../../../../effects/loginUserEffects';
import { EventsRouter } from '../../../../../common/events/eventsRouter';
import { Events } from '../../../../../common/events/types';
import { setAuthUserAction, setCopyVideoOnLoginAction, setMainViewMode } from '../../../../store/general/actions';
import { updateUserLanguages } from '../../../../effects/updateUserLanguages';
import { UserRoleEffects } from '../../../../effects/userRoleEffects';
import { getAuthUser } from '../../../../store/general/selectors';
import { AuthManager } from '../../../../../common/utils/auth-manager';
import { getUserRoles } from '../../../../store/models/selectors';
import { EMainViewMode, EUserLevel } from '../../../../store/general/types';
import { ContentLibActions } from '../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../store/contentLib/types';


export class InstallSelectLangsEvents {

  public static onSave(nativeLangCode: TLanguageCode, targetLangCode: TLanguageCode, roles: number[], level: EUserLevel) : AppThunk {
    return async(
      dispatch,
      getState
    ): Promise<any> => {
      EventsRouter.trackEvent(Events.GET_STARTED_CLICKED);
      try {
        await LoginUserEffects.prepareLangsOnInstall(nativeLangCode, targetLangCode, '', true, level);
        await UserRoleEffects.saveUserRoles(roles);

        if (roles && roles.length) {
          const userRoles = getUserRoles(getState());
          roles.forEach(roleId => {
            const role = userRoles.find(item => item.id === roleId)?.name;
            if (role) EventsRouter.trackEvent(Events.USER_ROLE_SELECTED, undefined, { role });
          });
        }
      } catch(e) {
        console.error(e)
      }
      // dispatch(setCopyVideoOnLoginAction({newUser: true}));
      Navigator.openMyStuff();
      dispatch(setMainViewMode(EMainViewMode.MY_LIBRARY));
      dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST));
      // Navigator.openDefault();
    }
  }

  public static onLoad() : AppThunk {
    return async(
      dispatch,
      getState
    ): Promise<any> => {
      const authUser = getAuthUser(getState());
      if (!authUser) {
        const result = await AuthManager.autoLogin();
        if (result && result.authUser) {
          dispatch(setAuthUserAction(result.authUser));
        }
      }
      dispatch(updateUserLanguages(false));
      UserRoleEffects.loadUserRoles();
    }
  }


}
