import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import {
  CONTENT_LIB_USER_VIDEOS_SEPARATOR,
  IContentLibVideoListEvents,
  IContentLibVideoListFields
} from '../components/types';
import { ContentLibVideoListEvents } from './events';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibVideoList } from '../components/ContentLibVideoList';
import { findLanguageByCode, getAllUserVideoKeys } from '../../../../../store/models/selectors';

const mapStateToProps = (
) => {
  return (
    state: IState
  ): IContentLibVideoListFields => {
    const {text: searchText, targetLangCode, nativeLangCode} = ContentLibSelectors.getSearchFilter(state);
    const userVideos: string[] = getAllUserVideoKeys(state);
    const targetLangId = findLanguageByCode(state, targetLangCode)?.id || 0;

    return {
      groupId: ContentLibSelectors.getSelectedGroupId(state),
      searchText,
      targetLangCode,
      targetLangId,
      nativeLangCode,
      userVideosLine: userVideos.join(CONTENT_LIB_USER_VIDEOS_SEPARATOR), // fix empty video list on useeffect userVideos array
      lockedMode: ContentLibSelectors.isLockedMode(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibVideoListEvents => ({
    onLoadVideos: (startIndex, count) => dispatch(ContentLibVideoListEvents.onLoadVideos(startIndex, count)),
    onVideoClick: videoId => dispatch(ContentLibVideoListEvents.onVideoClick(videoId)),
    onClickMyStuff: () => dispatch(ContentLibVideoListEvents.onClickMyStuff()),
  }
);

export const ContentLibVideoListHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibVideoList);

ContentLibVideoListHOC.displayName = 'ContentLibVideoListHOC';