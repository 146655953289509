import React from 'react';
import { ContentLibGroupHOC } from '../../ContentLibGroup/hocs/ContentLibGroupHOC';
import { IContentLibGroupListProps } from './types';
import { Box, Button, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { LibraryIcon } from '../../../../../../../icons/LibraryIcon';


export const ContentLibGroupList: React.FC<IContentLibGroupListProps> = ({
  viewMode,
  groups,
  loading,
  text,
  nativeLangCode,
  targetLangCode,
  onSearch,
  onClickMyStuff,
}) => {

  React.useEffect(() => {
    onSearch();
  }, [text, nativeLangCode, targetLangCode])

  const groupsNotFound = groups && groups.length === 0;

  if (groupsNotFound) {
    return <Box
      sx={{
        py: theme.spacing(3),
        height: 1,
      }}
    >
      <Stack
        gap={3}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          p: theme.spacing(2),
          height: 1,
          minHeight: '50vh',
        }}
      >
        <Text
          sx={{
            fontSize: theme.typography.h5,
            color: theme.palette.grey[500],
          }}
        >
          There are no playlists for selected language
        </Text>

        <Text
          sx={{
            fontSize: theme.typography.subtitle2,
            fontWeight: 300,
          }}
        >
          Go to
          {' '}
          <Button
            variant={'contained'}
            sx={{
              px: theme.spacing(.5),
              color: theme.palette.grey[900],
              background: 'transparent',

              '&:hover': {
                background: theme.palette.grey[50],
              }
            }}
            onClick={onClickMyStuff}
          >
            <LibraryIcon
              sx={{
                mr: theme.spacing(1),
                color: theme.palette.primary.main,
              }}
            />
            My Stuff
          </Button>
          {' '}
          section and add content from there...
        </Text>

      </Stack>
    </Box>
  }

  if (loading && !groups?.length && !text) {
    return  <Stack
      sx={{
        width: 1,
        height: 1,
        p: 4,
      }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <CircularProgress />
    </Stack>
  }

  return <>
    {groups?.map(group => {
     /* if (viewMode === EContentLibViewMode.VIDEO_LIST) {
        return <ContentLibGroupDetailsHOC
          key={group.id}
          groupId={group.id}
        />
      }*/
      return <ContentLibGroupHOC
        key={group.id}
        groupId={group.id}
      />
    })}
  </>
};
