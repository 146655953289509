import { connect } from 'react-redux';
import { IPhraseTranslationEditEvents, IPhraseTranslationEditFields } from '../components/types';
import { PhraseTranslationEdit } from '../components/PhraseTranslationEdit';
import { IState } from '../../../../../store/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseTranslationEditEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseTranslationEditFields => {
    const edit = PhraseDetailsSelectors.getTranslateEdit(state);
    const show = PhraseDetailsSelectors.getTranslateEdit(state)?.enabled
    return {
      show: edit?.enabled || false,
      text: edit?.text || ''
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseTranslationEditEvents => ({
    onChange: (text) => dispatch(PhraseTranslationEditEvents.onChange(text)),
    onClose: () => dispatch(PhraseTranslationEditEvents.onClose()),
  }
);

export const PhraseTranslationEditHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseTranslationEdit);

PhraseTranslationEditHOC.displayName = 'PhraseTranslationEditHOC';
