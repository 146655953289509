import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { Navigator } from '../../../../../effects/navigator';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';

export class ContentLibGroupListEvents {

  public static onSearch(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      ContentLibEffects.searchGroups();
    }
  }

  public static onClickMyStuff (): AppThunk {
    return (
      dispatch,
      getState,
    ) => {
      Navigator.openMyStuff();
      dispatch(setMainViewMode(EMainViewMode.MY_LIBRARY));
      dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST));
    }
  }

}