import { Reducer } from 'redux';
import {
  CONTENT_LIB_SET_GROUP_COPY_CONFIRM_ACTION, CONTENT_LIB_SET_LANGS_ACTION,
  CONTENT_LIB_SET_SEARCH_FILTER_ACTION,
  CONTENT_LIB_SET_GROUPS_SEARCH_RESULT_ACTION, CONTENT_LIB_SET_SELECTED_GROUP_ACTION,
  CONTENT_LIB_SET_VIDEO_COPY_CONFIRM_ACTION,
  CONTENT_LIB_SET_VIDEO_POPUP_ACTION,
  CONTENT_LIB_SET_VIEW_MODE_ACTION,
  EContentLibViewMode,
  IContentLibState,
  TContentLibActions
} from './types';

const initialState: IContentLibState = {
  searchFilter: {
    targetLangCode: '',
    nativeLangCode: '',
    text: ''
  },
  groupsSearchResult: {
    groups: null,
    loading: false
  },
  videoPopup: {
    groupId: 0,
    videoId: 0
  },
  viewMode: EContentLibViewMode.VIDEO_LIST,
  selectedGroupId: 0,
  copyGroupConfirm: {
    show: false
  },
  copyVideoConfirm: {
    show: false
  },
  langs: {
    nativeLangs: [],
    targetLangs: []
  }
};

export const contentLibReducer: Reducer<IContentLibState, TContentLibActions> = (
  state = initialState,
  action
) => {

  switch (action.type) {
    case CONTENT_LIB_SET_GROUPS_SEARCH_RESULT_ACTION:
      return {
        ...state,
        groupsSearchResult: {...state.groupsSearchResult, ...action.groupsSearchResult}
      };
    case CONTENT_LIB_SET_SEARCH_FILTER_ACTION:
      return {
        ...state,
        searchFilter: {...state.searchFilter, ...action.searchFilter}
      };
    case CONTENT_LIB_SET_VIEW_MODE_ACTION:
      const group = action.viewMode === EContentLibViewMode.GROUP_VIDEO_LIST
        ? {}
        : {
          selectedGroupId : null,
        };
      return {
        ...state,
        viewMode: action.viewMode,
        ...group,
      };
    case CONTENT_LIB_SET_VIDEO_POPUP_ACTION:
      return {
        ...state,
        videoPopup: {...state.videoPopup, ...action.videoPopup}
      }
    case CONTENT_LIB_SET_VIDEO_COPY_CONFIRM_ACTION:
      return {
        ...state,
        copyVideoConfirm:  {...state.copyVideoConfirm, ...action.copyVideoConfirm}
      }
    case CONTENT_LIB_SET_GROUP_COPY_CONFIRM_ACTION:
      return {
        ...state,
        copyGroupConfirm:  {...state.copyGroupConfirm, ...action.copyGroupConfirm}
      }
    case CONTENT_LIB_SET_SELECTED_GROUP_ACTION:
      return {
        ...state,
        selectedGroupId: action.selectedGroupId
      };
    case CONTENT_LIB_SET_LANGS_ACTION:
      return {
        ...state,
        langs: {...action.langs}
      };
    default:
      return state;
  }
};