import React from 'react';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { Box, Button, Stack } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { ICaptionsFixAiPopupProps } from './types';
import { Text } from '../../../../../common/components/Text/Text';
import { FIX_SUBTITLES_BUTTON_CANCEL, FIX_SUBTITLES_BUTTON_FIX, FIX_SUBTITLES_BUTTON_NO, FIX_SUBTITLES_TEXT, FIX_SUBTITLES_TITLE } from './wordings';

enum EWorkStatus {
  NOT_RUN,
  RUN,
  SUCCESS,
  ERROR
}

export const CaptionsFixAiPopup: React.FC<ICaptionsFixAiPopupProps> = ({
  isOpen,
  groupId,
  videoId,
  onStart,
  onClose
}) => {

  if (!isOpen) return null;

  const [workStatus, setWorkStatus] = React.useState<EWorkStatus>(EWorkStatus.NOT_RUN);
  const [progressMessage, setProgressMessage] = React.useState<string>('');

  const { isMobile } = React.useContext(DeviceContext);

  React.useEffect(() => {
    if (groupId && videoId) {
      setWorkStatus(EWorkStatus.NOT_RUN);
      setProgressMessage('');
    }
  }, [groupId, videoId])

  const onProgress = React.useCallback((handledCount: number, total: number) => {
    let percent = Math.round(handledCount / total * 100);
    if (percent > 100) percent = 100;
    setProgressMessage(percent + '% handled');
  }, []);

  const onSuccess = React.useCallback((): Promise<any> => {
    return new Promise<any>(resolve => {
      setWorkStatus(EWorkStatus.SUCCESS);
      setProgressMessage('Process has been successfully completed');
      setTimeout(() => {
        onClose();
        resolve(0);
      }, 2000)
    })

  }, []);

  const onError = React.useCallback((error: string) => {
    setWorkStatus(EWorkStatus.ERROR);
    setProgressMessage('The error has been occurred: ' + error);
  }, []);

  const handleCancel = () => {
    if (workStatus === EWorkStatus.RUN) {
        return onClose();
    }
  }

  const onStartHandle = () => {
    onStart(onProgress, onSuccess, onError);
    setWorkStatus(EWorkStatus.RUN);
  }

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      isMobile={isMobile}
      PaperProps={{
        sx: {
          width: 1,
          maxWidth: `${theme.spacing(62)} !important`,
        }
      }}
      title={FIX_SUBTITLES_TITLE}
    >
      <Stack
        gap={theme.spacing(2)}
        alignItems={'center'}
      >
        <Text
          variant={'subtitle2'}
          sx={{
            textAlign: 'center',
          }}
        >
          {FIX_SUBTITLES_TEXT}
        </Text>

        {workStatus !== EWorkStatus.NOT_RUN &&
          <Text
            variant='h6'
            fontWeight={600}
            sx={{
              textAlign: 'center',
            }}
          >
            {progressMessage}
          </Text>
        }

        {workStatus === EWorkStatus.NOT_RUN &&
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={theme.spacing(1)}
          >
            <Button
              variant={'outlined'}
              onClick={onClose}
            >
              {FIX_SUBTITLES_BUTTON_NO}
            </Button>
            <Button
              variant={'contained'}
              onClick={onStartHandle}
            >
              {FIX_SUBTITLES_BUTTON_FIX}
            </Button>
          </Stack>
        }

        {workStatus === EWorkStatus.RUN &&
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Button
              variant={'contained'}
              onClick={handleCancel}
            >
              {FIX_SUBTITLES_BUTTON_CANCEL}
            </Button>
          </Stack>
        }
      </Stack>
    </Modal>
  );
}
