import React from 'react';
import { theme } from '../../../../../common/styles/theme';
import { alpha, IconButton, Tooltip } from '@mui/material';
import { HelpIcon } from '../../../../../../icons/HelpIcon';

export const ButtonHelp = ({
	onClick
}) => {

	return (
		<Tooltip
			placement={'left'}
			title={`Help`}
		>
			<IconButton
				className={'button-help'}
				sx={{
					width: theme.spacing(4),
					height: theme.spacing(4),
					mt: theme.spacing(1),
					color: theme.palette.grey[400],
					borderRadius: '50%',
					transition: 'all .3s ease',
					
					'&:hover': {
						background: alpha(theme.palette.common.white, .75),
					},
					
					'.MuiSvgIcon-root': {
						width: theme.spacing(2.5),
						height: theme.spacing(2.5),
					},
				}}
				onClick={onClick}
			>
				<HelpIcon />
			</IconButton>
		</Tooltip>
	);
};