import React from 'react';
import { useParams } from 'react-router-dom';
import { theme } from '../../../../../common/styles/theme';
import { Stack } from '@mui/material';
import { Paper } from '../../../../../common/components/Paper/Paper';
import { IAudioProps } from './types';
import { AudioCaptionsContainerHOC } from '../../AudioCaptions/CaptionsContainer/hocs/AudioCaptionsContainerHOC';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
// @ts-ignore
import css from '!!raw-loader!react-h5-audio-player/lib/styles.css';
import { createGlobalStyle } from 'styled-components';
import { PlayButton } from '../../PlayButton/components/PlayButton';

const PlayerStyle = createGlobalStyle`${css}`;
import { EPhrasesColumnType } from '../../../../store/general/types';
import { PhraseNotesPopupHOC } from '../../PhraseDetails/PhraseNotesPopup/hocs/PhraseNotesPopupHOC';

export const Audio: React.FC<IAudioProps> = ({
	groupsLoaded,
  	phrasesColumn,
	audioUrl,
	onLoad,
	onUpdateAudioPlayer,
	onUpdateGroupId,
}) => {
	let { audioId, groupId } = useParams();
	
	const elemRef = React.useRef(null);
	React.useEffect(() => {
		if (elemRef.current) {
			onUpdateAudioPlayer(elemRef.current.audio.current);
		}
	}, [audioUrl]);

	React.useEffect(() => {
		if (groupsLoaded) {
			const gId = parseInt(groupId);
			const aId = parseInt(audioId);
			onLoad(aId, gId);
		}
	}, [groupId, audioId, groupsLoaded])

	React.useEffect(() => {
		onUpdateGroupId(parseInt(groupId));
	  }, [groupId]);

	return (
		<Stack
			className={`video-column
        	${phrasesColumn === EPhrasesColumnType.COLUMNS_1 ? 'phrases-column-1' : ''}
			${phrasesColumn === EPhrasesColumnType.HIDE ? 'phrases-column-0' : ''}
      	`}
			gap={2}
			sx={{
				flex: `1 1 33%`,
				minWidth: 0,

				[`&.phrases-column-1`]: {
					flexBasis: '50%',
				},
			}}
		>
			<Paper
				sx={{
					p: theme.spacing(1),

					'.rhap_container': {
						position: 'relative',
						p: 0,
						background: 'none',
  						boxShadow: 'none',
					},

					'.rhap_time': {
						fontFamily: 'monospace',
						fontSize: theme.typography.fontSize,
						color: theme.palette.grey[500],
					},

					'.rhap_progress-section': {
						height: theme.spacing(4),
						pl: theme.spacing(4),
					},

					'.rhap_stacked .rhap_controls-section': {
						m: 0,
						position: 'absolute',
						left: 0,
						top: 0,
					},

					'.rhap_main-controls': {
						width: theme.spacing(4),
						height: theme.spacing(4),
					},

					'.rhap_main-controls-button': {
						m: 0,
						color: theme.palette.primary.main,
						outline: 'none',
					},

					'.rhap_progress-indicator': {
						background: theme.palette.primary.main,
						boxShadow: 'none',
						transform: 'scale(0)',
						transition: 'transform .3s ease',
					},

					'.rhap_progress-bar, .rhap_download-progress': {
						background: theme.palette.primary[100],
					},

					'.rhap_progress-container': {
						outline: 'none',
						'&:hover': {
							'.rhap_progress-indicator': {
								transform: 'scale(1)',
							},
						},
					},

					'.rhap_progress-filled': {
						background: theme.palette.primary.main,
					},

					'.phrases-column-0 &': {
						pr: theme.spacing(3),
					},
				}}
			>
				{audioUrl &&
					<AudioPlayer
						src={audioUrl}
						showJumpControls={false}
						customProgressBarSection={[
							RHAP_UI.PROGRESS_BAR,
							RHAP_UI.CURRENT_LEFT_TIME,
						]}
						customControlsSection={[
							RHAP_UI.MAIN_CONTROLS,
						]}
						customIcons={{
							play: <PlayButton
								isPlay={false}
								onClick={()=>{}}
							/>,
							pause: <PlayButton
								isPlay={true}
								onClick={()=>{}}
							/>,
						}}
						ref={elemRef}
					/>
				}
			</Paper>

			<AudioCaptionsContainerHOC />
			<PlayerStyle />

			<PhraseNotesPopupHOC />
		</Stack>
	);
};