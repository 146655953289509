import {
  APPEND_PHRASE_LIST,
  EPhraseListMode,
  EPhraseListPlayMode,
  EPhraseListViewType,
  IPhraseListAppend,
  IPhraseListCurrentPhraseId,
  IPhraseListDeletePhrase, IPhraseListForwardPhraseId,
  IPhraseListIsNotePopup,
  IPhraseListMode,
  IPhraseListNoteId,
  IPhraseListPauseDelay,
  IPhraseListPlayCaptionByPhraseId,
  IPhraseListPlayMode,
  IPhraseListPlayOffset,
  IPhraseListPlayPhraseId,
  IPhraseListRemoveByGroup,
  IPhraseListRemoveByVideoGroup,
  IPhraseListSet,
  IPhraseListTopPhraseId,
  IPhraseListUpdatePhrase, IPhrasePlayOffset,
  ISetPhraseListViewTypeAction,
  PHRASE_LIST_DELETE_PHRASE,
  PHRASE_LIST_REMOVE_BY_GROUP,
  PHRASE_LIST_REMOVE_BY_VIDEO_GROUP,
  PHRASE_LIST_UPDATE_PHRASE,
  SET_PHRASE_LIST,
  SET_PHRASE_LIST_CURRENT_PHRASE_ID, SET_PHRASE_LIST_FORWARD_PHRASE_ID,
  SET_PHRASE_LIST_IS_NOTE_POPUP,
  SET_PHRASE_LIST_LIST_MODE,
  SET_PHRASE_LIST_NOTE_ID,
  SET_PHRASE_LIST_PAUSE_DELAY,
  SET_PHRASE_LIST_PLAY_CAPTION_BY_PHRASE_ID,
  SET_PHRASE_LIST_PLAY_MODE,
  SET_PHRASE_LIST_PLAY_OFFSET,
  SET_PHRASE_LIST_PLAY_PHRASE_ID,
  SET_PHRASE_LIST_TOP_PHRASE_ID,
  SET_PHRASE_LIST_VIEW_TYPE
} from './types';
import { IVocabularyPhrase } from '../../types/common';

export class PhraseListActions {

  public static setPlayMode(playMode: EPhraseListPlayMode): IPhraseListPlayMode {
    return {
      type: SET_PHRASE_LIST_PLAY_MODE,
      playMode
    }
  }

  public static setListMode(listMode: EPhraseListMode, listModeLang?: string): IPhraseListMode {
    return {
      type: SET_PHRASE_LIST_LIST_MODE,
      listMode,
      listModeLang
    }
  }

  public static setPauseDelay(pauseDelay: number): IPhraseListPauseDelay {
    return {
      type: SET_PHRASE_LIST_PAUSE_DELAY,
      pauseDelay
    }
  }

  public static setCurrentPhraseId(currentPhraseId: number): IPhraseListCurrentPhraseId {
    return {
      type: SET_PHRASE_LIST_CURRENT_PHRASE_ID,
      currentPhraseId
    }
  }

  public static setPlayPhraseId(playPhraseId: number): IPhraseListPlayPhraseId {
    return {
      type: SET_PHRASE_LIST_PLAY_PHRASE_ID,
      playPhraseId
    }
  }

  public static setForwardPhraseId(forwardPhraseId: number): IPhraseListForwardPhraseId {
    return {
      type: SET_PHRASE_LIST_FORWARD_PHRASE_ID,
      forwardPhraseId
    }
  }

  public static setPlayCaptionByPhraseId(playCaptionByPhraseId: number): IPhraseListPlayCaptionByPhraseId {
    return {
      type: SET_PHRASE_LIST_PLAY_CAPTION_BY_PHRASE_ID,
      playCaptionByPhraseId
    }
  }

  public static setTopPhraseId(topPhraseId: number): IPhraseListTopPhraseId {
    return {
      type: SET_PHRASE_LIST_TOP_PHRASE_ID,
      topPhraseId
    }
  }

  public static setPhraseList(list: IVocabularyPhrase[]): IPhraseListSet {
    return {
      type: SET_PHRASE_LIST,
      list
    }
  }

  public static appendPhraseList(list: IVocabularyPhrase[]): IPhraseListAppend {
    return {
      type: APPEND_PHRASE_LIST,
      list
    }
  }

  public static deletePhrase(phraseId: number): IPhraseListDeletePhrase {
    return {
      type: PHRASE_LIST_DELETE_PHRASE,
      phraseId
    }
  }

  public static updatePhrase(phrase: IVocabularyPhrase): IPhraseListUpdatePhrase {
    return {
      type: PHRASE_LIST_UPDATE_PHRASE,
      phrase
    }
  }

  public static removeByVideoGroup(videoKey: string, groupId: number): IPhraseListRemoveByVideoGroup {
    return {
      type: PHRASE_LIST_REMOVE_BY_VIDEO_GROUP,
      videoKey,
      groupId
    }
  }

  public static removeByGroup(groupId: number): IPhraseListRemoveByGroup {
    return {
      type: PHRASE_LIST_REMOVE_BY_GROUP,
      groupId
    }
  }

  public static setPlayOffset(offset: Partial<IPhrasePlayOffset>): IPhraseListPlayOffset {
    return {
      type: SET_PHRASE_LIST_PLAY_OFFSET,
      offset
    }
  }

  public static setPhraseListViewTypeAction(
    value: EPhraseListViewType
  ): ISetPhraseListViewTypeAction {
    return {
      type: SET_PHRASE_LIST_VIEW_TYPE,
      value,
    }
  }

  public static setNoteId(
    noteId: number
  ): IPhraseListNoteId {
    return {
      type: SET_PHRASE_LIST_NOTE_ID,
      noteId,
    }
  }

  public static setNotePopup(
    isNotePopup: boolean
  ): IPhraseListIsNotePopup {
    return {
      type: SET_PHRASE_LIST_IS_NOTE_POPUP,
      isNotePopup,
    }
  }

}