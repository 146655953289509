import { ITextState, ITextUpdateAction, IVideosUpdateAction, TEXT_UPDATE_ACTION, TTextVideos, VIDEOS_UPDATE_ACTION } from './types';

export class TextActions {

	public static update (
		text: Partial<ITextState>,
	): ITextUpdateAction {
		return {
			type: TEXT_UPDATE_ACTION,
			text,
		}
	}

	public static updateVideos (
		videos: Partial<TTextVideos>,
	): IVideosUpdateAction {
		return {
			type: VIDEOS_UPDATE_ACTION,
			videos,
		}
	}

}