import { AppThunk } from '../../../../../store/types';
import { setMainViewMode } from '../../../../../store/general/actions';
import { getLibraryGroupId } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import { Navigator } from '../../../../../effects/navigator';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { SaveVideoConfirmManager } from '../../../../../effects/saveVideoConfirmManager';

export class PlaylistEvents {

	public static onClick (
	): AppThunk {
		return (
			dispatch,
			getState,
		) => {
			const groupId = getLibraryGroupId(getState()) || 0;
			Navigator.openContentLib();
			dispatch(setMainViewMode(EMainViewMode.MY_LIBRARY));
			dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_VIDEO_LIST));
			dispatch(ContentLibActions.setSelectedGroupId(groupId));
		}
	}


	public static onSave (
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			SaveVideoConfirmManager.saveToMyStuff();
		}
	}

}