import React from 'react';
import { Box, IconButton, Portal, Stack } from '@mui/material';
import { IOneTimeTooltipSaveForMagicProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { CloseIconLight } from '../../../../../../../icons/CloseIconLight';

export function OneTimeTooltipSaveForMagic ({
	show,
	text,
	isDone,
	onClose,
	onClickLink,
	onShowDone,
}: IOneTimeTooltipSaveForMagicProps) {
	if (!show) return null;

	const className = 'one-time-tooltip-save-for-magic';

	const ref = React.useRef(null);

	const [isClosed, setClosed] = React.useState(false);
	const handleClose = () => {
		let w = window as any;
		w.oneTimeTooltipSaveForMagicClosed = 1; // save until refresh
		setClosed(true);
		onClose();
	  };

	React.useEffect(() => {
		if (isDone) onShowDone();
	}, [isDone]);

	React.useEffect(() => {
		let w = window as any;
		if (w.oneTimeTooltipSaveForMagicClosed) {
			setClosed(true);
		}

		let a;	
		setTimeout(() => {
			if (ref.current) {
				const box = ref.current as HTMLElement;
				a = box.querySelector('a');
				if (a) a.addEventListener('click', onClickLink);
			}
		}, 100);

		return () => {
			if (a) a.removeEventListener('click', onClickLink);
		}
	}, []);

	return isClosed ? null : (
		<Portal
			container={document.body}
		>	
			<Box
				className={className}
				sx={{
					position: 'fixed',
					display: 'flex',
					justifyContent: 'center',
					left: theme.spacing(4),
					right: theme.spacing(4),
					bottom: theme.spacing(4),
					zIndex: theme.zIndex.drawer,
				}}
				ref={ref}
			>
				<Stack
					className={`animate__animated animate__slideInUp`}
					alignItems={'center'}
					sx={{
						position: 'relative',
					}}
				>
					<Box
						dangerouslySetInnerHTML={{ __html: text }}
						sx={{
							p: `${theme.spacing(2)} ${theme.spacing(4)}`,
							fontSize: theme.typography.h5,
							boxShadow: theme.shadows[16],
							background: theme.palette.info[100],
							borderRadius: theme.spacing(1),

							'h3': {
								mt: 0,
								mb: theme.spacing(1),
								fontWeight: 500,
							},

							'a': {
								color: theme.palette.primary.main,
								fontWeight: 500,
							},
						}}
					/>
					<IconButton
						onClick={handleClose}
						sx={{
							position: 'absolute',
							zIndex: 100,
							top: 0,
							right: 0,
							color: theme.palette.grey[400],

							'.MuiSvgIcon-root': { width: theme.spacing(2.5), height: theme.spacing(2.5) }
						}}
					>
						<CloseIconLight />
					</IconButton>
				</Stack>
			</Box>
		</Portal>		
	);
}
