import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ContentLibraryFilters } from '../components/ContentLibraryFilters';
import { IContentLibraryFiltersEvents, IContentLibraryFiltersFields } from '../components/types';
import { ContentLibraryFiltersEvents } from './events';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IContentLibraryFiltersFields => {
		const { targetLangCode, nativeLangCode, text } = ContentLibSelectors.getSearchFilter(state);

		return {
			text,
			targetLangCode,
			nativeLangCode,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IContentLibraryFiltersEvents => ({
	onChangeText: text => dispatch(ContentLibraryFiltersEvents.onTextChange(text)),
	onChangeTargetLangCode: code => dispatch(ContentLibraryFiltersEvents.onChangeTargetLangCode(code)),
	onChangeNativeLangCode: code => dispatch(ContentLibraryFiltersEvents.onChangeNativeLangCode(code)),
}
);

export const ContentLibraryFiltersHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(ContentLibraryFilters);

ContentLibraryFiltersHOC.displayName = 'ContentLibraryFiltersHOC';
