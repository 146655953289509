export enum EOneTimeTooltipType {
	PHRASES_EDIT,
	NATIVE_LANGUAGE,
	NATIVE_AVAILABLE,
	TUTORIAL,
	SAVE_FOR_MAGIC,
	SAVED_FOR_MAGIC,
}

export class OneTimeTooltipTextProvider {

	private static texts: Record<EOneTimeTooltipType, string> = {
		[EOneTimeTooltipType.PHRASES_EDIT]: `Highlight the text <span class="highlighted-span">around your phrase</span> to modify a phrase context then click <strong>“{0}”</strong> button.`,
		[EOneTimeTooltipType.NATIVE_LANGUAGE]: `Subtitles in your native language are not available.
      		<p>Close this message to collapse the column.</p>`,
		[EOneTimeTooltipType.NATIVE_AVAILABLE]: `Subtitles in your native language are&nbsp;available for this video`,
		[EOneTimeTooltipType.TUTORIAL]: `Click the help icon anytime to access video tutorials`,
		[EOneTimeTooltipType.SAVE_FOR_MAGIC]: `<h3>Save {0} words or phrases you want to learn and watch the magic happen!</h3>
			You’ve saved {1}, add another {2}. Watch <a href="#" class="how">how to save</a>.`,
		[EOneTimeTooltipType.SAVED_FOR_MAGIC]: `<h3>Brilliant! You’ve saved {0} phrases.</h3>
			Now monitor your email inbox! Make sure an email from LangMagic is not spam-trapped!<br/>
			Our wizards are working on something that will surprise you!<br/>
			<a href="https://mail.google.com/mail/#inbox" target="_blank" class="mail">Open your inbox</a>`,
	}

	public static getText(
		type: EOneTimeTooltipType,
		vars?: string[],
	) {
		let text = OneTimeTooltipTextProvider.texts[type] || '';
		if (text && vars && vars.length) {
			for (let i = 0; i < vars.length; i++) {
				text = text.replace(`{${i}}`, vars[i]);
			}
		}
		return text;
	}
}
