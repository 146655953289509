import React from 'react';
import { IPhrasesViewProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { HistoryProrvider } from '../../../../effects/historyProrvider';
import { Stack } from '@mui/material';
import { PhraseDetailsContainerHOC } from '../../PhraseDetails/PhraseDetailsContainer/hocs/PhraseDetailsContainerHOC';
import { PhraseListHOC } from '../../PhraseList/hocs/PhraseListHOC';
import { ButtononShowLeftPanel } from '../../Dashboard/components/ButtononShowLeftPanel';
import { VideoLeftColumnHOC } from '../../VideoGroups/VideoLeftColumn/hocs/VideoLeftColumnHOC';
import { ButtonHelp } from '../../Dashboard/components/ButtonHelp';
import { MIN_WIDTH } from '../../../../../common/contexts/DeviceContext';

export const PhrasesView: React.FC<IPhrasesViewProps> = ({
	isShow,
	showLeftPanel,
	onShowLeftPanel,
	onShowHelp,
}) => {
	if (!isShow) return null;

	const history = HistoryProrvider.getHistory();

	const className = 'phrases-view';

	const containerStyle = {
		width: 1,
		minWidth: MIN_WIDTH + 'px',
		height: 1,
		overflowX: 'auto',
		overflowY: 'hidden',
		p: theme.spacing(2),
		background: theme.palette.secondary.main,

		'&.left-panel-collapsed': {
			pl: theme.spacing(3),
		},
	};

	return (
		<Stack
			className={`
				${className}
				${!showLeftPanel ? 'left-panel-collapsed' : ''}
			`}
			direction='row'
			spacing={theme.spacing(2)}
			sx={containerStyle}
		>
			{showLeftPanel &&
				<VideoLeftColumnHOC />
			}
			{!showLeftPanel &&
				<>
					<ButtononShowLeftPanel onClick={onShowLeftPanel} />
					<ButtonHelp onClick={onShowHelp} />
				</>
			}
			<PhraseListHOC />
			<PhraseDetailsContainerHOC />
			
		</Stack>
	)
};
