import { ILanguageItem } from '../../../store/general/types';
import { IPhrasesDetailTranslateLangResult } from '../../../store/phrase-details/types';

export class PhraseDetailsTranslateCache {

  private static MAX_SIZE = 300;
  private static cache: Record<string, IPhrasesDetailTranslateLangResult[]> = {};

  public static save(fromLang: ILanguageItem | null,
                     toLangs: ILanguageItem[],
                     text: string,
                     results: IPhrasesDetailTranslateLangResult[]) {
    if (Object.keys(this.cache).length > this.MAX_SIZE) {
      this.cache = {};
    }
    this.cache[this.getKey(fromLang, toLangs, text)] = results;
  }

  public static find(fromLang: ILanguageItem | null,
                     toLangs: ILanguageItem[],
                     text: string): IPhrasesDetailTranslateLangResult[] | null {
    const key = this.getKey(fromLang, toLangs, text);
    return this.cache[key] || null;
  }

  private static getKey(fromLang: ILanguageItem,
                        toLangs: ILanguageItem[],
                        text: string) {
    const separator = '|||';
    return text + separator + (fromLang?.code || '') + separator + toLangs.map(l => l.code).join('-');
  }
}