import React from 'react';
import { IPhraseTextProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { Text } from '../../../../../../common/components/Text/Text';
import { HighLightedText } from '../../../../../../common/components/HighLightedText/HighLightedText';
import { alpha, Button, Stack, SxProps, Tooltip } from '@mui/material';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';
import { TranslationsIcon } from '../../../../../../../icons/TranslationsIcon';
import { ContextEditIcon } from '../../../../../../../icons/ContextEditIcon';
import { ContextAddIcon } from '../../../../../../../icons/ContextAddIcon';
import { ContextRemoveIcon } from '../../../../../../../icons/ContextRemoveIcon';

export const PhraseText: React.FC<IPhraseTextProps> = ({
	context,
	text,
	fromLangCode,
	isContext,
	isPhrase,
	isTranslation,
	isTranslateContext,
	isEdit,
	isContextEditable,
	onContextModify,
	onContextDelete,
	onContextTranslate,
	onEdit
}) => {
	if (!text) return null;

	const className = `phrase-text`;

	const buttonStyle: SxProps = {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		color: theme.palette.grey[400],

		'&:hover': {
			color: theme.palette.grey[700],
			background: alpha(theme.palette.grey[50], .5),
		},

		'&.active': {
			color: theme.palette.common.white,
			background: theme.palette.primary.main,
		},

		'.MuiSvgIcon-root': {
			width: theme.spacing(2.5),
			height: theme.spacing(2.5),
		},
	};

	const onClick = (e) => {
		if (isEdit) {
			e.preventDefault();
			onEdit('');
		}
	};

	const phrase = isContext ? context : text;
	const isLong = phrase.length > 100;

	return (<Stack
		className={`${className} ${isPhrase ? 'phrase' : 'selected'}
			${isTranslation && isEdit ? 'edit' : ''}
		`}
		sx={{
			'&.edit': {
				opacity: .5,
				cursor: 'pointer',

				'.text-with-speaker': {
					pointerEvents: 'none',
				},
			},

			'.speaker': {
				top: theme.spacing(.5),
			},
		}}
		onClick={onClick}
	>
		<TextWithSpeakerHOC
			lang={fromLangCode}
			children={
				<Text
					className={isLong ? 'long' : ''}
					sx={{
						fontSize: theme.typography.h5,
						fontWeight: 400,

						'&.long': {
							fontSize: theme.typography.subtitle2,
						},

						[`.${className}.selected & .highlighted-span`]: {
							background: `linear-gradient(0deg, #d7aeff 15%, ${alpha(theme.palette.info[100], .5)} 20%)`,
						},

						[`&:hover .${className}__buttons`]: {
							opacity: 1,
							pointerEvents: 'all',
						},
					}}
				>
					{isContext
						? <HighLightedText
							text={context}
							highLighted={[text]}
						/>
						: text
					}

					{isContextEditable && !isEdit &&
						<Stack
							className={`${className}__buttons class-controll`}
							flexDirection={'row'}
							alignItems={'center'}
							gap={theme.spacing(.5)}
							sx={{
								display: 'inline-flex',
								ml: theme.spacing(.5),
								height: theme.spacing(2),
								transform: 'translateY(4px)',

								opacity: .1,
								pointerEvents: 'none',
								transition: 'opacity .3s ease',
							}}
						>
							{isTranslation &&
								<Tooltip
									title={'Edit phrase'}
								>
									<Button
										sx={buttonStyle}
										onClick={(e) => {
											e.preventDefault();
											onEdit(phrase);
										}}
									>
										<PencilIcon
											sx={{
												width: `${theme.spacing(2)} !important`,
												height: `${theme.spacing(2)} !important`,
											}}
										/>
									</Button>
								</Tooltip>
							}

							{!isContext &&
								<Tooltip
									title={'Add context'}
								>
									<Button
										onClick={onContextModify}
										sx={buttonStyle}
									>
										<ContextAddIcon />
									</Button>
								</Tooltip>
							}

							{isContext &&
							<>
								<Tooltip
									title={'Edit context'}
								>
									<Button
										onClick={onContextModify}
										sx={buttonStyle}
									>
										<ContextEditIcon />
									</Button>
								</Tooltip>

								{isTranslation &&
									<Tooltip
										title={'Translate context'}
									>
										<Button
											className={`${isTranslateContext ? 'active' : ''}`}
											onClick={onContextTranslate}
											sx={buttonStyle}
										>
											<TranslationsIcon />
										</Button>
									</Tooltip>
								}

								<Tooltip
									title={'Remove context'}
								>
									<Button
										onClick={onContextDelete}
										sx={buttonStyle}
									>
										<ContextRemoveIcon />
									</Button>
								</Tooltip>
							</>
							}
						</Stack>
					}
					
				</Text>
			}
			text={String(text)}
		/>

	</Stack>);

}
