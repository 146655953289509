import React from 'react';
import { alpha, Box, IconButton, Stack, SxProps, Tab, Tabs } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { PhraseDetailsContainerHOC } from '../../../PhraseDetails/PhraseDetailsContainer/hocs/PhraseDetailsContainerHOC';
import { PhraseListHOC } from '../../../PhraseList/hocs/PhraseListHOC';
import { IPhrasesColumnProps } from './types';
import { EPhrasesColumnTab, EPhrasesColumnType } from '../../../../../store/general/types';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { ArrowIcon } from '../../../../../../../icons/ArrowIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { CLASS_TABS_SAVED, CLASS_TABS_TRANSLATED } from '../../../Video/components/constants';
import { PhraseListTranslatedHOC } from '../../../PhraseListTranslated/hocs/PhraseListTranslatedHOC';
import { TeacherListHOC } from '../../../Teacher/TeacherList/hocs/TeacherListHOC';
import { EPhraseListPlayMode } from '../../../../../store/phrase-list/types';

export const PhrasesColumn: React.FC<IPhrasesColumnProps> = ({
	phrasesColumn,
	tab,
	text,
	savedCount,
	translatedCount,
	teachersCount,
	phrasesPlayStatus,
	setTab,
	setType,
}) => {
	const className = 'phrases-column';
	const classCount = className + '__count';

	const { isFullScreen } = React.useContext(DeviceContext);

	let [phrasesColumnBeforeFullscreen, setPhrasesColumnBeforeFullscreen] = React.useState(phrasesColumn);

	React.useEffect(() => {
		if (phrasesColumn === EPhrasesColumnType.COLUMNS_2 &&
			tab === EPhrasesColumnTab.TRANSLATE
		) {
			setTab(EPhrasesColumnTab.TRANSLATED);
		}
	}, [phrasesColumn]);

	React.useEffect(() => {
		if (text) {
			if (phrasesColumn !== EPhrasesColumnType.COLUMNS_2 &&
				phrasesPlayStatus === EPhraseListPlayMode.STOP
			) {
				setTab(EPhrasesColumnTab.TRANSLATE);
			}

			if (phrasesColumn === EPhrasesColumnType.HIDE) {
				setType(EPhrasesColumnType.COLUMNS_1);
			}
		}
	}, [text]);

	const isFullScreenPrev = React.useRef(null as any);
	React.useEffect(() => {
		if (isFullScreen) {
			if (!isFullScreenPrev.current) { // enter fulscreen
				setPhrasesColumnBeforeFullscreen(phrasesColumn);
				if (phrasesColumn === EPhrasesColumnType.COLUMNS_2) {
					setType(EPhrasesColumnType.COLUMNS_1);
				}
			}
		} else {
			if (isFullScreenPrev.current && // exit fulscreen
				phrasesColumnBeforeFullscreen === EPhrasesColumnType.COLUMNS_2 &&
				phrasesColumnBeforeFullscreen !== phrasesColumn
			) {
				setType(phrasesColumnBeforeFullscreen);
			}
		}
		isFullScreenPrev.current = isFullScreen;
		
	}, [isFullScreen]);

	const buttonSx: SxProps = {
		position: 'fixed',
		zIndex: 500,
		right: theme.spacing(.25),
		top: theme.spacing(3.75),
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		color: theme.palette.common.white,
		background: theme.palette.grey[500],
		borderRadius: '50%',

		'.MuiSvgIcon-root': {
			position: 'relative',
			zIndex: 100,
			width: theme.spacing(2),
			height: theme.spacing(1.5),
		},

		'&:before': {
			content: '""',
			position: 'absolute',
			left: theme.spacing(-.5),
			right: theme.spacing(-.5),
			top: theme.spacing(-.5),
			bottom: theme.spacing(-.5),
		},
	};

	const tabsSx: SxProps = {
		background: alpha(theme.palette.grey[50], .25),
		borderBottom: `1px solid ${theme.palette.grey[100]}`,

		'.MuiTab-root': {
			p: `0 ${theme.spacing(1.5)}`,
			maxWidth: 'none',
		},

		[`.${CLASS_TABS_SAVED}.flash, .${CLASS_TABS_TRANSLATED}.flash`]: {
			animation: 'animation_tabs_flash .5s 3',
		},

		'@keyframes animation_tabs_flash': {
			'100%': { background: theme.palette.warning.light },
		},

		[`.${classCount}`]: {
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: theme.spacing(3),
			minWidth: theme.spacing(3),
			ml: theme.spacing(.5),
			p: theme.spacing(.25, .5),
			fontSize: theme.typography.subtitle1,
			color: theme.palette.grey[700],
			background: theme.palette.grey[100],
			borderRadius: theme.spacing(1.5),
		},
	};

	const getTabs = (
		phrasesColumn: EPhrasesColumnType,
	) => {
		interface ITabs {
			tab: EPhrasesColumnTab;
			body: JSX.Element;
		}
		let arr: ITabs[] = [];
		if (phrasesColumn === EPhrasesColumnType.COLUMNS_1) {
			arr.push({
				tab: EPhrasesColumnTab.TRANSLATE,
				body: <PhraseDetailsContainerHOC />
			});
		}
		arr.push({
			tab: EPhrasesColumnTab.TRANSLATED,
			body: <PhraseListTranslatedHOC />
		});
		arr.push({
			tab: EPhrasesColumnTab.SAVED,
			body: <PhraseListHOC />
		});
		arr.push({
			tab: EPhrasesColumnTab.TEACHERS,
			body: <TeacherListHOC />
		});

		return <>
			<Tabs
				value={tab}
				onChange={(e, value) => {
					setTab(value)
				}}
				sx={tabsSx}
			>
				{phrasesColumn === EPhrasesColumnType.COLUMNS_1 &&
					<Tab
						label={'Translations & Prompts'}
						value={EPhrasesColumnTab.TRANSLATE}
					/>
				}
				<Tab
					className={CLASS_TABS_TRANSLATED}
					label={<Box
						sx={{
							display: 'inline',
						}}
					>
						Translated
						{!!translatedCount &&
							<Box
								className={classCount}
							>{translatedCount}</Box>
						}
					</Box>}
					value={EPhrasesColumnTab.TRANSLATED}
				/>
				<Tab
					className={CLASS_TABS_SAVED}
					label={<Box
						sx={{
							display: 'inline',
						}}
					>
						Saved
						{!!savedCount &&
							<Box
								className={classCount}
							>{savedCount}</Box>
						}
					</Box>}
					value={EPhrasesColumnTab.SAVED}
				/>
				{!!teachersCount &&
					<Tab
						label={<Box
							sx={{
								display: 'inline',
							}}
						>
							Teachers
						</Box>}
						value={EPhrasesColumnTab.TEACHERS}
					/>
				}
			</Tabs>
			{arr.map(i => {
				return <Box
					sx={{
						flex: `1 1 100%`,
						minHeight: 0,
						display: tab === i.tab ? 'block' : 'none',
						zIndex: 100, // hover onboarding hints over translation panel
					}}
				>
					{i.body}
				</Box>
			})}
		</>;
	};

	return <>
		{phrasesColumn === EPhrasesColumnType.COLUMNS_2 &&
			<>
				<PhraseDetailsContainerHOC />
				
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flex: '1 1 33%',
						minWidth: 0,

						'.root-inner.fullscreen &': {
							mr: theme.spacing(2),
							maxWidth: theme.spacing(60),
						}
					}}
				>
					{getTabs(phrasesColumn)}
				</Paper>

				<IconButton
					sx={buttonSx}
					onClick={() => setType(EPhrasesColumnType.COLUMNS_1)}
				>
					<ArrowIcon />
				</IconButton>
			</>
		}
		{phrasesColumn === EPhrasesColumnType.COLUMNS_1 &&
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: '1 1 50%',
					maxWidth: theme.spacing(100),

					'.root-inner.fullscreen &': {
						mr: theme.spacing(2),
						maxWidth: theme.spacing(60),
					}
				}}
			>
				{getTabs(phrasesColumn)}		

				{!isFullScreen &&
					<IconButton
						sx={{
							...buttonSx,
							transform: 'scaleX(-1)',
						}}
						onClick={() => setType(EPhrasesColumnType.COLUMNS_2)}
					>
						<ArrowIcon />
					</IconButton>
				}

				<IconButton
					sx={{
						...buttonSx,
						top: theme.spacing(8),
					}}
					onClick={() => setType(EPhrasesColumnType.HIDE)}
				>
					<ArrowIcon />
				</IconButton>
			</Paper>
		}
		{phrasesColumn === EPhrasesColumnType.HIDE && <>
			<IconButton
				sx={{
					...buttonSx,
					transform: 'scaleX(-1)',
				}}
				onClick={() => setType(EPhrasesColumnType.COLUMNS_1)}
			>
				<ArrowIcon />
			</IconButton>
		</>}
	</>
};
