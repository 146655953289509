import { PLAYLIST_SAVE_TEXT } from '../../common/wordings';
import { getDispatch, getState } from '../store';
import { getCurrentVideoKey } from '../store/current-video/selectors';
import { setAddVideoConfirmAction, setSavedVideoConfirmAction } from '../store/general/actions';
import { ESavedVideoConfirmShowMode } from '../store/general/types';
import { getActiveGroupId, getGroupTargetLanguage, getUserGroupById, getVideoByGroupIdAndKey } from '../store/models/selectors';

export class SaveVideoConfirmManager {

  public static show(
    groupId: number,
    videoId: string,
    videoLangCodes: string[],
  ) {
    const dispatch = getDispatch();
    dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.NOTIFY, groupId, videoId, videoLangCodes}));
  }

  public static showError(errorMessage: string, videoUrl: string) {
    const dispatch = getDispatch();
    dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.ERROR, errorMessage, videoUrl}));
  }

  public static saveToMyStuff() {
    const dispatch = getDispatch();
    const state = getState();
    const srcGroupId = getActiveGroupId(state) || 0;
    const videoId = getCurrentVideoKey(state);

    const video = getVideoByGroupIdAndKey(state, srcGroupId, videoId);
    const group = getUserGroupById(state, srcGroupId);
    const lang = getGroupTargetLanguage(state, group);
    const videoLangCodes = lang?.code ? [lang.code] : video?.info?.langList;

    dispatch(setAddVideoConfirmAction({
      show: true,
      targetGroupId: 0,
      videoInfo: {
        title: video?.info.title || '',
        thumbnailUrl: video?.info.thumb || '',
        channelTitle: video?.info.author || '',
      },
      videoId: videoId,
      videoLangCodes,
      text: PLAYLIST_SAVE_TEXT,
    }));
  }

}