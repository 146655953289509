import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PlaylistEvents } from './events';
import { IPlaylistEvents, IPlaylistFields } from '../components/types';
import { Playlist } from '../components/Playlist';
import { IPlaylistHOCProps } from './types';
import { getActiveUserGroup } from '../../../../../store/models/selectors';
import { EUserGroupType } from '../../../../../store/general/types';

const mapStateToProps = (
	dispatch: any,
) => {
	return (
		state: IState,
		props: IPlaylistHOCProps,
	): IPlaylistFields => {
		const { isSmall } = props;
		const group = getActiveUserGroup(state);
		const isPreview = group?.type === EUserGroupType.PREVIEW;

		return {
			isPreview,
			isSmall,
			name: group?.name || '',
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IPlaylistEvents => ({
	onClick: () => dispatch(PlaylistEvents.onClick()),
	onSave: () => dispatch(PlaylistEvents.onSave()),
});

export const PlaylistHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(Playlist);

PlaylistHOC.displayName = 'PlaylistHOC';
