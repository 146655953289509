import { getAuthUser } from '../../../spa/store/general/selectors';
import { getState } from '../../../spa/store';
import { SyncStorage } from './local-storage-helpers';

export enum EUserStateStoragePageType {
  VIDEO = 1, AUDIO = 2, CONTENT_LIB = 3, MY_STUFF = 4, TRANSCRIPT = 5
}
export type TUserStateStorage = {
  libraryGroupId?: number;
  groupId?: number;
  videoId?: string;
  phraseId?: number;
  audioId?: number;
  pageType?: EUserStateStoragePageType;
}

export class UserStateStorage extends SyncStorage {

  default: TUserStateStorage;

  constructor(fold: TUserStateStorage) {
    super();
    this.default = fold;
  }

  private getStorageKey() {
    const user = getAuthUser(getState());
    return 'user-state' + (user ? '-' + user.hashId : '');
  }

  getData(): TUserStateStorage {
    return this.getItem(this.getStorageKey(), this.default);
  }
  saveData(value: TUserStateStorage): TUserStateStorage {
    this.saveItem(this.getStorageKey(), value);
    return value;
  }

  public setLibraryGroupId(libraryGroupId: number) {
    const data: TUserStateStorage = {
      ...this.getData(),
      ...{libraryGroupId}
    }
    this.saveData(data);
  }

  public setVideoInfo(videoId: string, groupId: number, phraseId: number) {
    const data: TUserStateStorage = {
      ...this.getData(),
      ...{videoId, groupId, phraseId, videoPosition: 0, audioId: 0, pageType: EUserStateStoragePageType.VIDEO}
    }
    this.saveData(data);
  }

  public setAudioInfo(audioId: number, groupId: number) {
    const data: TUserStateStorage = {
      ...this.getData(),
      ...{audioId, videoId: undefined, groupId, phraseId: undefined, videoPosition: undefined, pageType: EUserStateStoragePageType.AUDIO}
    }
    this.saveData(data);
  }

  public setMyStuffPage() {
    this.saveData({
      ...this.getData(),
      pageType: EUserStateStoragePageType.MY_STUFF
    });
  }

  public setContentLibPage() {
    this.saveData({
      ...this.getData(),
      pageType: EUserStateStoragePageType.CONTENT_LIB
    });
  }

  public setTranscriptPage() {
    this.saveData({
      ...this.getData(),
      pageType: EUserStateStoragePageType.TRANSCRIPT
    });
  }

  public clear() {
    this.saveData({});
  }

}

const userStateStorage = new UserStateStorage({});
export const getUserStateStorage = (): UserStateStorage => {
  return userStateStorage;
}