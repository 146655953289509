
export const LOGIN_POPUP_SET_ACTION = 'LOGIN_POPUP/LOGIN_POPUP_SET_ACTION';

export interface ILoginPopupSetAction {
	type: typeof LOGIN_POPUP_SET_ACTION;
	value: ILoginPopupState;
}

export interface ILoginPopupState {
	show: boolean;
	text?: string;
	onLogin?: () => void;
}

export type TLoginPopupActions =
	| ILoginPopupSetAction
