import { connect } from 'react-redux';
import { IState } from '../../../../store/types';
import { createGroupNameIndex, getActiveGroupId, getGroupTargetLanguage, getNativeLanguage, getUserGroupById, getUserOwnGroups, getVideoByGroupIdAndKey } from '../../../../store/models/selectors';
import { TUserGroup } from '../../../../store/models/types';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { IAddVideoConfirmInlineEvents, IAddVideoConfirmInlineFields } from '../AddVideoConfirmInline/types';
import { AddVideoConfirmEvents } from './events';
import { AddVideoConfirmInline } from '../AddVideoConfirmInline/AddVideoConfirmInline';
import { getCurrentVideoKey } from '../../../../store/current-video/selectors';
import { PLAYLIST_SAVE_TEXT } from '../../../../../common/wordings';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IAddVideoConfirmInlineFields => {
		const srcGroupId = getActiveGroupId(state) || 0;
		const videoId = getCurrentVideoKey(state);
	
		const video = getVideoByGroupIdAndKey(state, srcGroupId, videoId);
		const group = getUserGroupById(state, srcGroupId);
		const lang = !!group ? getGroupTargetLanguage(state, group) : null;
		const videoLangCodes = lang?.code ? [lang.code] : video?.info?.langList;
	
		let targetLang = '';
		if (group) {
			targetLang = getGroupTargetLanguage(state, group)?.code || '';
		}

		let groups: TUserGroup[] = [];
		const userGroups = getUserOwnGroups(state);
		if (userGroups) {
			groups = userGroups.reduce((arr, item) => {
				const targetLang = getGroupTargetLanguage(state, item)?.code;
				if (targetLang &&
					videoLangCodes?.includes(targetLang)
				) {
					arr.push(item);
				}
				return arr;
			}, groups);
		}

		const nativeLang = getNativeLanguage(state)?.code || '';
		let newListName = createGroupNameIndex(state, `${LangUtil.getLangNameByCode(targetLang)} / ${LangUtil.getLangNameByCode(nativeLang)}`);

		return {
			groups,
			nativeLang,
			videoLangCodes,
			newListName,
			text: PLAYLIST_SAVE_TEXT,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IAddVideoConfirmInlineEvents => ({
	onChange: (groupId, newListName) => dispatch(AddVideoConfirmEvents.onChange(groupId, newListName)),
	onClose: () => dispatch(AddVideoConfirmEvents.onClose()),
});

export const AddVideoConfirmInlineHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(AddVideoConfirmInline);

AddVideoConfirmInlineHOC.displayName = 'AddVideoConfirmInlineHOC';
