import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsExplainService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-explain-service';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode, EPhrasesColumnTab } from '../../../../../store/general/types';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PhraseDetailsUsageService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-usage-service';
import { PhraseDetailsLibPromptService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-lib-prompt-service';
import { PhraseDetailsCustomPromptService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-custom-prompt-service';
import { PhraseDetailsPromptLibEffects } from '../../../../../effects/phrase-details/phraseDetailsPromptLibEffects';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { LibGptPromptRest } from '../../../../../../common/rest/libGptPrompt/libGptPromptRest';
import { getActiveGroupTargetLanguage, getGroupNativeLanguage, getGroupTargetLanguage, getPhraseDetailsTabs, getUserGroupById } from '../../../../../store/models/selectors';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { GptUtils } from '../../../../../effects/gptUtils';
import { historyPush } from '../../../../../../common/utils/historyHelper';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';


export class PhraseChatItemEvents {

	public static onLoad (
		useCache: boolean,
		type: EPhraseDetailsTabType,
		id?: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();

			const activeTab = PhraseDetailsSelectors.getActiveTab(state);
			if (activeTab?.type !== type || activeTab.relationId !== id) {
				const tabs = getPhraseDetailsTabs(getState());
				const tab = tabs.find(t => t.relationId === id && t.type === type);
				if (tab) dispatch(PhraseDetailsActions.setActiveTab(tab));
			}

			const text = PhraseDetailsSelectors.getSelectText(state) || '';
			const context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
			const fromLang = PhraseDetailsSelectors.getFromLang(state);
			const toLang = PhraseDetailsSelectors.getToLang(state);
			let result;

			if (id && type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
				dispatch(PhraseDetailsActions.setCustomPromptResult(id, '', true, false));
				result = await new PhraseDetailsCustomPromptService(id, text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setCustomPromptResult(id, result, false, true));
				}
			} else if (id && type === EPhraseDetailsTabType.LIB_PROMPT) {
				dispatch(PhraseDetailsActions.setLibPromptResult(id, '', true, false));
				let libs = PhraseDetailsTabsEditorPopupSelectors.getPromptLibs(state);
				if (!libs) {
					libs = await LibGptPromptRest.getLists();
					dispatch(PhraseDetailsTabsEditorPopupActions.setPromptLibs(libs));
				}
				result = await new PhraseDetailsLibPromptService(id, text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setLibPromptResult(id, result, false, true));
				}
			} else if (type === EPhraseDetailsTabType.EXPLAIN) {
				dispatch(PhraseDetailsActions.setExplainResult('', true, false));
				result = await new PhraseDetailsExplainService(text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setExplainResult(result, false, true));
				}
			} else if (type === EPhraseDetailsTabType.USAGE) {
				dispatch(PhraseDetailsActions.setUsageResult('', true, false));
				result = await new PhraseDetailsUsageService(text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setUsageResult(result, false, true));
				}
			}

			const langCode = LangUtil.checkLangCode(getActiveGroupTargetLanguage(getState())?.code);
			StatLogManager.logPromptAccess(langCode);
		}
	}

	public static onClickSettings(
		type: EPhraseDetailsTabType,
		id?: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {

			if (type === EPhraseDetailsTabType.LIB_PROMPT) {
				await PhraseDetailsPromptLibEffects.loadPromptLibs();
				const lib = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(getState(), id || 0);
				if (lib) {
					dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
					dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.LIBS));
					dispatch(PhraseDetailsTabsEditorPopupActions.setOpenPromptLibId(lib.id));
					dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
				}
			} else {
				const tabs = getPhraseDetailsTabs(getState());
				if (tabs && tabs.length) {
					let t = tabs.find(tab => {
						return (type === EPhraseDetailsTabType.CUSTOM_PROMPT)
							? tab.relationId === id
							: tab.type === type;
					});
					id = t?.id || 0;
				}
				dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
				dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.CREATE));
				dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(id || 0));
			}
			historyPush({
				state: { page: 'settings' },
				title: 'Settings',
				onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
			});
		}
	}

	public static onCopyNote(
		type: EPhraseDetailsTabType,
		id?: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const item = PhraseDetailsSelectors.getPromptsChat(state)?.find(i => i.type === type && i.id === id);
			if (item) {
				let result = '';
				if (id) {
					let promptResult;
					if (type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
						promptResult = PhraseDetailsSelectors.getCustomPrompt(state, id);
					} else if (type === EPhraseDetailsTabType.LIB_PROMPT) {
						promptResult = PhraseDetailsSelectors.getLibPrompt(state, id);
					}
					result = promptResult?.resultText;
				}
				if (!result) {
					if (type === EPhraseDetailsTabType.EXPLAIN) {
						result = PhraseDetailsSelectors.getExplainResultText(state) || '';
					} else if (type === EPhraseDetailsTabType.USAGE) {
						result = PhraseDetailsSelectors.getUsageResultText(state) || '';
					}
				}
				result = GptUtils.clear(result + '\n\n').replaceAll(/\n/gm, '<br/>');
				PhraseNoteEffects.runCopyToNoteText(result);
			}
		}
	}

	public static onOpenNote(
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			// const state = getState();
			// const phraseId = PhraseDetailsSelectors.getPhraseId(state) || 0;
			// const phrase = PhraseListSelectors.findPhraseById(state, phraseId);
			// if (!phrase?.userGroupId) return;
			// const group = getUserGroupById(state, phrase.userGroupId);
			// if (!group) return;
			// const targetLang = getGroupTargetLanguage(state, group);
			// if (!targetLang) return;
			// const nativeLang = getGroupNativeLanguage(state, group);
			// PhraseDetailsEffects.showNoteFromSavedPhrase(targetLang, nativeLang, nativeLang, phrase.highlighted, phrase, true);
			// MainLayoutEffects.setPhrasesColumnTab(EPhrasesColumnTab.SAVED);

			dispatch(PhraseListActions.setNotePopup(true));
		}
	}

}