import { IState } from '../../../../../store/types';
import { getMainLayoutPhrasesColumn, getMainLayoutPhrasesColumnTab } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { PhrasesColumn } from '../components/PhrasesColumn';
import { IPhrasesColumnEvents, IPhrasesColumnFields } from '../components/types';
import { PhrasesColumnEvents } from './events';
import { PhraseDetailsPanelSelectors } from '../../../PhraseDetails/selectors';
import { getVideoTeacherUsers } from '../../../../../store/videos/selectors';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { EVocabularyPhraseSaveType, EVocabularyPhraseType } from '../../../../../types/common';


const mapStateToProps = (
	state: IState
): IPhrasesColumnFields => {
	const phrasesColumn = getMainLayoutPhrasesColumn(state);
	const text = PhraseDetailsPanelSelectors.getText(state);

	let phrasesCount = PhraseListSelectors.findPhrasesByTypes(state, [
        EVocabularyPhraseType.DEFAULT,
        EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED,
        EVocabularyPhraseType.LESSON
	])
        .filter(phrase => phrase.fullPhrase?.trim()?.length);
	let savedCount = 0;
	let translatedCount = 0;
	if (phrasesCount.length) {
		phrasesCount.forEach(i => {
			if (i.saveType === EVocabularyPhraseSaveType.TRANSLATE) {
				translatedCount++;
			} else {
				savedCount++;
			}
		});
	}

	const teacherList = getVideoTeacherUsers(state).filter(t => t.user); // filter deleted users
	const tab = getMainLayoutPhrasesColumnTab(state);
	const phrasesPlayStatus = PhraseListSelectors.getPlayMode(state);

	return {
		phrasesColumn,
		tab,
		text,
		savedCount,
		translatedCount,
		teachersCount: teacherList.length,
		phrasesPlayStatus,
	}
};

const mapDispatchToProps = (
	dispatch: any
): IPhrasesColumnEvents => {
	return {
		setTab: (tab) => dispatch(PhrasesColumnEvents.setTab(tab)),
		setType: (type) => dispatch(PhrasesColumnEvents.setType(type)),
	}
};

export const PhrasesColumnHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PhrasesColumn);

PhrasesColumnHOC.displayName = 'PhrasesColumnHOC';
