import { isProd, isTestMode } from '../../webpack/constants';

const DEV_HOST_NAME = 'easytolearn-loc.io';
const TEST_HOST_NAME = 'language-project--backend-test.herokuapp.com';
const PROD_HOST_NAME = 'app.langmagic.com';

const BACK_HOST_DEV = `https://${DEV_HOST_NAME}`;
const BACK_HOST_TEST = `https://${TEST_HOST_NAME}`;
const BACK_HOST_PROD = `https://${PROD_HOST_NAME}`;

export const VERSION = '1.95.0';

export const BASE_URL = '/front/';
export const ADMIN_PANEL_URL = '/admin/';

export enum EBuildMode {
  DEVELOPMENT = 0, TEST = 1, PRODUCTION
}

export const getBuildMode = (): EBuildMode => {
  if (isProd && isTestMode)
    return EBuildMode.TEST;
  if (isProd)
    return EBuildMode.PRODUCTION;
  return EBuildMode.DEVELOPMENT;
}

export const getBackHost = () => {
  const buildMode = getBuildMode();
  switch (buildMode) {
    case EBuildMode.PRODUCTION:
      return BACK_HOST_PROD;
    case EBuildMode.TEST:
      return BACK_HOST_TEST;
    default:
      return BACK_HOST_DEV
  }
}

export const isTranslationApiEnabled = () => {
  return true; //isProd;
}

const EXTENSION_BASE_URL = 'https://chrome.google.com/webstore/detail/language-project/';
export const getExtensionUrl = () => {
  return EXTENSION_BASE_URL + chrome.runtime.id;
}

export const APP_TITLE = 'LangMagic';

export const API_URL = getBackHost() + '/api';

//export const APP_KEY = 'AIzaSyDcsCgPGMpl8wwPTOQVSWOnjnqajqlwQXg';
export const APP_KEY = 'AIzaSyCQTDQ7yaWjdyFwREOdeezMgZbtCU0WnZ0';

export const AUTH_CLIENT_ID =  '618633773293-rud8ubumsjvar38vu2qfrk5brv3b0brt.apps.googleusercontent.com';

export const EXTENSION_ID = 'kmdpnafoknackjdoieijinkjengbhcid';
export const EXT_REDIRECT_URL = 'https://' + EXTENSION_ID + '.chromiumapp.org';

export const EXTENSION_INSTALL_URL = 'https://chrome.google.com/webstore/detail/language-project/' + EXTENSION_ID;

const DEMO_VIDEO_ID = 'qp0HIF3SfI4';

export const DEMO_VIDEO_URL = `http://youtube.com/watch?v=${DEMO_VIDEO_ID}`;
export const TUTORIAL_URL = 'https://easytolearn.io/howtouse';
export const FEEDBACk_URL = 'https://langmagic.com/feedback/';
export const TELEGRAM_BOT_URL = 'https://t.me/lang_project_feed_bot';
export const YOUTUBE_URL = `https://youtube.com`;

export const CAPTION_START_POSITION = 3;

const GA4_ID_DEV = 'G-HZZZS25GM4';
const GA4_ID_PROD = 'AW-11149840956';

export const getGa4Id = () => {
  return isProduction() ? GA4_ID_PROD : GA4_ID_DEV;
}

export const isProduction = () => {
  return isProd
}

export const isEventsEnabled = () => {
  return true;
}

export const isProductionHost = () => {
  return location.hostname.indexOf(PROD_HOST_NAME) === 0;
}

export const AMPLITUDE_API_KEY = '37bb462e444dfbe306fc5413482ac6f4';

export const DEFAULT_VOCABULARY_PHRASE_DETAILS_HEIGHT = 320;

export const SCROLLBAR_WIDTH = 6;

export const PUBLIC_PROFILE_USERNAME_MAX_LENGTH = 50;

//export const AUDIO_PODCAST_FORM_URL = 'https://forms.gle/g5NQYhf1efRyXhq37';

export const SAVE_FOR_MAGIC_NUMBER = 20;

export const USE_REMOTE_DEV_BACKEND = false;
