import React from 'react';
import { IPhraseTranslationEditProps, PHRASE_DETAILS_TRANSLATION_EDITOR_CLASS_NAME } from './types';
import { Button, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { CloseIcon } from '../../../../../../../icons/CloseIcon';
import { Textarea } from '../../../../../../common/components/Textarea/Textarea';

export const PhraseTranslationEdit: React.FC<IPhraseTranslationEditProps> = ({
    show,
	text,
	onChange,
	onClose
}) => {
	if (!show) return null;

	const [value, setValue] = React.useState<string>(text);

	const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const debounce = React.useRef(null);
	const onKeyUp = (event: KeyboardEvent) => {
		if (debounce.current) clearTimeout(debounce.current);
		if (event.code === 'Enter') {
			onChange(value);
		} else {
			debounce.current = setTimeout(() => {
				onChange(value);
			}, 1000) as any;
		}
	}

	const onBlur = () => {
		onChange(value);
	}

	React.useEffect(() => {
		return () => {
			if (debounce.current) clearTimeout(debounce.current);
		}
	}, []);

	return (
		<Stack
			className={PHRASE_DETAILS_TRANSLATION_EDITOR_CLASS_NAME}
			flexDirection={'row'}
			alignItems={'flex-start'}
			sx={{
				pt: theme.spacing(1),
				pr: theme.spacing(1),
			}}
		>
			<Textarea
				autoFocus
				fullWidth
				value={value}
				onChange={onChangeText}
				onBlur={onBlur}
				onKeyUp={onKeyUp}
				multiline={false}
				variant={'filled'}
				sx={{
					flex: `1 1 100%`,
				}}
			/>

			<Button
				sx={{
					width: theme.spacing(3.5),
					height: theme.spacing(3.5),
					color: theme.palette.grey[400],

					'.MuiSvgIcon-root': {
						width: theme.spacing(2),
						height: theme.spacing(2),
					},
				}}
				onClick={onClose}
			>
				<CloseIcon />
			</Button>

		</Stack>
	);
};
