import { ErrorRest } from '../../common/rest/error/errorRest';
import { getAuthUser } from '../store/general/selectors';
import { getState } from '../store';

export class ErrorEffects {

  public static async logError(e: Error | string): Promise<any> {
    try {
      const url = location?.href || '';
      const email = getAuthUser(getState())?.email || '';
      const text: string = (e instanceof Error) ? e.message : e;
      let log = 'url: ' + url + "\n" +
        'email: ' + email + "\n" +
        'error: ' + text;
      if (e instanceof Error) {
        log += "\nstack: " + e.stack;
      }
      await ErrorRest.log(log);
    } catch(e) {
      console.error(e);
    }
  }
}