import { EMainViewMode } from '../../../../store/general/types';
import { EPhraseListMode, EPhraseListPlayMode } from '../../../../store/phrase-list/types';
import { IVocabularyPhrase } from '../../../../types/common';

export interface IPhraseListMode {
  id: string,
  title: string;
  listMode: EPhraseListMode,
  langCode?: string
}

export interface IPhraseListFields {
  groupId: number,
  videoId: string,
  phraseList: number[],
  phrasesPlayStatus: EPhraseListPlayMode,
  phrasesExist: boolean,
  lazyLoadingPhrases: boolean,
  activeListModeId: string,
  listModes: IPhraseListMode[];
  mainViewMode: EMainViewMode;
  isTeacherMode?: boolean;
  isTeacherList: boolean;
}

export interface IPhraseListEvents {
  onPlay: () => void;
  onStop: () => void;
  onChangeListMode: (listModeId: string) => void,
  onLoadPhrases: (count: number) => Promise<boolean>,
  onAppendPhrases: (offset: number, count: number) => Promise<boolean>
  onTargetCaptionIndexChange: (index: number) => void;
  onKeyUp: (event: KeyboardEvent) => void;
  onKeyDown: (event: KeyboardEvent) => void;
  // onClickTitle: () => void;
  onAddAnnotation: (prevId?: number, nextId?: number,) => void;
}

export interface IPhraseListProps extends
  IPhraseListFields,
  IPhraseListEvents
{}

export const phrasesPartLoadSize = 50;