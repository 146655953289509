import { AppThunk } from '../../../../store/types';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';

export class PhraseListTranslatedEvents {

  public static onLoadPhrases(count: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return await PhraseEffects.loadPhrases(0, count, false);
    }
  }

  public static onAppendPhrases(offset: number, count: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return await PhraseEffects.loadPhrases(offset, count, true);
    }
  }

}