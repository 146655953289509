import { ICaptionsItem } from '../../../../../types/common';
import { findVideoPhraseByCaption, findVideoPhraseById, getVideoPhraseNoteByPhraseId, getVideoPhrases } from '../../../../../store/videos/selectors';
import {
  getActiveGroupNativeLanguage,
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById
} from '../../../../../store/models/selectors';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { SelectionPopupEffects } from '../../../../../effects/selectionPopupEffects';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';
import { CaptionsSelectionPopupActions } from '../../../../../store/captions-selection-popup/actions';
import { getSelectedNativeCaption, getSelectedTargetCaption } from '../../../../../store/current-video/selectors';
import { batch } from 'react-redux';
import { EShortcutsListenerContainer, setShortcutsListenerContainer } from '../../../../../effects/shortcutsListener';
import { IPhraseSelectResult } from '../../../../../effects/phrase/utils/phrase-select-preparator';
import { TextSelectionPopupEvents } from '../../../TextSelectionPopup/hocs/events';
import { getDispatch, getState } from '../../../../../store';
import { TextSelectors } from '../../../../../store/text/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { isMainLayoutPhrases2Columns } from '../../../../../store/general/selectors';
import { EPhrasesColumnTab } from '../../../../../store/general/types';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';

export class TargetCaptionsEffects {

  public static selectCaption(
    index: number,
    caption: ICaptionsItem,
    wordIndex: number,
    phraseId?: number,
  ) {
      const state = getState();
      const dispatch = getDispatch();

      const selResult: IPhraseSelectResult = {
        text: caption.text,
        startTime: caption.startTime,
        endTime: caption.endTime,
        startCaptionIndex: index,
        endCaptionIndex: index,
        startPosition: 0,
        endPosition: caption.text.length,
        elementId: `caption-item-${index}`,
      };

      let is2Columns = isMainLayoutPhrases2Columns(state);
      const storyFromTranslatedId = TextSelectors.getTextActiveId(state);
      const phrase = !storyFromTranslatedId && (phraseId
          ? findVideoPhraseById(state, phraseId)
          : findVideoPhraseByCaption(state, index, wordIndex)
      )
      if (phrase) {
        const group = getUserGroupById(state, phrase.userGroupId);
        if (!group) return;
        const targetLang = getGroupTargetLanguage(state, group);
        if (!targetLang) return;
        const nativeLang = getGroupNativeLanguage(state, group);
        SelectionUtil.removeWindowSelection();

        let note = getVideoPhraseNoteByPhraseId(state, phrase?.wordPhraseId || phrase?.id || 0)?.text;
        if (note) {
          note = note.replaceAll(/\n|<p><\/p>|<br\s*\/?>/gm, '').trim();
        }

        batch(() => {
          SelectionPopupEffects.dismissSelectText();
          dispatch(PhraseListActions.setTopPhraseId(phrase.id));
          dispatch(PhraseListActions.setPlayCaptionByPhraseId(phrase.id));
          dispatch(CaptionsSelectionPopupActions.setCurrentSelection({contextPhraseId: phrase.id, wordPhraseId: phrase.wordPhraseId}));
          if (note) {
            if (!is2Columns) {
              MainLayoutEffects.setPhrasesColumnTab(EPhrasesColumnTab.SAVED);
            }
            PhraseDetailsEffects.showNoteFromSavedPhrase(targetLang, nativeLang, nativeLang, phrase.highlighted, phrase);
          } else {
            if (!is2Columns) {
              MainLayoutEffects.setPhrasesColumnTab(EPhrasesColumnTab.TRANSLATE);
            }
            PhraseDetailsEffects.showFromSavedPhrase(targetLang, nativeLang, nativeLang, phrase.highlighted, phrase, true);
          }
        });
        
        PhraseEffects.seekPhrase(phrase);

        if (phraseId) {
          selResult.text = phrase.highlighted;
          selResult.startTime = phrase.startTime;
          selResult.endTime = phrase.endTime
          selResult.startCaptionIndex = phrase.startCaptionIndex;
          selResult.endCaptionIndex = phrase.endCaptionIndex;
          selResult.startPosition = phrase.startPosition;
          selResult.endPosition = phrase.endPosition;
        }

      } else {
        const targetLang = getSelectedTargetCaption(state);
        const nativeLang = getSelectedNativeCaption(state);
        const activeGroupNativeLang = getActiveGroupNativeLanguage(state);
        PhraseDetailsEffects.showFromCaption(index, targetLang, nativeLang, activeGroupNativeLang, caption);
        batch(() => {
          dispatch(PhraseListActions.setPlayCaptionByPhraseId(0));
          dispatch(PhraseListActions.setCurrentPhraseId(0));
          dispatch(PhraseListActions.setPlayPhraseId(0));
          if (!is2Columns) {
            MainLayoutEffects.setPhrasesColumnTab(EPhrasesColumnTab.TRANSLATE);
          }
        })
        SelectionUtil.removeWindowSelection();
        SelectionPopupEffects.dismissSelectText();
        dispatch(CaptionsSelectionPopupActions.setCurrentSelection({
          captionIndex: index,
          startTime: caption.startTime,
          endTime: caption.endTime
        }));
      }
      setShortcutsListenerContainer(EShortcutsListenerContainer.CAPTIONS);

      batch(() => {
        dispatch(TextSelectionPopupEvents.onPreviewPhrase(selResult));
        dispatch(CaptionsSelectionPopupActions.setShow(true));
        dispatch(CaptionsSelectionPopupActions.setSelectResult(selResult));
      })
    }
}