import React from 'react';
import { IDashboardProps } from './types';
import { EMainLayoutType } from '../../../../store/general/types';
import { theme } from '../../../../../common/styles/theme';
import { HistoryProrvider } from '../../../../effects/historyProrvider';
import { Route, Router, Switch } from 'react-router-dom';
import { alpha, Box, Drawer, Stack } from '@mui/material';
import { VideoLibraryHOC } from '../../VideoGroups/VideoLibrary/hocs/VideoLibraryHOC';
import { ButtononShowLeftPanel } from './ButtononShowLeftPanel';
import { PhraseListDetailsColumnHOC } from '../../Vocabulary/PhraseListDetailsColumn/hocs/PhraseListDetailsColumnHOC';
import { PhraseDetailsContainerHOC } from '../../PhraseDetails/PhraseDetailsContainer/hocs/PhraseDetailsContainerHOC';
import { PhraseListHOC } from '../../PhraseList/hocs/PhraseListHOC';
import { VideoHOC } from '../../Video/hocs/VideoHOC';
import { ButtonHelp } from './ButtonHelp';
import { AudioHOC } from '../../Audio/hocs/AudioHOC';
import { TopBarPanelHOC } from '../../ToolbarPanel/Toolbar/hocs/TopBarPanelHOC';
import { DeviceContext, MIN_WIDTH } from '../../../../../common/contexts/DeviceContext';
import { NoVideoMobileHOC } from '../../NoVideoMobile/hocs/NoVideoMobileHOC';
import { OneTimeTooltipSaveForMagicHOC } from '../../OneTimeTooltip/hocs/OneTimeTooltipSaveForMagicHOC/OneTimeTooltipSaveForMagicHOC';
import { MenuHOC } from '../../ToolbarPanel/Menu/hocs/MenuHOC';
import { DailyGoalHOC } from '../../ToolbarPanel/DailyGoal/hocs/DailyGoalHOC';

export const Dashboard: React.FC<IDashboardProps> = ({
  isCaptions2Columns,
  visible,
  layoutType,
  showLeftPanel,
  onShowLeftPanel,
  onShowHelp,
}) => {
  const {isMobile, isSmall} = React.useContext(DeviceContext);
  if (!visible && !isMobile) return null;

  const history = HistoryProrvider.getHistory();

  // const [canShowOnboarding, setCanShowOnboarding] = React.useState(false);
  // const onboardingTimeout = React.useRef(null as any);
  // React.useEffect(() => {
  // 	if (onboardingTimeout.current) clearTimeout(onboardingTimeout.current);
  // 	if (showOnboardingStep !== null) {
  // 		onboardingTimeout.current = setTimeout(() => {
  // 			onShowOnboarding(showOnboardingStep);
  // 			setCanShowOnboarding(true);
  // 		}, 3000);
  // 	}

  // 	return () => {
  // 		if (onboardingTimeout.current) clearTimeout(onboardingTimeout.current);
  // 	}
  // }, [showOnboardingStep]);

  const isFloatLeftPanel = isCaptions2Columns || isSmall;
  const [isFloatLeftPanelOpened, setFloatLeftPanelOpened] = React.useState(false);
  const toggleDrawer = (value: boolean) => {
    setFloatLeftPanelOpened(value);
  };

  const menuButton = <Box
    sx={{
      position: 'fixed',
      zIndex: 500,
      left: theme.spacing(.5),
      top: theme.spacing(7),
      m: `0 !important`,

      '.menu__button': {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.grey[400],
        background: theme.palette.common.white + ' !important',
        borderRadius: '50%',
        boxShadow: theme.shadows[8],

        '.MuiSvgIcon-root': {
          width: theme.spacing(2.5),
          height: theme.spacing(2.5),
        },
      },

      '.PrivateSwipeArea-root': {
        width: theme.spacing(.5) + ' !important',
      },
    }}
  >
    <MenuHOC />
  </Box>;

  return (
    <Router history={history}>
      {isMobile ? (
        <Stack
          direction='column'
          sx={{
            width: '100%',
            height: 1,
            background: theme.palette.secondary.main,
            display: visible ? 'flex' : 'none',
          }}
        >
          <TopBarPanelHOC
            isVideo={isMobile}
          />
          <Stack
            direction='column'
            gap={theme.spacing(2)}
            sx={{
              flex: `1 1 100%`,
              minHeight: 0,
              width: '100%',
              p: theme.spacing(1),
            }}
          >
            <Switch>
              <Route exact path='/video/:groupId/:videoId/:phraseId?/:pause?'>
                <VideoHOC />
              </Route>
              {visible &&
                <NoVideoMobileHOC />
              }
            </Switch>
          </Stack>
        </Stack>
      ) : (
        <Stack
          className={`
				${layoutType === EMainLayoutType.COLUMNS_4 ? 'columns-4' : ''}	
				${!showLeftPanel ? 'left-panel-collapsed' : ''}
				${isCaptions2Columns && !isSmall ? 'captions-2-columns' : ''}
        ${isSmall ? 'small' : ''}
			`}
          direction='row'
          spacing={theme.spacing(2)}
          sx={{
            minWidth: MIN_WIDTH + 'px',
            width: 1,
            height: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            p: theme.spacing(2),
            background: theme.palette.secondary.main,
            justifyContent: 'flex-start',

            '&.left-panel-collapsed, &.captions-2-columns, &.small': {
              pl: theme.spacing(3),
            },
          }}
        >
          {isFloatLeftPanel ? (
            <>
              <ButtononShowLeftPanel onClick={() => toggleDrawer(true)} />
              {menuButton}
              <ButtonHelp onClick={onShowHelp} />
              <DailyGoalHOC isSmall />
              <Drawer
                className={'left-panel-float'}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  '.MuiDrawer-paper': {
                    height: `calc(100% - ${theme.spacing(4)})`,
                    m: theme.spacing(2),
                    borderRadius: theme.spacing(0.75),
                  },
                  '.MuiBackdrop-root': {
                    background: alpha(theme.palette.grey[900], 0.2),
                  },
                }}
                open={isFloatLeftPanelOpened}
                onClose={() => toggleDrawer(false)}
              >
                <VideoLibraryHOC />
              </Drawer>
            </>
          ) : showLeftPanel ? (
            <Switch>
              <Route exact path='/video/:groupId/:videoId?'>
                <VideoLibraryHOC />
              </Route>
              <VideoLibraryHOC />
            </Switch>
          ) : (
            <>
              <ButtononShowLeftPanel onClick={onShowLeftPanel} />
              {menuButton}
              <ButtonHelp onClick={onShowHelp} />
              <DailyGoalHOC isSmall />
            </>
          )}

          <Switch>
            <Route exact path='/video/:groupId/:videoId/:phraseId?/:pause?'>
              <VideoHOC />
            </Route>
          </Switch>
          <Switch>
            <Route exact path='/audio/:groupId/:audioId'>
              <AudioHOC />
            </Route>
          </Switch>

          <Switch>
            <Route
              exact
              path={['/video/:groupId/:videoId/:phraseId?/:pause?', '/audio/:groupId/:audioId']}
            >
              {layoutType === EMainLayoutType.COLUMNS_3 && <PhraseListDetailsColumnHOC />}
              {layoutType === EMainLayoutType.COLUMNS_4 && (
                <>
                  <PhraseDetailsContainerHOC />
                  <PhraseListHOC />
                </>
              )}
            </Route>
          </Switch>
        </Stack>
      )}
      <OneTimeTooltipSaveForMagicHOC />
    </Router>
  );
};

