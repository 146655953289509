import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ContentLibGroup } from '../components/ContentLibGroup';
import { IContentLibGroupEvents, IContentLibGroupFields } from '../components/types';
import { IContentLibGroupHOCOwnProps } from './types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibGroupEvents } from './events';

const mapStateToProps = (
  dispatch: any,
  {groupId}: IContentLibGroupHOCOwnProps
) => {
  return (
    state: IState
  ): IContentLibGroupFields => {
    const group = ContentLibSelectors.findGroupById(state, groupId);
    let {
      id = 0,
      videos = [],
    } = group || {};
    return {
      id,
      videos
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId}: IContentLibGroupHOCOwnProps
): IContentLibGroupEvents => ({
    onClick: () => dispatch(ContentLibGroupEvents.onClick(groupId)),
  }
);

export const ContentLibGroupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibGroup);

ContentLibGroupHOC.displayName = 'ContentLibGroupHOC';
