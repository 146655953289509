import { PhraseDetailsBase } from './phrase-details-base';
import { GptChatRest } from '../../../../common/rest/gptChat/gptChatRest';
import { PhraseDetailsCache } from './phrase-details-cache';
import { EPhraseDetailsCustomPromptType, PhraseDetailsCustomPromptVars } from '../phraseDetailsCustomPromptVars';
import { LangUtil } from '../../../../common/utils/lang-util';
import { getAllVideoPhrasesWithFilterOrphans, getTargetCaptions } from '../../../store/videos/selectors';
import { getState } from '../../../store';
import { EVocabularyPhraseType, ICaptionsItem } from '../../../types/common';
import { EntireScriptPromptPreparator } from './entireScriptPromptPreparator';

const md5 = require('md5');

interface IPreparePromptParams {
  text: string;
  context: string;
  targetLang: string;
  nativeLang: string;
}

export abstract class PhraseDetailsGptchatService extends PhraseDetailsBase {

  private static ENTIRY_SCRIPT_PROMPT_MAX_LINES = 100;
  private static PARAM_RE = new RegExp(/\{([^\}]+)\}/g);

  protected text: string;
  protected context: string;
  protected targetLangCode: string;
  protected nativeLangCode: string;

  private paramValues: Record<string, string> = null;

  constructor(text: string, context: string, targetLangCode: string, nativeLangCode: string) {
    super();
    this.text = text;
    this.context = context;
    this.targetLangCode = this.checkLangCode(targetLangCode);
    this.nativeLangCode = this.checkLangCode(nativeLangCode);
  }

  public async load(useCache: boolean = true): Promise<string | undefined> {
    const promptText = await this.getPromptText();
    const entireScriptUsed = this.isPromptEntireScriptUsed(promptText);
    const multiPrompts = entireScriptUsed && this.getEntiryScriptLines().length > PhraseDetailsGptchatService.ENTIRY_SCRIPT_PROMPT_MAX_LINES;

    let prompt = await this.getPreparedPrompt(promptText, entireScriptUsed);
    if (entireScriptUsed && !multiPrompts) {
      prompt += ' ' + this.getEntiryScript();
    }
    if (!prompt) {
      return '';
    }

    if (multiPrompts) {
      return this.execBatchPromps(prompt, useCache);
    } else {
      return this.execCommonPrompt(prompt, useCache);
    }
  }

  private async execCommonPrompt(prompt: string, useCache: boolean): Promise<string | undefined> {
    let result;
    if (useCache) {
      result = PhraseDetailsCache.get([prompt, this.constructor.name]);
    }
    if (result) {
      return result;
    }

    const hash = md5(prompt);
    result = await this.callApi(prompt, hash);

    if (result) {
      result = result.trim();
      PhraseDetailsCache.put([prompt, this.constructor.name], result);
    }
    return result;
  }

  private async execBatchPromps(prompt: string, useCache: boolean): Promise<string | undefined> {
    const prompts = new EntireScriptPromptPreparator(prompt, this.getEntiryScriptLines(), PhraseDetailsGptchatService.ENTIRY_SCRIPT_PROMPT_MAX_LINES).prepare();
    let result;
    let hash;
    if (useCache) {
      hash = md5(prompts.join(' '));
      result = PhraseDetailsCache.get([hash, this.constructor.name]);
    }
    if (result) {
      return result;
    }

    result = await this.callBatchApi(prompts);

    if (result && useCache && hash) {
      PhraseDetailsCache.put([hash, this.constructor.name], result);
    }
    return result;
  }

  private isPromptEntireScriptUsed(promptText: string): boolean {
    const v = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.ENTIRE_SCRIPT);
    return promptText.indexOf(v.name) >= 0;
  }

  public async getPreparedPrompt(promptText: string, entireScriptUsed: boolean): Promise<string> {
  //  const params = this.getPromptParams();

    if (!promptText) {
      return '';
    }

    PhraseDetailsCustomPromptVars.getListWithNameLenSort(true).forEach(v => {
      promptText = promptText.replaceAll(v.name, v.originalName)
    })

    const paramNames = PhraseDetailsCustomPromptVars.getVarsInPrompt(promptText);
    paramNames.forEach(paramName => {
      const value = this.getPromptParam(paramName);
      if (value) {
        promptText = promptText.replaceAll('{'+paramName+'}', value);
      } else {
        promptText = promptText.replaceAll('{'+paramName+'}', '');
      }

    })
    promptText = promptText.trim();
    if (entireScriptUsed && promptText.endsWith(':'))
      promptText = promptText.substring(0, promptText.length - 1);

    return promptText;
  }

  public static preparePrompt(
    params: IPreparePromptParams,
    prompt: string,
  ): string {
    if (!prompt) return '';

    PhraseDetailsCustomPromptVars.getListWithNameLenSort(true).forEach(v => {
      prompt = prompt.replaceAll(v.name, v.originalName)
    })

    const paramNames = PhraseDetailsCustomPromptVars.getVarsInPrompt(prompt);
    paramNames.forEach(paramName => {
      if (paramName && params.hasOwnProperty(paramName)) {
            let key = paramName as keyof IPreparePromptParams;
            const value = params[key];
            prompt = prompt.replaceAll('{'+paramName+'}', value);
          }
    })
    
    // const result = prompt.matchAll(PhraseDetailsGptchatService.PARAM_RE);
    // for (const item of result) {
    //   const paramName = item[1]?.trim();
    //   if (paramName && params.hasOwnProperty(paramName)) {
    //     const value = params[paramName];
    //     prompt = prompt.replaceAll('{'+paramName+'}', value);
    //   }
    // }
    return prompt;
  }

  private async callApi(prompt: string, hash: string): Promise<string | undefined> {
    if (this.useHashCallApi()) {
      this.saveHash(hash);
    }
    const resp = await GptChatRest.exec({ prompt, hash });
    hash = this.getHash();
    if (this.useHashCallApi() && hash !== resp.hash) {
      return undefined;
    }
    return resp.result;
  }

  private async callBatchApi(prompts: string[]): Promise<string | undefined> {
    const {result} = await GptChatRest.execBatch({ prompts });
    return result;
  }

  protected useHashCallApi(): boolean {
    return true;
  }

  protected getPromptParam(param: string): string {
    if (!this.paramValues) {
      this.paramValues = this.getPromptParams()
    }
    if (this.paramValues[param]) {
      return this.paramValues[param];
    }
    if (PhraseDetailsCustomPromptVars.ENTIRE_SCRIPT_PARAM === param) {
      return ''; //this.getEntiryScript();
    }
    if (PhraseDetailsCustomPromptVars.PHRASE_LIST_PARAM === param) {
      return this.getPhraseList();
    }
    return '';
  }

  private getPromptParams(): Record<string, string> {
    const targetLang = LangUtil.getLangNameByCode(this.targetLangCode);
    const nativeLang = LangUtil.getLangNameByCode(this.nativeLangCode);
    return {
      targetLang,
      nativeLang,
      text: this.text,
      context: this.context
    }
  }

  private getEntiryScriptLines(): string[] {
    return getTargetCaptions(getState()).map((caption: ICaptionsItem) => caption.text);
  }

  private getEntiryScript(): string {
    return this.getEntiryScriptLines().join(' ');
  }

  private getPhraseList() : string {
    return getAllVideoPhrasesWithFilterOrphans(getState()).
      filter(p => p.type === EVocabularyPhraseType.WORD_SELECTED).
      map(p => p.highlighted).join(', ');
  }

  protected abstract getPromptText(): Promise<string>;

  protected abstract saveHash(hash: string): void;

  protected abstract getHash(): string;

}