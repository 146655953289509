import React from 'react';
import { Box, Grid, Stack, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../../../../../common/styles/theme';
import { Text } from '../../../../../../../common/components/Text/Text';
import { VideoIcon } from '../../../../../../../../icons/VideoIcon';
import { CommentIcon } from '../../../../../../../../icons/CommentIcon';
import { TrashIcon } from '../../../../../../../../icons/TrashIcon';
import { ConfirmPopup } from '../../../../ConfirmPopup/ConfirmPopup';
import { IEditPlaylistItemProps } from './types';
import { LangFlag } from '../../../../LangFlag/LangFlag';
import { PencilIcon } from '../../../../../../../../icons/PencilIcon';
import { LinkIcon } from '../../../../../../../../icons/LinkIcon';
import { IconMenuItem } from '../../../../../../../common/components/IconMenuItem/IconMenuItem';
import { PlayListLevelTitles } from '../../../PlayListLevelSelect/types';
import { WorldIcon } from '../../../../../../../../icons/WorldIcon';
import { ButtonMenu } from '../../../../../../../common/components/ButtonMenu/ButtonMenu';
import { ButtonIconGrey } from '../../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowLightLeftIcon } from '../../../../../../../../icons/ArrowLightLeftIcon';

export const EditPlaylistItem: React.FC<IEditPlaylistItemProps> = ({
	id,
	label,
	description,
	itemsCount,
	phrasesCount,
	targetLangCode,
	nativeLangCode,
	publicGroup,
	videos,
	level,
	isActive,
	onSelectGroup,
	onDelete,
	onEdit,
	onCopyLink,
	onBack,
}) => {
	const className = 'edit-playlist-item';

	const history = useHistory();
	const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);

	const menuRef = React.useRef(null);

	const handleDelete = () => {
		setAnchor(null);
		onDelete(history);
	};

	const [menuOpen, setMenuOpen] = React.useState(false);
	const handleMenuOpen = () => {
		setMenuOpen(true);
	}
	const handleMenuClose = () => {
		setMenuOpen(false);
	}

	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [label]);

	const titleElement = <Box
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',
			lineHeight: `1.3 !important`,
			
			fontSize: theme.typography.h5,
			fontWeight: 600,
		}}
		ref={ref1}
	>
		<span
			ref={ref2}
		>
			{label}
		</span>
	</Box>

	const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: '80vw',
		},
	});

	let v0: string = '',
		v1: string = '',
		v2: string = '',
		v3: string = '';
	
	if (videos && videos.length) {
		v0 = videos[0].info.thumb;

		if (videos.length > 1) {
			v1 = videos[1].info.thumb;

			if (videos.length > 2) {
				v2 = videos[2].info.thumb;

				if (videos.length > 3) {
					v3 = videos[3].info.thumb;
				}
			}
		}
	}

	return (
		<Stack
			className={`${className} ${isActive ? 'active': ''}`}
			direction={'row'}
			gap={3}
			sx={{
				alignItems: 'center',
				py: 2,
				borderBottom: `2px solid ${theme.palette.grey[200]}`,

				'&.active': {
					border: 'none',
				},

				'.content-lib-group-info__vertical': {
					gap: 2,
				},

				[theme.breakpoints.down('sm')]: {
					flexDirection: 'column',

					[`.${className}__video`]: {
						flexBasis: 0,
					},
				},
			}}
		>

			{isActive
				? <ButtonIconGrey
					sx={{
						alignSelf: 'flex-start',
						'.MuiSvgIcon-root': {
							width: theme.spacing(3),
							height: theme.spacing(3),
						},
					}}
					onClick={onBack}
				>
					<ArrowLightLeftIcon />
				</ButtonIconGrey>
				: <Box
					className={`${className}__video`}
					sx={{
						alignSelf: 'flex-start',
						flex: `1 1 256px`,
						width: 1,
						position: 'relative',
						mt: theme.spacing(1),
						cursor: 'pointer',

						[`&:before, &:after`]: {
							content: '""',
							position: 'absolute',
							zIndex: 1,
							aspectRatio: 16/9,
							borderRadius: theme.spacing(1),
						},

						'&:before': {
							top: theme.spacing(-1.5),
							left: theme.spacing(-1.5),
							right: theme.spacing(1.5),
							background: `rgb(252,252,254)`,
						},

						'&:after': {
							top: theme.spacing(-.75),
							left: theme.spacing(-.75),
							right: theme.spacing(.75),
							background: theme.palette.grey[200],
						},
					}}
					onClick={onSelectGroup}
				>
					<Box
						sx={{
							position: 'relative',
							zIndex: 2,
							aspectRatio: 16/9,
							background: v0 ? `url(${v0})` : theme.palette.grey[400],
							backgroundPosition: '50% 50%',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							borderRadius: theme.spacing(1),
							overflow: 'hidden',
						}}
					>
						{v1 &&
							<Grid container
								sx={{
									width: 1,
									height: 1,

									'.MuiGrid-root': {
										width: .5,
										height: .5,
										backgroundPosition: '50% 50%',
										backgroundSize: 'cover',
										backgroundRepeat: 'no-repeat',
									}
								}}
							>
								<Grid
									sx={{
										backgroundImage: `url(${v0})`,
									}}
								/>
								<Grid
									sx={{
										backgroundImage: `url(${v1})`,
									}}
								/>
								{v2 &&
									<Grid
										sx={{
											backgroundImage: `url(${v2})`,
										}}
									/>
								}
								{v3 &&
									<Grid
										sx={{
											backgroundImage: `url(${v3})`,
										}}
									/>
								}
							</Grid>
						}
					</Box>
				</Box>
			}

			<Stack
				className={`${className}__vertical`}
				gap={2}
				sx={{
					position: 'relative',
					zIndex: 90,
					flex: `1 1 100%`,
					background: theme.palette.common.white,
				}}
			>
				<Stack
					gap={1}
					sx={{
						cursor: 'pointer',
					}}
					onClick={isActive ? onEdit : onSelectGroup}
				>
					{isLongTitle
						? <NoMaxWidthTooltip
							title={label}
							enterDelay={1000}
						>
							{titleElement}	
						</NoMaxWidthTooltip>
						: titleElement
					}

					{description &&
						<Box
							sx={{
								fontSize: theme.typography.subtitle2,

								[`.${className}:not(.active) &`]: {
									display: '-webkit-box',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									'-webkit-line-clamp': '5',
									'-webkit-box-orient': 'vertical',
									maxHeight: '7.7em',
									lineHeight: `1.3 !important`,
								},
							}}
							dangerouslySetInnerHTML={{ __html: description.replaceAll('\n', '<br>') }}
						/>
					}
				</Stack>

				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					gap={3}
					rowGap={0}
				>
					<Stack
						direction={'row'}
						gap={3}
						rowGap={0}
						flexWrap={'wrap'}
						alignItems={'center'}
					>
						<Stack
							direction={'row'}
							gap={1}
							sx={{
								'.lang-flag__code': {
									color: theme.palette.grey[500],
								},
							}}
						>
							<LangFlag code={targetLangCode} showCode />
							<LangFlag code={nativeLangCode} showCode />
						</Stack>

						<Stack
							direction='row'
							gap={theme.spacing(1)}
							alignItems={'center'}
							sx={{
								minWidth: theme.spacing(4.5),
							}}
						>
							<VideoIcon
								sx={{
									width: theme.spacing(2),
									color: theme.palette.grey[300]
								}}
							/>
							<Text
								sx={{ color: theme.palette.grey[500] }}
							>
								{itemsCount}
							</Text>
						</Stack>

						<Stack
							direction='row'
							spacing={theme.spacing()}
							alignItems={'center'}
							sx={{
								minWidth: theme.spacing(4.5),
							}}
						>
							<CommentIcon
								sx={{
									width: theme.spacing(2),
									color: theme.palette.grey[300]
								}}
							/>
							<Text sx={{ color: theme.palette.grey[500] }}>
								{phrasesCount}
							</Text>
						</Stack>

						{!!level &&
							<Box
								sx={{
									mr: theme.spacing(1),
									p: theme.spacing(.25, .5),
									fontSize: theme.typography.fontSize,
									background: theme.palette.grey[50],
									borderRadius: theme.spacing(.5),
									cursor: 'pointer',
								}}
								onClick={onEdit}
							>
								{PlayListLevelTitles[level]}
							</Box>
						}
					</Stack>

					<ButtonMenu
						buttonProps={{
							sx: {
								position: 'absolute',
								right: theme.spacing(-1),
								top: theme.spacing(-1),
								height: theme.spacing(5),
								width: theme.spacing(5),
								background: theme.palette.common.white,
							},
							ref: menuRef,
						}}
						menuProps={{
							keepMounted: true,
							open: !!menuOpen,
						}}
						onOpen={handleMenuOpen}
						onClose={handleMenuClose}
					>
						<>
						<IconMenuItem
							title={publicGroup ? 'Public playlist' : 'Edit playlist'}
							onClick={() => {
								handleMenuClose();
								onEdit();
							}}
						>
							{publicGroup
								? <WorldIcon />
								: <PencilIcon />
							}
						</IconMenuItem>
						<IconMenuItem
							title={'Share public link'}
							onClick={() => {
								handleMenuClose();
								onCopyLink();
							}}
						>
							<LinkIcon />
						</IconMenuItem>
						<IconMenuItem
							title={'Delete'}
							onClick={() => {
								handleMenuClose();
								setAnchor(menuRef.current);
							}}
						>
							<TrashIcon />
						</IconMenuItem>
						</>
					</ButtonMenu>

					<ConfirmPopup
						anchor={anchor}
						text={'Delete playlist?'}
						onConfirm={handleDelete}
						onClose={() => setAnchor(null)}
					/>

				</Stack>
			</Stack>

		</Stack>
	);
}
