import React from 'react';
import { ILoginPopupProps } from './types';
import { Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { ButtonLogin } from '../ButtonLogin/ButtonLogin';
import { Text } from '../../../../../../common/components/Text/Text';

export const LoginPopup: React.FC<ILoginPopupProps> = ({
	isOpen,
	text,
	onClose,
	onLogin,
}) => {

	return (
		<Modal
			onClose={onClose}
			open={isOpen}
			PaperProps={{
				sx: {
					width: 1,
					maxWidth: `${theme.spacing(60)} !important`,
				}
			}}
		>
			<Stack
				gap={theme.spacing(3)}
				alignItems={'center'}
				sx={{
					pb: theme.spacing(5),
				}}
			>
				<Text
					variant={'h6'}
				>
					{text || 'Please login to use LangMagic'}
				</Text>
				<ButtonLogin
					onLogin={onLogin}
				/>
			</Stack>
		</Modal>
	);
}
