import React from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormGroup,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	TextField
} from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { IAddVideoConfirmInlineProps } from './types';
import { ADD_VIDEO_NO_CAPTIONS_TEXT } from './wordings';
import { Text } from '../../../../../common/components/Text/Text';
import { createGroupNameByLangs } from '../../../../store/models/selectors';
import { CheckIcon } from '../../../../../../icons/CheckIcon';
import { PlusIcon } from '../../../../../../icons/PlusIcon';
import LangFlag from '../../LangFlag/LangFlag';
import { ELanguageStatus } from '../../../../store/general/types';

export const AddVideoConfirmInline: React.FC<IAddVideoConfirmInlineProps> = ({
	groups,
	nativeLang,
	videoLangCodes,
	newListName,
	text,
	onChange,
	onClose,
}) => {
	const [progress, setProgress] = React.useState<boolean>(false);
	const [selected, setSelected] = React.useState(groups.length === 1
		? groups[0].id
		: -1
	);
	const [_newListName, setNewListName] = React.useState(newListName);
	const [isNewList, setIsNewList] = React.useState(false);
	const [success, setSuccess] = React.useState(false);

	const videoCaptionsExist = !!videoLangCodes?.length;
	const videoLangCodesOnlyNative = videoLangCodes?.length === 1 && videoLangCodes[0] === nativeLang;
	const canSelect = videoCaptionsExist && !videoLangCodesOnlyNative;

	const onChangeHandle = async (groupId: number, newListName?: string) => {
		setSelected(groupId);
		setProgress(true);
		try {
			await onChange(groupId, newListName);
		} catch(e) {
			setProgress(false);
		}
		setSuccess(true);
	};

	const onChangeSelect = (event: SelectChangeEvent) => {
		const groupId = +event.target.value;
		if (groupId) {
			setSelected(groupId);
		} else {
			setIsNewList(true);
		}
	};

	const onChangeNewList = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewListName(event.target.value);
	};

	const onSubmitNewList = () => {
		onChangeHandle(0, _newListName);
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSubmitNewList();
		}
	};

	const CLASS_NEWLIST_CONTROL = 'newlist-control';
	const onBlurNewList = (event: React.FocusEvent) => {
		if (event.relatedTarget && event.relatedTarget.classList.contains(CLASS_NEWLIST_CONTROL)) {
			// not blur
		} else {
			setIsNewList(false);
		}
	};

	const onSubmit = () => {
		onChangeHandle(selected);
	};

	const loader = <Box
		sx={{
			flex: `0 1 50%`,
			height: theme.spacing(5),
			width: 1,
			pt: theme.spacing(1),
			textAlign: 'center',
		}}
	>
		<CircularProgress
			size={theme.spacing(3)}
		/>
	</Box>;

	return (
		<Stack
			gap={theme.spacing(3)}
		>	
			{!canSelect
				? <Text>
					<Text
						sx={{
							fontSize: theme.typography.h4,
							fontWeight: 600,
						}}
					>
						Whoops
					</Text>

					<Text
						sx={{
							mt: theme.spacing(.5),
							fontSize: theme.typography.subtitle2,
							fontWeight: 300,
						}}
					>
						{ADD_VIDEO_NO_CAPTIONS_TEXT}
					</Text>
				</Text>
				: <Text
					sx={{
						fontSize: theme.typography.subtitle2,
						fontWeight: 300,
					}}
				>
					{text || 'Please select a playlist to insert this video into'}
				</Text>
			}

			<Box
				sx={{
					position: 'relative',
					maxWidth: `${theme.spacing(62)} !important`,
				}}
			>
				{success
					? <Stack
						sx={{
							height: theme.spacing(5),
							width: 1,
							alignItems: 'center',
						}}
					>
						<Stack
							className={'animate__animated animate__fadeIn'}
							justifyContent={'center'}
							alignItems={'center'}
							sx={{
								width: theme.spacing(4),
								height: theme.spacing(4),
								border: `3px solid ${theme.palette.success.main}`,
								borderRadius: '50%',
							}}
						>
							<CheckIcon sx={{
								width: theme.spacing(2),
								color: theme.palette.success.main,
							}} />  
						</Stack>
					</Stack>
					: !canSelect
						? <Button
							disabled={progress}
							onClick={onClose}
							variant={'contained'}
							fullWidth
							sx={{
								'&[disabled]': {
									background: theme.palette.grey[100],
								}
							}}
						>
							OK
						</Button>

						: <FormGroup
							sx={{
								flexFlow: 'row nowrap',
								gap: theme.spacing(1),

								[theme.breakpoints.down('sm')]: {
									flexWrap: 'wrap',
									rowGap: theme.spacing(1),
								}
							}}
							onKeyDown={handleKeyDown}
							onBlur={onBlurNewList}
						>
							{isNewList || !groups.length
								? <>     
									<TextField
										autoFocus
										fullWidth
										value={_newListName}
										onChange={onChangeNewList}
										multiline={false}
										variant={'filled'}
										sx={{
											flex: `0 1 50%`,
										}}
									/>
									{progress
										? loader
										: <Button
											className={CLASS_NEWLIST_CONTROL}
											disabled={progress}
											onClick={onSubmitNewList}
											variant={'contained'}
											fullWidth
											sx={{
												flex: `0 1 50%`,

												'&[disabled]': {
													background: theme.palette.grey[100],
												}
											}}
										>
											Create new playlist
										</Button>
									}
								</>
								: <>
									<Select
										disabled={progress}
										variant={'filled'}
										fullWidth
										value={String(selected)}
										onChange={onChangeSelect}
										sx={{
											flex: `0 1 50%`,
										}}
										MenuProps={{
											anchorOrigin: {
												vertical: 'bottom',
												horizontal: 'left', 
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'left', 
											},
										}}
									>
										<MenuItem
											disabled
											value={'-1'}
											key={'placeholder'}
											sx={{
												display: 'none',
											}}
										>
											Select playlist
										</MenuItem>

										{groups.map((group) => {
											let target = group.languages?.find(lang => lang.status === ELanguageStatus.TO_LEARN)?.code;
											let native = group.languages?.find(lang => lang.status === ELanguageStatus.KNOW)?.code;

											return <MenuItem
												key={group.id}
												value={group.id}
											>
												<Stack
													direction={'row'}
													alignItems={'center'}
													gap={theme.spacing(1)}
												>
													{group.name || createGroupNameByLangs(group)}
													
													<Stack
														direction={'row'}
														alignItems={'center'}
														gap={theme.spacing(1)}
													>
														{target &&
															<LangFlag
																code={target}
															/>
														}
														{native &&
															<LangFlag
																code={native}
															/>
														}
													</Stack>
												</Stack>
											</MenuItem>
										})}

										<Divider sx={{
											mt: theme.spacing(.5),
											mb: theme.spacing(.5),
										}}/>

										<MenuItem
											key={0}
											value={0}
										>
											<Stack
												direction={'row'}
												alignItems={'center'}
											>
												<IconButton
													color={'primary'}
													sx={{
														height: theme.spacing(4),
														p: 0,
														mr: theme.spacing(1),
														color: theme.palette.grey[400],
													}}
												>
													<PlusIcon />
												</IconButton>
												Create new playlist
											</Stack>
										</MenuItem>
										
									</Select>

									{progress
										? loader
										: selected > -1 &&
											<Button
												disabled={progress}
												onClick={onSubmit}
												variant={'contained'}
												fullWidth
												sx={{
													flex: `0 1 50%`,

													'&[disabled]': {
														background: theme.palette.grey[100],
													}
												}}
											>
												OK
											</Button>
									}
								</>
							}
						</FormGroup>
				}
			</Box>
		</Stack>
	);
}
