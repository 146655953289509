import { IMyLibAddButtonsProps } from './types';
import React from 'react';
import { Box, Button, SxProps } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { TextIcon } from '../../../../../../icons/TextIcon';
import { YouTubeLabelIcon } from '../../../../../../icons/YouTubeLabelIcon';
import { BookIcon } from '../../../../../../icons/BookIcon';

export function MyLibAddButtons ({
	isLib,
	langCode,
	onAddText,
	onAddVideoLib,
	onAddVideoYT,
	onLoad,
}: IMyLibAddButtonsProps) {

	let btnSx: SxProps = {
		height: theme.spacing(5),
		p: theme.spacing(0, 1, 0, 1.5),
		color: theme.palette.grey[900],
		background: theme.palette.grey[50],

		'&:hover, &:focus': {
			background: theme.palette.grey[100],
		},

		'.MuiButton-startIcon': {
			color: theme.palette.grey[400],
		},

		'@media (max-width: 1000px)': {
			width: theme.spacing(5),
			p: 0,

			'.MuiButton-startIcon': {
				m: 0,
			},

			'.MuiBox-root': {
				display: 'none',
			},
		},

	};

	React.useEffect(() => {
		if (!isLib) onLoad();
	}, [langCode]);

	return (<>
		<Button
			startIcon={<YouTubeLabelIcon />}
			sx={btnSx}
			onClick={onAddVideoYT}
		>
			<Box>Add from youtube</Box>
		</Button>

		<Button
			startIcon={<TextIcon
				sx={{
					color: theme.palette.secondary[300],
				}}
			/>}
			sx={btnSx}
			onClick={onAddText}
		>
			<Box>Text to audio</Box>
		</Button>

		{isLib &&
			<Button
				startIcon={<BookIcon
					sx={{
						width: theme.spacing(2),
						height: theme.spacing(2),
						color: theme.palette.secondary[200],
					}}
				/>}
				sx={btnSx}
				onClick={onAddVideoLib}
			>
				<Box>Add from library</Box>
			</Button>
		}
	</>);
}
