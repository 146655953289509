import React from 'react';
import { IContentLibraryProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Stack } from '@mui/material';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { ContentLibraryHeaderHOC } from '../../ContentLibraryHeader/hocs/ContentLibraryHeaderHOC';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibVideoPopupHOC } from '../../ContentLibVideoPopup/hocs/ContentLibVideoPopupHOC';
import { ContentLibCopyGroupConfirmHOC } from '../../ContentLibCopyGroupConfirm/hocs/ContentLibCopyGroupConfirmHOC';
import { ContentLibCopyVideoConfirmHOC } from '../../ContentLibCopyVideoConfirm/hocs/ContentLibCopyVideoConfirmHOC';
import { ContentLibVideoListHOC } from '../../ContentLibVideoList/hocs/ContentLibVideoListHOC';
import { ContentLibGroupListHOC } from '../../ContentLibGroupList/hocs/ContentLibGroupListHOC';
import { ContentLibraryFiltersHOC } from '../../ContentLibraryFilters/hocs/ContentLibraryFiltersHOC';
import { EMainViewMode } from '../../../../../store/general/types';
import { MyLibHOC } from '../../MyLib/hocs/MyLibHOC';
import { MyLibVideosHOC } from '../../MyLibVideos/hocs/MyLibVideosHOC';
import { AddVideoConfirmHOC } from '../../../AddVideoConfirm/hocs/AddVideoConfirmHOC';
import { MenuHOC } from '../../../ToolbarPanel/Menu/hocs/MenuHOC';
import { AddAudioConfirmHOC } from '../../../AddAudioConfirm/hocs/AddAudioConfirmHOC';
import { CopyVideoConfirmHOC } from '../../../CopyVideo/CopyVideoConfirm/CopyVideoConfirmPopup/hocs/CopyVideoConfirmHOC';

export const ContentLibraryDashboard: React.FC<IContentLibraryProps> = ({
	viewMode,
	mainViewMode,
	onLoad,
	onUnLoad
}) => {

	const className = 'content-library';

	React.useEffect(() => {
		onLoad();
		return () => {
			onUnLoad();
		}
	}, [])

	return <Stack
		direction={'row'}
		gap={2}
		sx={{
			width: 1,
			height: 1,
			overflowY: 'auto',
			background: theme.palette.grey[100],
		}}
	>		
		<Box
			sx={{
				p: theme.spacing(2, 0, 2, 2),

				'& > .menu': {
					minWidth: theme.spacing(30),
				},
			}}
		>
			<MenuHOC />
		</Box>

		<Stack
			className={`
				${className}
			`}
			gap={2}
			sx={{
				width: 1,
				p: theme.spacing(2, 2, 2, 0),
				overflow: 'auto',
			}}
		>
			<ContentLibraryHeaderHOC />

			<Stack
				gap={2}
				sx={{
					flex: `1 1 100%`,
					minHeight: 0,
					width: 1,
				}}
			>
				

				<Paper
					sx={{
						width: 1,
						px: 3,
					}}
				>
					{mainViewMode === EMainViewMode.MY_LIBRARY
						? viewMode === EContentLibViewMode.GROUP_LIST
							? <MyLibHOC />
							: <MyLibVideosHOC />
							
						: viewMode === EContentLibViewMode.GROUP_LIST
							? <ContentLibGroupListHOC/>
							: <ContentLibVideoListHOC/>
					}
				</Paper>

				<Box sx={{
					mt: '-1px',
					pt: '1px',
				}}/>

			</Stack>
			<ContentLibVideoPopupHOC/>
			<ContentLibCopyVideoConfirmHOC/>
			<ContentLibCopyGroupConfirmHOC/>

			<AddVideoConfirmHOC />
			<CopyVideoConfirmHOC />
			<AddAudioConfirmHOC />
		</Stack>
	</Stack>
};
