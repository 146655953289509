import { IMyLibVideosProps } from './types';
import React from 'react';
import { Box } from '@mui/material';
import { ContentLibVideo } from '../../ContentLibVideo/components/ContentLibVideo';
import { theme } from '../../../../../../common/styles/theme';

export function MyLibVideos({
	groups,
	onClickVideo,
	onVideoDelete,
  	onVideoCopyLink,
}: IMyLibVideosProps) {

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'grid',
				gridTemplateColumns: `repeat(5, 1fr)`,
				gap: 2,
				py: theme.spacing(2),

				'@media (min-width: 1600px)': {
					gridTemplateColumns: `repeat(7, 1fr)`,
				},

				'@media (max-width: 1000px)': {
					gridTemplateColumns: `repeat(3, 1fr)`,
				},

				'@media (max-width: 600px)': {
					gridTemplateColumns: `repeat(2, 1fr)`,
				},
			}}
		>
			{groups && !!groups.length &&
				groups.map(group => {
					return (group && group.videos && !!group.videos.length &&
						group.videos.map(video => {
							return (
								<ContentLibVideo
									key={video.id}
									title={video.info.title}
									thumbUrl={video.info.thumb}
									channel={video.info.author}
									onClick={() => onClickVideo(group.id, video.videoKey)}
									onDelete={() => onVideoDelete(group.id, video.videoKey)}
									onGetCopyLink={() => onVideoCopyLink(group.id, video.videoKey)}
								/>
							)
						})
					);
				})
			}
		</Box>
	);
}
