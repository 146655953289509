import React from 'react';
import { Box, IconButton, InputAdornment, Menu, Stack, TextField, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { IContentLibraryFiltersProps } from './types';
import { VideoIcon } from '../../../../../../../icons/VideoIcon';
import { AudioIcon } from '../../../../../../../icons/AudioIcon';
import { TextIcon } from '../../../../../../../icons/TextIcon';
import { Tabs } from '../../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../../common/components/Tab/Tab';
import { SearchIcon } from '../../../../../../../icons/SearchIcon';
import LangFlag from '../../../LangFlag/LangFlag';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { ArrowDropIcon } from '../../../../../../../icons/ArrowDropIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ContentLibLangSelectHOC } from '../../ContentLibLangSelect/hocs/ContentLibLangSelectHOC';
import { CloseIconLight } from '../../../../../../../icons/CloseIconLight';

export function ContentLibraryFilters({
	text,
	targetLangCode,
	nativeLangCode,
	onChangeText,
	onChangeTargetLangCode,
	onChangeNativeLangCode,
}: IContentLibraryFiltersProps) {

	const className = 'content-library-filters';
	const CLASS_CONTROLS = 'content-library-filters-text-controls';

	const {isMobile, isSmall} = React.useContext(DeviceContext);

	const [langsEl, setLangsEl] = React.useState<null | HTMLElement>(null);
	const [searchText, setSearchText] = React.useState<string>('');
	const [searchChangedText, setSearchChangedText] = React.useState<string>('');

	const langsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setLangsEl(event.currentTarget);
	};
	const langsClose = () => {
		setLangsEl(null);
	};

	const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(e.target?.value || '');
	}

	const onSearchTextClear = () => {
		if (!!text) {
			console.log(text);
			setSearchChangedText('');
		} else {
			setSearchText('');
		}
	}

	const onSearchBlur = (e: React.FocusEvent) => {
		const target = e.relatedTarget as HTMLElement;
		const controls = target?.closest(`.${CLASS_CONTROLS}`);
		if (controls) return;
		
		setSearchChangedText(searchText);
	}

	const onSearchKeyPress = (event: React.KeyboardEvent) => {
		if ('Enter' === event.key) {
			setSearchChangedText(searchText);
		}
	}

	React.useEffect(() => {
		setSearchText(text);
	}, [text])

	React.useEffect(() => {
		onChangeText(searchChangedText);
	}, [searchChangedText])

	return (
		<Paper
			className={className}
			sx={{
				position: 'relative',
				zIndex: 100,
				flex: `0 0 ${theme.spacing(8)}`,
				background: 'none',
			}}
		>
			<Stack
				className={`${className}__stack`}
				direction={'row'}
				alignItems={'center'}
				gap={2}
				sx={{
					width: 1,
					height: 1,
				}}
			>
				<Tabs
					className={`${className}__tabs`}
					sx={{
						p: theme.spacing(.5, .75),
						bgcolor: theme.palette.grey[50],
						overflow: 'visible',

						'.MuiTabs-flexContainer': {
							gap: theme.spacing(.75),
						},

						'.MuiTab-root': {
							minWidth: theme.spacing(5),
							minHeight: theme.spacing(5),
							padding: theme.spacing(0, 1),
							fontSize: theme.typography.subtitle2,
							cursor: 'default', // while audio & text disabled

							'.MuiSvgIcon-root': {
								opacity: .3,
							},

							'&.Mui-selected': {
								'.MuiSvgIcon-root': {
									color: theme.palette.primary.main,
									opacity: .9,
								}
							}
						},

						'@media (max-width: 600px)': {
							display: 'none',
						}
					}}
					value={0}
					onChange={(e, value) => {}}
				>
					<Tooltip
						title={'Videos'}
					>
						<Tab
							icon={<VideoIcon />}
							iconPosition={'start'}	
						/>
					</Tooltip>
					<Tooltip
						title={'Audiobooks'}
					>
						<Tab
							icon={<AudioIcon />}
							iconPosition={'start'}
						/>
					</Tooltip>
					<Tooltip
						title={'Text'}
					>
						<Tab
							icon={<TextIcon />}
							iconPosition={'start'}
						/>
					</Tooltip>
				</Tabs>

				<ButtonIconGrey
					sx={{
						minWidth: theme.spacing(16),
						height: theme.spacing(6),
					}}
					onClick={langsOpen}
				>
					<Stack
						direction='row'
						spacing={theme.spacing(1)}
						alignItems={'center'}
					>
						<LangFlag code={targetLangCode} showCode={true}/>
						<LangFlag code={nativeLangCode} showCode={true}/>
						<ArrowDropIcon />
					</Stack>
				</ButtonIconGrey>

				<Menu
					anchorEl={langsEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={!!langsEl}
					onClose={langsClose}
					sx={{
						'.MuiPaper-root': {
							width: theme.spacing(30),
							boxShadow: theme.shadows[16],
						},

						'.MuiList-root': {
							p: theme.spacing(2),
						},
					}}
				>
					<Stack
						gap={theme.spacing(2)}
					>
						<ContentLibLangSelectHOC
							label={'Target language'}
							targetLangs={true}
							onChange={onChangeTargetLangCode}
							langCode={targetLangCode}
						/>
						<ContentLibLangSelectHOC
							label={'Native language'}
							targetLangs={false}
							onChange={onChangeNativeLangCode}
							langCode={nativeLangCode}
						/>

					</Stack>
				</Menu>

				<Box
					sx={{
						flex: `0 1 ${theme.spacing(50)}`,
						position: 'relative',
					}}
				>
					<TextField
						sx={{
							width: 1,

							'.MuiInputBase-root': {
								height: theme.spacing(6),
								bgcolor: `${theme.palette.common.white} !important`,
							},

							'.MuiInputAdornment-root': {
								opacity: .3,
							},
						}}
						variant={'filled'}
						hiddenLabel
						InputProps={{
							startAdornment: (
								<InputAdornment position={'start'}>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						placeholder={'Search...'}
						value={searchText}
						onBlur={onSearchBlur}
						onKeyPress={onSearchKeyPress}
						onChange={onSearchTextChange}
					/>

					{searchText &&
						<IconButton
							className={CLASS_CONTROLS}
							onClick={onSearchTextClear}
							color={'primary'}
							sx={{
								position: 'absolute',
								top: theme.spacing(.75),
								right: theme.spacing(1),
								color: theme.palette.grey[400],

								'&:hover, &:active, &:focus': {
									color: theme.palette.grey[300],
								},
							}}
						>
							<CloseIconLight />
						</IconButton>
					}
				</Box>

			</Stack>
		</Paper>
	);
};
