import { AppThunk } from '../../../../../store/types';
import { EMainViewMode } from '../../../../../store/general/types';
import { setMainViewMode } from '../../../../../store/general/actions';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { EXTENSION_INSTALL_URL, FEEDBACk_URL, TELEGRAM_BOT_URL } from '../../../../../../common/constants';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { historyPush } from '../../../../../../common/utils/historyHelper';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { getAuthUser, getMainViewMode, isPublicPage } from '../../../../../store/general/selectors';
import { ProfilesEffects } from '../../../../../effects/profilesEffects';
import { Navigator } from '../../../../../effects/navigator';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { UserRecentViewRest } from '../../../../../../common/rest/userRecentView/userRecentViewRest';
import { AuthUserEffects } from '../../../../../effects/authUserEffects';

export class MenuEvents {

  public static onClickHome (): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(MainLayoutEffects.backToDashboard());
      Navigator.openDefault();
    }
  }
  
  public static onClickMyLibrary (): AppThunk {
    return (
      dispatch,
      getState,
    ) => {
      if (getAuthUser(getState())) {
        Navigator.openContentLib();
        dispatch(setMainViewMode(EMainViewMode.MY_LIBRARY));
        dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_LIST));
      } else {
        //Navigator.openDefault();
        return AuthUserEffects.loginProcess(AuthUserEffects.newUserDefaultHandler, '', '');
      }
    }
  }

  public static onClickContent (): AppThunk {
    return (
      dispatch
    ) => {
      Navigator.openContentLib();
      dispatch(setMainViewMode(EMainViewMode.CONTENT_LIBRARY));
      dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
    }
  }

  public static onClickChatGpt (): AppThunk {
    return async (
      dispatch,
      getState,
    ) => {
      const prepareView = () => {
        dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
        dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.MAIN));
        historyPush({
          state: { page: 'settings' },
          title: 'Settings',
          onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
        });
      }

      if (isPublicPage(getState())) {
        const result = await AuthUserEffects.loginProcess(AuthUserEffects.newUserDefaultHandler, '', '', true);
        if (result && result.authUser && !result.authUser.isNew) {
          prepareView();
        }
      } else {
        prepareView();
      }

    }
  }

  // public static onClickPhrases (): AppThunk {
  //   return (
  //     dispatch
  //   ) => {
  //     dispatch(setMainViewMode(EMainViewMode.PHRASES));

  //     historyPush({
  //       state: { page: 'Phrases' },
  //       title: 'Phrases',
  //       onpopstate: () => dispatch(MainLayoutEffects.backToDashboard())
  //     });
  //   }
  // }

  public static onClickHelp (): AppThunk {
    return (
      dispatch,
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }

  public static onClickPlaylists (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const mainViewModePrev = getMainViewMode(getState());
      dispatch(setMainViewMode(EMainViewMode.PLAYLISTS));

      historyPush({
        state: { page: 'Playlists' },
        title: 'Playlists',
        onpopstate: () => dispatch(setMainViewMode(mainViewModePrev))
      });
    }
  }

  public static onExtension(
    ): AppThunk {
      return (
        dispatch
      ) => {
        window.open(EXTENSION_INSTALL_URL);
        EventsRouter.trackEvent(Events.INSTALL_EXTENSION_BUTTON_CLICKED);
      }
  }

  public static onTelegram(
    ): AppThunk {
      return (
        dispatch
      ) => {
        window.open(TELEGRAM_BOT_URL);
        EventsRouter.trackEvent(Events.INSTALL_TELEGRAM_BOT_BUTTON_CLICKED);
      }
  }

  public static onClickFeedback (): AppThunk {
    return (
      dispatch,
    ) => {
      window.open(FEEDBACk_URL, '_blank');
    }
  }

  public static onLoadProfiles (
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			return ProfilesEffects.load();
		}
	}

  public static onLoadRecent (
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
      UserRecentViewRest
			return UserRecentViewRest.list(100);
		}
	}


}