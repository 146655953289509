import { Reducer } from 'redux';
import {
  ITranscriptSetTaskResultAction,
  ITranscriptState, ITranscriptUpdateTaskAction,
  TRANSCRIPT_SET_ACTIONS_ACTION,
  TRANSCRIPT_SET_ACTIVE_ACTION,
  TRANSCRIPT_SET_TASK_RESULT_ACTION,
  TRANSCRIPT_SET_TASKS_ACTION,
  TRANSCRIPT_SHOW_CREATE_ACTION_POPUP_ACTION,
  TRANSCRIPT_UPDATE_ACTIVE_ACTION, TRANSCRIPT_UPDATE_TASK_ACTION,
  TTranscriptActions
} from './types';

const initialState: ITranscriptState = {
  showCreateActionPopup: false,
  actions: [],
  tasks: []
};

const setTaskResultAction = (_state: ITranscriptState, action: ITranscriptSetTaskResultAction): ITranscriptState => {
  if (!_state.activeAction) return _state;
  const state = {..._state};
  const index = state.activeAction.results.findIndex(r => r.taskId === action.result.taskId);
  if (index >= 0) {
    state.activeAction.results[index] = {...action.result}
  } else {
    state.activeAction.results.push({...action.result});
  }
  return state;
}

const updateTaskAction = (_state: ITranscriptState, action: ITranscriptUpdateTaskAction): ITranscriptState => {
  const task = action.task;
  const index = _state.tasks.findIndex(t => t.id === task.id)
  if (index < 0) return _state;
  const state = {..._state};
  state.tasks[index] = {...state.tasks[index], ...task};
  return state;
}

export const transcriptReducer: Reducer<ITranscriptState, TTranscriptActions> = (
  state = initialState,
  action
) => {

  switch (action.type) {
    case TRANSCRIPT_SHOW_CREATE_ACTION_POPUP_ACTION:
      return {
        ...state,
        ...{showCreateActionPopup: action.show}
      };

    case TRANSCRIPT_SET_ACTIONS_ACTION:
      return {
        ...state,
        ...{actions: action.actions}
      };

    case TRANSCRIPT_SET_ACTIVE_ACTION:
      return {
        ...state,
        ...{activeAction: action.activeAction}
      };

    case TRANSCRIPT_UPDATE_ACTIVE_ACTION:
      return {
        ...state,
        ...{activeAction: {...state.activeAction, ...action.activeAction}}
      };

    case TRANSCRIPT_SET_TASKS_ACTION:
      return {
        ...state,
        ...{tasks: action.tasks}
      };

    case TRANSCRIPT_SET_TASK_RESULT_ACTION:
      return setTaskResultAction(state, action);

    case TRANSCRIPT_UPDATE_TASK_ACTION:
      return updateTaskAction(state, action);

    default:
      return state;
  }


};