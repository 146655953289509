import * as React from 'react';
import { IStatisitcsItemProps } from './types';
import { Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';

export const StatisitcsItem: React.FC<IStatisitcsItemProps> = ({
	title,
	dataKey,
	data,
	width,
}) => {

	return (
		<Stack
			gap={1}
			sx={{
				'.recharts-cartesian-axis': {
					opacity: .3,
				},
			}}
		>
			<Text variant={'h6'}>
				{title}
			</Text>

			<BarChart width={width} height={400} data={data}>
				<XAxis dataKey={'x'} />
				<YAxis
					domain={[0, 'dataMax']}
				/>
				<Tooltip wrapperStyle={{
					backgroundColor: '#eee',
				}} />
				<Bar
					dataKey={dataKey}
					fill={'#29BA98'}
				/>
			</BarChart>
		</Stack>
	)
};