import { IPlayerApiProps } from './player/player-api-context';
import { AppThunk, IState } from '../store/types';
import { EVideoLangCaptionsFixStatus, IAvailableCaptionsItem } from '../store/current-video/types';
import {
  addVideoAction,
  resetVideoAction,
  setVideoCaptionsAction,
  setVideoCaptionsEmpty
} from '../store/videos/actions';
import {
  setCurrentVideoCaptionsFixStatus,
  setCurrentVideoClickedCaptionIndex,
  setSelectedNativeCaptions,
  setSelectedTargetCaptions
} from '../store/current-video/actions';
import { IVideoItem } from '../store/videos/types';
import {
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById,
  getUserGroupLangKnown,
  getUserGroupLangToLearn,
  getUserGroups
} from '../store/models/selectors';
import { fetchGroupVideo, IRestVideoGroup } from '../../common/rest/video/fetchGroupVideo';
import { createGroupVideo } from '../../common/rest/video/createGroupVideo';
import { ICaptionsItem, IPhraseNote, LANG_CODE_AUTO_GEN_SUBSTR } from '../types/common';
import { addGroup, addVideoInGroup, setGroupsVideoLangListAction } from '../store/models/actions';
import { TVideoRest } from '../store/models/types';
import { IVideoMetaInfo, VideoMetaInfo } from '../../common/utils/videoMetaInfo';
import {
  setAddVideoConfirmAction,
  setFlashVideoIdAction,
  setShowInstallExtensionPopupAction
} from '../store/general/actions';
import { YtUtils } from './player/yt-utils';
import { getAuthUser, getLibraryGroupId, getPlayerRate } from '../store/general/selectors';
import { updateUserGroups } from './updateUserGroups';
import { UserGroupEffects } from './userGroupEffects';
import { updateVideoInfo } from '../../common/rest/video/updateVideoInfo';
import { LangUtil } from '../../common/utils/lang-util';
import { EventsRouter } from '../../common/events/eventsRouter';
import { Events } from '../../common/events/types';
import { SaveVideoConfirmManager } from './saveVideoConfirmManager';
import { PlayerManager } from './player/manager/playerManager';
import { PhraseContextEditorActions } from '../store/phrase-context-editor/actions';
import { PhraseNoteTimeScaleEffects } from './phrase/phraseNoteTimeScaleEffects';
import { getDispatch, getState } from '../store';
import { PhraseEffects } from './phrase/PhraseEffects';
import { ExtensionInstallChecker } from './extension-install-checker';
import { VideoLangRest } from '../../common/rest/videoLang/videoLangRest';

export const createVideoEffect = (
  groupId: number,
  url: string
): AppThunk => async(
  dispatch,
  getState
): Promise<TAddVideoResult> => {
  try {
    const result: TAddVideoResult = await dispatch(addVideoEffect(groupId, url, ''));
    if (result) {
      const addedGroupId: number = result.userGroupId;
      dispatch(setFlashVideoIdAction(result.videoId));
      if (addedGroupId) {
        SaveVideoConfirmManager.show(addedGroupId, result.videoId, result.videoLangCodes);
        EventsRouter.trackEvent(Events.ADD_VIDEO);
      }
    }
    return result;
  } catch(e) {
    console.log('error', e)
    if (e) {
      SaveVideoConfirmManager.showError(e, url);
    }
  }
}

export type TAddVideoResult = {
  userGroupId: number,
  videoId: string
  videoLangCodes?: string[];
}

export const addVideoEffect = (
  userGroupId: number,
  videoUrl: string,
  langTargetAutoGenCode: string,
  checkConfirm: boolean = true
): AppThunk => async(
  dispatch,
  getState
): Promise<TAddVideoResult> => {
  const metaInfo: IVideoMetaInfo = await VideoMetaInfo.getMetaInfoByVideoUrl(videoUrl);
  if (!metaInfo || !metaInfo.loaded)
    return Promise.reject('Unable load youtube video');

  const videoId = metaInfo.videoId;
  await dispatch(updateUserGroups(false));
  const state = getState();
  let groupId = userGroupId;

  if (!groupId) {
    groupId = getDefaultGroupIdToAddVideo(state);
    UserGroupEffects.setLibraryGroupId(groupId);
  }
  const group = getUserGroupById(state, groupId);
  const nativeLang = group ? getGroupNativeLanguage(state, group) : null;
  const targetLang = group ? getGroupTargetLanguage(state, group) : null;
  const videoLangCodes = [...new Set(metaInfo.captions.map(c => LangUtil.checkLangCode(c.code)))] // duplicates;
  
  if (checkConfirm) {
    if (!videoLangCodes || !targetLang ||
      !videoLangCodes.includes(targetLang.code)
    ) {
      const extensionInstalled = await ExtensionInstallChecker.check();
      if (extensionInstalled) {
        dispatch(setAddVideoConfirmAction({
          show: true,
          targetGroupId: groupId,
          videoInfo: metaInfo.videoInfo,
          videoId,
          videoLangCodes,
        }));
        return null;
      } else {
        dispatch(setShowInstallExtensionPopupAction(true));
      }

      return Promise.reject(false)
    }
  }

  const langs = videoLangCodes.join(',');
  const videoResult: TVideoRest =
    await dispatch(createGroupVideo(videoId, groupId, metaInfo.videoInfo,
      langTargetAutoGenCode,
      nativeLang ? nativeLang.code : '',
      targetLang ? targetLang.code : '', langs));
  if (videoResult.addedToGroup) {
    if (!getUserGroupById(getState(), videoResult.group.id)) {
      dispatch(addGroup(videoResult.group));
    }
    dispatch(addVideoInGroup(videoResult, videoResult.group.id));
  }

  return {
    userGroupId: videoResult.group.id,
    videoId,
    videoLangCodes,
  }
}

const getDefaultGroupIdToAddVideo = (state: IState) => {
  const groupId = getLibraryGroupId(state);
  if (groupId)
    return groupId;
  const groups = getUserGroups(state);
  if (groups && groups.length)
    return groups[0].id;
  return 0;
}

export const loadVideoEffect = (
  videoId: string,
  playerApi: IPlayerApiProps,
  groupId: number = 0,
  prepareOnly: boolean = false,
  startPosition: number = 0,
  onVideoLoaded?: () => void,
  onMetaInfoLoaded?: () => void,
): AppThunk => async(
  dispatch,
  getState
): Promise<any> => {

  const state = getState();
  dispatch(resetVideoAction());
  dispatch(setVideoCaptionsEmpty(false));
  dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({ phrases: [] }));
  const {teacherMode} = getAuthUser(state)
  const video: IRestVideoGroup = await dispatch(fetchGroupVideo(videoId, groupId, teacherMode));

  if (!video) {
    throw new Error('video not found');
  }

  const position = startPosition || video.position;
  await YtUtils.loadVideoById(videoId, playerApi, position);
  if (prepareOnly) {
    PlayerManager.getInstance().pausePlay();
    setTimeout(() => {
      PlayerManager.getInstance().pausePlay();
    }, 500);
  }

  if (onVideoLoaded) {
    onVideoLoaded();
  }
  const playerRate = getPlayerRate(state) || 1;
  playerApi.setPlaybackRate(playerRate);

  const group = getUserGroupById(state, groupId);
  const groupLangs = [
    LangUtil.checkLangCode(getUserGroupLangKnown(state, group)?.code),
    LangUtil.checkLangCode(getUserGroupLangToLearn(state, group)?.code)
  ].filter(code => !!code);

  const videoInfo = await VideoMetaInfo.getMetaInfoWithCaptionsByVideoUrl(videoId, groupId, groupLangs);
  if (!videoInfo.captions || Object.keys(videoInfo.captions).length < 1) {
    const extensionInstalled = await ExtensionInstallChecker.check();
    if (!extensionInstalled) {
      dispatch(setShowInstallExtensionPopupAction(true));
    }
  }

  dispatch(setGroupsVideoLangListAction(videoId,
    [...new Set(videoInfo.videoInfo.langs.split(',') || [])] // duplicates if en, en_auto
  ));
  //dispatch(updateVideoInfo(videoId, videoInfo.videoInfo.langs));

  if (onMetaInfoLoaded) {
    onMetaInfoLoaded();
  }


  let videoItem = {
    videoId,
    title: videoInfo.videoInfo.title,
    author: videoInfo.videoInfo.author,
    thumbnail: videoInfo.videoInfo.thumb
  };

  const phraseNotes: IPhraseNote[] = video?.phraseNotes?.map(note => {
    const authorInfo = note.authorInfo ? JSON.parse(note.authorInfo) : undefined;
    return {
      ...note,
      ...{authorInfo}
    }
  }) || [];

  const storeVideo: IVideoItem = {
    ...videoItem,
    phrases: video && video.phrases || [],
    phraseNotes,
    captions: {},
    captionsEmpty: false,
    phraseNoteTimeScales: [],
    teachers: {
      activeId: 0,
      showPhraseNoteId: 0,
      list: video && video.teacherUsers || [],
    }
  };
  dispatch(addVideoAction(storeVideo));
  captionsSetupEffect(groupId, videoInfo.captions);
  dispatch(setCurrentVideoClickedCaptionIndex(-1));
  PhraseNoteTimeScaleEffects.load();
  PhraseEffects.onVideoLoaded();
  //checkNeedFixCaptions(videoInfo);
  return Promise.resolve();
};

export const isEqualLangCodes = (code1: string, code2: string, checkAutogen = true): boolean => {

  const trimCode = (code: string): string => {
    const ps = code.indexOf('-');
    let result = ps >= 0 ? code.substring(0, ps) : code;
    if (checkAutogen) {
      const ps = result.indexOf(LANG_CODE_AUTO_GEN_SUBSTR);
      result = ps >= 0 ? result.substring(0, ps) : result;
    }
    return result;
  }
  return trimCode(code1) == trimCode(code2);
}

export const updateVideoCaptionsFixStatus = async (videoKey: string, langCode: string, status: EVideoLangCaptionsFixStatus) => {
  await VideoLangRest.saveCaptionsFixStatus(videoKey, langCode, status);
  const dispatch = getDispatch();
  dispatch(setCurrentVideoCaptionsFixStatus(status));
}

const captionsSetupEffect = (groupId: number, captions: Record<string, ICaptionsItem[]>) => {
  const state = getState();
  const dispatch = getDispatch();
  const group = getUserGroupById(state, groupId);
  const nativeLng = getUserGroupLangKnown(state, group);
  const targetLng = getUserGroupLangToLearn(state, group);

  if (captions[targetLng.code]) {
    dispatch(setSelectedTargetCaptions({
      code: targetLng.code,
      name: LangUtil.getLangNameByCode(targetLng.code),
      isAsr: false,
      url: ''
    }));
    VideoMetaInfo.checkCaptions(captions[targetLng.code]);
    dispatch(setVideoCaptionsAction(targetLng.code, captions[targetLng.code]));
  } else {
    dispatch(setSelectedTargetCaptions(null));
  }

  if (captions[nativeLng.code]) {
    dispatch(setSelectedNativeCaptions({
      code: nativeLng.code,
      name: LangUtil.getLangNameByCode(nativeLng.code),
      isAsr: false,
      url: ''
    }));
    VideoMetaInfo.checkCaptions(captions[nativeLng.code]);
    dispatch(setVideoCaptionsAction(nativeLng.code, captions[nativeLng.code]));
  } else {
    dispatch(setSelectedNativeCaptions(null));
  }

  EventsRouter.trackEvent(Events.DICTIONARY_LOADED);
};

