import { TAction } from '../../../common/types';
import { AudioPlayerStates, IYoutubeVideoInfo, PlayerStates } from '../../types/common';
import { EVideoTutorialType, TVideoInfo } from '../models/types';
import { IAppSettings } from '../../../common/rest/user/userRest';
import { IUserProfileRest } from '../../../common/rest/user/userProfileRest';

export const SYNC_USER_LOCAL_STORAGE = 'GENERAL/SYNC_USER_LOCAL_STORAGE';
export const TOGGLE_SETTINGS_MODAL = 'GENERAL/TOGGLE_SETTINGS_MODAL';
export const SET_EDIT_PLAYLIST_MODAL_OPEN = 'GENERAL/SET_EDIT_PLAYLIST_MODAL_OPEN';
export const SET_CREATE_PUBLIC_PROFILE_MODAL_OPEN= 'GENERAL/SET_CREATE_PUBLIC_PROFILE_MODAL_OPEN';
export const SET_CREATE_PLAYLIST_MODAL_OPEN = 'GENERAL/SET_CREATE_PLAYLIST_MODAL_OPEN';
export const SET_CREATE_PLAYLIST_SAVE_MODAL_OPEN = 'GENERAL/SET_CREATE_PLAYLIST_SAVE_MODAL_OPEN';
export const SET_FEEDBACK_MODAL_OPEN = 'GENERAL/SET_FEEDBACK_MODAL_OPEN';
export const SET_EDIT_PLAYLIST_MODAL_ID = 'GENERAL/SET_EDIT_PLAYLIST_MODAL_ID';
export const CHANGE_AUTO_SYNC = 'GENERAL/CHANGE_AUTO_SYNC';
export const SET_CAPTION_SELECT_STATUS = 'GENERAL/SET_CAPTION_SELECT_STATUS';
export const SET_AUTH_USER = 'GENERAL/SET_AUTH_USER';
export const SET_AUTO_PAUSE_SIZE = 'GENERAL/SET_AUTO_PAUSE_SIZE';
export const SET_PHRASE_PLAY_OFFSET = 'GENERAL/SET_PHRASE_PLAY_OFFSET';
export const SET_HOVER_AUTO_PAUSE = 'GENERAL/SET_HOVER_AUTO_PAUSE';
export const SET_LIBRARY_GROUP = 'GENERAL/SET_LIBRARY_GROUP';
export const SET_PLAYER_STATE = 'GENERAL/SET_PLAYER_STATE';
export const SET_AUDIO_PLAYER_STATE = 'GENERAL/SET_AUDIO_PLAYER_STATE';
export const SET_PLAYER_RATE = 'GENERAL/SET_PLAYER_RATE';
export const SET_VOCABULARY_PHRASE_DETAILS_HEIGHT = 'GENERAL/SET_VOCABULARY_PHRASE_DETAILS_HEIGHT';
export const SET_PLAY_MODE = 'GENERAL/SET_PLAY_MODE';
export const SET_CURRENT_FOCUS_AREA = 'GENERAL/SET_CURRENT_FOCUS_AREA';
export const SET_SETTINGS_TAB = 'GENERAL/SET_SETTINGS_TAB';
export const SET_SHOW_NEW_FEATURES_POPUP = 'GENERAL/SET_SHOW_NEW_FEATURES_POPUP';
export const SET_SHOW_INSTALL_EXTENSION_POPUP = 'GENERAL/SET_SHOW_INSTALL_EXTENSION_POPUP';
export const SET_EXTENSION_INSTALLED = 'GENERAL/SET_EXTENSION_INSTALLED';
export const SET_SAVED_VIDEO_CONFIRM = 'GENERAL/SET_SAVED_VIDEO_CONFIRM';
export const SET_ADD_VIDEO_ON_LOGIN = 'GENERAL/SET_ADD_VIDEO_ON_LOGIN';
export const SET_COPY_VIDEO_ON_LOGIN = 'GENERAL/SET_COPY_VIDEO_ON_LOGIN';
export const SET_COPY_GROUP_ON_LOGIN = 'GENERAL/SET_COPY_GROUP_ON_LOGIN';
export const SET_SHOW_PIN_EXTENSION_POPUP = 'GENERAL/SET_SHOW_PIN_EXTENSION_POPUP';
export const SET_SHOW_OPEN_PUBLIC_PROFILE_POPUP = 'GENERAL/SET_SHOW_OPEN_PUBLIC_PROFILE_POPUP';
export const SET_TEXT_TRANSLATION = 'GENERAL/SET_TEXT_TRANSLATION';
export const SHOW_ONE_TIME_TOOLTIP_PHRASES_EDIT = 'GENERAL/SHOW_ONE_TIME_TOOLTIP_PHRASES_EDIT';
export const SHOW_ONE_TIME_TOOLTIP_NATIVE_LANGUAGE = 'GENERAL/SHOW_ONE_TIME_TOOLTIP_NATIVE_LANGUAGE';
export const SET_ONE_TIME_TOOLTIP = 'GENERAL/SET_ONE_TIME_TOOLTIP';
export const SET_SHOW_TELEGRAM_BOT_POPUP = 'GENERAL/SET_SHOW_TELEGRAM_BOT_POPUP';
export const SET_SHOW_ADD_VIDEO_FROM_URL_POPUP = 'GENERAL/SET_SHOW_ADD_VIDEO_FROM_URL_POPUP';
export const SET_VIDEO_TUTORIAL_MODE = 'GENERAL/SET_VIDEO_TUTORIAL_MODE';
export const SET_SHOW_ADD_NEW_VIDEO_POPUP = 'GENERAL/SET_SHOW_ADD_NEW_VIDEO_POPUP';
export const SET_SHOW_ADD_NEW_AUDIO_POPUP = 'GENERAL/SET_SHOW_ADD_NEW_AUDIO_POPUP';
export const SET_MAIN_VIEW_MODE = 'GENERAL/SET_MAIN_VIEW_MODE';
export const SET_PHRASES_EXIST = 'GENERAL/SET_PHRASES_EXIST';
export const SET_FLASH_VIDEO_ID = 'GENERAL/SET_FLASH_VIDEO_ID';
export const SET_FLASH_PHRASE_ID = 'GENERAL/SET_FLASH_PHRASE_ID';
export const VOCABULARY_PHRASE_DETAILS_HEIGHT_LOCAL_STORAGE = 'VOCABULARY_PHRASE_DETAILS_HEIGHT';

export const USER_TRANSLATE_LANG_ADD = 'GENERAL/USER_TRANSLATE_LANG_ADD';
export const USER_TRANSLATE_LANG_DELETE = 'GENERAL/USER_TRANSLATE_LANG_DELETE';
export const USER_TRANSLATE_LANGS_UPDATE = 'GENERAL/USER_TRANSLATE_LANGS_UPDATE';
export const USER_TEACHER_SET_MODE = 'GENERAL/USER_TEACHER_SET_MODE';

export const SET_MAIN_LAYOUT_TYPE = 'GENERAL/SET_MAIN_LAYOUT_TYPE';
export const SET_MAIN_LAYOUT_SHOW_LEFT_PANEL = 'GENERAL/SET_MAIN_LAYOUT_SHOW_LEFT_PANEL';
export const SET_MAIN_LAYOUT_PHRASES_COLUMN = 'GENERAL/SET_MAIN_LAYOUT_PHRASES_COLUMN';
export const SET_MAIN_LAYOUT_PHRASES_COLUMN_TAB = 'GENERAL/SET_MAIN_LAYOUT_PHRASES_COLUMN_TAB';
export const SET_SHOW_NATIVE_CAPTIONS = 'GENERAL/SET_SHOW_NATIVE_CAPTIONS';

export const SET_ONBOARDING = 'GENERAL/SET_ONBOARDING';
export const SET_SNACKBAR_PANEL = 'GENERAL/SET_SNACKBAR_PANEL';
export const SET_COPY_VIDEO_CONFIRM = 'GENERAL/SET_COPY_VIDEO_CONFIRM';
export const SET_APP_SETTINGS = 'GENERAL/SET_APP_SETTINGS';
export const SET_PAUSE_ON_NOTE = 'GENERAL/SET_PAUSE_ON_NOTE';
export const SET_ADD_VIDEO_CONFIRM = 'GENERAL/SET_ADD_VIDEO_CONFIRM';
export const SET_ADD_AUDIO_CONFIRM = 'GENERAL/SET_ADD_AUDIO_CONFIRM';
export const SHOW_INSTALL_EXTENSION_WARNING_POPUP_ACTION = 'GENERAL/SHOW_INSTALL_EXTENSION_WARNING_POPUP_ACTION';
export const SET_ROUTE_ON_LOGIN = 'GENERAL/SET_ROUTE_ON_LOGIN';
export const SET_PUBLIC_PAGE = 'GENERAL/SET_PUBLIC_PAGE';
export const SET_CAPTIONS_FIX_AI_INFO = 'GENERAL/SET_CAPTIONS_FIX_AI_INFO';
export const SET_VIDEO_WATCH_TODAY = 'GENERAL/SET_VIDEO_WATCH_TODAY';

export enum ELanguageStatus {
  KNOW = '1',
  TO_LEARN = '2'
}

export enum ESettingsTabId {
  CREATE = 1,
  GROUPS = 2,
}

export interface ILanguageItem {
  name: string;
  code: string;
  status?: ELanguageStatus;
}

export enum EPlayMode {
  DEFAULT,
  REPLAY
}

export enum ECurrentFocusArea {
  MAIN,
  PHRASES
}

export const VocabularyPhrasesPauseNo = 0;
export const VocabularyPhrasesPauseFullStop = -1;


export enum EAuthUserRole {
  COMMON = 0, ADMIN = 1
}

export type TAuthUserTranslateLang = {
  code: string,
  orderNum: number
}

export enum EAuthUserConfirmEmailStatus {
  NOT_SET = 0, AWAIT = 1, DONE = 2
}


export type TAuthUser = {
  id: number;
  email: string;
  googleId: string;
  hashId: string;
  name: string;
  picture: string;
  locale: string;
  token: string;
  isNew: boolean;
  role: EAuthUserRole;
  translateLangs: TAuthUserTranslateLang[];
  roles: number[];
  teacher: boolean;
  teacherMode: boolean;
  level: EUserLevel;
  publicProfile?: TPublicProfile;
  inviteByUserId?: number;
  confirmEmailStatus?: EAuthUserConfirmEmailStatus;
  langCode?: string;
  anonymous?: boolean;
}

export enum EUserLevel {
  UNKNOWN = 0, BEGINNER = 1, INTERMEDIATE = 2, ADVANCED = 3
}


export enum EUserGroupType {
  USER = 0,
  PREVIEW = 1
}

export type TPublicProfile = IUserProfileRest

export type TPublicProfilePlaylist = {
  description?: string;
  name: string;
  nativeLang: string;
  id: number;
  targetLang: string;
  videos?: TVideoInfo[];
}

export enum ESavedVideoConfirmShowMode {
  HIDE, NOTIFY, SELECT_GROUP, ERROR
}

export interface ISavedVideoConfirm {
  showMode: ESavedVideoConfirmShowMode,
  groupId?: number,
  videoId?: string,
  videoUrl?: string,
  errorMessage?: string
  videoLangCodes?: string[];
}

export interface ISnackbarPanel {
  show: boolean,
  text: string
}

export interface IAddVideoOnLogin {
  groupId: number,
  videoUrl: string
}

export interface ICopyVideoOnLogin {
  hash?: string,
  newUser?: boolean,
  groupId?: number,
  videoId?: string
}

export interface ICopyGroupOnLogin {
  hash?: string,
  groupId?: number,
  contentGroupId?: number
}

export interface ICopyVideoConfirm {
  show: boolean,
  targetGroupId?: number,
  targetGroupName?: string,
  hash?: string,
  videoInfo?: TVideoInfo,
  videoId?: string,
  srcGroupTargetLang?: string,
  srcGroupNativeLang?: string
}

export interface ITextTranslationState {
  active: boolean,
  text?: string,
  fromLang?: ILanguageItem,
  toLang?: ILanguageItem
}

export interface ISyncUserLocalStorageData {
  autoPause: number;
  showPinExtensionPopup: boolean;
  mainLayoutType: EMainLayoutType;
  showLeftPanel: boolean;
  phrasesColumn: EPhrasesColumnType;
}

export interface IOneTimeTooltips {
  showPhrasesEditTooltip: boolean;
  showNativeLanguageTooltip: boolean;
  showNativeAvailableTooltip: boolean;
  showTutorialPopup: boolean;
  showTutorialTooltip: boolean;
  showSaveForMagicTooltip: boolean;
  showSavedForMagicTooltip: boolean;
  showTranslations: boolean;
  showFullscreen: boolean;
}

export enum EMainLayoutType {
  COLUMNS_3, COLUMNS_4
}

export enum EPhrasesColumnType {
  COLUMNS_1,
  COLUMNS_2,
  HIDE,
}

export enum EPhrasesColumnTab {
  TRANSLATE = 'TRANSLATE',
  TRANSLATED = 'TRANSLATED',
  SAVED = 'SAVED',
  TEACHERS = 'TEACHERS',
}

export interface IMainLayout {
  showLeftPanel: boolean,
  type: EMainLayoutType
  phrasesColumn: EPhrasesColumnType;
  phrasesColumnTab: EPhrasesColumnTab;
}

export enum EMainViewMode {
  DASHBOARD,
  PHRASE_DETAILS_TABS_EDITOR,
  PHRASES,
  CONTENT_LIBRARY,
  MY_LIBRARY,
  STATISTICS,
  PLAYLISTS,
  PHRASE_DETAILS,
  PROFILE,
}

export enum EMainMenuActive {
  HOME,
  CONTENT_LIBRARY,
  MY_LIBRARY,
  RECENTS,
  CHATGPT_PROMPTS,
  SAVED_PHRASES,
  HELP,
  SETTINGS,
  PLAYLISTS,
}

export interface IOnboarding {
  isActive?: boolean;
  step?: number;
  tags?: string[];
  show?: string;
}

export interface IAddVideoConfirm {
  show: boolean;
  targetGroupId?: number;
  videoInfo?: IYoutubeVideoInfo;
  videoId?: string;
  videoLangCodes?: string[];
  copyHash?: string;
  text?: string;
}

export interface IAddAudioConfirm {
  show: boolean;
  langCode?: string;
  text?: string;
}

export interface IRouteOnLogin {
  route?: string;
}

export interface ICaptionsFixAiInfo {
  videoId?: string;
  groupId?: number;
  showPopup?: boolean;
}

export interface IGeneralState {
  isEditPlaylistModalOpen: boolean;
  isCreatePublicProfileModalOpen: boolean;
  isCreatePlaylistModalOpen: boolean;
  isCreatePlaylistSaveModalOpen: boolean;
  isFeedbackModalOpen: boolean;
  editPlaylistModalId: number;
  autoPause: number;
  autoSync: boolean;
  hoverAutoPause: boolean;
  showCaptionSelect: boolean;
  authUser: TAuthUser | null;
  libraryGroupId: number | null;
  playerState: PlayerStates;
  playerRate: number;
  vocabularyPhraseDetailsHeight: number;
  playMode: EPlayMode;
  audioPlayerState: AudioPlayerStates;
  currentFocusArea: ECurrentFocusArea;
  showNewFeaturesPopup: boolean;
  extensionInstalled: boolean;
  savedVideoConfirm: ISavedVideoConfirm;
  snackbarPanel: ISnackbarPanel;
  addVideoOnLogin?: IAddVideoOnLogin;
  copyVideoOnLogin?: ICopyVideoOnLogin;
  copyVideoConfirm: ICopyVideoConfirm;
  copyGroupOnLogin?: ICopyGroupOnLogin;
  showPinExtensionPopup: boolean;
  showOpenPublicProfilePopup: boolean;
  textTranslation: ITextTranslationState;
  oneTimeTooltip: IOneTimeTooltips;
  showTelegramBotPopup: boolean;
  videoTutorialMode?: EVideoTutorialType;
  showNewVideoPopup: boolean;
  showNewAudioPopup: boolean;
  mainViewMode: EMainViewMode;
  phrasesExist: boolean;
  flashVideoId?: string;
  flashPhraseId?: number;
  mainLayout: IMainLayout;
  showNativeCaptions: boolean;
  onboarding: IOnboarding;
  appSettings: IAppSettings;
  pauseOnNote: boolean;
  addVideoConfirm: IAddVideoConfirm;
  addAudioConfirm: IAddAudioConfirm;
  showInstallExtensionWarningPopup: boolean;
  routeOnLogin: IRouteOnLogin;
  publicPage: boolean;
  captionsFixAiInfo: ICaptionsFixAiInfo | null,
  videoWatchToday: number | null;
}
export interface ISyncUserLocalStorage extends TAction<typeof SYNC_USER_LOCAL_STORAGE> {
  data: ISyncUserLocalStorageData
}

export interface ISetEditPlaylistModalOpen extends TAction<typeof SET_EDIT_PLAYLIST_MODAL_OPEN> {
  open: boolean;
}

export interface ISetCreatePublicProfileModalOpen extends TAction<typeof SET_CREATE_PUBLIC_PROFILE_MODAL_OPEN> {
  open: boolean;
}

export interface ISetCreatePlaylistModalOpen extends TAction<typeof SET_CREATE_PLAYLIST_MODAL_OPEN> {
  open: boolean;
}

export interface ISetCreatePlaylistSaveModalOpen extends TAction<typeof SET_CREATE_PLAYLIST_SAVE_MODAL_OPEN> {
  open: boolean;
}

export interface ISetFeedbackModalOpenAction extends TAction<typeof SET_FEEDBACK_MODAL_OPEN> {
  open: boolean;
}

export interface ISetEditPlaylistModalId extends TAction<typeof SET_EDIT_PLAYLIST_MODAL_ID> {
  id: number;
}

export interface IToggleSettingsModal extends TAction<typeof TOGGLE_SETTINGS_MODAL> {
  forceState?: boolean;
}

export interface IChangeAutoSync extends TAction<typeof CHANGE_AUTO_SYNC> {
  state: boolean;
}

export interface ISetCaptionSelectStatus extends TAction<typeof SET_CAPTION_SELECT_STATUS> {
  status: boolean;
}

export interface ISetCaptionSelectStatus extends TAction<typeof SET_CAPTION_SELECT_STATUS> {
  status: boolean;
}

export interface ISetAuthUser extends TAction<typeof SET_AUTH_USER> {
  user: TAuthUser | null
}
export interface ISetAutoPause extends TAction<typeof SET_AUTO_PAUSE_SIZE> {
  pause: number
}

export interface ISetHoverAutoPause extends TAction<typeof SET_HOVER_AUTO_PAUSE> {
  hoverAutoPause: boolean
}
export interface ISetVocabularyPhraseDetailsHeight extends TAction<typeof SET_VOCABULARY_PHRASE_DETAILS_HEIGHT> {
  height: number
}

export interface ISetLibraryGroupId extends TAction<typeof SET_LIBRARY_GROUP> {
  groupId: number | null
}
export interface ISetPlayerState extends TAction<typeof SET_PLAYER_STATE> {
  state: PlayerStates
}
export interface ISetAudioPlayerState extends TAction<typeof SET_AUDIO_PLAYER_STATE> {
  state: AudioPlayerStates
}

export interface ISetPlayerRate extends TAction<typeof SET_PLAYER_RATE> {
  rate: number
}
export interface ISetPlayMode extends TAction<typeof SET_PLAY_MODE> {
  mode: EPlayMode
}
export interface ISetCurrentFocusArea extends TAction<typeof SET_CURRENT_FOCUS_AREA> {
  currentFocusArea: ECurrentFocusArea
}
export interface ISetShowNewFeaturesPopup extends TAction<typeof SET_SHOW_NEW_FEATURES_POPUP> {
  show: boolean
}
export interface ISetExtensionInstalled extends TAction<typeof SET_EXTENSION_INSTALLED> {
  extensionInstalled: boolean
}
export interface ISetShowPinExtensionPopup extends TAction<typeof SET_SHOW_PIN_EXTENSION_POPUP> {
  show: boolean
}
export interface ISetShowOpenPublicProfilePopup extends TAction<typeof SET_SHOW_OPEN_PUBLIC_PROFILE_POPUP> {
  show: boolean
}
export interface ISetShowTelegramBotPopup extends TAction<typeof SET_SHOW_TELEGRAM_BOT_POPUP> {
  show: boolean
}
export interface ISetShowAddNewVideoPopup extends TAction<typeof SET_SHOW_ADD_NEW_VIDEO_POPUP> {
  show: boolean
}
export interface ISetShowAddNewAudioPopup extends TAction<typeof SET_SHOW_ADD_NEW_AUDIO_POPUP> {
  show: boolean
}
export interface ISetPhrasesExist extends TAction<typeof SET_PHRASES_EXIST> {
  exist: boolean
}

export interface ISetSavedVideoConfirm extends TAction<typeof SET_SAVED_VIDEO_CONFIRM> {
  value: Partial<ISavedVideoConfirm>
}

export interface ISetTextTranslation extends TAction<typeof SET_TEXT_TRANSLATION> {
  textTranslation: ITextTranslationState
}

export interface ISetOneTimeTooltipPhrasesEdit extends TAction<typeof SHOW_ONE_TIME_TOOLTIP_PHRASES_EDIT> {
  show: boolean
}

export interface ISetOneTimeTooltipNativeLanguage extends TAction<typeof SHOW_ONE_TIME_TOOLTIP_NATIVE_LANGUAGE> {
  show: boolean
}

export interface ISetOneTimeTooltip extends TAction<typeof SET_ONE_TIME_TOOLTIP> {
  value: Partial<IOneTimeTooltips>
}

export interface ISetVideoTutorialMode extends TAction<typeof SET_VIDEO_TUTORIAL_MODE> {
  value?: EVideoTutorialType
}

export interface ISetMainViewMode extends TAction<typeof SET_MAIN_VIEW_MODE> {
  mainViewMode: EMainViewMode
}


export enum EHintPopupContentType {
  PIN
}
export interface ISetAddVideoOnLogin extends TAction<typeof SET_ADD_VIDEO_ON_LOGIN> {
  groupId: number,
  videoUrl: string
}

export interface ISetCopyVideoOnLogin extends TAction<typeof SET_COPY_VIDEO_ON_LOGIN> {
  data: Partial<ICopyVideoOnLogin>
}

export interface ISetCopyGroupOnLogin extends TAction<typeof SET_COPY_GROUP_ON_LOGIN> {
  data: Partial<ICopyGroupOnLogin>
}

export interface ISetFlashVideoId extends TAction<typeof SET_FLASH_VIDEO_ID> {
  flashVideoId: string
}
export interface ISetFlashPhraseId extends TAction<typeof SET_FLASH_PHRASE_ID> {
  flashPhraseId: number
}

export interface IUserTranslateLangAdd extends TAction<typeof USER_TRANSLATE_LANG_ADD> {
  langCode: string,
  orderNum: number
}

export interface IUserTranslateLangDelete extends TAction<typeof USER_TRANSLATE_LANG_DELETE> {
  langCode: string
}

export interface IUserTranslateLangsUpdate extends TAction<typeof USER_TRANSLATE_LANGS_UPDATE> {
  langs: TAuthUserTranslateLang[]
}

export interface ISetMainLayoutType extends TAction<typeof SET_MAIN_LAYOUT_TYPE> {
  layoutType: EMainLayoutType
}

export interface ISetMainLayoutShowLeftPanel extends TAction<typeof SET_MAIN_LAYOUT_SHOW_LEFT_PANEL> {
  showLeftPanel: boolean
}

export interface ISetMainLayoutPhrasesColumn extends TAction<typeof SET_MAIN_LAYOUT_PHRASES_COLUMN> {
  phrasesColumn: EPhrasesColumnType
}

export interface ISetMainLayoutPhrasesColumnTab extends TAction<typeof SET_MAIN_LAYOUT_PHRASES_COLUMN_TAB> {
  phrasesColumnTab: EPhrasesColumnTab
}

export interface ISetShowNativeCaptions extends TAction<typeof SET_SHOW_NATIVE_CAPTIONS> {
  show: boolean
}

export interface ISetOnboardingAction {
  type: typeof SET_ONBOARDING;
  onboarding: Partial<IOnboarding>;
}

export interface ISetSnackbarPanelAction {
  type: typeof SET_SNACKBAR_PANEL,
  show: boolean,
  text: string
}

export interface ISetCopyVideoConfirmAction {
  type: typeof SET_COPY_VIDEO_CONFIRM,
  copyVideoConfirm: Partial<ICopyVideoConfirm>
}

export interface ISetAppSettingsAction {
  type: typeof SET_APP_SETTINGS,
  value: Partial<IAppSettings>
}

export interface ISetPauseOnNoteAction {
  type: typeof SET_PAUSE_ON_NOTE,
  pauseOnNote: boolean
}

export interface IUserTeacherSetModeAction {
  type: typeof USER_TEACHER_SET_MODE,
  teacherMode: boolean
}

export interface ISetAddVideoConfirmAction {
  type: typeof SET_ADD_VIDEO_CONFIRM,
  value: Partial<IAddVideoConfirm>
}

export interface ISetAddAudioConfirmAction {
  type: typeof SET_ADD_AUDIO_CONFIRM,
  value: Partial<IAddAudioConfirm>
}

export interface IShowInstallExtensionPopupAction {
  type: typeof SHOW_INSTALL_EXTENSION_WARNING_POPUP_ACTION,
  show: boolean
}

export interface IUserTeacherSetModeAction {
  type: typeof USER_TEACHER_SET_MODE,
  teacherMode: boolean
}

export interface ISetRouteOnLoginAction extends TAction<typeof SET_ROUTE_ON_LOGIN> {
  data: Partial<IRouteOnLogin>
}

export interface ISetPublicPageAction extends TAction<typeof SET_PUBLIC_PAGE> {
  publicPage: boolean
}

export interface ISetCaptionsFixAiInfoAction extends TAction<typeof SET_CAPTIONS_FIX_AI_INFO> {
  data: Partial<ICaptionsFixAiInfo> | null
}

export interface ISetVideoWatchTodayAction extends TAction<typeof SET_VIDEO_WATCH_TODAY> {
  videoWatchToday: number
}



export type IGeneralActions =
  ISyncUserLocalStorage
    | IToggleSettingsModal
    | ISetEditPlaylistModalOpen
    | IChangeAutoSync
    | ISetCaptionSelectStatus
    | ISetAuthUser
    | ISetCreatePlaylistModalOpen
    | ISetCreatePlaylistSaveModalOpen
    | ISetFeedbackModalOpenAction
    | ISetEditPlaylistModalId
    | ISetAutoPause
    | ISetHoverAutoPause
    | ISetLibraryGroupId
    | ISetPlayerState
    | ISetAudioPlayerState
    | ISetPlayerRate
    | ISetPlayMode
    | ISetCurrentFocusArea
    | ISetShowNewFeaturesPopup
    | ISetExtensionInstalled
    | ISetSavedVideoConfirm
    | ISetAddVideoOnLogin
    | ISetCopyVideoOnLogin
    | ISetCopyGroupOnLogin
    | ISetShowPinExtensionPopup
    | ISetShowOpenPublicProfilePopup
    | ISetTextTranslation
    | ISetOneTimeTooltipPhrasesEdit
    | ISetOneTimeTooltipNativeLanguage
    | ISetOneTimeTooltip
    | ISetShowTelegramBotPopup
    | ISetVideoTutorialMode
    | ISetShowAddNewVideoPopup
    | ISetShowAddNewAudioPopup
    | ISetMainViewMode
    | ISetPhrasesExist
    | ISetVocabularyPhraseDetailsHeight
    | ISetFlashVideoId
    | ISetFlashPhraseId
    | IUserTranslateLangAdd
    | IUserTranslateLangDelete
    | IUserTranslateLangsUpdate
    | ISetMainLayoutType
    | ISetMainLayoutShowLeftPanel
    | ISetMainLayoutPhrasesColumn
    | ISetMainLayoutPhrasesColumnTab
    | ISetShowNativeCaptions
    | ISetOnboardingAction
    | ISetSnackbarPanelAction
    | ISetCopyVideoConfirmAction
    | ISetAppSettingsAction
    | ISetPauseOnNoteAction
    | IUserTeacherSetModeAction
    | ISetAddVideoConfirmAction
    | ISetAddAudioConfirmAction
    | IShowInstallExtensionPopupAction
    | ISetCreatePublicProfileModalOpen
    | ISetRouteOnLoginAction
    | ISetPublicPageAction
    | ISetCaptionsFixAiInfoAction
    | ISetVideoWatchTodayAction
