import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';


export type TVideoPreviewMoveToGroupBody = {
  videoId: string,
  srcGroupId: number,
  destGroupId: number
}


export class VideoRest {

  private static PREVIEW_MOVE_TO_GROUP_URL = API_URL + '/video/previewMoveToGroup';

  public static async previewMoveToGroup(body: TVideoPreviewMoveToGroupBody): Promise<boolean>  {
    const dispatch = getDispatch();
    return dispatch(postRequest(this.PREVIEW_MOVE_TO_GROUP_URL, body));
  }

}