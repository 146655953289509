import { DateUtil } from '../../../../../../common/utils/dateUtil';
import { IDateRange } from './types';

export class StaticsFilterUtils {

	public static getDateRangeByFilters(): IDateRange[] {
		const todayDate = DateUtil.trimToDay(new Date());
		let ret: IDateRange[] = [];
		for (let i = 0; i < 45; i++) {
			let from = DateUtil.incDays(todayDate, -i-1);
			let to = DateUtil.incDays(todayDate, -i);
			let id = from.toISOString().split('T')[0]
			ret.push({
				id,
				from,
				to,
			});
		}
		return ret;

			// {
			//   id: EStatDateRangeId.LAST_10_DAYS,
			//   from: DateUtil.incDays(todayDate, -10),
			//   to: todayDate
			// },
			// {
			//   id: EStatDateRangeId.LAST_MONTH,
			//   from: DateUtil.incDays(todayDate, -30),
			//   to: todayDate
			// }

	}
}