import { API_URL } from '../../constants';
import { TUserGroup } from '../../../spa/store/models/types';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';
import { EUserLevel } from '../../../spa/store/general/types';

const USER_GROUPS_URL = API_URL + '/groups/update';

export interface IUserGroupRest {
  id: number,
  name: string,
  description?: string,
  targetLangCode?: string,
  nativeLangCode?: string,
  publicGroup?: boolean,
  level: EUserLevel
}

export const updateUserGroup = (
  group: IUserGroupRest
): TAsyncThunk => async (
  dispatch
): Promise<TUserGroup> => {
  return dispatch(postRequest(USER_GROUPS_URL, group));
};
