import * as React from 'react';
import { ICaptionsProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, IconButton, Stack } from '@mui/material';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { TextSelectionPopupHOC } from '../../../TextSelectionPopup/hocs/TextSelectionPopupHOC';
import { TargetCaptionsHOC } from '../../TargetCaptions/hocs/TargetCaptionsHOC';
import { NativeCaptionsStub } from '../../NativeCaptions/components/NativeCaptionsStub';
import { NativeCaptionsHOC } from '../../NativeCaptions/hocs/NativeCaptionsHOC';
import { PlayerStates } from '../../../../../types/common';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { PlaybackSettingsHOC } from '../../PlaybackSettings/hocs/PlaybackSettingsHOC';
import { CLASS_ONBOARDING_ACTIVE, CLASS_ONBOARDING_NOEVENT, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { EmptyCaptions } from '../../EmptyCaptions/EmptyCaptions';
import { nativeItemsHeightList } from '../../NativeCaptions/components/NativeCaptions';
import { targetItemsHeightList } from '../../TargetCaptions/components/TargetCaptions';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { StoryFromTranslatedButtonsHOC } from '../../../StoryFromTranslated/hocs/StoryFromTranslatedButtons/StoryFromTranslatedButtonsHOC';

export const Captions: React.FC<ICaptionsProps> = ({
	playerState,
	videoId,
	groupId,
	targetCaptions,
	nativeCaptions,
	targetLang,
	nativeLang,
	showNativeLangTooltip,
	showNativeCaptions,
	captionsEmpty,
	groupNativeLangName,
	groupTargetLangName,
	storyFromTranslatedId,
	onKeyDown,
	onVideoChange,
	onTargetCaptionsChange,
	onNativeCaptionsChange
}) => {

	const { isFullScreen } = React.useContext(DeviceContext);
	const ref = React.useRef<any>(null);
	const targetCaptionsContainer = React.useRef<HTMLDivElement | null>(null);
	const nativeCaptionsContainer = React.useRef<HTMLDivElement | null>(null);
	const [settingsButtonAnchor, setSettingsButtonAnchor] = React.useState<null | HTMLElement>(null);

	const [targetActiveCaptionIndex, setTargetActiveCaptionIndex] = React.useState<number>(0);
	const [nativeActiveCaptionIndex, setNativeActiveCaptionIndex] = React.useState<number>(0);
	const [targetTopScrolling, setTargetTopScrolling] = React.useState<boolean>(false);

	const targetMouseLeaveCaptionTimerRef = React.useRef<ReturnType<typeof setTimeout> | number>(0);

	const _showNativeCaptions: boolean = !isFullScreen && showNativeCaptions && !!(nativeLang && nativeLang !== targetLang);
	const _showNativeCaptionsStub: boolean = showNativeCaptions && !(nativeLang && nativeLang !== targetLang);

	const targetActiveCaptionIndexRef = React.useRef<number>(0);
	const nativeActiveCaptionIndexRef = React.useRef<number>(0);
	targetActiveCaptionIndexRef.current = targetActiveCaptionIndex;
	nativeActiveCaptionIndexRef.current = nativeActiveCaptionIndex;

	const handleKeyDown = () => {
		onKeyDown();
	}

	const scrollTaregetCaptionsTo = (captionElement: HTMLElement) => {
		if (document.body.classList.contains(CLASS_ONBOARDING_ACTIVE)) return;

		const containerElement = targetCaptionsContainer.current;
		if (containerElement) {
			setTargetTopScrolling(true);
			const offset = Math.round(containerElement.offsetHeight / 4);
			const scrollValue = captionElement.offsetTop - offset;
			containerElement.scrollTo({
				top: scrollValue,
				behavior: "smooth",
			})
			setTimeout(() => {
				setTargetTopScrolling(false);
			}, 1000)
		}
	}

	const calcItemTop = (heightList: number[], index: number): number => {
		let result = 0;
		for(let i=0; i<index; i++) {
			result += heightList[i];
		}
		return result;
	}

	const syncNativeCaptionsScroll = () => {
		const nativeContainer = nativeCaptionsContainer.current;
		const targetContainer = targetCaptionsContainer.current;
		const nativeCaptionIndex = nativeActiveCaptionIndexRef.current;
		const targetCaptionIndex = targetActiveCaptionIndexRef.current;
		if (nativeContainer && targetContainer) {
			const nativeTop = calcItemTop(nativeItemsHeightList, nativeCaptionIndex);
			const targetTop = calcItemTop(targetItemsHeightList, targetCaptionIndex);
			const diff = targetTop - nativeTop;
			const nativeScroll = targetContainer.scrollTop - diff;
			nativeContainer.scrollTo({
				top: nativeScroll,
				behavior: "smooth",
			})
		}
	}

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const getActiveCaptionItemElement = (): HTMLElement => {
		return document.getElementById('caption-item-' + targetActiveCaptionIndex) as HTMLElement;
	}

	React.useEffect(() => {
		if (PlayerStates.PLAYING === playerState) {
			const containerElement = targetCaptionsContainer.current;
			if (containerElement) {
				//const captionElement = containerElement.children[targetActiveCaptionIndex] as HTMLElement;
				const captionElement = getActiveCaptionItemElement();
				if (captionElement) {
					if (captionElement.offsetTop < containerElement.scrollTop) {
						scrollTaregetCaptionsTo(captionElement);
					}
				}
			}
		}
	}, [playerState]);

	React.useEffect(() => {
		onVideoChange();
	}, [videoId, groupId]);

	React.useEffect(() => {
		onTargetCaptionsChange();
	}, [targetCaptions]);

	React.useEffect(() => {
		onNativeCaptionsChange();
	}, [nativeCaptions]);

	React.useEffect(() => {
		let timer;
		setTimeout(() => {
			timer = setInterval(syncNativeCaptionsScroll, 500);
		}, 5000)
//		const timer = setInterval(syncNativeCaptionsScroll, 500);
		return () => {
			clearInterval(timer);
		}
	}, []);


	const onChangeTargetActiveIndex = (index: number) => {
		setTargetActiveCaptionIndex(index);
	}
	const onChangeNativeActiveIndex = (index: number) => {
		setNativeActiveCaptionIndex(index);
	}

	const resetTargetMouseLeaveCaptionTimer = () => {
		if (targetMouseLeaveCaptionTimerRef.current) {
			clearTimeout(targetMouseLeaveCaptionTimerRef.current);
		}
		targetMouseLeaveCaptionTimerRef.current = 0;
	}

	const runTargetMouseLeaveCaptionTimer = () => {
		targetMouseLeaveCaptionTimerRef.current = setTimeout(() => {
			targetMouseLeaveCaptionTimerRef.current = 0;
		}, 1000);
	}

	const onTargetCaptionMouseEnter = (index: number) => {
		resetTargetMouseLeaveCaptionTimer();
	}
	const onTargetCaptionMouseLeave = (index: number) => {
		runTargetMouseLeaveCaptionTimer();
	}

	/*const onTargetCaptionScrolling = (scroll: boolean) => {
		if (!targetTopScrolling && !scroll) {
			onTargetCaptionsScroll();
		}
	}*/

	const handleSettingsClick = (e: React.MouseEvent<HTMLElement>) => {
		setSettingsButtonAnchor(e.currentTarget);
	}

	const handleSettingsClose = () => {
		setSettingsButtonAnchor(null);
	}

	const onboardingHighlight = () => {
		let selected = ref.current && ref.current.querySelector('.selected-onboarding') as HTMLElement;
		if (ref.current && ref.current.querySelector('.highlighted-span') ||
			!document.querySelector('.onboarding-hint--step2')
		) {
			if (selected) selected.remove();
		} else {
			const letters = !selected && ref.current && ref.current.querySelectorAll('.caption-item__letters') as HTMLElement[];
			if (selected || letters && letters.length) {
				if (!selected) {
					selected = document.createElement('span');
					selected.classList.add('selected-onboarding');
					let i = letters.length > 1 ? 1 : 0;
					letters[i].append(selected);
				}
				setTimeout(onboardingHighlight, 1000);
			}
		}
	}

	React.useEffect(() => {
		setTimeout(onboardingHighlight, 2000);
	}, [targetCaptions, videoId, groupId]);

	return (
		<Paper
			className={`shortcuts-listener shortcuts-listener--captions`}
			sx={{
				position: 'relative',
				flex: '1 1 100%',
				minHeight: theme.spacing(18),

				'&.shortcuts-listener--active': {
					':after': {
						content: '""',
						position: 'absolute',
						inset: '-6px',
						border: `2px solid ${theme.palette.primary.main}`,
						borderRadius: '10px',
					}
				},

				'.root-inner.fullscreen &': {
					position: 'absolute',
					zIndex: 100,
					bottom: theme.spacing(7),
					left: 0,
					right: 0,
					pt: theme.spacing(4),
					borderRadius: 0,
					background: PlayerStates.PLAYING === playerState ? 'none' : theme.palette.common.black,

					'&.shortcuts-listener--active': {
						':after': {
							display: 'none',
						}
					},
				},
			}}
			ref={ref}
		>
			{!!storyFromTranslatedId &&
				<StoryFromTranslatedButtonsHOC />
			}

			{captionsEmpty &&
				<EmptyCaptions targetLang={groupTargetLangName} nativeLang={groupNativeLangName} />
			}

			{!captionsEmpty &&
			<>
				<TextSelectionPopupHOC/>

				{!isFullScreen && !storyFromTranslatedId &&
					<IconButton
						className={`${CLASS_ONBOARDING_NOEVENT} playback-settings-button`}
						color={'primary'}
						sx={{
							position: 'absolute',
							zIndex: 150,
							right: theme.spacing(1),
							top: theme.spacing(1),
							color: theme.palette.grey[400],
							background: theme.palette.common.white,
							'&:hover, &:focus': {
								background: theme.palette.grey[100],
							},
						}}
						onClick={handleSettingsClick}
					>
						<SettingsIcon/>
					</IconButton>
				}

				{/* <OneTimeTooltipNativeAvailableHOC /> */}

				{settingsButtonAnchor &&
				<PlaybackSettingsHOC
					anchor={settingsButtonAnchor}
					onClose={handleSettingsClose}
				/>
				}

				<Stack
					sx={{
						width: '100%',
						height: '100%',
					}}
					direction='row'
				>

					<TargetCaptionsHOC
						elementRef={targetCaptionsContainer}
						onChangeActiveIndex={onChangeTargetActiveIndex}
						onCaptionMouseEnter={onTargetCaptionMouseEnter}
						onCaptionMouseLeave={onTargetCaptionMouseLeave}
					/>

					{_showNativeCaptionsStub && showNativeLangTooltip && <NativeCaptionsStub/>}

					{_showNativeCaptions &&
					<NativeCaptionsHOC
						elementRef={nativeCaptionsContainer}
						onChangeActiveIndex={onChangeNativeActiveIndex}
					/>}

					<Box
						className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step2`}
						sx={{
							position: 'absolute',
							pointerEvents: 'none',
							left: 0,
							right: 0,
							top: 0,
							height: theme.spacing(20),
						}}
					/>

					<Box
						className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step2-highlight`}
						sx={{
							position: 'absolute',
							pointerEvents: 'none',
							left: theme.spacing(-.5),
							right: theme.spacing(-.5),
							top: theme.spacing(-.5),
							bottom: theme.spacing(-.5),
							borderRadius: theme.spacing(1),
						}}
					/>

				</Stack>
			</>
			}



		</Paper>
	)

};
