import { theme } from "../../../common/styles/theme";

export enum EPhraseDetailsCustomPromptType {
  PHRASE, CONTEXT, TARGET_LANG, NATIVE_LANG, ENTIRE_SCRIPT, PHRASE_LIST
}

export interface IPhraseDetailsCustomPromptVar {
  name: string;
  wrapQuotes: boolean;
  originalName: string;
  color: string;
  type: EPhraseDetailsCustomPromptType;
  visible: boolean;
}

export class PhraseDetailsCustomPromptVars {

  private static PARAM_RE = new RegExp(/\{([^\}]+)\}/g);

  private static PHRASE_VAR = '$Phrase';
  private static CONTEXT_VAR = '$Context';
  private static PHRASE_LIST_VAR = '$PhraseList';

  public static ENTIRE_SCRIPT_PARAM = 'entireScript';
  public static PHRASE_LIST_PARAM = 'phraseList';
  public static TRANSLATED_PHRASE_LIST_PARAM = 'translatedPhraseList';

  private static VARS: IPhraseDetailsCustomPromptVar[] = [
    {name: this.PHRASE_VAR, wrapQuotes: true, originalName: '{text}', color: theme.palette.info[100], type: EPhraseDetailsCustomPromptType.PHRASE, visible: true},
    {name: this.CONTEXT_VAR, wrapQuotes: true, originalName: '{context}', color: theme.palette.info[500], type: EPhraseDetailsCustomPromptType.CONTEXT, visible: true},
    {name: '$TargetLanguage', wrapQuotes: false, originalName: '{targetLang}', color: theme.palette.primary[100], type: EPhraseDetailsCustomPromptType.TARGET_LANG, visible: true},
    {name: '$NativeLanguage', wrapQuotes: false, originalName: '{nativeLang}', color: theme.palette.primary[100], type: EPhraseDetailsCustomPromptType.NATIVE_LANG, visible: true},
    {name: '$EntireScript', wrapQuotes: false,
      originalName: '{'+PhraseDetailsCustomPromptVars.ENTIRE_SCRIPT_PARAM+'}', color: theme.palette.primary[100], type: EPhraseDetailsCustomPromptType.ENTIRE_SCRIPT, visible: false},

    {name: this.PHRASE_LIST_VAR, wrapQuotes: false,
      originalName: '{'+PhraseDetailsCustomPromptVars.PHRASE_LIST_PARAM+'}', color: theme.palette.primary[100], type: EPhraseDetailsCustomPromptType.PHRASE_LIST, visible: false},
    {name: '$SavedPhrases', wrapQuotes: false,
      originalName: '{'+PhraseDetailsCustomPromptVars.PHRASE_LIST_PARAM+'}', color: theme.palette.primary[100], type: EPhraseDetailsCustomPromptType.PHRASE_LIST, visible: true},
    {name: '$TranslatedPhrases', wrapQuotes: false,
      originalName: '{'+PhraseDetailsCustomPromptVars.TRANSLATED_PHRASE_LIST_PARAM+'}', color: theme.palette.primary[100], type: EPhraseDetailsCustomPromptType.PHRASE_LIST, visible: true},
  ]

  public static getList(all: boolean = false): IPhraseDetailsCustomPromptVar[] {
    return all ? PhraseDetailsCustomPromptVars.VARS : PhraseDetailsCustomPromptVars.VARS.filter(v => v.visible);
  }

  public static getListWithNameLenSort(all: boolean = false) {
    return [...this.getList(all)].sort((i1, i2) => {
      return i2.name.length - i1.name.length;
    });
  }

  public static getVarByType(type: EPhraseDetailsCustomPromptType): IPhraseDetailsCustomPromptVar | undefined {
    return PhraseDetailsCustomPromptVars.getList(true).find(v => v.type === type)
  }

  public static getVarListByType(type: EPhraseDetailsCustomPromptType): IPhraseDetailsCustomPromptVar[] {
    return PhraseDetailsCustomPromptVars.getList(true).filter(v => v.type === type)
  }

  public static isContextVarExist(prompt: string): boolean {
    return this.isVarExist(prompt, PhraseDetailsCustomPromptVars.CONTEXT_VAR);
  }

  public static isPhraseListVarExist(prompt: string): boolean {
    return this.isVarExist(prompt, PhraseDetailsCustomPromptVars.PHRASE_LIST_VAR);
  }

  public static isEntireScriptParamExist(prompt: string): boolean {
    return this.isVarExist(prompt, PhraseDetailsCustomPromptVars.ENTIRE_SCRIPT_PARAM);
  }

  private static isPhraseOrContextVarExist(prompt: string): boolean {
    return this.isVarExist(prompt, PhraseDetailsCustomPromptVars.PHRASE_VAR) || this.isVarExist(prompt, PhraseDetailsCustomPromptVars.CONTEXT_VAR);
  }

  public static canExec(selText: string, prompt: string): boolean {
    if (!prompt) return false;
    return !!selText || !PhraseDetailsCustomPromptVars.isPhraseOrContextVarExist(prompt);
  }

  private static isVarExist(prompt: string, variable: string): boolean {
    if (!prompt) return false;
    const regex = new RegExp('\\' + variable + '(?![a-zA-Z])', 'g'); // dont find phrase in phraseList
    const result = Array.from(prompt.matchAll(regex));

    return !!result.length;
  }

  public static getVarsInPrompt(prompt: string): string[] {
    const result = prompt.matchAll(this.PARAM_RE);
    const list = [];
    for (const item of result) {
      const paramName = item[1]?.trim();
      if (paramName) {
        list.push(paramName)
      }
    }
    return list;
  }
}



