export interface ElementHeightCalcParams {
  width: number;
  fontSize: number;
  lineHeight: number;
  fontFamily: string;
  fontWeight: string;
}
export class ElementHeightCalc {

  private context: CanvasRenderingContext2D;
  private params: ElementHeightCalcParams;

  public constructor(params: ElementHeightCalcParams) {
    this.params = {...params};
    const {fontFamily, fontSize, fontWeight, lineHeight} = params;

    const canvas = document.createElement('canvas');
    this.context = canvas.getContext('2d');
    this.context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
  }

  public getHeight(text: string) {
    const words = text.split(' ');
    let currentLine = '';
    let lines: string[] = [];

    for (let i = 0; i < words.length; i++) {
      const testLine = currentLine ? currentLine + ' ' + words[i] : words[i];
      const testWidth = this.context.measureText(testLine).width;

      // Check if adding this word exceeds the width
      if (testWidth > this.params.width && currentLine.length > 0) {
        // If it does, push the current line and start a new one
        lines.push(currentLine);
        currentLine = words[i];
      } else {
        // Otherwise, continue adding words to the current line
        currentLine = testLine;
      }
    }

    // Push the last line
    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    // Calculate the height based on the number of lines and line height
    const height = lines.length * this.params.lineHeight;

    return height;
  }

}