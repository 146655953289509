import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseNotesPopup } from '../components/PhraseNotesPopup';
import { IPhraseNotesPopupEvents, IPhraseNotesPopupFields } from '../components/types';
import { PhraseNotesPopupEvents } from './events';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IPhraseNotesPopupFields => {

		return {
			isShow: PhraseListSelectors.getIsNotePopup(state),
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IPhraseNotesPopupEvents => {
	return {
		onClose: () => dispatch(PhraseNotesPopupEvents.onClose()),
	}
}

export const PhraseNotesPopupHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PhraseNotesPopup);

PhraseNotesPopupHOC.displayName = 'PhraseNotesPopupHOC';
