import React from 'react';
import { Box, Stack } from '@mui/material';
import { IOneTimeTooltipSelectArrowProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { OneTimeTooltipSelectHOC } from '../../hocs/OneTimeTooltipSelectHOC/OneTimeTooltipSelectHOC';

export function OneTimeTooltipSelectArrow ({
	show,
}: IOneTimeTooltipSelectArrowProps) {
	if (!show) return null;

	const { isFullScreen, isMobile } = React.useContext(DeviceContext);

	return !isFullScreen && !isMobile && (
		<Stack
			gap={1}
			sx={{
				position: 'absolute',
				zIndex: 110,
				left: `calc(100% + ${theme.spacing(6)})`,
				top: `calc(100% + ${theme.spacing(10)})`,
				width: 1,
				pointerEvents: 'none',
				color: theme.palette.grey[300],
				fontSize: theme.typography.subtitle2,
				fontWeight: 400,
			}}
		>
			<ArrowLightLeftIcon
				sx={{
					position: 'absolute',
					ml: theme.spacing(-3),
				}}
			/>

			<Box>
				Select text in subtitles <br/>
				to translate and get explanations.
				<Box
					sx={{
						mt: 1,
						lineHeight: 1.7,
					}}
				>
					<Box
						component={'span'}
						sx={{
							color: theme.palette.grey[400],
						}}
					>Click and drag</Box> to select arbitrary text.<br/>
					<Box
						component={'span'}
						sx={{
							color: theme.palette.grey[400],
						}}
					>Double-click</Box> to select a word.<br/>
					<Box
						component={'span'}
						sx={{
							color: theme.palette.grey[400],
						}}
					>Single-click</Box> to select an entire line.

				</Box>
			</Box>

			<OneTimeTooltipSelectHOC />
		</Stack>	
	);
}
