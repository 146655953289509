import { TAuthUser } from '../store/general/types';
import { IUserRestResponse, UserRest } from '../../common/rest/user/userRest';
import { getDispatch, getState } from '../store';
import { getAuthUser, getAuthUserLangCode } from '../store/general/selectors';
import { ContentLibActions } from '../store/contentLib/actions';
import { getUserStateStorage } from '../../common/utils/local-storage/user-state';
import { getCopyGroupOnLogin } from '../store/general/selectors';
import { setAuthUserAction } from '../store/general/actions';
import { AuthManager } from '../../common/utils/auth-manager';
import { resetModelsAction } from '../store/models/actions';
import { EventsRouter } from '../../common/events/eventsRouter';
import { Events } from '../../common/events/types';
import { prepareAfterOpen } from './prepareAfterOpen';
import { UserGroupCopyRest } from '../../common/rest/userGroupCopy/userGroupCopyRest';
import { LoginUserEffects } from './loginUserEffects';
import { Navigator } from './navigator';
import { LangUtil } from '../../common/utils/lang-util';
import { getUserGroups } from '../store/models/selectors';
import { batch } from 'react-redux';

export type TNewUserCallback = (loginResult: IUserRestResponse, targetLangName: string, nativeLangName: string) => Promise<void>

export class AuthUserEffects {

  public static async updateUser(data: Partial<TAuthUser>) {
    let authUser = getAuthUser(getState());
    if (authUser) {
      await UserRest.updateUser(data);
      authUser = {...authUser, ...data};
      getDispatch()(setAuthUserAction(authUser));
    }
  }

  public static async setLang(langCode: string) {
    if (langCode && getAuthUserLangCode(getState()) !== langCode) {
      const dispatch = getDispatch();
      batch(() => {
        dispatch(ContentLibActions.setSearchFilter({ targetLangCode: langCode }));
        dispatch(ContentLibActions.setVideosSearchResult(null));
      });
      getUserStateStorage().clear();
      return this.updateUser({langCode});
    }
    return null;
  }

  public static async sendConfirmEmail() {
    let authUser = getAuthUser(getState());
    if (authUser) {
      const result: Partial<TAuthUser> = await UserRest.sendConfirmEmail();
      authUser = {...authUser, ...result};
      getDispatch()(setAuthUserAction(authUser));
    }
  }

  public static async loginProcess(newUserCallback: TNewUserCallback, targetLangName: string, nativeLangName: string, disableNavigateOnLogin = false): Promise<IUserRestResponse | null> {
    const dispatch = getDispatch();
    try {
      const result = await AuthManager.login();
      if (!result || !result.authUser)
        return null;
      dispatch(resetModelsAction());
      if (result.authUser.isNew) {
        EventsRouter.trackEvent(Events.AUTHORIZATION, new Date(), {
          userId: result.authUser.hashId
        });
        EventsRouter.trackEvent(Events.NEW_USER_SIGNED_UP, undefined, {
          email: result.authUser.email,
        });

        await newUserCallback(result, targetLangName, nativeLangName);

      } else {
        dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings, disableNavigateOnLogin));
      }
      return result;
    } catch (e) {
      console.log('err', e);
      return null;
    }
  }

  public static async newUserDefaultHandler(result: IUserRestResponse, targetLangName: string, nativeLangName: string) {
    const dispatch = getDispatch();
    const copyGroupData = getCopyGroupOnLogin(getState());
    if (copyGroupData && copyGroupData.hash) {
      dispatch(setAuthUserAction(result.authUser));
      const copyGroupInfo = await UserGroupCopyRest.getCopyInfo(copyGroupData.hash);
      if (copyGroupInfo.targetLangCode && copyGroupInfo.nativeLangCode) {
        if (copyGroupInfo.userId) {
          await AuthUserEffects.updateUser({inviteByUserId: copyGroupInfo.userId});
        }
        await LoginUserEffects.prepareLangsOnInstall(copyGroupInfo.nativeLangCode, copyGroupInfo.targetLangCode, '', false);
        dispatch(prepareAfterOpen(result.authUser, result.settings, result.appSettings));
        Navigator.openDefault();
        return;
      }
    }

    const targetLangCode = targetLangName && LangUtil.getLangCodeByName(targetLangName);
    const nativeLangCode = nativeLangName && LangUtil.getLangCodeByName(nativeLangName);

    await LoginUserEffects.prepareBeforeShowSelectLangs(result.authUser);
    if (targetLangCode && nativeLangCode && targetLangCode !== nativeLangCode) {
      await LoginUserEffects.prepareLangsOnInstall(nativeLangCode, targetLangCode, '',true);

      const groups = getUserGroups(getState());
      if (groups && groups.length && groups[0].videos.length) {
        const groupId = groups[0].id;
        const videoId = groups[0].videos[0].videoKey;
        Navigator.openVideo(groupId, videoId, undefined, true); // pause video because of tutorial
      } else {
        Navigator.openDefault();
      }
    } else {
      Navigator.openInstallSelectLangs();
    }
  }

}