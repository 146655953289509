import { AppThunk } from '../../../../../store/types';
import { setMainViewMode, setShowAddNewAudioPopupAction, setShowAddNewVideoPopupAction } from '../../../../../store/general/actions';
import { Navigator } from '../../../../../effects/navigator';
import { EMainViewMode } from '../../../../../store/general/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { getAuthUserLangCode } from '../../../../../store/general/selectors';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';

export class MyLibAddButtonsEvents {

	public static onAddVideoLib(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			Navigator.openContentLib();
			dispatch(setMainViewMode(EMainViewMode.CONTENT_LIBRARY));
			dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
		}
	}

	public static onAddVideoYT(
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setShowAddNewVideoPopupAction(true));
		}
	}

	public static onAddText(
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setShowAddNewAudioPopupAction(true))
		}
	}

	public static onLoad(
	): AppThunk {
		return (
			dispatch,
			getState
		) => {
			const state = getState();
			let targetLangCode = state.contentLib.searchFilter.targetLangCode || getAuthUserLangCode(state);
			if (!targetLangCode) return;

			ContentLibEffects.searchVideos({
				groupId: 0,
				searchText: '',
				targetLangCode,
				nativeLangCode: '',
				startIndex: 0,
				count: 1,
			}).then((result) => {
				if (result && result.length) {
					dispatch(ContentLibActions.setVideosSearchResult(result));
				}
			})
		}
	}

}