import * as React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { alpha, Button, Stack, Tooltip } from '@mui/material';
import { IPlaylistProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import { HintTooltip } from '../../../../../../common/components/HintTooltip/HintTooltip';
import { ListIcon } from '../../../../../../../icons/ListIcon';
import { PLAYLIST_TITLE } from './wordings';
import { PLAYLIST_SAVE_TEXT } from '../../../../../../common/wordings';

export function Playlist ({
	isPreview,
	isSmall,
	name,
	onClick,
	onSave,
}: IPlaylistProps) {
	if (!name && !isPreview) return null;

	const className = 'playlist';

	const [open, setOpen] = React.useState<any>({});
	React.useEffect(() => {
		setTimeout(() => setOpen({ open: true, }), 1000);
		setTimeout(() => setOpen({}), 6000);
	}, []);

	return isSmall
		? isPreview
			? <HintTooltip
				arrow
				placement={'left'}
				title={PLAYLIST_SAVE_TEXT}
				sx={{
					zIndex: 100,
				}}
				{...open}
				key={'open' + open?.open}
			>
				<Stack
					flexDirection={'row'}
					justifyContent={'center'}
					alignItems={'center'}
					className={className}
					gap={theme.spacing(2)}
					sx={{
						width: theme.spacing(4),
						height: theme.spacing(4),
						m: `0 !important`,
						color: theme.palette.common.white,
						background: theme.palette.error.main,
						borderRadius: '50%',
						cursor: 'pointer',
					}}
					onClick={onSave}
				>
					<ListIcon />
				</Stack>
			</HintTooltip>
			: <Tooltip
				placement={'left'}
				title={`Playlist: ${name}`}
			>
				<Stack
					flexDirection={'row'}
					justifyContent={'center'}
					alignItems={'center'}
					className={className}
					gap={theme.spacing(2)}
					sx={{
						width: theme.spacing(4),
						height: theme.spacing(4),
						m: `0 !important`,
						color: theme.palette.grey[400],
						borderRadius: '50%',
						cursor: 'pointer',
						transition: 'all .3s ease',
						
						'&:hover': {
							background: alpha(theme.palette.common.white, .75),
						},
					}}
					onClick={onClick}
				>
					<ListIcon />
				</Stack>
			</Tooltip>
		: isPreview
			? <Stack
				className={className}
				sx={{
					position: 'sticky',
					bottom: theme.spacing(-2),
					left: 0,
					right: 0,
					p: theme.spacing(0, 2),
					background: theme.palette.common.white,
					borderRadius: theme.spacing(.75),
					cursor: 'pointer',
				}}
				onClick={onSave}
			>
				<Text
					sx={{
						fontSize: '13px',
						fontWeight: 300,
						color: theme.palette.grey[700],
					}}
				>
					{PLAYLIST_SAVE_TEXT}
				</Text>
				<Button
					variant={'text'}
					sx={{
						display: 'inline-block',
						textDecoration: 'underline',
						textAlign: 'left',
					}}
				>
					Save
				</Button>
			</Stack>
			: <Stack
				className={className}
				sx={{
					position: 'sticky',
					bottom: theme.spacing(-2),
					left: 0,
					right: 0,
					p: theme.spacing(0, 2),
					background: theme.palette.common.white,
					borderRadius: theme.spacing(.75),
					cursor: 'pointer',
				}}
				onClick={onClick}
			>
				<Text
					sx={{
						fontSize: theme.typography.subtitle1,
						fontWeight: 600,
					}}
				>
					{PLAYLIST_TITLE}
				</Text>
				<Button
					sx={{
						display: 'block',
						lineHeight: 1,
						fontSize: theme.typography.subtitle2,
						fontWeight: 300,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						textAlign: 'left',
					}}
				>
					{name}
				</Button>
			</Stack>
};