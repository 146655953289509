import React from 'react';
import { alpha, Box, Button, IconButton, Menu, Stack, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../../../../../common/styles/theme';
import { Text } from '../../../../../../../common/components/Text/Text';
import { VideoIcon } from '../../../../../../../../icons/VideoIcon';
import { CommentIcon } from '../../../../../../../../icons/CommentIcon';
import { TrashIcon } from '../../../../../../../../icons/TrashIcon';
import { ConfirmPopup } from '../../../../ConfirmPopup/ConfirmPopup';
import { IEditPlaylistItemProps } from './types';
import { LangFlag } from '../../../../LangFlag/LangFlag';
import { TranslationsIcon } from '../../../../../../../../icons/TranslationsIcon';
import { PencilIcon } from '../../../../../../../../icons/PencilIcon';
import { ContentLibVideo } from '../../../../ContentLibrary/ContentLibVideo/components/ContentLibVideo';
import { LinkIcon } from '../../../../../../../../icons/LinkIcon';
import { PlusIcon } from '../../../../../../../../icons/PlusIcon';
import { IconMenuItem } from '../../../../../../../common/components/IconMenuItem/IconMenuItem';
import { YouTubeLabelIcon } from '../../../../../../../../icons/YouTubeLabelIcon';
import { ArrowDropIcon } from '../../../../../../../../icons/ArrowDropIcon';
import { AddNewVideoPopupHOC } from '../../../../AddNewVideoPopup/hocs/AddNewVideoPopupHOC';
import { PlayListLevelTitles } from '../../../PlayListLevelSelect/types';

export const EditPlaylistItem: React.FC<IEditPlaylistItemProps> = ({
	id,
	label,
	description,
	videoCount,
	phrasesCount,
	targetLangCode,
	nativeLangCode,
	publicGroup,
	videos,
	level,
	onDelete,
	onEdit,
	onCopyLink,
	onAddVideoLib,
	onAddVideoYT,
	onVideoClick,
	onVideoCopyLink,
	onVideoDelete,
}) => {
	const className = 'edit-playlist-item';

	const history = useHistory();
	const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);

	const handleDelete = () => {
		setAnchor(null);
		onDelete(history);
	};

	const [anchorAdd, setAnchorAdd] = React.useState<null | HTMLElement>(null);
	const [openAddYT, setOpenAddYT] = React.useState(false);
	const openAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorAdd(event.currentTarget);
	};
	const closeAdd = () => {
		setAnchorAdd(null);
	};

	const handleAddVideoYT = () => {
		closeAdd();
		onAddVideoYT();
		setOpenAddYT(true);
	};


	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [label]);

	const titleElement = <Box
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',
			lineHeight: `1.3 !important`,
			
			fontSize: theme.typography.h5,
			fontWeight: 600,
		}}
		ref={ref1}
	>
		<span
			ref={ref2}
		>
			{label}
		</span>
	</Box>

	const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: '80vw',
		},
	});

	const [isShowVideos, setShowVideos] = React.useState(false);
	const onClickVideos = () => {
		setShowVideos(!isShowVideos);
	}

	return (
		<Stack
			className={`${className} ${isShowVideos ? 'showvideos' : ''}`}
		>
			<Stack
				className={`${className}__vertical`}
				gap={.5}
				sx={{
					top: theme.spacing(-2),
					zIndex: 90,
					flex: `1 1 100%`,
					pt: theme.spacing(2),
					pb: theme.spacing(1),
					background: theme.palette.common.white,

					[`.${className}.showvideos &`]: {
						position: 'sticky',
					},
				}}
			>
				<Stack
					direction={'row'}
					gap={1}
					sx={{
						cursor: 'pointer',
					}}
					onClick={onEdit}
				>
					{isLongTitle
						? <NoMaxWidthTooltip
							title={label}
							enterDelay={1000}
						>
							{titleElement}	
						</NoMaxWidthTooltip>
						: titleElement
					}

					{description &&
						<Box
							sx={{
								display: '-webkit-box',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								'-webkit-line-clamp': '5',
								'-webkit-box-orient': 'vertical',
								maxHeight: '7.7em',
								lineHeight: `1.3 !important`,
								fontSize: theme.typography.subtitle2,
							}}
						>
							{description}
						</Box>
					}
				</Stack>

				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					gap={3}
					rowGap={0}
				>
					<Stack
						direction={'row'}
						gap={3}
						rowGap={0}
						flexWrap={'wrap'}
						alignItems={'center'}
					>
						<Stack
							direction={'row'}
							gap={1}
							sx={{
								'.lang-flag__code': {
									color: theme.palette.grey[500],
								},
							}}
						>
							<LangFlag code={targetLangCode} showCode />
							<LangFlag code={nativeLangCode} showCode />
						</Stack>

						<Stack
							direction='row'
							gap={theme.spacing(1)}
							alignItems={'center'}
							sx={{
								minWidth: theme.spacing(4.5),
							}}
						>
							<VideoIcon
								sx={{
									width: theme.spacing(2),
									color: theme.palette.grey[300]
								}}
							/>
							<Text
								sx={{ color: theme.palette.grey[500] }}
							>
								{videoCount}
							</Text>
						</Stack>

						<Stack
							direction='row'
							spacing={theme.spacing()}
							alignItems={'center'}
							sx={{
								minWidth: theme.spacing(4.5),
							}}
						>
							<CommentIcon
								sx={{
									width: theme.spacing(2),
									color: theme.palette.grey[300]
								}}
							/>
							<Text sx={{ color: theme.palette.grey[500] }}>
								{phrasesCount}
							</Text>
						</Stack>

						<Stack
							direction='row'
							alignItems={'center'}
							sx={{}}
						>
							<Button
								variant={'text'}
								startIcon={<PlusIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />}
								sx={{
									mr: theme.spacing(2),

									'.MuiButton-icon': {
										mr: 0,
									},
								}}
								onClick={openAdd}
								>
								Add
							</Button>

							<Menu
								anchorEl={anchorAdd}
								open={!!anchorAdd}
								onClose={closeAdd}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								sx={{
									'.MuiMenuItem-root': {
										pl: theme.spacing(.5),
									}
								}}
							>
								<IconMenuItem
									title={'Video from the library'}
									onClick={onAddVideoLib}
								>
									<VideoIcon />
								</IconMenuItem>
								<IconMenuItem
									title={'Video from youtube'}
									onClick={handleAddVideoYT}
								>
									<YouTubeLabelIcon />
								</IconMenuItem>
								<IconMenuItem
									props={{
										disabled: true,
									}}
									title={'Text or document'}
									onClick={()=>{}}
								>
								</IconMenuItem>
								<IconMenuItem
									props={{
										disabled: true,
									}}
									title={'Audio file'}
									onClick={()=>{}}
								>
									<Box
										sx={{
											color: theme.palette.common.black,
											fontSize: '8px',
											letterSpacing: '1px',
											whiteSpace: 'normal',
											background: 'yellow',
										}}
									>
										coming soon
									</Box>
								</IconMenuItem>
								<IconMenuItem
									props={{
										disabled: true,
									}}
									title={'External link'}
									onClick={()=>{}}
								>
								</IconMenuItem>
							</Menu>

							<Tooltip
								title={publicGroup ? 'Public playlist' : 'Edit playlist'}
							>
								<IconButton
									className={publicGroup ? 'active' : ''}
									onClick={onEdit}
									color={'primary'}
									sx={{
										color: theme.palette.grey[400],
										'&:hover, &:active': { color: theme.palette.primary.main },
										'&:focus': { color: theme.palette.grey[600] },
										'.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) },

										'&.active': {
											color: theme.palette.primary.main,
										},
									}}
								>
									{publicGroup
										? <TranslationsIcon />
										: <PencilIcon />
									}
								</IconButton>
							</Tooltip>

							<Tooltip
								title={'Share public link'}
							>
								<IconButton
									onClick={onCopyLink}
									color={'primary'}
									sx={{
										color: theme.palette.grey[400],
										'&:hover, &:active': { color: theme.palette.primary.main },
									}}
								>
									<LinkIcon />
								</IconButton>
							</Tooltip>

							<IconButton
								onClick={e => setAnchor(e.currentTarget)}
								color={'primary'}
								sx={{
									color: theme.palette.grey[400],
									'&:hover, &:active, &:focus': { color: theme.palette.error.main },
									'.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) }
								}}
							>
								<TrashIcon />
							</IconButton>

							<ConfirmPopup
								anchor={anchor}
								text={'Delete playlist?'}
								onConfirm={handleDelete}
								onClose={() => setAnchor(null)}
							/>
						</Stack>

						{!!level &&
							<Box
								sx={{
									mr: theme.spacing(1),
									p: theme.spacing(.25, .5),
									fontSize: theme.typography.fontSize,
									background: theme.palette.grey[50],
									borderRadius: theme.spacing(.5),
									cursor: 'pointer',
								}}
								onClick={onEdit}
							>
								{PlayListLevelTitles[level]}
							</Box>
						}
					</Stack>

					<Stack
						direction={'row'}
						justifyContent={'flex-end'}
					>
						<Button
							className={isShowVideos ? 'active' : ''}
							disabled={!videos || !videos.length}
							variant={'text'}
							startIcon={<ArrowDropIcon/>}
							onClick={onClickVideos}
							sx={{
								height: theme.spacing(4),
								pl: theme.spacing(2),
								color: theme.palette.grey[500],

								'&.active': {
									color: theme.palette.grey[700],

									'.MuiSvgIcon-root': {
										transform: `rotate(180deg)`,
									},
								},

								'&.Mui-disabled': {
									color: theme.palette.grey[200],
								},
							}}
						>
							{!videos || !videos.length
								? 'No items'
								: isShowVideos
									? 'Hide items'
									: 'Show items'
							}
						</Button>
					</Stack>

				</Stack>
			</Stack>

			<Box
				sx={{
					position: 'relative',
					display: 'grid',
					gridTemplateColumns: `repeat(5, 1fr)`,
					gap: 2,
					maxHeight: theme.spacing(20),
					overflow: 'hidden',

					'@media (min-width: 1600px)': {
						gridTemplateColumns: `repeat(7, 1fr)`,
					},

					'@media (max-width: 1000px)': {
						gridTemplateColumns: `repeat(3, 1fr)`,
					},

					'@media (max-width: 600px)': {
						gridTemplateColumns: `repeat(2, 1fr)`,
					},

					'&:after': {
						content: '""',
						position: 'absolute',
						left: 0,
						right: 0,
						bottom: 0,
						height: theme.spacing(4),
						background: `linear-gradient(0deg, ${theme.palette.common.white} 25%, ${alpha(theme.palette.common.white, 0)} 100%)`,
					},

					[`.${className}.showvideos &`]: {
						maxHeight: 'none',

						'&:after': {
							display: 'none',
						},
					},
				}}
			>
				{videos.map((video) => {
					return (
						<ContentLibVideo
							key={video.id}
							title={video.info.title}
							thumbUrl={video.info.thumb}
							channel={video.info.author}
							onClick={() => onVideoClick(video.videoKey)}
							onDelete={() => onVideoDelete(video.videoKey)}
							onGetCopyLink={() => onVideoCopyLink(video.videoKey)}
						/>
					)
				})}
			</Box>

			{openAddYT &&
				<AddNewVideoPopupHOC
					groupId={id}
					onClose={() => setOpenAddYT(false)}
				/>
			}
		</Stack>
	);
}
